import { Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material'
import { DateCalendar, DayCalendarSkeleton } from '@mui/x-date-pickers'
import { useEffect, useState } from 'react'
import AddAppointment from '../Appointment/AddAppointment'
import { Add } from '@mui/icons-material'
import { usePost } from '../../hooks/usePost'
// import Calendar from 'react-calendar'
// import 'react-calendar/dist/Calendar.css'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Appointment() {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  const [openAddAppointment, setOpenAddAppointment] = useState(false)

  const [refetch, setRefetch] = useState(false)

  const { data: appointments, mutate: fetchAppointments } = usePost(
    '/public/appointment/appointments/list'
  )

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchAppointments(dataP)
  }, [refetch])

  return (
    <Grid item xs={12} sm={6}>
      <Box sx={{ bgcolor: '#F4FAFF', height: '100%', borderRadius: 5, mr: 5 }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: 'divider',
            pt: 2,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label='basic tabs example'
          >
            <Tab
              label='My Appointment'
              {...a11yProps(0)}
              sx={{ fontWeight: 'bold' }}
            />
            <Tab
              label={`Client's`}
              {...a11yProps(1)}
              sx={{ fontWeight: 'bold' }}
            />
          </Tabs>
          <Box sx={{ height: '100%', px: 3 }}>
            <Button
              variant='outlined'
              sx={{ borderRadius: 5, bgcolor: '#E6F4FF' }}
              onClick={() => setOpenAddAppointment(true)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Add />
                <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                  New
                </Typography>
              </Box>
            </Button>
          </Box>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <DateCalendar
            disabled={true}
            disableHighlightToday
            renderLoading={() => <DayCalendarSkeleton />}
            // sx={{ width: '100%' }}
          />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <DateCalendar
            disabled={true}
            renderLoading={() => <DayCalendarSkeleton />}
          />
          {/* <Calendar
            onChange={onChange}
            value={val}
            style={{ width: '100%', height: '100%', backgrounnd: 'none' }}
          /> */}
        </CustomTabPanel>
      </Box>
      <AddAppointment
        open={openAddAppointment}
        close={() => setOpenAddAppointment(false)}
        refetch={refetch}
        setRefetch={setRefetch}
      />
    </Grid>
  )
}
