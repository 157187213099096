import { UseMutateFunction, useMutation } from 'react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { BASE_URL } from '../utils/config'
import { authHeader } from '../utils/auth'

interface MUTATE {
  mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, void, unknown>
  isLoading: boolean
  isError: boolean
  error: AxiosError | any
  isSuccess: boolean
  data: any
}

export function usePatch(endpoint: string) {
  const { mutate, isLoading, isError, error, isSuccess, data }: MUTATE =
    useMutation((data: any) => {
      console.log({ data })
      return axios.patch(
        data?.status && data?.id
          ? `${BASE_URL}${endpoint}/${data?.id}`
          : `${BASE_URL}${endpoint}`,
        data,
        {
          headers: authHeader(),
        }
      )
    })
  return { mutate, isLoading, isError, error, isSuccess, data }
}
