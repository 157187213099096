import { Message } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

export default function Alerts() {
  return (
    <Box sx={{ p: 5 }}>
      <Box
        sx={{
          py: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography
            sx={{ color: '#26335E', fontWeight: 'bold', fontSize: 17 }}
          >
            No Response on Appointment
          </Typography>
          <Typography sx={{ fontSize: 14 }}>
            Kidus Abba | 0912345678 | ababa@gmail.com | CRN#123
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ px: 1, py: 0.5, bgcolor: '#DEF0FF', borderRadius: 5 }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                System
              </Typography>
            </Box>
            <Typography sx={{ fontSize: 12 }}>
              Patient John Doe has a documented allergy to Penicillin. Please
              review the prescription for Penicillin VK
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              p: 1,
              bgcolor: '#F4FAFF',
              my: 2,
              borderRadius: 2,
            }}
          >
            <Typography sx={{ fontWeight: 'bold', color: '#26335E' }}>
              Note:
            </Typography>
            <Typography sx={{ color: '#26335E', fontSize: 12 }}>
              Patient didn’t show up on appointment
            </Typography>
          </Box>
        </Box>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>04:00</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          <Box sx={{ bgcolor: '#F4FAFF', borderRadius: 1, px: 2, py: 0.5 }}>
            <Typography sx={{ color: '#5AA7E9', fontWeight: 'bold' }}>
              Review
            </Typography>
          </Box>
          <Message sx={{ color: '#3376D6' }} />
        </Box>
      </Box>

      <Box
        sx={{
          py: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography
            sx={{ color: '#26335E', fontWeight: 'bold', fontSize: 17 }}
          >
            No Response on Appointment
          </Typography>
          <Typography sx={{ fontSize: 14 }}>
            Kidus Abba | 0912345678 | ababa@gmail.com | CRN#123
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ px: 1, py: 0.5, bgcolor: '#DEF0FF', borderRadius: 5 }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                System
              </Typography>
            </Box>
            <Typography sx={{ fontSize: 12 }}>
              Patient John Doe has a documented allergy to Penicillin. Please
              review the prescription for Penicillin VK
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              p: 1,
              bgcolor: '#F4FAFF',
              my: 2,
              borderRadius: 2,
            }}
          >
            <Typography sx={{ fontWeight: 'bold', color: '#26335E' }}>
              Note:
            </Typography>
            <Typography sx={{ color: '#26335E', fontSize: 12 }}>
              Patient didn’t show up on appointment
            </Typography>
          </Box>
        </Box>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>04:00</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          <Box sx={{ bgcolor: '#F4FAFF', borderRadius: 1, px: 2, py: 0.5 }}>
            <Typography sx={{ color: '#5AA7E9', fontWeight: 'bold' }}>
              Review
            </Typography>
          </Box>
          <Message sx={{ color: '#3376D6' }} />
        </Box>
      </Box>
      <Box
        sx={{
          py: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box>
          <Typography
            sx={{ color: '#26335E', fontWeight: 'bold', fontSize: 17 }}
          >
            No Response on Appointment
          </Typography>
          <Typography sx={{ fontSize: 14 }}>
            Kidus Abba | 0912345678 | ababa@gmail.com | CRN#123
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ px: 1, py: 0.5, bgcolor: '#DEF0FF', borderRadius: 5 }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                System
              </Typography>
            </Box>
            <Typography sx={{ fontSize: 12 }}>
              Patient John Doe has a documented allergy to Penicillin. Please
              review the prescription for Penicillin VK
            </Typography>
          </Box>
          <Box
            sx={{
              width: '100%',
              p: 1,
              bgcolor: '#F4FAFF',
              my: 2,
              borderRadius: 2,
            }}
          >
            <Typography sx={{ fontWeight: 'bold', color: '#26335E' }}>
              Note:
            </Typography>
            <Typography sx={{ color: '#26335E', fontSize: 12 }}>
              Patient didn’t show up on appointment
            </Typography>
          </Box>
        </Box>
        <Typography sx={{ fontSize: 18, fontWeight: 'bold' }}>04:00</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
          <Box sx={{ bgcolor: '#F4FAFF', borderRadius: 1, px: 2, py: 0.5 }}>
            <Typography sx={{ color: '#5AA7E9', fontWeight: 'bold' }}>
              Review
            </Typography>
          </Box>
          <Message sx={{ color: '#3376D6' }} />
        </Box>
      </Box>
    </Box>
  )
}
