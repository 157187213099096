import { Add, CloudUploadOutlined, Remove } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Slide,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useCSVReader } from 'react-papaparse'
import { usePost } from '../../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'

export default function NewIntake({
  open,
  close,
  refetch,
  setRefetch,
}: {
  open: boolean
  close: any
  refetch: boolean
  setRefetch: any
}) {
  const [type, setType] = useState<{ value: string[] }[]>([])
  const [program_id, setProgramId] = useState() as any
  const { data: programs, mutate: fetchPrograms } = usePost(
    '/public/configs/programs/list'
  )
  const { mutate, isLoading, isError, isSuccess, error } = usePost(
    '/public/configs/intakes'
  )
  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchPrograms(dataP)
  }, [])
  console.log({ programs, program_id })
  console.log({ type })

  type FormValues = {
    title: string
    intake_category: {
      title: string
      intake_category_fields: {
        field_label: string
        name: string
        type: string
        required: boolean
        values: string[]
        editable: boolean
        auditable: boolean
        include_in_report: boolean
        vital: boolean
        intake_additional_config:
          | {
              intake_category_field_value: string
              option?: {
                option: string
                is_task: boolean
                is_case_note: boolean
                due_date: string
              }
            }[]
      }[]
    }[]
  }

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      title: '',
      intake_category: [
        {
          title: '',
          intake_category_fields: [
            {
              field_label: '',
              include_in_report: false,
              name: '',
              type: '',
              required: false,
              values: [],
              editable: false,
              auditable: false,
              vital: false,
              intake_additional_config: [],
            },
          ],
        },
      ],
    },
    mode: 'onBlur',
  })
  console.log({ errors })

  const { fields, append, remove } = useFieldArray({
    name: 'intake_category',
    control,
  })

  console.log({ fields })

  const onSubmit = (data: any) => {
    data.program_id = program_id
    data?.intake_category?.map((item: any, index: number) => {
      item?.intake_category_fields?.map((item2: any, index2: number) => {
        data.intake_category[index].intake_category_fields[index2].values =
          item2?.intake_additional_config?.map(
            (item3: any) => item3?.intake_category_field_value
          )
      })
    })
    console.log({ data })
    mutate(data)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Intake Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      setValue('title', '')
      setValue('intake_category', [
        {
          title: '',
          intake_category_fields: [
            {
              name: '',
              type: '',
              required: false,
              values: [],
              editable: false,
              auditable: false,
              vital: false,
              field_label: '',
              include_in_report: false,
              intake_additional_config: [],
            },
          ],
        },
      ])
      setRefetch(!refetch)
      close()
    }
    if (isError) {
      console.log({ error })
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', xl: 'calc(100vw - 280px)' },
              height: '100vh',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  bgcolor: '#FBFBFB',
                  height: 60,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  px: 3,
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#EDEDED',
                    px: 3,
                    py: 0.8,
                    cursor: 'pointer',
                    borderRadius: 2,
                  }}
                  onClick={close}
                >
                  Back
                </Box>
                <Typography sx={{ fontWeight: 'bold' }}>New Intake</Typography>
                <Box> </Box>
              </Box>
              <Box
                sx={{
                  p: 3,
                  height: 'calc(100vh - 140px)',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                  <Box>
                    <Typography
                      sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                    >
                      Intake Title
                    </Typography>
                    <TextField
                      size='small'
                      sx={{ width: 400 }}
                      {...register('title', {
                        required: true,
                      })}
                    />
                  </Box>
                  <Box>
                    <Typography
                      sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                    >
                      Program
                    </Typography>
                    <Autocomplete
                      options={programs?.data?.programs || []}
                      getOptionLabel={(item: any) => item?.name}
                      size='small'
                      fullWidth
                      sx={{ width: 250 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size='small'
                          fullWidth
                          sx={{ bgcolor: 'white' }}
                        />
                      )}
                      onChange={(event, value: any) => {
                        setProgramId(value?.id)
                      }}
                    />
                  </Box>
                </Box>
                {fields?.map((field, index) => (
                  <Box sx={{ p: 3 }}>
                    <Divider sx={{ bgcolor: '#006CEA', height: 2, mb: 3 }} />
                    <Typography
                      sx={{ fontWeight: 'bold', fontSize: 13, py: 0.5 }}
                    >
                      CATEGORY TITLE
                    </Typography>
                    <TextField
                      size='small'
                      sx={{ width: 300 }}
                      {...register(`intake_category.${index}.title`, {
                        required: true,
                      })}
                    />
                    {field?.intake_category_fields?.map((item, index2) => (
                      <Box key={index2}>
                        <Box sx={{ display: 'flex' }}>
                          <Box>
                            <Box
                              sx={{
                                my: 2,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 3,
                              }}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    py: 0.5,
                                  }}
                                >
                                  Question Label
                                </Typography>
                                <TextField
                                  size='small'
                                  sx={{ width: 300 }}
                                  {...register(
                                    `intake_category.${index}.intake_category_fields.${index2}.field_label`,
                                    { required: true }
                                  )}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    py: 0.5,
                                  }}
                                >
                                  Question
                                </Typography>
                                <TextField
                                  size='small'
                                  sx={{ width: 300 }}
                                  {...register(
                                    `intake_category.${index}.intake_category_fields.${index2}.name`,
                                    { required: true }
                                  )}
                                />
                              </Box>

                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    py: 0.5,
                                  }}
                                >
                                  Question Type
                                </Typography>
                                <Autocomplete
                                  options={[
                                    'Text',
                                    'Dropdown',
                                    'Checkbox',
                                    'Radio',
                                    'Date',
                                  ]}
                                  getOptionLabel={(item) => item}
                                  size='small'
                                  fullWidth
                                  sx={{ width: 250 }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size='small'
                                      fullWidth
                                      sx={{ bgcolor: 'white' }}
                                      {...register(
                                        `intake_category.${index}.intake_category_fields.${index2}.type`,
                                        {
                                          required: true,
                                        }
                                      )}
                                    />
                                  )}
                                  onChange={(event, value: any) => {
                                    setType((prev) => {
                                      let newP = [...prev]
                                      if (newP[index]) {
                                        newP[index].value[index2] = value
                                      } else {
                                        newP[index] = { value: [value] }
                                      }
                                      return newP
                                    })

                                    if (
                                      value === 'Dropdown' ||
                                      value === 'Radio' ||
                                      value === 'Checkbox'
                                    ) {
                                      const updatedFields = [...fields]
                                      updatedFields[
                                        index
                                      ].intake_category_fields[
                                        index2
                                      ].intake_additional_config = [
                                        {
                                          intake_category_field_value: '',
                                        },
                                      ]
                                      setValue('intake_category', updatedFields)
                                    } else {
                                      const updatedFields = [...fields]
                                      updatedFields[
                                        index
                                      ].intake_category_fields[
                                        index2
                                      ].intake_additional_config = []
                                      setValue('intake_category', updatedFields)
                                    }
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Box sx={{ m: 2, flexGrow: 1 }}>
                            {field?.intake_category_fields?.[
                              index2
                            ]?.intake_additional_config?.map(
                              (item3, index3) => (
                                <Box>
                                  <Box>
                                    <Grid container>
                                      <Grid item xs={12} md={3.7}>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            gap: 2,
                                          }}
                                        >
                                          <Box>
                                            <Typography
                                              sx={{
                                                fontWeight: 'bold',
                                                fontSize: 12,
                                                py: 0.5,
                                              }}
                                            >
                                              {type?.[index]?.value?.[
                                                index2
                                              ]?.toLowerCase()}{' '}
                                              {index3 + 1}
                                            </Typography>
                                            <TextField
                                              size='small'
                                              // sx={{ width: 300 }}
                                              {...register(
                                                `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.intake_category_field_value`,
                                                {
                                                  required: true,
                                                }
                                              )}
                                            />
                                          </Box>
                                          {field?.intake_category_fields?.[
                                            index2
                                          ]?.intake_additional_config?.length >
                                            1 && (
                                            <IconButton
                                              sx={{
                                                bgcolor: '#BCBCBC',
                                                '&:hover': {
                                                  bgcolor: '#BCBCBC',
                                                },
                                              }}
                                              onClick={() => {
                                                // setType((prev) =>
                                                //   prev?.filter(
                                                //     (item, index5) => index5 !== index2
                                                //   )
                                                // )
                                                const updatedFields = [
                                                  ...fields,
                                                ]

                                                updatedFields[
                                                  index
                                                ].intake_category_fields[
                                                  index2
                                                ].intake_additional_config =
                                                  updatedFields[
                                                    index
                                                  ].intake_category_fields[
                                                    index2
                                                  ].intake_additional_config.filter(
                                                    (_, index5) =>
                                                      index5 !== index3
                                                  )

                                                setValue(
                                                  'intake_category',
                                                  updatedFields
                                                )
                                              }}
                                            >
                                              <Remove sx={{ color: 'white' }} />
                                            </IconButton>
                                          )}
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} md={0.8}>
                                        <Divider
                                          orientation='vertical'
                                          sx={{ mx: 2, height: 80, mt: 1 }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={7.5}>
                                        <Box>
                                          {item3?.option ? (
                                            <Box>
                                              <Typography
                                                sx={{
                                                  fontWeight: 'bold',
                                                  fontSize: 12,
                                                }}
                                              >
                                                Option
                                              </Typography>
                                              <Box
                                                sx={{ display: 'flex', gap: 2 }}
                                              >
                                                <Box>
                                                  <textarea
                                                    style={{
                                                      width: '100%',
                                                      height: 80,
                                                      borderRadius: 8,
                                                      resize: 'none',
                                                    }}
                                                    {...register(
                                                      `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.option`
                                                    )}
                                                  />
                                                  <Box
                                                    sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent:
                                                        'space-between',
                                                    }}
                                                  >
                                                    <FormControlLabel
                                                      name='addtocaseplan'
                                                      label={
                                                        'Add to Care Plan Notes'
                                                      }
                                                      slotProps={{
                                                        typography: {
                                                          fontSize: 11,
                                                          color: '#8D8D8D',
                                                          fontWeight: 'bold',
                                                        },
                                                      }}
                                                      control={
                                                        <Checkbox
                                                          {...register(
                                                            `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.is_case_note`
                                                          )}
                                                        />
                                                      }
                                                    />
                                                    <FormControlLabel
                                                      name='addtotodolist'
                                                      // label={'Add to To do List Due is '}
                                                      label={
                                                        <Typography
                                                          sx={{
                                                            fontSize: 11,
                                                            color: '#A0A0A0',
                                                            fontWeight: 'bold',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 12,
                                                              color: '#596B86',
                                                            }}
                                                          >
                                                            Add to To do List
                                                          </span>
                                                          <br />
                                                          Due in Bussines Days
                                                        </Typography>
                                                      }
                                                      slotProps={{
                                                        typography: {
                                                          fontSize: 12,
                                                          color: '#485B78',
                                                          fontWeight: 'bold',
                                                        },
                                                      }}
                                                      control={
                                                        <Checkbox
                                                          {...register(
                                                            `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.is_task`,
                                                            {
                                                              required: true,
                                                            }
                                                          )}
                                                          checked={true}
                                                        />
                                                      }
                                                    />
                                                    <TextField
                                                      type='number'
                                                      size='small'
                                                      sx={{ width: 80 }}
                                                      {...register(
                                                        `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.due_date`
                                                      )}
                                                    />
                                                  </Box>
                                                </Box>

                                                <IconButton
                                                  sx={{
                                                    bgcolor: '#BCBCBC',
                                                    '&:hover': {
                                                      bgcolor: '#BCBCBC',
                                                    },
                                                    width: 30,
                                                    height: 30,
                                                  }}
                                                  onClick={() => {
                                                    const updatedFields = [
                                                      ...fields,
                                                    ]
                                                    delete updatedFields[index]
                                                      .intake_category_fields[
                                                      index2
                                                    ].intake_additional_config[
                                                      index3
                                                    ].option

                                                    setValue(
                                                      'intake_category',
                                                      updatedFields
                                                    )
                                                  }}
                                                >
                                                  <Remove
                                                    sx={{ color: 'white' }}
                                                  />
                                                </IconButton>
                                              </Box>
                                            </Box>
                                          ) : (
                                            <Typography
                                              sx={{
                                                fontWeight: 'bold',
                                                fontSize: 12,
                                              }}
                                            >
                                              You can add options to show after
                                              selection
                                            </Typography>
                                          )}

                                          {!item3?.option && (
                                            <IconButton
                                              sx={{
                                                bgcolor: 'primary.main',
                                                '&:hover': {
                                                  bgcolor: 'primary.main',
                                                },
                                                width: 30,
                                                height: 30,
                                              }}
                                              onClick={() => {
                                                const updatedFields = [
                                                  ...fields,
                                                ]
                                                updatedFields[
                                                  index
                                                ].intake_category_fields[
                                                  index2
                                                ].intake_additional_config[
                                                  index3
                                                ].option = {
                                                  due_date: '',
                                                  is_case_note: false,
                                                  is_task: false,
                                                  option: '',
                                                }

                                                setValue(
                                                  'intake_category',
                                                  updatedFields
                                                )
                                              }}
                                            >
                                              <Add sx={{ color: 'white' }} />
                                            </IconButton>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Divider sx={{ my: 2 }} />
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 2,
                                    }}
                                  >
                                    {index3 ===
                                      field?.intake_category_fields?.[index2]
                                        ?.intake_additional_config?.length -
                                        1 && (
                                      <IconButton
                                        sx={{
                                          bgcolor: 'primary.main',
                                          '&:hover': {
                                            bgcolor: 'primary.main',
                                          },
                                        }}
                                        onClick={() => {
                                          const updatedFields = [...fields]
                                          updatedFields[
                                            index
                                          ].intake_category_fields[
                                            index2
                                          ].intake_additional_config = [
                                            ...updatedFields[index]
                                              .intake_category_fields[index2]
                                              .intake_additional_config,
                                            {
                                              intake_category_field_value: '',
                                            },
                                          ]
                                          setValue(
                                            'intake_category',
                                            updatedFields
                                          )
                                        }}
                                      >
                                        <Add sx={{ color: 'white' }} />
                                      </IconButton>
                                    )}
                                  </Box>
                                </Box>
                              )
                            )}
                          </Box>
                        </Box>
                        <Divider />
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            gap: 2,
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box>
                              <FormControlLabel
                                name='required'
                                label={'Required'}
                                control={
                                  <Checkbox
                                    {...register(
                                      `intake_category.${index}.intake_category_fields.${index2}.required`
                                    )}
                                  />
                                }
                              />
                              <FormControlLabel
                                name='vital'
                                label={'Vital'}
                                control={
                                  <Checkbox
                                    {...register(
                                      `intake_category.${index}.intake_category_fields.${index2}.vital`
                                    )}
                                  />
                                }
                              />
                              <FormControlLabel
                                name='editable'
                                label={'Editable'}
                                control={
                                  <Checkbox
                                    {...register(
                                      `intake_category.${index}.intake_category_fields.${index2}.editable`
                                    )}
                                  />
                                }
                              />
                              <FormControlLabel
                                name='auditable'
                                label={'Save History'}
                                control={
                                  <Checkbox
                                    {...register(
                                      `intake_category.${index}.intake_category_fields.${index2}.auditable`
                                    )}
                                  />
                                }
                              />
                              <FormControlLabel
                                name='include_in_report'
                                label={'Include in report'}
                                control={
                                  <Checkbox
                                    {...register(
                                      `intake_category.${index}.intake_category_fields.${index2}.include_in_report`
                                    )}
                                  />
                                }
                              />
                            </Box>
                          </Box>
                          {field?.intake_category_fields?.length > 1 && (
                            <Box
                              sx={{
                                bgcolor: '#BBC4DD',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 1,
                                width: 200,
                                py: 1,
                                my: 2,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                // setType((prev) =>
                                //   prev?.filter(
                                //     (item, index5) => index5 !== index2
                                //   )
                                // )
                                const updatedFields = [...fields]

                                console.log({ index2 })

                                updatedFields[index].intake_category_fields =
                                  updatedFields[
                                    index
                                  ].intake_category_fields.filter(
                                    (_, index3) => index3 !== index2
                                  )

                                console.log({
                                  1: updatedFields[index]
                                    .intake_category_fields,
                                })

                                setValue('intake_category', updatedFields)
                              }}
                            >
                              <Remove sx={{ color: 'white' }} />
                              <Typography sx={{ color: 'white' }}>
                                Remove Field
                              </Typography>
                            </Box>
                          )}
                          {index2 ===
                            field?.intake_category_fields?.length - 1 && (
                            <Box
                              sx={{
                                bgcolor: '#F4FAFF',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 1,
                                width: 200,
                                py: 1,
                                my: 2,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                const updatedFields = [...fields]
                                updatedFields[index].intake_category_fields = [
                                  ...updatedFields[index]
                                    .intake_category_fields,
                                  {
                                    name: '',
                                    type: '',
                                    required: false,
                                    values: [],
                                    editable: false,
                                    auditable: false,
                                    vital: false,
                                    field_label: '',
                                    include_in_report: false,
                                    intake_additional_config: [],
                                  },
                                ]
                                setValue('intake_category', updatedFields)
                              }}
                            >
                              <Add sx={{ color: 'primary.main' }} />
                              <Typography sx={{ color: 'primary.main' }}>
                                Add New Field
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    ))}
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',

                        gap: 2,
                      }}
                    >
                      {fields.length > 1 && (
                        <Box
                          sx={{
                            bgcolor: '#BBC4DD',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                            width: 200,
                            py: 1,
                            my: 2,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            remove(index)
                          }}
                        >
                          <Remove sx={{ color: 'white' }} />
                          <Typography sx={{ color: 'white' }}>
                            Remove
                          </Typography>
                        </Box>
                      )}
                      {index === fields.length - 1 && (
                        <Box
                          sx={{
                            bgcolor: '#F4FAFF',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                            width: 200,
                            py: 1,
                            my: 2,
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            append({
                              title: '',
                              intake_category_fields: [
                                {
                                  name: '',
                                  type: '',
                                  required: false,
                                  values: [],
                                  editable: false,
                                  auditable: false,
                                  vital: false,
                                  field_label: '',
                                  include_in_report: false,
                                  intake_additional_config: [],
                                },
                              ],
                            })
                          }
                        >
                          <Add sx={{ color: 'primary.main' }} />
                          <Typography sx={{ color: 'primary.main' }}>
                            Add New Catgory
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  bottom: 0,
                  width: { xs: '100vw', md: 'calc(100vw - 280px)' },
                  height: 80,
                  py: 3,
                  px: 5,
                }}
              >
                <Button
                  variant='contained'
                  // onClick={handleSubmit}
                  type='submit'
                  sx={{ px: 4, py: 2.5 }}
                >
                  {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress sx={{ color: 'white' }} size={20} />
                      <Typography>submitting...</Typography>
                    </Box>
                  ) : (
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Submit Form
                    </Typography>
                  )}
                </Button>
              </Box>
            </form>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
