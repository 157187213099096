import { getUser } from '../../utils/auth'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

export function CheckAuth({ children }: any) {
  const user = getUser()
  const navigate = useNavigate()
  const location = useLocation()
  console.log({ location })

  useEffect(() => {
    if (!user) {
      navigate('/login')
    }
  }, [navigate, user])
  return children
}
