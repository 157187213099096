import { Modal } from '@mui/material'
import AICard from './AICard'

export default function AIAssistant({
  open,
  close,
}: {
  open: boolean
  close: any
}) {
  return (
    <Modal open={open} onClose={close}>
      <AICard close={close} />
    </Modal>
  )
}
