import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import FileUpload from '../File/FileUpload'
import { DatePicker } from '@mui/x-date-pickers'
import { Add, Close, Remove } from '@mui/icons-material'
import { useForm } from 'react-hook-form'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
export default function AddTask({
  open,
  close,
}: {
  open: boolean
  close: any
}) {
  const [subTasks, setSubTasks] = useState<string[]>([''])
  const [dueDate, setDueDate] = useState() as any
  const [taskParticipants, setTaskParticipants] = useState() as any
  const [priority, setPriority] = useState('HIGH') as any

  const { data: clientData, mutate: fetchParticipants } =
    usePost('/public/clients')
  console.log({ clientData })
  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchParticipants(datap)
  }, [])
  const { mutate, isLoading, isError, isSuccess, error } = usePost(
    '/public/goal/create_task_without_goal_id'
  )
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Task Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      // setRefetch(!refetch)
      close()
    }
    if (isError) {
      console.log({ error })
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  type FormValues = {
    title: string
    description: string
    dueDate: string
    task_participant_ids: string[]
    priority: string
    subtasks: string[]
    type: string
  }

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      title: '',
      description: '',
      dueDate: '',
      task_participant_ids: [],
      priority: '',
      subtasks: [''],
      type: 'task',
    },
  })

  const onSubmit = (data: any) => {
    const d: any = {
      title: data.title,
      description: data.description,
      dueDate: dueDate?.$d?.toISOString()?.replace('.000', ''),
      task_participant_ids: taskParticipants?.map((item: any) => item.id),
      priority: priority,
      subtasks: subTasks,
      type: 'task',
    }
    console.log({ d })
    mutate(d)
  }

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 500 },
              height: '100vh',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  bgcolor: '#FBFBFB',
                  height: 60,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  px: 3,
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#EDEDED',
                    px: 3,
                    py: 0.8,
                    cursor: 'pointer',
                    borderRadius: 2,
                  }}
                  onClick={close}
                >
                  Back
                </Box>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Add New Task
                </Typography>
                <Box> </Box>
              </Box>
              <Divider />
              <Box
                sx={{
                  p: 6,
                  height: 'calc(100vh - 140px)',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                }}
              >
                <Box>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Task Title
                  </Typography>
                  <TextField
                    size='small'
                    fullWidth
                    {...register('title', {
                      required: true,
                    })}
                  />
                </Box>

                <Box sx={{ my: 2 }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Task Description
                  </Typography>
                  <textarea
                    style={{
                      borderRadius: 10,
                      borderColor: 'gray',
                      resize: 'none',
                      width: '100%',
                      height: 100,
                    }}
                    {...register('description', {
                      required: true,
                    })}
                  />
                </Box>

                <Box sx={{ my: 2 }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Due Date
                  </Typography>
                  <DatePicker
                    value={dueDate}
                    onChange={(newValue) => setDueDate(newValue)}
                    sx={{ width: '100%' }}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Persons(s)
                  </Typography>
                  <Autocomplete
                    options={clientData?.data?.clients || []}
                    getOptionLabel={(item: any) =>
                      item.firstName + ' ' + item?.lastName
                    }
                    size='small'
                    multiple
                    onChange={(event, value) => {
                      setTaskParticipants(value)
                    }}
                    renderTags={(value, getTagProps) => (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        {value.map((option, index) => (
                          <Box
                            sx={{
                              bgcolor: '#F4FAFF',
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              border: '1px solid #ECEDED',
                              px: 1,
                              py: 0.5,
                              borderRadius: 1.5,
                            }}
                          >
                            <Avatar sx={{ width: 20, height: 20 }} />
                            <Typography
                              sx={{
                                fontWeight: 'bold',
                                fontSize: 12,
                              }}
                            >
                              {option?.firstName?.split(' ')[0]}
                            </Typography>
                            <IconButton
                              sx={{
                                border: '1px solid gray',
                                width: 16,
                                height: 16,
                              }}
                              onClick={() =>
                                getTagProps({ index })?.onDelete(option)
                              }
                            >
                              <Close
                                sx={{
                                  width: 13,
                                  height: 13,
                                  color: 'gray',
                                }}
                              />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    )}
                    renderOption={(props, option) => (
                      <Box
                        component='li'
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 6,
                          width: '100%',
                        }}
                        {...props}
                      >
                        <Avatar sx={{ width: 45, height: 45 }} />

                        <Typography sx={{ width: 200 }}>
                          {option?.firstName + ' ' + option?.lastName}
                        </Typography>
                        <Box
                          sx={{
                            bgcolor: '#F4FAFF',
                            px: 1,
                            borderRadius: 5,
                          }}
                        >
                          <Typography
                            sx={{
                              color: '#4D72A6',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              fontSize: 12,
                            }}
                          >
                            Client
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} size='small' fullWidth />
                    )}
                  />
                </Box>
                <Box sx={{ my: 2 }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Priority
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      borderRadius: 1,
                    }}
                  >
                    <FormControl sx={{ display: 'flex' }}>
                      <RadioGroup
                        value={priority}
                        onChange={(e) => {
                          setPriority(e.target.value)
                        }}
                        row
                        name='taskpriority'
                      >
                        <FormControlLabel
                          value='HIGH'
                          control={
                            <Radio
                              sx={{
                                color: priority === 'HIGH' ? '#fff' : '#5E7FA4',
                                '&.MuiButtonBase-root': {
                                  padding: '0px',
                                  marginRight: '5px',
                                  '&.Mui-checked': {
                                    color: '#fff',
                                  },
                                },
                              }}
                            />
                          }
                          label='HIGH'
                          sx={{
                            px: 3,
                            py: 1,
                            // marginRight: '0',
                            // marginTop: '90px',
                            background:
                              priority === 'HIGH' ? '#0A81F5' : '#F7F7F7',
                            color: priority === 'HIGH' ? '#fff' : '#000',
                            borderRadius: '10px',
                            // height: '35px',
                          }}
                        />
                        <FormControlLabel
                          value='MEDIUM'
                          control={
                            <Radio
                              sx={{
                                color:
                                  priority === 'MEDIUM' ? '#fff' : '#5E7FA4',
                                '&.MuiButtonBase-root': {
                                  padding: '0px',
                                  marginRight: '5px',
                                  '&.Mui-checked': {
                                    color: '#fff',
                                  },
                                },
                              }}
                            />
                          }
                          label='MEDIUM'
                          sx={{
                            px: 2,
                            //   marginRight: '0',
                            //   marginTop: '15px',
                            background:
                              priority === 'MEDIUM' ? '#0A81F5' : '#F7F7F7',
                            color: priority === 'MEDIUM' ? '#fff' : '#000',
                            borderRadius: '10px',
                            //   height: '35px',
                          }}
                        />
                        <FormControlLabel
                          value='LOW'
                          control={
                            <Radio
                              sx={{
                                color: priority === 'LOW' ? '#fff' : '#5E7FA4',
                                '&.MuiButtonBase-root': {
                                  padding: '0px',
                                  marginRight: '5px',
                                  '&.Mui-checked': {
                                    color: '#fff',
                                  },
                                },
                              }}
                            />
                          }
                          label='LOW'
                          sx={{
                            px: 2,
                            //   marginRight: '0',
                            //   marginTop: '15px',
                            background:
                              priority === 'LOW' ? '#0A81F5' : '#F7F7F7',
                            color: priority === 'LOW' ? '#fff' : '#000',
                            borderRadius: '10px',
                            //   height: '35px',
                          }}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
                <Box sx={{ px: 1 }}>
                  <Typography sx={{ fontSize: 11, fontWeight: 'bold' }}>
                    Sub Task
                  </Typography>
                  {subTasks?.map((item, index2) => (
                    // ))}
                    // {subCount?.map((item, index) => (
                    <Box sx={{ py: 2 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          width: '100%',
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: 11,
                            fontWeight: 'bold',
                          }}
                        >
                          Sub
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: 11,
                            fontWeight: 'bold',
                          }}
                        >
                          {' '}
                          Task
                        </Typography>
                        <TextField
                          size='small'
                          fullWidth
                          InputProps={{
                            sx: {
                              '&.MuiOutlinedInput-root': {
                                borderRadius: 8,
                              },
                            },
                          }}
                          value={subTasks?.[index2]}
                          onChange={(e) => {
                            setSubTasks((prevValues) => {
                              const newValues = [...prevValues]
                              newValues[index2] = e.target.value
                              return newValues
                            })
                          }}
                        />
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                          }}
                        >
                          {subTasks?.length > 1 && (
                            <IconButton
                              sx={{
                                bgcolor: 'error.main',
                                '&:hover': {
                                  color: 'white',
                                  bgcolor: 'error.main',
                                },
                              }}
                              onClick={() =>
                                setSubTasks((prevValues) => {
                                  let newValues = [...prevValues]
                                  newValues = newValues?.filter(
                                    (elt, ind) => ind !== index2
                                  )
                                  console.log({ newValues })
                                  return newValues
                                })
                              }
                            >
                              <Remove sx={{ color: 'white' }} />
                            </IconButton>
                          )}
                          {index2 === subTasks?.length - 1 && (
                            <IconButton
                              sx={{
                                bgcolor: 'primary.main',
                                '&:hover': {
                                  color: 'white',
                                  bgcolor: 'primary.main',
                                },
                              }}
                              onClick={() =>
                                setSubTasks((prevValues) => {
                                  const newValues = [...prevValues]
                                  newValues?.push('')
                                  return newValues
                                })
                              }
                            >
                              <Add sx={{ color: 'white' }} />
                            </IconButton>
                          )}
                        </Box>
                      </Box>
                    </Box>
                  ))}
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  bottom: 0,
                  width: { xs: '100vw', sm: 500 },
                  height: 80,
                  py: 3,
                  px: 5,
                }}
              >
                <Button
                  variant='contained'
                  type='submit'
                  sx={{ px: 4, py: 2.5 }}
                >
                  {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress sx={{ color: 'white' }} size={20} />
                      <Typography>submitting...</Typography>
                    </Box>
                  ) : (
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Create Task
                    </Typography>
                  )}
                </Button>
              </Box>
            </form>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
