import styled from '@emotion/styled'
import { Check } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  stepConnectorClasses,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useFetch } from '../../hooks/useFetch'
import { DatePicker } from '@mui/x-date-pickers'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import { useNavigate } from 'react-router-dom'

export default function ClientStepper({
  clientId,
  close,
  refetch,
  setRefetch,
}: {
  clientId: string
  close: any
  refetch: boolean
  setRefetch: any
}) {
  const navigate = useNavigate()
  const { data: client } = useFetch(
    'fetchprogram',
    `/public/clients/${clientId}`
    // '/public/clients/fae8af51-2a4f-4498-b9e1-2574b5eda97a'
  )

  console.log({ client })

  // const { data: intake, refetch } = useFetch(
  //   'fetchprogram',
  //   `/public/configs/intakes/${intakeId}`
  // )

  const QontoConnector = styled(StepConnector)(({ theme }: any) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 20,
      left: 'calc(-50% + 25px)',
      right: 'calc(50% + 25px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }))

  const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
    ({ theme, ownerState }: any) => ({
      color:
        theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
      display: 'flex',
      height: 40,
      alignItems: 'center',
      ...(ownerState.active && {
        color: '#784af4',
        border: '1px solid #784af4',
        width: 40,
        justifyContent: 'center',
        borderRadius: '100%',
      }),
      '& .QontoStepIcon-completedIcon': {
        color: 'white',
        zIndex: 1,
        fontSize: 18,
      },
      '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
      },
    })
  )

  function QontoStepIcon(props: StepIconProps) {
    const { active, completed, className } = props

    return (
      <QontoStepIconRoot ownerState={{ active }} className={className}>
        {completed ? (
          <Box
            sx={{
              bgcolor: '#784af4',
              width: 40,
              height: 40,
              borderRadius: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Check className='QontoStepIcon-completedIcon' />
          </Box>
        ) : (
          <div className='QontoStepIcon-circle' />
        )}
      </QontoStepIconRoot>
    )
  }

  const intakeSteps: string[] = []

  client?.client?.intake?.intakeCategory?.map((item: any) => {
    intakeSteps?.push(item?.title)
  })

  const programSteps: string[] = []

  client?.client?.programs?.map((item: any) => {
    programSteps?.push(item?.title)
  })

  const steps = [...(intakeSteps || []), ...(programSteps || [])]

  const [activeStep, setActiveStep] = useState(0)
  const [completed, setCompleted] = useState<{
    [k: number]: boolean
  }>({})
  const totalSteps = () => {
    return steps.length
  }

  const completedSteps = () => {
    return Object.keys(completed).length
  }

  const isLastStep = () => {
    return activeStep === totalSteps() - 1
  }

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps()
  }

  const handleNext = () => {
    // onSubmit()
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1
    setActiveStep(newActiveStep)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleStep = (step: number) => () => {
    setActiveStep(step)
  }

  const handleComplete = () => {
    const newCompleted = completed
    newCompleted[activeStep] = true
    setCompleted(newCompleted)
    handleNext()
  }

  const stepperItems = [
    ...(client?.client?.intake?.intakeCategory || []),
    ...(client?.client?.programs || []),
  ]

  console.log({ stepperItems })

  const [intakeValues, setIntakeValues] = useState<
    { intake_category_field_id: string; values: string[] }[]
  >([])
  const [programValues, setProgramValues] = useState<
    { program_field_id: string; values: string[] }[]
  >([])
  const [programId, setProgramId] = useState() as any

  const { mutate, isSuccess, isError, error, isLoading } = usePost(
    `/public/configs/intakes/${clientId}/${client?.client?.intake?.id}`
  )
  const [isDraft, setIsDraft] = useState(false)

  console.log({ programId, programValues })

  const {
    mutate: submitPrograms,
    isError: isPError,
    isLoading: isPLoading,
    isSuccess: isPSuccess,
    error: perror,
  } = usePost(`/public/configs/programs/${clientId}/${programId}`)

  const onSubmit = () => {
    if (programId) {
      const data: any = {
        client_id: clientId,
        program_id: programId,
        values: programValues,
      }
      console.log({ data })
      submitPrograms(data)
    } else {
      if (stepperItems?.[activeStep + 1]?.intakeCategoryFields) {
        handleNext()
      } else {
        const data: any = {
          client_id: clientId,
          intake_id: client?.client?.intake?.id,
          values: intakeValues,
          submit: true,
        }
        console.log({ data })
        mutate(data)
      }
    }
  }

  const handleDraft = () => {
    const data: any = {
      client_id: clientId,
      intake_id: client?.client?.intake?.id,
      values: intakeValues,
      submit: false,
    }
    setIsDraft(true)
    console.log({ data })
    mutate(data)
  }

  useEffect(() => {
    console.log(isPSuccess, activeStep, steps.length - 1)
    if (isSuccess || isPSuccess) {
      enqueueSnackbar('Entry Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      if (activeStep === steps.length - 1) {
        setRefetch(!refetch)
        close()
      } else {
        setIntakeValues([])
        setProgramValues([])
        setProgramId()
        if (isDraft) {
          handleNext()
          const newActiveStep = steps?.length - 1
          setActiveStep(newActiveStep)
        }
      }
    }
    if (isError || isPError) {
      enqueueSnackbar(
        error?.response?.data?.message ||
          perror?.response?.data?.message ||
          error?.message ||
          perror?.message ||
          'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError, isPSuccess, isPError])

  console.log({ intakeValues })

  return (
    <Box>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<QontoConnector />}
      >
        {stepperItems?.map((item: any, index: number) => (
          <Step key={item?.title} completed={completed[index]}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              onClick={handleStep(index)}
            >
              {item?.title}
            </StepLabel>
          </Step>
        ))}
        {/* {client?.client?.programs?.map((item: any, index: number) => (
          <Step key={item?.title} completed={completed[index]}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              onClick={handleStep(index)}
            >
              {item?.title}
            </StepLabel>
          </Step>
        ))} */}
        {/* {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepLabel
              StepIconComponent={QontoStepIcon}
              onClick={handleStep(index)}
            >
              {label}
            </StepLabel>
          </Step>
        ))} */}
      </Stepper>

      {stepperItems?.map((item: any, index: number) => {
        if (activeStep === index) {
          return (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {item?.intakeCategoryFields ? (
                <Box
                  sx={{
                    width: '60%',
                    // display: 'flex',
                    // justifyContent: 'center',
                    py: 5,
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold', fontSize: 25, my: 3 }}>
                    {item?.title}
                  </Typography>
                  <Grid container spacing={2} sx={{ width: '100%' }}>
                    {item?.intakeCategoryFields?.map(
                      (field: any, index: number) => (
                        <Grid item xs={12} sx={{ my: 1 }}>
                          <Box>
                            {field?.type === 'Text' ? (
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    // py: 0.5,
                                  }}
                                >
                                  {index + 1}. {field?.name}
                                </Typography>
                                <TextField
                                  size='small'
                                  sx={{ my: 1 }}
                                  fullWidth
                                  onChange={(e) => {
                                    const existingIndex =
                                      intakeValues.findIndex(
                                        (item) =>
                                          item.intake_category_field_id ===
                                          field?.id
                                      )
                                    if (existingIndex !== -1) {
                                      setIntakeValues((prevState) => {
                                        const updatedValues = [...prevState]
                                        updatedValues[existingIndex].values = [
                                          e.target.value,
                                        ]
                                        return updatedValues
                                      })
                                    } else {
                                      // FieldId doesn't exist, create a new object and add it to the state
                                      setIntakeValues((prevState) => [
                                        ...prevState,
                                        {
                                          intake_category_field_id: field?.id,
                                          values: [e.target.value],
                                        },
                                      ])
                                    }
                                  }}
                                />
                              </Box>
                            ) : field?.type === 'Dropdown' ? (
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    // py: 0.5,
                                  }}
                                >
                                  {index + 1}. {field?.name}
                                </Typography>
                                <Autocomplete
                                  options={field?.values || []}
                                  getOptionLabel={(item: any) => item}
                                  size='small'
                                  onChange={(event, value) => {
                                    const existingIndex =
                                      intakeValues.findIndex(
                                        (item) =>
                                          item.intake_category_field_id ===
                                          field?.id
                                      )
                                    if (existingIndex !== -1) {
                                      setIntakeValues((prevState) => {
                                        const updatedValues = [...prevState]
                                        updatedValues[existingIndex].values = [
                                          value,
                                        ]
                                        return updatedValues
                                      })
                                    } else {
                                      setIntakeValues((prevState) => [
                                        ...prevState,
                                        {
                                          intake_category_field_id: field?.id,
                                          values: [value],
                                        },
                                      ])
                                    }
                                  }}
                                  fullWidth
                                  sx={{ width: '100%' }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size='small'
                                      fullWidth
                                      sx={{ bgcolor: 'white' }}
                                    />
                                  )}
                                />
                              </Box>
                            ) : field?.type === 'Date' ? (
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    // py: 0.5,
                                  }}
                                >
                                  {index + 1}. {field?.name}
                                </Typography>
                                <DatePicker
                                  //   value={appDate}
                                  onChange={(newValue: any) => {
                                    const existingIndex =
                                      intakeValues.findIndex(
                                        (item) =>
                                          item.intake_category_field_id ===
                                          field?.id
                                      )
                                    if (existingIndex !== -1) {
                                      setIntakeValues((prevState) => {
                                        const updatedValues = [...prevState]
                                        updatedValues[existingIndex].values = [
                                          newValue?.$d
                                            ?.toISOString()
                                            ?.replace('.000', ''),
                                        ]
                                        return updatedValues
                                      })
                                    } else {
                                      setIntakeValues((prevState) => [
                                        ...prevState,
                                        {
                                          intake_category_field_id: field?.id,
                                          values: [
                                            newValue?.$d
                                              ?.toISOString()
                                              ?.replace('.000', ''),
                                          ],
                                        },
                                      ])
                                    }
                                  }}
                                  sx={{ width: '100%' }}
                                  slotProps={{ textField: { size: 'small' } }}
                                />
                              </Box>
                            ) : field?.type === 'Checkbox' ? (
                              <Box
                                sx={{
                                  // display: 'flex',
                                  // alignItems: 'center',
                                  gap: 3,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    py: 0.5,
                                  }}
                                >
                                  {index + 1}. {field?.name}
                                </Typography>
                                {field?.values?.map((elt: any) => (
                                  <FormControlLabel
                                    name={elt}
                                    label={elt}
                                    onChange={(e) => {
                                      console.log({ intakeId: field?.id })
                                      const existingIndex =
                                        intakeValues.findIndex(
                                          (item) =>
                                            item.intake_category_field_id ===
                                            field?.id
                                        )
                                      if (existingIndex !== -1) {
                                        setIntakeValues((prevState) => {
                                          const updatedValues = [...prevState]
                                          if (
                                            updatedValues[
                                              existingIndex
                                            ].values?.includes(elt)
                                          ) {
                                            updatedValues[
                                              existingIndex
                                            ].values = updatedValues[
                                              existingIndex
                                            ].values?.filter((el) => el !== elt)
                                          } else {
                                            updatedValues[
                                              existingIndex
                                            ].values?.push(elt)
                                          }
                                          return updatedValues
                                        })
                                      } else {
                                        setIntakeValues((prevState) => [
                                          ...prevState,
                                          {
                                            intake_category_field_id: field?.id,
                                            values: [elt],
                                          },
                                        ])
                                      }
                                    }}
                                    control={<Checkbox />}
                                  />
                                ))}
                              </Box>
                            ) : (
                              <Box
                                sx={
                                  {
                                    // display: 'flex',
                                    // alignItems: 'center',
                                    // gap: 3,
                                  }
                                }
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    py: 0.5,
                                  }}
                                >
                                  {index + 1}. {field?.name}
                                </Typography>
                                <RadioGroup
                                  row
                                  aria-labelledby='demo-row-radio-buttons-group-label'
                                  name='row-radio-buttons-group'
                                  onChange={(e) => {
                                    console.log({ intakeId: field?.id })

                                    const existingIndex =
                                      intakeValues.findIndex((item) => {
                                        return (
                                          item.intake_category_field_id ===
                                          field?.id
                                        )
                                      })
                                    console.log({ existingIndex })
                                    if (existingIndex !== -1) {
                                      setIntakeValues((prevState) => {
                                        const updatedValues = [...prevState]
                                        updatedValues[existingIndex].values = [
                                          e.target.value,
                                        ]
                                        return updatedValues
                                      })
                                    } else {
                                      setIntakeValues((prevState) => [
                                        ...prevState,
                                        {
                                          intake_category_field_id: field?.id,
                                          values: [e.target.value],
                                        },
                                      ])
                                    }
                                  }}
                                >
                                  {field?.values?.map((elt: any) => (
                                    <FormControlLabel
                                      value={elt}
                                      control={<Radio />}
                                      label={elt}
                                    />
                                  ))}
                                </RadioGroup>
                              </Box>
                            )}
                          </Box>
                        </Grid>
                      )
                    )}
                  </Grid>
                </Box>
              ) : (
                <Box
                  sx={{
                    width: '60%',
                    // display: 'flex',
                    // justifyContent: 'center',
                    py: 5,
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold', fontSize: 25, my: 3 }}>
                    {item?.title}
                  </Typography>
                  <Grid container spacing={2} sx={{ width: '100%' }}>
                    {item?.programFields?.map((field: any) => (
                      <Grid item xs={6}>
                        <Box>
                          {field?.type === 'Text' ? (
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                  py: 0.5,
                                }}
                              >
                                {field?.name}
                              </Typography>
                              <TextField
                                size='small'
                                fullWidth
                                onChange={(e) => {
                                  setProgramId(item?.id)
                                  const existingIndex = programValues.findIndex(
                                    (item) =>
                                      item.program_field_id === field?.id
                                  )
                                  if (existingIndex !== -1) {
                                    setProgramValues((prevState) => {
                                      const updatedValues = [...prevState]
                                      updatedValues[existingIndex].values = [
                                        e.target.value,
                                      ]
                                      return updatedValues
                                    })
                                  } else {
                                    // FieldId doesn't exist, create a new object and add it to the state
                                    setProgramValues((prevState) => [
                                      ...prevState,
                                      {
                                        program_field_id: field?.id,
                                        values: [e.target.value],
                                      },
                                    ])
                                  }
                                }}
                              />
                            </Box>
                          ) : field?.type === 'Dropdown' ? (
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                  py: 0.5,
                                }}
                              >
                                {field?.name}
                              </Typography>
                              <Autocomplete
                                options={field?.values || []}
                                getOptionLabel={(item: any) => item}
                                size='small'
                                onChange={(event, value) => {
                                  setProgramId(item?.id)
                                  const existingIndex = programValues.findIndex(
                                    (item) =>
                                      item.program_field_id === field?.id
                                  )
                                  if (existingIndex !== -1) {
                                    setProgramValues((prevState) => {
                                      const updatedValues = [...prevState]
                                      updatedValues[existingIndex].values = [
                                        value,
                                      ]
                                      return updatedValues
                                    })
                                  } else {
                                    // FieldId doesn't exist, create a new object and add it to the state
                                    setProgramValues((prevState) => [
                                      ...prevState,
                                      {
                                        program_field_id: field?.id,
                                        values: [value],
                                      },
                                    ])
                                  }
                                }}
                                fullWidth
                                sx={{ width: '100%' }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size='small'
                                    fullWidth
                                    sx={{ bgcolor: 'white' }}
                                  />
                                )}
                              />
                            </Box>
                          ) : field?.type === 'Date' ? (
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                  py: 0.5,
                                }}
                              >
                                {field?.name}
                              </Typography>
                              <DatePicker
                                //   value={appDate}
                                onChange={(newValue: any) => {
                                  setProgramId(item?.id)
                                  const existingIndex = programValues.findIndex(
                                    (item) =>
                                      item.program_field_id === field?.id
                                  )
                                  if (existingIndex !== -1) {
                                    setProgramValues((prevState) => {
                                      const updatedValues = [...prevState]
                                      updatedValues[existingIndex].values = [
                                        newValue?.$d
                                          ?.toISOString()
                                          ?.replace('.000', ''),
                                      ]
                                      return updatedValues
                                    })
                                  } else {
                                    // FieldId doesn't exist, create a new object and add it to the state
                                    setProgramValues((prevState) => [
                                      ...prevState,
                                      {
                                        program_field_id: field?.id,
                                        values: [
                                          newValue?.$d
                                            ?.toISOString()
                                            ?.replace('.000', ''),
                                        ],
                                      },
                                    ])
                                  }
                                }}
                                sx={{ width: '100%' }}
                                slotProps={{ textField: { size: 'small' } }}
                              />
                            </Box>
                          ) : field?.type === 'Checkbox' ? (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 3,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                  py: 0.5,
                                }}
                              >
                                {field?.name}
                              </Typography>
                              {field?.values?.map((elt: any) => (
                                <FormControlLabel
                                  name={elt}
                                  label={elt}
                                  onChange={(e) => {
                                    setProgramId(item?.id)
                                    console.log({ intakeId: field?.id })
                                    const existingIndex =
                                      programValues.findIndex(
                                        (item) =>
                                          item.program_field_id === field?.id
                                      )
                                    if (existingIndex !== -1) {
                                      setProgramValues((prevState) => {
                                        const updatedValues = [...prevState]
                                        if (
                                          updatedValues[
                                            existingIndex
                                          ].values?.includes(elt)
                                        ) {
                                          updatedValues[existingIndex].values =
                                            updatedValues[
                                              existingIndex
                                            ].values?.filter((el) => el !== elt)
                                        } else {
                                          updatedValues[
                                            existingIndex
                                          ].values?.push(elt)
                                        }
                                        return updatedValues
                                      })
                                    } else {
                                      setProgramValues((prevState) => [
                                        ...prevState,
                                        {
                                          program_field_id: field?.id,
                                          values: [elt],
                                        },
                                      ])
                                    }
                                  }}
                                  control={<Checkbox />}
                                />
                              ))}
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 3,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: 14,
                                  py: 0.5,
                                }}
                              >
                                {field?.name}
                              </Typography>
                              <RadioGroup
                                row
                                aria-labelledby='demo-row-radio-buttons-group-label'
                                name='row-radio-buttons-group'
                                onChange={(e) => {
                                  setProgramId(item?.id)
                                  const existingIndex = programValues.findIndex(
                                    (item) => {
                                      return item.program_field_id === field?.id
                                    }
                                  )
                                  console.log({ existingIndex })
                                  if (existingIndex !== -1) {
                                    setProgramValues((prevState) => {
                                      const updatedValues = [...prevState]
                                      updatedValues[existingIndex].values = [
                                        e.target.value,
                                      ]
                                      return updatedValues
                                    })
                                  } else {
                                    // FieldId doesn't exist, create a new object and add it to the state
                                    setProgramValues((prevState) => [
                                      ...prevState,
                                      {
                                        program_field_id: field?.id,
                                        values: [e.target.value],
                                      },
                                    ])
                                  }
                                }}
                              >
                                {field?.values?.map((elt: any) => (
                                  <FormControlLabel
                                    value={elt}
                                    control={<Radio />}
                                    label={elt}
                                  />
                                ))}
                              </RadioGroup>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              )}
            </Box>
          )
        }
      })}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          position: 'fixed',
          bottom: 0,
          width: { xs: '100vw', xl: 'calc(100vw - 280px)' },
          height: 80,
          px: { xs: 5, md: 10 },
          gap: 5,
        }}
      >
        <Box
          sx={{
            display:
              activeStep === 0 || activeStep === steps?.length - 1
                ? 'none'
                : 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 120,
            height: 55,
            bgcolor: '#EFEFEF',
            borderRadius: 2,
            cursor: 'pointer',
          }}
          onClick={handleBack}
        >
          <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
            Back
          </Typography>
        </Box>
        {activeStep < steps.length - 1 && (
          <Button
            variant='contained'
            onClick={handleDraft}
            sx={{ px: 2, borderRadius: 2, height: 50, width: 120 }}
          >
            {/* {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CircularProgress sx={{ color: 'white' }} size={20} />
                    <Typography>submitting...</Typography>
                  </Box>
                ) : ( */}
            <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
              Save as Draft
            </Typography>
            {/* )} */}
          </Button>
        )}
        {activeStep === steps.length - 1 ? (
          <Button
            variant='contained'
            onClick={onSubmit}
            sx={{ px: 2, borderRadius: 2, height: 50, width: 120 }}
          >
            {isPLoading || isLoading ? (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <CircularProgress sx={{ color: 'white' }} size={20} />
                <Typography>submitting...</Typography>
              </Box>
            ) : (
              <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                Submit
              </Typography>
            )}
          </Button>
        ) : (
          <Button
            variant='contained'
            onClick={onSubmit}
            sx={{ px: 2, borderRadius: 2, height: 50, width: 120 }}
          >
            {/* {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <CircularProgress sx={{ color: 'white' }} size={20} />
                  <Typography>submitting...</Typography>
                  </Box>
                  ) : ( */}
            <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
              Next
            </Typography>
            {/* )} */}
          </Button>
        )}
      </Box>
    </Box>
  )
}
