import {
  AutoAwesome,
  ChevronRight,
  Close,
  HighlightOff,
  History,
  Info,
  Mic,
  Send,
  Stop,
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  Card,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import a from '../../assets/2.json'
import d from '../../assets/4.json'
import think from '../../assets/3.json'
import assistant from '../../assets/AIAssistant.json'
import voice from '../../assets/voice.svg'
import Lottie from 'lottie-react'
import { useAudioRecorder } from 'react-audio-voice-recorder'
import { useEffect, useState } from 'react'
import axios from 'axios'
import ReactPlayer from 'react-player'

export default function AICard({ close }: any) {
  const { startRecording, stopRecording, recordingBlob, isRecording } =
    useAudioRecorder()

  console.log({ isRecording, recordingBlob })

  const [sentText, setSentText] = useState<string[]>([])
  const [text, setText] = useState() as any
  const [response, setResponse] = useState() as any
  const [type, setType] = useState<string>('text')
  const [isVoiceLoading, setIsVoiceLoading] = useState(false)
  const [voiceResponse, setVoiceResponse] = useState() as any
  const [isPlaying, setIsPlaying] = useState(false)

  console.log({ voiceResponse, res: response?.data, load: isVoiceLoading })

  const [toDisplay, setToDisplay] = useState<{
    chat_id: number
    response: string
    chat_history: { role: string; content: string }[]
  }>()
  const [isLoading, setisLoading] = useState(false)
  console.log({ response })
  console.log({ text })

  useEffect(() => {
    if (recordingBlob) {
      const reader = new FileReader()
      reader.readAsArrayBuffer(recordingBlob)
      reader.onloadend = () => {
        const arrayBuffer = reader.result as any
        const byteArray = new Uint8Array(arrayBuffer) as any
        const base64 = btoa(String.fromCharCode(...byteArray))
        console.log({ base64 })
        setIsVoiceLoading(true)
        setisLoading(true)
        axios
          .post('http://64.226.72.36:8001/api/v1/send-voice', {
            ...(response ? { chat_id: response?.data?.chat_id } : {}),
            prompt_voice: base64,
            response_type: 'voice',
            stream: false,
            task: 'chat',
          })
          .then((res) => {
            setisLoading(false)
            if (type === 'audio') {
              setVoiceResponse(res?.data?.response)
            }
            setResponse(res)
          })
          .catch((e) => {
            setisLoading(false)
            setIsVoiceLoading(false)
            console.log({ e })
          })
      }
    }
  }, [recordingBlob])

  const handleSend = () => {
    setSentText((texts) => {
      return [...texts, text]
    })
    const history = toDisplay?.chat_history
    if (history?.length) {
      const newHistory = [...history, { role: 'user', content: text }]

      setToDisplay((prev) => {
        if (prev) {
          return {
            ...prev,
            chat_history: newHistory,
          }
        }
      })
    }

    setisLoading(true)
    axios
      .post('http://64.226.72.36:8001/api/v1/send-text', {
        ...(response ? { chat_id: response?.data?.chat_id } : {}),
        prompt_text: text,
        response_type: 'text',
        stream: false,
      })
      .then((res) => {
        setisLoading(false)
        setResponse(res)
      })
      .catch((e) => {
        setisLoading(false)
        console.log({ e })
      })
    setText('')
  }

  useEffect(() => {
    setToDisplay(response?.data)
  }, [response])

  return (
    <Card
      sx={{
        position: 'absolute',
        left: '240px',
        bottom: 5,
        bgcolor: 'white',
        width: 500,
        borderRadius: 6,
        boxShadow: 24,
        height: '98vh',
      }}
    >
      <Box
        sx={{
          bgcolor: 'whitesmoke',
          borderBottom: '1px solid #C6CDE2',
          px: 1.5,
          py: 3,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 3,
            px: 1,
            // py: 2,
          }}
        >
          <AutoAwesome sx={{ color: '#FFAC33', width: 40, height: 40 }} />
          <Box>
            <Typography
              sx={{
                fontStyle: 'normal',
                background:
                  'var(--newww, linear-gradient(90deg, rgba(14,142,250,1) 30%, rgba(112,136,132,1) 60%, rgba(231,139,13,1) 100%))',
                backgroundClip: 'text',
                '-webkit-background-clip': 'text',
                '-webkit-text-fill-color': 'transparent',
                fontWeight: 'bold',
                fontSize: 25,
              }}
            >
              Hello, I am Austin
            </Typography>
            <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
              Your AI Client Support Assistant
            </Typography>
          </Box>
          {/* <Info /> */}
        </Box>
        {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}> */}
        <Box
          sx={
            {
              // border: '1px solid green',
            }
          }
        >
          <IconButton onClick={close}>
            <Close sx={{ color: 'black' }} />
          </IconButton>
        </Box>
        {/* </Box> */}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pl: 2.3,
          pr: 1,
          py: 0.3,
          borderBottom: '1px solid #C6CDE2',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
          <History />
          <Typography sx={{ fontSize: 14 }}>Access Chat History</Typography>
        </Box>
        <IconButton>
          <ChevronRight />
        </IconButton>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 2,
        }}
      >
        <Box
          sx={{
            bgcolor: 'whitesmoke',
            display: 'flex',
            alingItems: 'center',
            p: 0.6,
            borderRadius: 10,
          }}
        >
          <Box
            sx={{
              bgcolor: type === 'text' ? 'white' : 'none',
              boxShadow:
                type === 'text' ? '4px 4px 10px 1px rgba(0,0,0,0.07)' : 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 5,
              borderRadius: 10,
              py: 0.5,
              cursor: 'pointer',
            }}
            onClick={() => setType('text')}
          >
            <Typography
              sx={{ fontSize: 14, color: type === 'text' ? 'black' : 'gray' }}
            >
              Text
            </Typography>
          </Box>
          <Box
            sx={{
              bgcolor: type === 'audio' ? 'white' : 'none',
              boxShadow:
                type === 'audio' ? '4px 4px 10px 1px rgba(0,0,0,0.07)' : 'none',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              px: 5,
              borderRadius: 10,
              py: 0.5,
              cursor: 'pointer',
            }}
            onClick={() => setType('audio')}
          >
            <Typography
              sx={{ fontSize: 14, color: type === 'audio' ? 'black' : 'gray' }}
            >
              Audio
            </Typography>
          </Box>
        </Box>
      </Box>
      {type === 'audio' ? (
        <Box
          sx={{
            px: 2,
            py: 2,
            height: 'calc(100vh - 250px)',
          }}
        >
          {isVoiceLoading || isPlaying ? (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90%',
                position: 'relative',
              }}
            >
              <Lottie
                animationData={think}
                style={{ width: 300, height: 300 }}
              />
            </Box>
          ) : (
            // : isRecording ? (
            //   <Box
            //     sx={{
            //       display: 'flex',
            //       flexDirection: 'column',
            //       alignItems: 'center',
            //       justifyContent: 'center',
            //       height: '90%',
            //     }}
            //   >
            //     <Typography sx={{ color: '#6983A9' }}>
            //       Speak what you want to be generated
            //     </Typography>
            //     <Lottie animationData={a} style={{ width: 200, height: 200 }} />
            //   </Box>
            // )
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '90%',
              }}
            >
              <Box
                component='img'
                src={voice}
                sx={{ width: 150, height: 150, my: 4 }}
              />
              <Typography sx={{ color: '#6983A9' }}>
                Speak what you want to be generated
              </Typography>
            </Box>
          )}
        </Box>
      ) : (
        <Box
          sx={{
            pl: 3,
            pr: 4,
            height: 'calc(100vh - 330px)',
            '&::-webkit-scrollbar': {
              width: 0,
            },
            overflowY: 'auto',
          }}
        >
          {toDisplay ? (
            toDisplay?.chat_history?.map((item: any) => {
              if (item?.role === 'user') {
                return (
                  <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      {/* <Avatar /> */}
                      <Avatar
                        sx={{
                          width: 32,
                          height: 32,
                        }}
                      />

                      <Box
                        sx={{
                          bgcolor: '#FFF5F4',
                          py: 1.7,
                          px: 2,
                          borderRadius: 4,
                          my: 1,
                        }}
                      >
                        <Typography
                          sx={{ fontSize: 14, fontFamily: 'sans-serif' }}
                        >
                          {item?.content}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                )
              } else if (item?.role === 'assistant') {
                return (
                  // <Box sx={{ display: 'flex', gap: 3 }}>
                  //   <Box
                  //     sx={{
                  //       // my: 2,
                  //       bgcolor: '#FFAC33',
                  //       // borderRadius: 10,
                  //       width: 60,
                  //       height: 60,
                  //       display: 'flex',
                  //       alignItems: 'center',
                  //       justifyContent: 'center',
                  //       cursor: 'pointer',
                  //     }}
                  //   >
                  //     <AutoAwesome sx={{ color: 'white' }} />
                  //   </Box>
                  //   <Box sx={{ display: 'flex' }}>
                  //     <Box
                  //       sx={{
                  //         // p: 3,
                  //         border: '1px solid gray',
                  //         borderTopLeftRadius: 20,
                  //         borderBottomLeftRadius: 20,
                  //         // width: 553,
                  //         zIndex: 100,
                  //       }}
                  //     >
                  //       <Typography>{item?.content}</Typography>
                  //     </Box>
                  //   </Box>
                  // </Box>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    {/* <Box sx={{ bgcolor: '##FFAC33' }}> */}
                    <Avatar
                      sx={{
                        mt: 2,
                        height: 32,
                        width: 32,
                        backgroundImage:
                          'linear-gradient(120deg, rgba(14,142,250,1) 0%, rgb(255,172,51,1) 70%, rgb(255,172,51,1) 100%)',
                      }}
                    >
                      <AutoAwesome
                        sx={{ color: 'white', width: 20, height: 20 }}
                      />
                    </Avatar>
                    {/* </Box> */}
                    <Box
                      sx={{
                        // bgcolor: '#FFF5F4',
                        border: '1px solid #D3DEE6',
                        py: 1.7,
                        px: 2,
                        borderRadius: 4,
                        my: 2,
                      }}
                    >
                      <Typography
                        sx={{ fontSize: 14, fontFamily: 'sans-serif' }}
                      >
                        {item?.content}
                      </Typography>
                    </Box>
                  </Box>
                )
              }
            })
          ) : (
            <Box>
              {sentText?.length > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <Avatar
                    sx={{
                      width: 32,
                      height: 32,
                    }}
                  />
                  <Box
                    sx={{
                      bgcolor: '#FFF5F4',
                      py: 1.7,
                      px: 2,
                      borderRadius: 4,
                      my: 2,
                    }}
                  >
                    <Typography sx={{ fontSize: 14, fontFamily: 'sans-serif' }}>
                      {sentText[0]}
                    </Typography>
                  </Box>
                </Box>
              )}
            </Box>
          )}
          <Box sx={{ display: isLoading ? 'block' : 'none' }}>
            <Lottie
              animationData={assistant}
              style={{ width: 30, height: 30 }}
            />
          </Box>
        </Box>
      )}
      <Box
        sx={{
          gap: 2,
          my: 3,
          mx: 2,
          position: 'fixed',
          bottom: 0,
          width: 470,
        }}
      >
        <Box
          sx={{
            backgroundImage:
              'linear-gradient(180deg,transparent,rgba(255, 255, 255, 0.5),white)',
            height: 50,
          }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {isRecording ? (
            <Box sx={{ bgcolor: '#F7F7F8', borderRadius: 20 }}>
              <Lottie animationData={d} style={{ height: 80 }} />
            </Box>
          ) : (
            <TextField
              // variant='outlined'
              // variant='filled'
              onKeyDown={(event) => {
                if (event?.key === 'Enter') {
                  if (text.length > 0) {
                    handleSend()
                  }
                }
              }}
              placeholder='Wrtie Here'
              fullWidth
              value={text}
              onChange={(e) => setText(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    border: 'none', // Remove the outline
                  },
                },
                height: 70,
              }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={handleSend}>
                    <Send sx={{ color: 'primary.main' }} />
                  </IconButton>
                ),
                sx: {
                  borderRadius: 30,
                  bgcolor: '#F0F0F0',
                  border: '1px solid #EDEDED',
                  height: 70,
                  px: 1,
                  // boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.05)',
                },
              }}
            />
          )}
          {isRecording ? (
            <IconButton onClick={stopRecording}>
              <HighlightOff sx={{ width: 40, height: 40 }} />
            </IconButton>
          ) : (
            <IconButton onClick={startRecording}>
              <Mic />
            </IconButton>
          )}
        </Box>
        <ReactPlayer
          style={{
            position: 'absolute',
            width: 5,
            height: 5,
          }}
          url={voiceResponse}
          loop={false}
          controls={false}
          playing={voiceResponse ? true : false}
          playsinline
          onEnded={() => {
            setVoiceResponse()
            setIsVoiceLoading(false)
            setIsPlaying(false)
          }}
          onStart={() => setIsPlaying(true)}
          onPlay={() => setIsPlaying(true)}
        />
      </Box>
    </Card>
  )
}
