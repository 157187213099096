import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Modal,
  Slide,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import FileUpload from '../File/FileUpload'
import { useFetch } from '../../hooks/useFetch'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import axios from 'axios'
import { authHeader } from '../../utils/auth'
import { BASE_URL } from '../../utils/config'

export default function AddUser({
  open,
  close,
  refetch,
}: {
  open: boolean
  close: any
  refetch: any
}) {
  const [first_name, setFirstName] = useState<string>()
  const [middle_name, setMiddleName] = useState<string>()
  const [last_name, setLastName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [phone_number, setPhone] = useState<string>()
  const [role_id, setRoleId] = useState<string>()
  const [profile_picture_url, setProfile] = useState<string>()
  const [imageState, setImageState] = useState<any>()
  const [url, setUrl] = useState() as any
  const [selectedPrograms, setSelectedPrograms] = useState<string[]>([])

  console.log({ profile_picture_url })

  const { data: programs, mutate: fetchProgram } = usePost(
    '/public/configs/programs/list'
  )
  const { mutate: fetchRoles, data: roles } = usePost(
    '/public/access-control/roles/list'
  )
  const { mutate, isLoading, isError, isSuccess, error } =
    usePost('/public/user/users')
  console.log({ programs, roles })

  useEffect(() => {
    console.log({ imageState })
    if (imageState) {
      axios
        .get(
          `${BASE_URL}/public/document/presigned-url?fileKey=${imageState?.name}`,
          {
            headers: authHeader(),
          }
        )
        .then((res) => setUrl(res?.data?.url))
        .catch((e) => console.log({ e }))
    }
  }, [imageState])
  useEffect(() => {
    if (url) {
      const reader = new FileReader()
      reader.onload = handleReaderLoaded
      reader.readAsArrayBuffer(imageState)
    }
  }, [url])
  const handleReaderLoaded = (event: any) => {
    const binaryData = event.target.result
    console.log({ binaryData })

    axios
      .put(url, binaryData)
      .then((res) => console.log({ res }))
      .catch((e) => console.log({ e }))
  }
  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchProgram(dataP)
  }, [])

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchRoles(dataP)
  }, [])

  const handleSubmit = () => {
    const data: any = {
      first_name,
      middle_name,
      last_name,
      email,
      phone_number,
      role_id,
      program_ids: selectedPrograms,
    }
    console.log({ data })
    mutate(data)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('User Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      refetch()
      close()
    }
    if (isError) {
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 700 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>Add New User</Typography>
              <Box> </Box>
            </Box>
            <Box
              sx={{
                p: { xs: 5, sm: 10 },
                height: 'calc(100vh - 140px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Box sx={{ my: 2 }}>
                <FileUpload
                  name='image'
                  type='PROFILE_IMAGE'
                  setFileState={setImageState}
                />
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    First Name
                  </Typography>
                  <TextField
                    size='small'
                    fullWidth
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Middle Name
                  </Typography>
                  <TextField
                    size='small'
                    fullWidth
                    onChange={(e) => setMiddleName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 'bold' }}>Last Name</Typography>
                  <TextField
                    size='small'
                    fullWidth
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 'bold' }}>Phone No</Typography>
                  <TextField
                    size='small'
                    fullWidth
                    onChange={(e) => setPhone(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 'bold' }}>Email</Typography>
                  <TextField
                    size='small'
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 'bold' }}>Role</Typography>
                  <Autocomplete
                    options={roles?.data?.roles || []}
                    filterSelectedOptions={true}
                    getOptionLabel={(option: any) => option.name}
                    onChange={(event, value) => setRoleId(value?.id)}
                    size='small'
                    renderInput={(params: any) => (
                      <TextField {...params} name='roleId' placeholder='Role' />
                    )}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ my: 2 }} />
              <Typography sx={{ fontWeight: 'bold' }}>Add Programs</Typography>
              <Box
                sx={{
                  p: 2,
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 2,
                  border: '1px solid #EEF3F7',
                  borderRadius: 2,
                  my: 1,
                }}
              >
                {programs?.data?.programs?.map((program: any) => (
                  <Box
                    sx={{
                      py: 1,
                      px: 2,
                      border: selectedPrograms?.includes(program?.id)
                        ? '1px solid #006CEA'
                        : '1px solid #C1C1C1',
                      borderRadius: 2,
                      cursor: 'pointer',
                    }}
                    onClick={() => {
                      if (selectedPrograms?.includes(program?.id)) {
                        setSelectedPrograms((state: any) => [
                          ...state.filter(
                            (programId: any) => programId !== program?.id
                          ),
                        ])
                      } else {
                        setSelectedPrograms((state: any) => [
                          ...state,
                          program?.id,
                        ])
                      }
                    }}
                  >
                    <Typography
                      sx={{
                        color: selectedPrograms?.includes(program?.id)
                          ? '#006CEA'
                          : '#ADADAD',
                      }}
                    >
                      {program?.name}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'fixed',
                bottom: 0,
                width: { xs: '100vw', sm: 700 },
                height: 80,
                py: 3,
                px: { xs: 5, sm: 10 },
              }}
            >
              <Button
                variant='contained'
                onClick={handleSubmit}
                sx={{ px: 4, py: 2.5 }}
              >
                {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CircularProgress sx={{ color: 'white' }} size={20} />
                    <Typography>submitting...</Typography>
                  </Box>
                ) : (
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Add User
                  </Typography>
                )}
              </Button>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
