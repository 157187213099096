import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#006CEA',
    },
    secondary: {
      main: '#F5F5F5',
      dark: '#1D2426',
    },
    warning: {
      main: '#FFC260',
    },
  },
})

export default theme
