import { Close, PeopleAlt } from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  IconButton,
  Modal,
  Slide,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { usePost } from '../../hooks/usePost'

export default function TagPeople({
  open,
  close,
  messageId,
  refetch,
}: {
  open: boolean
  close: any
  messageId: string
  refetch: any
}) {
  const [selectedClients, setSelectedClients] = useState([]) as any[]
  console.log({ selectedClients })
  const { data: users, mutate: fetchUser } = usePost('/public/user/users/list')

  console.log({ users })

  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchUser(datap)
  }, [])

  //   const handleSubmit = () => {
  //     const data: any = {
  //       directory_id,
  //       user_ids: selectedClients.map((client: any) => client.id),
  //     }
  //     mutate(data)
  //   }

  //   useEffect(() => {
  //     if (isSuccess) {
  //       enqueueSnackbar('User Tagged Successfully', {
  //         variant: 'success',
  //         preventDuplicate: true,
  //       })
  //       refetch()
  //       close()
  //     }
  //     if (isError) {
  //       console.log({ error })
  //       enqueueSnackbar(
  //         error?.response?.data?.message || error?.message || 'Network Error',
  //         { variant: 'error', preventDuplicate: true }
  //       )
  //     }
  //   }, [isSuccess, isError])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 500 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>Tag People</Typography>
              <Box> </Box>
            </Box>
            <Box
              sx={{
                px: 5,
                py: { xs: 5, sm: 5 },
                height: 'calc(100vh - 140px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>
                Search
              </Typography>
              <Autocomplete
                options={users?.data?.users || []}
                getOptionLabel={(item: any) => item.name}
                size='small'
                multiple
                onChange={(event, value) => {
                  setSelectedClients(value)
                }}
                renderTags={(value, getTagProps) => (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      alignItems: 'center',
                      flexWrap: 'wrap',
                    }}
                  >
                    {value.map((option, index) => (
                      <Box
                        sx={{
                          bgcolor: '#F4FAFF',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          border: '1px solid #ECEDED',
                          px: 1,
                          py: 0.5,
                          borderRadius: 1.5,
                        }}
                      >
                        <Avatar
                          sx={{ width: 20, height: 20 }}
                          src={option?.profilePictureUrl}
                        />
                        <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                          {option?.firstName}
                        </Typography>
                        <IconButton
                          sx={{
                            border: '1px solid gray',
                            width: 16,
                            height: 16,
                          }}
                          onClick={() =>
                            getTagProps({ index })?.onDelete(option)
                          }
                        >
                          <Close
                            sx={{
                              width: 13,
                              height: 13,
                              color: 'gray',
                            }}
                          />
                        </IconButton>
                      </Box>
                    ))}
                  </Box>
                )}
                renderOption={(props, option) => (
                  <Box
                    component='li'
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 6,
                      width: '100%',
                    }}
                    {...props}
                  >
                    <Avatar
                      sx={{ width: 45, height: 45 }}
                      src={option?.profilePictureUrl}
                    />

                    <Typography sx={{ width: 200 }}>
                      {option?.firstName}
                    </Typography>
                    <Box
                      sx={{
                        bgcolor: '#F4FAFF',
                        px: 1,
                        borderRadius: 5,
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#4D72A6',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          fontSize: 12,
                        }}
                      >
                        Therapist
                      </Typography>
                    </Box>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField {...params} size='small' fullWidth />
                )}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'fixed',
                bottom: 0,
                width: { xs: '100vw', sm: 500 },
                height: 80,
                py: 3,
                px: 5,
              }}
            >
              <Button
                variant='contained'
                sx={{ px: 10, py: 2.5 }}
                // onClick={handleSubmit}
              >
                {/* {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CircularProgress sx={{ color: 'white' }} size={20} />
                    <Typography>submitting...</Typography>
                  </Box>
                ) : (
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Tag
                  </Typography>
                )} */}
              </Button>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
