import { Add, Edit, Search, Visibility } from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  IconButton,
  LinearProgress,
  Popover,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import AddGoal from '../components/Goal/AddGoal'
import { usePost } from '../hooks/usePost'
import moment from 'moment'
import GoalDetails from '../components/Goal/GoalDetails'
import AddTask from '../components/Task/AddTask'
import { s } from '@fullcalendar/core/internal-common'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Goals() {
  const [value, setValue] = useState(0)
  const [type, setType] = useState('all')

  const [refetchGoal, setRefetchGoal] = useState(false)
  const { data: goalData, mutate } = usePost('/public/goal/goals/list')

  console.log({ goalData })

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    mutate(datap)
  }, [refetchGoal])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
    if (newValue === 0) {
      setType('all')
    } else if (newValue === 1) {
      setType('TO_DO')
    } else if (newValue === 2) {
      setType('IN_PROGRESS')
    } else if (newValue === 3) {
      setType('Overdue')
    } else if (newValue === 4) {
      setType('COMPLETED')
    }
  }
  const [openAddGoal, setOpenAddGoal] = useState(false)
  const [openAddTask, setOpenAddTask] = useState(false)
  const [openGoalDetail, setOpenGoalDetail] = useState(false)
  const [goalItem, setGoalItem] = useState() as any

  return (
    <Box sx={{ px: 3, pt: 3 }}>
      <Box
        sx={{
          pt: 2,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box
          sx={{
            pt: 2,
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            flexGrow: 1,
          }}
        >
          <TextField
            sx={{
              borderColor: '2px solid red',
            }}
            InputProps={{
              startAdornment: <Search />,
              sx: {
                '&.MuiOutlinedInput-root': {
                  borderRadius: 8,
                  borderColor: '2px solid red',
                  height: 45,
                  bgcolor: '#E6F4FF ',
                },
              },
            }}
            placeholder='Search'
          />
          <Box
            sx={{
              borderBottom: 1,
              borderColor: 'divider',
              pt: 2,
              display: 'flex',
              gap: 3,
              width: '100%',
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label='basic tabs example'
            >
              <Tab
                label='All'
                {...a11yProps(0)}
                sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
              />
              <Tab
                label={`To Do`}
                {...a11yProps(1)}
                sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
              />
              <Tab
                label='In Progress'
                {...a11yProps(2)}
                sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
              />
              <Tab
                label={'Overdue'}
                {...a11yProps(3)}
                sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
              />
              <Tab
                label={'Completed'}
                {...a11yProps(4)}
                sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
              />
            </Tabs>
          </Box>
        </Box>
        <Box
          sx={{
            height: '100%',
            px: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 3,
          }}
        >
          <Autocomplete
            options={['Goal', 'Task']}
            getOptionLabel={(item: any) => item}
            size='small'
            renderInput={(params) => (
              <TextField {...params} size='small' sx={{ width: 150 }} />
            )}
          />
          <Button
            variant='contained'
            sx={{ borderRadius: 5 }}
            // onClick={() => setOpenAddGoal(true)}
            onClick={handleClick}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Add />
              <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                Add
              </Typography>
            </Box>
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box sx={{ bgcolor: '#0178DD' }}>
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => setOpenAddGoal(true)}
              >
                <Typography
                  sx={{
                    px: 4,
                    py: 1,
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Goal
                </Typography>
              </Box>
              <Box
                sx={{ cursor: 'pointer' }}
                onClick={() => setOpenAddTask(true)}
              >
                <Typography
                  sx={{
                    px: 4,
                    py: 1,
                    color: 'white',
                    fontWeight: 'bold',
                  }}
                >
                  Task
                </Typography>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
      {goalData?.data?.goals
        ?.filter((elt: any) => (type === 'all' ? elt : elt?.status === type))
        ?.map((item: any, index: number) => (
          <Box sx={{ my: 5, px: { xs: 2, sm: 5 } }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                // justifyContent: 'space-between',
              }}
            >
              <Box sx={{ width: 300 }}>
                <Typography sx={{ color: '#A0AABE', fontSize: 14 }}>
                  {moment(item?.createdAt)?.format('ll')}
                </Typography>
                <Typography sx={{ fontWeight: 'bold', color: '#26335E' }}>
                  {item?.title}
                </Typography>
              </Box>
              <Box sx={{ width: 200 }}>
                <Typography sx={{ color: '#A0AABE', fontSize: 14 }}>
                  Type
                </Typography>
                <Typography
                  sx={{ color: '#006CEA', fontWeight: 'bold', fontSize: 18 }}
                >
                  {item?.type}
                </Typography>
              </Box>
              <Box sx={{ width: 180 }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                  }}
                >
                  <Avatar
                    sx={{ width: 30, height: 30, position: 'absolute' }}
                  ></Avatar>
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 23,
                      p: 0.3,
                      borderRadius: '100%',
                      bgcolor: '#F4FAFF',
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 30,
                        height: 30,
                      }}
                    ></Avatar>
                  </Box>
                  <Box
                    sx={{
                      position: 'absolute',
                      left: 46,
                      p: 0.3,
                      borderRadius: '100%',
                      bgcolor: '#F4FAFF',
                    }}
                  >
                    <Avatar
                      sx={{
                        width: 30,
                        height: 30,
                      }}
                    ></Avatar>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ width: 270 }}>
                <LinearProgress
                  variant='determinate'
                  value={40}
                  sx={{
                    height: 10,
                    borderRadius: 3,
                    bgcolor: '#F4FAFF',
                    width: 200,
                  }}
                />
              </Box>
              <Box sx={{ width: 180 }}>
                <Typography sx={{ color: '#A0AABE', fontSize: 14 }}>
                  Status
                </Typography>
                <Typography
                  sx={{ color: '#0178DD', fontWeight: 'bold', fontSize: 14 }}
                >
                  {item?.status}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                <IconButton
                  onClick={() => {
                    setGoalItem(item)
                    setOpenGoalDetail(true)
                  }}
                >
                  <Visibility sx={{ color: '#0178DD' }} />
                </IconButton>
                {item?.priority}
                {/* <Edit sx={{ color: '#0178DD' }} /> */}
              </Box>
            </Box>
          </Box>
        ))}
      <AddGoal
        open={openAddGoal}
        close={() => setOpenAddGoal(false)}
        refetch={refetchGoal}
        setRefetch={setRefetchGoal}
      />
      <AddTask open={openAddTask} close={() => setOpenAddTask(false)} />
      {goalItem && (
        <GoalDetails
          open={openGoalDetail}
          close={() => setOpenGoalDetail(false)}
          data={goalItem}
        />
      )}
    </Box>
  )
}
