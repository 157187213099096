import {
  Add,
  Close,
  CloudUploadOutlined,
  Remove,
  Upload,
} from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useCSVReader } from 'react-papaparse'
import { usePost } from '../../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'

export default function NewProgram({
  open,
  close,
  refetch,
  setRefetch,
}: {
  open: boolean
  close: any
  refetch: boolean
  setRefetch: any
}) {
  const [csvData, setCsvData] = useState() as any
  const [values, setValues] = useState<{ index: number; value: any[] }[]>([])
  const [type, setType] = useState<string[]>([])

  const [isCapitated, setIsCapitated] = useState<
    { index: number; value: string }[]
  >([])
  const [noteType, setNoteType] = useState<{ index: number; value: any }[]>([])
  const [directory, setDirectory] = useState<{ index: number; value: any }[]>(
    []
  )

  console.log({ values })
  const { CSVReader } = useCSVReader()

  const { mutate, isLoading, isError, isSuccess, error } = usePost(
    '/public/configs/programs'
  )

  const { data: directories, mutate: fetchDirectories } = usePost(
    '/public/directory/directories/list'
  )
  const { data: noteTypes, mutate: fetchNoteTypes } = usePost(
    '/public/configs/note_types/list'
  )

  console.log({ noteTypes })

  console.log({ directories })
  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchDirectories(dataP)
  }, [])
  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchNoteTypes(dataP)
  }, [])

  console.log({ csvData })

  type FormValues = {
    enabled_billing: boolean
    billing: {
      diagnostic_code: string
      service_code: string
      amount_per_client: string
      is_capitated: boolean
      internal_label: string
      directory: {
        id: string
        name: string
      }[]
    }[]
    note_config: {
      note_type_id: string
      min_number_of_notes: number
      frequency: 'Day' | 'Week' | 'Month' | 'Year'
    }[]
    title: string
    program_fields: {
      name: string
      type: string
      required: boolean
      values: string[]
      editable: boolean
      auditable: boolean
      vital: boolean
    }[]
  }

  const { register, control, handleSubmit, setValue } = useForm<FormValues>({
    defaultValues: {
      enabled_billing: false,
      billing: [
        {
          diagnostic_code: '',
          service_code: '',
          amount_per_client: '',
          is_capitated: false,
          internal_label: '',
          directory: [
            {
              id: '',
              name: '',
            },
          ],
        },
      ],
      note_config: [
        {
          note_type_id: '',
          min_number_of_notes: 0,
          frequency: 'Day',
        },
      ],
      title: '',
      program_fields: [
        {
          name: '',
          type: '',
          required: false,
          values: [],
          editable: false,
          auditable: false,
          vital: false,
        },
      ],
    },
    mode: 'onBlur',
  })

  const { fields, append, remove } = useFieldArray({
    name: 'program_fields',
    control,
  })

  const {
    fields: fields2,
    append: append2,
    remove: remove2,
  } = useFieldArray({
    name: 'billing',
    control,
  })

  const {
    fields: fields3,
    append: append3,
    remove: remove3,
  } = useFieldArray({
    name: 'note_config',
    control,
  })

  const onSubmit = (data: any) => {
    values?.map((item) => {
      let val: string[] = []
      item?.value?.map((el: any) => {
        val.push(el?.options)
      })
      data.program_fields[item.index].values = val || []
    })
    data?.billing?.map((item: any, index: number) => {
      data.billing[index].is_capitated =
        item?.is_capitated === 'capitated' ? true : false
    })
    directory?.map((item) => {
      data.billing[item.index].directory = item?.value?.map((elt: any) => {
        return {
          id: elt?.id,
          name: elt?.businessName,
        }
      })
    })
    noteType?.map((item) => {
      data.note_config[item.index].note_type_id = item?.value?.id
    })
    console.log({ data, noteType, directory })
    mutate(data)
  }
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Program Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      setValue('title', '')
      setValue('program_fields', [
        {
          name: '',
          type: '',
          required: false,
          values: [],
          editable: false,
          auditable: false,
          vital: false,
        },
      ])
      setRefetch(!refetch)
      close()
    }
    if (isError) {
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', xl: 'calc(100vw - 280px)' },
              height: '100vh',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  bgcolor: '#FBFBFB',
                  height: 60,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  px: 3,
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#EDEDED',
                    px: 3,
                    py: 0.8,
                    cursor: 'pointer',
                    borderRadius: 2,
                  }}
                  onClick={close}
                >
                  Back
                </Box>
                <Typography sx={{ fontWeight: 'bold' }}>New Program</Typography>
                <Box></Box>
              </Box>
              <Box
                sx={{
                  py: 3,
                  px: 7,
                  height: 'calc(100vh - 140px)',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                }}
              >
                <Box>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Setup Insurance & Billing
                  </Typography>
                  <FormControlLabel
                    name='enabled_billing'
                    label='Enable Billing'
                    control={<Checkbox {...register(`enabled_billing`)} />}
                  />
                </Box>
                {fields2?.map((item, index) => (
                  <Box sx={{ my: 1 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                        >
                          Diagnostic Code
                        </Typography>
                        <TextField
                          size='small'
                          sx={{ width: 250 }}
                          {...register(`billing.${index}.diagnostic_code`, {
                            required: true,
                          })}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                        >
                          Service Code
                        </Typography>
                        <TextField
                          size='small'
                          sx={{ width: 250 }}
                          {...register(`billing.${index}.service_code`, {
                            required: true,
                          })}
                        />
                      </Box>
                      <Box
                        sx={{
                          height: 40,
                          mt: 3,
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <RadioGroup
                          value={
                            isCapitated?.filter(
                              (item) => item?.index === index
                            )[0]?.value || 'noncapitated'
                          }
                          onChange={(e) => {
                            if (isCapitated?.some((el) => el.index === index)) {
                              setIsCapitated((state) => [
                                ...state.filter(
                                  (val: any) => val.index !== index
                                ),
                                { index: index, value: e.target.value },
                              ])
                            } else {
                              setIsCapitated((state) => [
                                ...state,
                                { index: index, value: e.target.value },
                              ])
                            }
                          }}
                          name='isCapitated'
                          row
                        >
                          <FormControlLabel
                            value='capitated'
                            control={
                              <Radio
                                {...register(`billing.${index}.is_capitated`, {
                                  required: true,
                                })}
                              />
                            }
                            label='Capitated'
                          />
                          <FormControlLabel
                            value='noncapitated'
                            control={
                              <Radio
                                {...register(`billing.${index}.is_capitated`, {
                                  required: true,
                                })}
                              />
                            }
                            label='Non Capitated'
                          />
                        </RadioGroup>
                      </Box>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                        >
                          Choose Insurance
                        </Typography>

                        <Autocomplete
                          options={directories?.data?.directories || []}
                          getOptionLabel={(item: any) => item.name}
                          size='small'
                          multiple
                          onChange={(event, value) => {
                            if (directory?.some((el) => el.index === index)) {
                              setDirectory((state) => [
                                ...state.filter(
                                  (val: any) => val.index !== index
                                ),
                                { index: index, value: value },
                              ])
                            } else {
                              setDirectory((state) => [
                                ...state,
                                { index: index, value: value },
                              ])
                            }
                          }}
                          renderTags={(value, getTagProps) => (
                            <Box
                              sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                                flexWrap: 'wrap',
                              }}
                            >
                              {value.map((option, index) => (
                                <Box
                                  sx={{
                                    bgcolor: '#F4FAFF',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    border: '1px solid #ECEDED',
                                    px: 1,
                                    py: 0.5,
                                    borderRadius: 1.5,
                                  }}
                                >
                                  <Avatar sx={{ width: 20, height: 20 }} />
                                  <Typography
                                    sx={{ fontWeight: 'bold', fontSize: 12 }}
                                  >
                                    {option?.businessName}
                                  </Typography>
                                  <IconButton
                                    sx={{
                                      border: '1px solid gray',
                                      width: 16,
                                      height: 16,
                                    }}
                                    onClick={() =>
                                      getTagProps({ index })?.onDelete(option)
                                    }
                                  >
                                    <Close
                                      sx={{
                                        width: 13,
                                        height: 13,
                                        color: 'gray',
                                      }}
                                    />
                                  </IconButton>
                                </Box>
                              ))}
                            </Box>
                          )}
                          renderOption={(props, option) => (
                            <Box
                              component='li'
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 6,
                                width: '100%',
                              }}
                              {...props}
                            >
                              <Avatar sx={{ width: 45, height: 45 }} />

                              <Typography sx={{ width: 200 }}>
                                {option?.businessName}
                              </Typography>
                            </Box>
                          )}
                          sx={{ width: 515 }}
                          renderInput={(params) => (
                            <TextField {...params} size='small' fullWidth />
                          )}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                        >
                          Amount Per Client
                        </Typography>
                        <TextField
                          size='small'
                          sx={{ width: 200 }}
                          {...register(`billing.${index}.amount_per_client`, {
                            required: true,
                          })}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                        >
                          Internal Label
                        </Typography>
                        <TextField
                          size='small'
                          sx={{ width: 250 }}
                          {...register(`billing.${index}.internal_label`, {
                            required: true,
                          })}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          mt: 3,
                        }}
                      >
                        {fields2?.length > 1 && (
                          <IconButton
                            sx={{
                              bgcolor: 'error.light',
                              '&:hover': {
                                bgcolor: 'error.light',
                              },
                            }}
                            onClick={() => {
                              remove2(index)
                            }}
                          >
                            <Remove sx={{ color: 'white' }} />
                          </IconButton>
                        )}
                        {index === fields2?.length - 1 && (
                          <IconButton
                            sx={{
                              bgcolor: 'primary.main',
                              '&:hover': {
                                bgcolor: 'primary.main',
                              },
                            }}
                            onClick={() => {
                              append2({
                                diagnostic_code: '',
                                service_code: '',
                                amount_per_client: '',
                                is_capitated: false,
                                internal_label: '',
                                directory: [
                                  {
                                    id: '',
                                    name: '',
                                  },
                                ],
                              })
                            }}
                          >
                            <Add sx={{ color: 'white' }} />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  </Box>
                ))}

                <Box sx={{ my: 3 }}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Attatch Note
                  </Typography>
                  {fields3?.map((item, index) => (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                        >
                          Choose
                        </Typography>
                        <Autocomplete
                          options={noteTypes?.data?.noteTypes || []}
                          getOptionLabel={(item: any) => item?.title}
                          size='small'
                          fullWidth
                          sx={{ width: 250 }}
                          onChange={(event, value) => {
                            if (noteType?.some((el) => el.index === index)) {
                              setNoteType((state) => [
                                ...state.filter(
                                  (val: any) => val.index !== index
                                ),
                                { index: index, value: value },
                              ])
                            } else {
                              setNoteType((state) => [
                                ...state,
                                { index: index, value: value },
                              ])
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size='small'
                              fullWidth
                              sx={{ bgcolor: 'white' }}
                            />
                          )}
                        />
                      </Box>

                      <Box>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                        >
                          Minimum Number of notes
                        </Typography>
                        <TextField
                          size='small'
                          sx={{ width: 250 }}
                          {...register(
                            `note_config.${index}.min_number_of_notes`,
                            {
                              required: true,
                            }
                          )}
                        />
                      </Box>
                      <Box>
                        <Typography
                          sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                        >
                          Frequency
                        </Typography>
                        <Autocomplete
                          options={['Day', 'Week', 'Month', 'Year'] || []}
                          getOptionLabel={(item: any) => item}
                          size='small'
                          fullWidth
                          sx={{ width: 250 }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size='small'
                              fullWidth
                              sx={{ bgcolor: 'white' }}
                              {...register(`note_config.${index}.frequency`, {
                                required: true,
                              })}
                            />
                          )}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          mt: 3,
                        }}
                      >
                        {fields3?.length > 1 && (
                          <IconButton
                            sx={{
                              bgcolor: 'error.light',
                              '&:hover': {
                                bgcolor: 'error.light',
                              },
                            }}
                            onClick={() => {
                              remove3(index)
                            }}
                          >
                            <Remove sx={{ color: 'white' }} />
                          </IconButton>
                        )}
                        {index === fields3?.length - 1 && (
                          <IconButton
                            sx={{
                              bgcolor: 'primary.main',
                              '&:hover': {
                                bgcolor: 'primary.main',
                              },
                            }}
                            onClick={() => {
                              append3({
                                note_type_id: '',
                                min_number_of_notes: 0,
                                frequency: 'Day',
                              })
                            }}
                          >
                            <Add sx={{ color: 'white' }} />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                  ))}
                </Box>
                <Divider sx={{ my: 3 }} />
                <Typography sx={{ fontWeight: 'bold' }}>Add Fields</Typography>
                <Box>
                  <Typography
                    sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                  >
                    Title
                  </Typography>
                  <TextField
                    size='small'
                    sx={{ width: 400 }}
                    {...register('title', {
                      required: true,
                    })}
                  />
                </Box>
                {fields?.map((item, index2) => (
                  <Box
                    sx={{
                      my: 2,
                      display: 'flex',
                      // alignItems: 'center',
                      gap: 3,
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                      >
                        Question
                      </Typography>
                      <TextField
                        size='small'
                        sx={{ width: 300 }}
                        {...register(`program_fields.${index2}.name`, {
                          required: true,
                        })}
                      />
                    </Box>
                    <Box>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                      >
                        Question Type
                      </Typography>
                      {/* Radio = 0; Dropdown = 1; Text = 2; Checkbox = 3; Date = 4; */}
                      <Autocomplete
                        options={[
                          'Text',
                          'Dropdown',
                          'Checkbox',
                          'Radio',
                          'Date',
                        ]}
                        getOptionLabel={(item) => item}
                        size='small'
                        fullWidth
                        sx={{ width: 250 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size='small'
                            fullWidth
                            sx={{ bgcolor: 'white' }}
                            {...register(`program_fields.${index2}.type`, {
                              required: true,
                            })}
                          />
                        )}
                        onChange={(event, value: any) => {
                          setType((prev) => {
                            let newP = [...prev]
                            newP[index2] = value
                            return newP
                          })

                          if (
                            value === 'Dropdown' ||
                            value === 'Radio' ||
                            value === 'Checkbox'
                          ) {
                            const updatedFields = [...fields]
                            updatedFields[index2].values = ['']
                            // updatedFields[
                            //   index
                            // ].intake_category_fields[
                            //   index2
                            // ].intake_additional_config = [
                            //   {
                            //     intake_category_field_value: '',
                            //   },
                            // ]
                            setValue('program_fields', updatedFields)
                          } else {
                            const updatedFields = [...fields]
                            updatedFields[index2].values = []
                            setValue('program_fields', updatedFields)
                          }
                        }}
                      />
                    </Box>

                    <Box>
                      {item?.values?.map((item3, index3) => (
                        <Box>
                          <Box>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'flex-end',
                                gap: 2,
                              }}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    py: 0.5,
                                  }}
                                >
                                  {type?.[index2]?.toLowerCase()} {index3 + 1}
                                </Typography>
                                <TextField
                                  size='small'
                                  // sx={{ width: 300 }}
                                  // {...register(
                                  //   `program_fields.${index2}.values.${index3}`,
                                  //   {
                                  //     required: true,
                                  //   }
                                  // )}
                                  onChange={(e) => {
                                    const updatedFields = [...fields]
                                    updatedFields[index2].values[index3] =
                                      e.target.value
                                    setValue('program_fields', updatedFields)
                                  }}
                                />
                              </Box>
                              {item?.values?.length > 1 && (
                                <IconButton
                                  sx={{
                                    bgcolor: '#BCBCBC',
                                    '&:hover': {
                                      bgcolor: '#BCBCBC',
                                    },
                                  }}
                                  onClick={() => {
                                    const updatedFields = [...fields]
                                    updatedFields[index2].values =
                                      updatedFields[index2]?.values?.filter(
                                        (el, index5) => index5 !== index3
                                      )

                                    setValue('program_fields', updatedFields)
                                  }}
                                >
                                  <Remove sx={{ color: 'white' }} />
                                </IconButton>
                              )}
                            </Box>
                            <Divider sx={{ my: 2 }} />
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                              }}
                            >
                              {index3 === item?.values?.length - 1 && (
                                <IconButton
                                  sx={{
                                    bgcolor: 'primary.main',
                                    '&:hover': {
                                      bgcolor: 'primary.main',
                                    },
                                  }}
                                  onClick={() => {
                                    const updatedFields = [...fields]
                                    updatedFields[index2].values?.push('')

                                    setValue('program_fields', updatedFields)
                                  }}
                                >
                                  <Add sx={{ color: 'white' }} />
                                </IconButton>
                              )}
                            </Box>
                          </Box>
                        </Box>
                      ))}

                      {/* <Typography
                        sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                      >
                        Field values
                      </Typography>

                      <Autocomplete
                        options={csvData?.data || []}
                        getOptionLabel={(item: any) => item?.options}
                        size='small'
                        multiple
                        fullWidth
                        sx={{ width: 250 }}
                        renderInput={(params) => (
                          <Box
                            sx={{
                              display: 'flex',
                              gap: 1,
                              alignItems: 'center',
                            }}
                          >
                            <TextField
                              {...params}
                              size='small'
                              fullWidth
                              sx={{ bgcolor: 'white' }}
                            />
                            <CSVReader
                              onUploadAccepted={(results: any) => {
                                if (values?.some((el) => el.index === index2)) {
                                  setValues((state) => [
                                    ...state.filter(
                                      (val: any) => val.index !== index2
                                    ),
                                    { index: index2, value: results?.data },
                                  ])
                                } else {
                                  setValues((state) => [
                                    ...state,
                                    { index: index2, value: results?.data },
                                  ])
                                }
                              }}
                              config={{
                                header: true,
                                delimiter: '',
                                encoding: 'UTF-8',
                              }}
                            >
                              {({ getRootProps }: any) => (
                                <IconButton {...getRootProps()}>
                                  <CloudUploadOutlined
                                    sx={{ color: '#1B85E0' }}
                                  />
                                </IconButton>
                              )}
                            </CSVReader>
                          </Box>
                        )}
                      /> */}
                    </Box>

                    <Box sx={{ mt: 3 }}>
                      <FormControlLabel
                        name='required'
                        label={'Required'}
                        control={
                          <Checkbox
                            {...register(`program_fields.${index2}.required`)}
                          />
                        }
                      />
                      <FormControlLabel
                        name='vital'
                        label={'Vital'}
                        control={
                          <Checkbox
                            {...register(`program_fields.${index2}.vital`)}
                          />
                        }
                      />
                      <FormControlLabel
                        name='editable'
                        label={'Editable'}
                        control={
                          <Checkbox
                            {...register(`program_fields.${index2}.editable`)}
                          />
                        }
                      />
                      <FormControlLabel
                        name='auditable'
                        label={'Save History'}
                        control={
                          <Checkbox
                            {...register(`program_fields.${index2}.auditable`)}
                          />
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        mt: 3,
                      }}
                    >
                      {fields?.length > 1 && (
                        <IconButton
                          sx={{
                            bgcolor: 'error.light',
                            '&:hover': {
                              bgcolor: 'error.light',
                            },
                          }}
                          onClick={() => {
                            remove(index2)
                          }}
                        >
                          <Remove sx={{ color: 'white' }} />
                        </IconButton>
                      )}
                      {index2 === fields?.length - 1 && (
                        <IconButton
                          sx={{
                            bgcolor: 'primary.main',
                            '&:hover': {
                              bgcolor: 'primary.main',
                            },
                          }}
                          onClick={() => {
                            append({
                              name: '',
                              type: '',
                              required: false,
                              values: [],
                              editable: false,
                              auditable: false,
                              vital: false,
                            })
                          }}
                        >
                          <Add sx={{ color: 'white' }} />
                        </IconButton>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  bottom: 0,
                  width: { xs: '100vw', md: 'calc(100vw - 280px)' },
                  height: 80,
                  py: 3,
                  px: { xs: 5, sm: 10 },
                }}
              >
                <Button
                  variant='contained'
                  // onClick={handleSubmit}
                  type='submit'
                  sx={{ px: 4, py: 2.5 }}
                >
                  {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress sx={{ color: 'white' }} size={20} />
                      <Typography>submitting...</Typography>
                    </Box>
                  ) : (
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Submit Form
                    </Typography>
                  )}
                </Button>
              </Box>
            </form>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
