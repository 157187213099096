import { Box, Button, IconButton } from '@mui/material'
import {
  MRT_ColumnDef,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
  MRT_ToggleFullScreenButton,
} from 'material-react-table'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import AddRole from './AddRole'
import { usePost } from '../../hooks/usePost'
import { useFetch } from '../../hooks/useFetch'
import { Done, Edit, Visibility } from '@mui/icons-material'
import EditRole from './EditRole'

export type Role = {
  id: string
  name: string
  status: string
  createdAt: string
}

export default function Roles({
  data,
  permissions,
  refetch,
  setRefetch,
}: {
  data: any
  permissions: any
  refetch: boolean
  setRefetch: any
}) {
  const [cols, setCols] = useState([]) as any

  console.log({ data, permissions })

  let allp: any[] = []
  permissions?.permissionGroups?.map((item: any) => {
    allp?.push(...item?.permissions)
  })

  console.log({ allp })

  useEffect(() => {
    const colArray = [] as any

    allp &&
      allp?.map((item) => {
        colArray?.push({
          accessorFn: (originalRow: any) => (
            <Box>
              {originalRow?.permissions?.some?.(
                (elt: any) => elt?.id === item.id
              ) && <Done />}
            </Box>
          ),
          id: item?.name,
          header:
            item?.name?.split('.')?.[2] + ' ' + item?.name?.split('.')?.[1],
          size: 220,
        })
      })
    setCols(colArray)
  }, [])

  console.log({ cols })

  const [openAddRole, setOpenAddRole] = useState(false)
  const [openEditRole, setOpenEditRole] = useState(false)
  const [isView, setIsView] = useState(false)
  const [editData, setEditData] = useState<any>()

  const columns = useMemo<MRT_ColumnDef<Role>[]>(
    () => [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      ...cols,
      {
        accessorFn: (originalRow) =>
          moment(originalRow.createdAt).format('lll'),
        id: 'createdAt',
        header: 'Created At',
        size: 220,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        filterVariant: 'multi-select',
        size: 220,
      },
      {
        accessorKey: 'actions' as any,
        header: 'Actions',
        enableSorting: false,
        flex: 1,
        size: 200,
        enableColumnFilter: false,
        Cell: ({ row, table }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton
              onClick={() => {
                setEditData(row.original)
                setIsView(true)
                setOpenEditRole(true)
              }}
            >
              <Visibility sx={{ color: '#006CEA' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditData(row.original)
                setIsView(false)
                setOpenEditRole(true)
              }}
            >
              <Edit sx={{ color: '#006CEA' }} />
            </IconButton>
          </Box>
        ),
      },
    ],
    [cols]
  )

  console.log({ columns })

  return (
    <Box>
      <MaterialReactTable
        columns={columns}
        data={data?.data?.roles || []}
        renderToolbarInternalActions={({ table }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 1,
            }}
          >
            <Button
              variant='contained'
              sx={{ borderRadius: 5 }}
              onClick={() => setOpenAddRole(true)}
            >
              Add Role
            </Button>
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_ToggleDensePaddingButton table={table} />
            <MRT_ToggleFullScreenButton table={table} />
          </Box>
        )}
        initialState={{
          columnPinning: { right: ['actions'] },
        }}
      />
      <AddRole
        open={openAddRole}
        close={() => setOpenAddRole(false)}
        refetch={() => {}}
      />
      {openEditRole && editData && (
        <EditRole
          open={openEditRole}
          close={() => setOpenEditRole(false)}
          refetch={refetch}
          setRefetch={setRefetch}
          isView={isView}
          data={editData}
        />
      )}
    </Box>
  )
}
