import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Popover,
  TextField,
  Typography,
} from '@mui/material'
import {
  Add,
  CalendarMonth,
  Close,
  Flag,
  Search,
  Send,
} from '@mui/icons-material'
import { useState } from 'react'

export default function Messages() {
  const [isSearch, setIsSearch] = useState(false)
  function MessageCard() {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          my: 1,
        }}
      >
        <Avatar />
        <Box>
          <Typography sx={{ fontWeight: 'bold' }}>Dr. Nadia</Typography>
          <Typography sx={{ fontSize: 12 }}>
            I hope this email finds you well. I am writing to request a
            reschedule for my upcoming Kidney Checkup appointment, which is
            currently scheduled for June 22… 2023, at 10:00 AM. Due to an
            unexpected conflict, I am unable to attend the appointment at the
            designated time.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                5 Min ago
              </Typography>
              <Box sx={{ borderRadius: 5, border: '2px solid gray' }} />
              <Box sx={{ bgcolor: '#F4FAFF', borderRadius: 5, px: 1 }}>
                <Typography sx={{ color: '#10376F', fontSize: 12 }}>
                  Email
                </Typography>
              </Box>
              <Typography
                sx={{ color: 'primary.main', fontSize: 12, fontWeight: 'bold' }}
              >
                Change to original language
              </Typography>
              <Typography
                sx={{ color: 'black', fontSize: 12, fontWeight: 'bold' }}
              >
                (Spanish)
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: 1,
              width: '100%',
            }}
          >
            <Button>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 1,
                }}
              >
                <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                  Reply
                </Typography>
                <Send sx={{ width: 20, height: 20 }} />
              </Box>
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Grid
      item
      xs={12}
      sm={6}
      sx={{ borderLeft: '1px solid', borderLeftColor: '#F4F4F4' }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 3,
          py: 2,
        }}
      >
        <Typography
          sx={{
            color: '#0178DD',
            fontWeight: 'bold',
            display: isSearch ? 'none' : 'block',
          }}
        >
          Messages
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <TextField
            sx={{ display: isSearch ? 'block' : 'none' }}
            size='small'
            InputProps={{
              sx: {
                '&.MuiOutlinedInput-root': {
                  borderRadius: 8,
                },
              },
              startAdornment: <Search />,
              endAdornment: (
                <IconButton onClick={() => setIsSearch(false)}>
                  <Close />
                </IconButton>
              ),
            }}
            fullWidth
          />
          <Button
            variant='outlined'
            sx={{
              borderRadius: 5,
              borderColor: '#EBF3F9',
              bgcolor: '#F4FAFF',
              display: isSearch ? 'none' : 'block ',
            }}
            onClick={() => setIsSearch(true)}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Search sx={{ color: 'black' }} />
              <Typography
                sx={{
                  fontSize: 13,
                  color: 'black',
                }}
              >
                Search
              </Typography>
            </Box>
          </Button>
          <Button
            variant='outlined'
            sx={{ borderRadius: 5, bgcolor: '#E6F4FF' }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Add />
              <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                New
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          p: 3,
          height: '33vh',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: 0,
          },
        }}
      >
        <MessageCard />
        <Divider sx={{ my: 2 }} />
        <MessageCard />
        <Divider sx={{ my: 2 }} />
        <MessageCard />
        <Divider sx={{ my: 2 }} />
        <MessageCard />
      </Box>
    </Grid>
  )
}
