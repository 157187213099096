import { PeopleAlt } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Modal,
  Slide,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { usePost } from '../../../hooks/usePost'

export default function AddTemplate({
  open,
  close,
  refetch,
}: {
  open: boolean
  close: any
  refetch: any
}) {
  const timeUnits = [
    { name: 'Minutes', value: 'MINUTES' },
    { name: 'Hours', value: 'HOURS' },
    { name: 'Days', value: 'DAYS' },
    { name: 'Weeks', value: 'WEEKS' },
  ]
  const platforms = [
    { name: 'Text', value: 'TEXT' },
    { name: 'Email', value: 'EMAIL' },
    { name: 'Fax', value: 'FAX' },
    // { name: 'Phone Call', value: 'PHONE_CALL' },
  ]

  const [title, setTitle] = useState() as any
  const [every, setEvery] = useState() as any
  const [unit, setUnit] = useState() as any
  const [before, setBefore] = useState() as any
  const [before_unit, setBeforeUnit] = useState() as any
  const [platform, setPlatform] = useState() as any
  const { mutate, isLoading, isError, error, isSuccess } = usePost(
    '/public/appointment/reminder-templates'
  )

  const handleSubmit = () => {
    const data: any = {
      template: {
        name: title,
        schedules: [
          {
            every,
            unit,
            before,
            before_unit,
            platform: [platform],
          },
        ],
      },
    }
    console.log({ data })
    mutate(data)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Appointment Type Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      close()
      refetch()
    }
    if (isError) {
      console.log({ error })
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 500 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>Add Template</Typography>
              <Box></Box>
            </Box>
            <Box
              sx={{
                px: 7,
                py: { xs: 5, sm: 5 },
                height: 'calc(100vh - 140px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>
                  Title
                </Typography>
                <TextField
                  size='small'
                  fullWidth
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Box>
              <Box sx={{ my: 2 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Grid item xs={2}>
                    <Typography
                      sx={{
                        color: '#787878',
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}
                    >
                      Every
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      size='small'
                      onChange={(e) => setEvery(Number(e.target.value))}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Autocomplete
                      options={timeUnits}
                      getOptionLabel={(item) => item?.name}
                      size='small'
                      onChange={(event, value) => {
                        setUnit(value?.value)
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size='small' />
                      )}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box sx={{ my: 2 }}>
                <Grid
                  container
                  spacing={2}
                  sx={{ display: 'flex', alignItems: 'center' }}
                >
                  <Grid item xs={2}>
                    <Typography
                      sx={{
                        color: '#787878',
                        fontSize: 14,
                        fontWeight: 'bold',
                      }}
                    >
                      Before
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      size='small'
                      onChange={(e) => setBefore(Number(e.target.value))}
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <Autocomplete
                      options={timeUnits}
                      getOptionLabel={(item) => item?.name}
                      size='small'
                      onChange={(event, value) => {
                        setBeforeUnit(value?.value)
                      }}
                      renderInput={(params) => (
                        <TextField {...params} size='small' />
                      )}
                    />
                  </Grid>
                </Grid>
                <Box sx={{ py: 3 }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Platform
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                      py: 1,
                    }}
                  >
                    {platforms?.map((item) => (
                      <Box
                        sx={{
                          border:
                            platform === item?.value
                              ? '1px solid #90C3EE'
                              : '1px solid #EAF1F7',
                          py: 1,
                          px: 2,
                          borderRadius: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minWidth: 60,
                          cursor: 'pointer',
                        }}
                        onClick={() => setPlatform(item?.value)}
                      >
                        <Typography
                          sx={{
                            color:
                              platform === item?.value ? '#90C3EE' : '#BBBBBB',
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'fixed',
                bottom: 0,
                width: { xs: '100vw', sm: 500 },
                height: 80,
                py: 3,
                px: 5,
              }}
            >
              <Button
                variant='contained'
                sx={{ px: 10, py: 2.5 }}
                onClick={handleSubmit}
              >
                {/* {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CircularProgress sx={{ color: 'white' }} size={20} />
                    <Typography>submitting...</Typography>
                  </Box>
                ) : ( */}
                <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                  Add
                </Typography>
                {/* )} */}
              </Button>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
