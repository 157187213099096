import {
  CalendarMonth,
  Clear,
  Close,
  Delete,
  Draw,
  Edit,
  FormatShapes,
  Group,
  LocationOn,
  Print,
  Save,
  Search,
  Send,
} from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material'
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers'
import { useEffect, useRef, useState } from 'react'
import FileUpload from '../File/FileUpload'
import { useFetch } from '../../hooks/useFetch'
import { useForm } from 'react-hook-form'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import { Worker, Viewer, ScrollMode } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { pdfjs } from 'react-pdf'
import SignatureCanvas from 'react-signature-canvas'
import Draggable from 'react-draggable'

export default function EditDocument({
  open,
  close,
  documentData,
}: {
  open: boolean
  close: any
  documentData: any
}) {
  console.log({ documentData })
  const [openCanvas, setOpenCanvas] = useState(false)
  const [dataUrl, setDataUrl] = useState() as any
  const signatureRef: any = useRef()
  const [dragging, setDragging] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 }) as any

  console.log({ position })

  const saveSignature = () => {
    const dataUrlI = signatureRef.current.toDataURL()
    console.log({ dataUrlI })
    setDataUrl(dataUrlI)
  }

  const handleDragStart = (e: any) => {
    setDragging(true)
    // Calculate the offset between the cursor and the image position
    const offsetX = e.clientX - position.x
    const offsetY = e.clientY - position.y
    setPosition((prevPosition: any) => ({ ...prevPosition, offsetX, offsetY }))
    document.addEventListener('mousemove', handleDragMove)
    document.addEventListener('mouseup', handleDragEnd)
  }

  const handleDragMove = (e: any) => {
    if (dragging) {
      // Calculate the new position based on the cursor and the stored offset
      const x = e.clientX - position.offsetX
      const y = e.clientY - position.offsetY
      setPosition({ x, y })
    }
  }

  const handleDragEnd = () => {
    if (dragging) {
      setDragging(false)
      document.removeEventListener('mousemove', handleDragMove)
      document.removeEventListener('mouseup', handleDragEnd)
    }
  }

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', sm: 900 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                {documentData?.title}
              </Typography>
              <Box></Box>
            </Box>
            <Divider />
            <Box
              sx={{
                height: 'calc(100vh - 70px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                  justifyContent: 'space-between',
                  py: 1,
                  px: 10,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 120,
                      height: 55,
                      bgcolor: '#EFEFEF',
                      borderRadius: 2,
                      cursor: 'pointer',
                    }}
                    onClick={close}
                  >
                    <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                      Save As
                    </Typography>
                  </Box>
                  <Button
                    variant='contained'
                    type='submit'
                    // onClick={handleSubmit}
                    sx={{ px: 5, borderRadius: 2, height: 50 }}
                  >
                    {/* {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress sx={{ color: 'white' }} size={20} />
                      <Typography>submitting...</Typography>
                    </Box>
                  ) : ( */}
                    <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                      Save
                    </Typography>
                    {/* )} */}
                  </Button>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  <Send sx={{ color: '#3091E3' }} />
                  <Print sx={{ color: '#3091E3' }} />
                  <Delete sx={{ color: '#3091E3' }} />
                </Box>
              </Box>
              <Divider />
              <Box>
                <Grid container>
                  <Grid item xs={8}>
                    <Box
                      sx={{
                        height: 'calc(100vh - 140px)',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                          width: 0,
                        },
                        // border: '1px solid red',
                      }}
                    >
                      <Worker
                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                      >
                        <Viewer
                          fileUrl={documentData?.documentCurrentVersion?.url}
                          // defaultScale={1}
                          // scrollMode={ScrollMode.Page}
                        />
                      </Worker>
                    </Box>
                  </Grid>
                  <Grid item xs={0.4}>
                    <Divider orientation='vertical' />
                  </Grid>
                  <Grid item xs={3.6}>
                    <Box>
                      <Box sx={{ px: 2, py: 5 }}>
                        <Typography
                          sx={{
                            color: '#4A5E7B',
                            fontWeight: 'bold',
                            fontSize: 15,
                          }}
                        >
                          TEMPLATES TO ADD
                        </Typography>
                        <TextField
                          size='small'
                          sx={{ bgcolor: '#F4FAFF' }}
                          InputProps={{
                            sx: {
                              '&.MuiOutlinedInput-root': {
                                borderRadius: 8,
                              },
                            },
                            endAdornment: <Search />,
                            // endAdornment: (
                            //   <IconButton onClick={() => setIsSearch(false)}>
                            //     <Close />
                            //   </IconButton>
                            // ),
                          }}
                        />
                      </Box>
                      <Divider />
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: 3,
                          py: 5,
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                            px: 3,
                            py: 1,
                            borderRadius: 2,
                            width: 220,
                          }}
                        >
                          <IconButton>
                            <Draw
                              sx={{ color: '#1F87E1' }}
                              onClick={() => setOpenCanvas(true)}
                            />
                          </IconButton>
                          <Typography
                            sx={{ color: '#718098', fontWeight: 'bold' }}
                          >
                            Signature Box
                          </Typography>
                        </Box>
                        {openCanvas && (
                          <Box
                            sx={{
                              width: 250,
                              boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                              p: 2,
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  py: 1,
                                }}
                              >
                                <IconButton
                                  onClick={() => {
                                    saveSignature()
                                    setOpenCanvas(false)
                                  }}
                                >
                                  <Save sx={{ color: '#1F87E1' }} />
                                </IconButton>
                                <IconButton
                                  onClick={() => signatureRef?.current?.clear()}
                                >
                                  <Delete sx={{ color: 'red' }} />
                                </IconButton>
                              </Box>
                              <IconButton onClick={() => setOpenCanvas(false)}>
                                <Close sx={{ color: 'red' }} />
                              </IconButton>
                            </Box>

                            <Box
                              sx={{
                                bgcolor: '#CFCFCD',
                                borderRadius: 2,
                                width: 220,
                              }}
                            >
                              <SignatureCanvas
                                ref={signatureRef}
                                penColor='black'
                                canvasProps={{
                                  width: 220,
                                  height: 150,
                                  className: 'sigCanvas',
                                }}
                              />
                            </Box>
                          </Box>
                        )}
                        {dataUrl && (
                          <Draggable>
                            <Box
                              component='img'
                              src={dataUrl}
                              draggable='true'
                              // onDragStart={(e) => e.preventDefault()} // Prevent default drag behavior
                              style={
                                {
                                  // position: 'absolute',
                                  // left: position.x,
                                  // top: position.y,
                                  // cursor: 'move',
                                }
                              }
                              onMouseDown={handleDragStart}
                            />
                          </Draggable>
                        )}
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                            px: 3,
                            py: 1,
                            borderRadius: 2,
                            width: 220,
                          }}
                        >
                          <IconButton>
                            <FormatShapes sx={{ color: '#1F87E1' }} />
                          </IconButton>
                          <Typography
                            sx={{ color: '#718098', fontWeight: 'bold' }}
                          >
                            Text Box
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                            px: 3,
                            py: 1,
                            borderRadius: 2,
                            width: 220,
                          }}
                        >
                          <IconButton>
                            <Group sx={{ color: '#1F87E1' }} />
                          </IconButton>
                          <Typography
                            sx={{ color: '#718098', fontWeight: 'bold' }}
                          >
                            Client Name
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                            px: 3,
                            py: 1,
                            borderRadius: 2,
                            width: 220,
                          }}
                        >
                          <IconButton>
                            <CalendarMonth sx={{ color: '#1F87E1' }} />
                          </IconButton>
                          <Typography
                            sx={{ color: '#718098', fontWeight: 'bold' }}
                          >
                            Date
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
