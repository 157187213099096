import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Modal,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
// import { useFetch } from '../../hooks/useFetch'
import { CheckBox } from '@mui/icons-material'
import { useEffect, useState } from 'react'
// import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
// import { usePatch } from '../../hooks/usePatch'
import FileUpload from '../File/FileUpload'
import { usePatch } from '../../hooks/usePatch'
import { usePost } from '../../hooks/usePost'

export default function EditUser({
  open,
  close,
  refetch,
  setRefetch,
  data,
  isView,
}: {
  open: boolean
  close: any
  refetch: boolean
  setRefetch: any
  data: any
  isView: boolean
}) {
  // const { data: roles } = useFetch('roles', '/roles')
  // console.log({ roles, data })

  console.log({ data })

  const { mutate, isSuccess, isError, error, isLoading } = usePatch(
    `/public/user/users/${data?.id}`
  )

  let programIds: string[] = []

  data?.programs?.map((item: any) => {
    programIds.push(item.id)
  })

  const { data: programs, mutate: fetchProgram } = usePost(
    '/public/configs/programs/list'
  )
  const { mutate: fetchRoles, data: roles } = usePost(
    '/public/access-control/roles/list'
  )
  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchProgram(dataP)
  }, [])

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchRoles(dataP)
  }, [open])

  const [firstName, setFirstName] = useState<string>()
  const [middleName, setMiddleName] = useState<string>()
  const [lastName, setLastName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [phoneNumber, setPhone] = useState<string>()
  const [roleId, setRoleId] = useState<string>()
  const [profile_picture_url, setProfile] = useState<string>()
  const [imageState, setImageState] = useState<any>()
  const [selectedPrograms, setSelectedPrograms] = useState<string[]>(programIds)

  const handleSubmit = () => {
    const data = {
      ...(firstName && { firstName }),
      ...(middleName && { middleName }),
      ...(lastName && { lastName }),
      ...(email && { email }),
      ...(roleId && { roleId }),
      ...(phoneNumber && { phoneNumber }),
      ...(selectedPrograms && { programIds: selectedPrograms }),
    } as any
    console.log({ data })
    mutate(data)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('User Updated Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      setRefetch(!refetch)
      close()
    }
    if (isError) {
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  console.log({ selectedPrograms, programIds })

  useEffect(() => {
    setSelectedPrograms(programIds)
  }, [])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 700 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={() => {
                  setSelectedPrograms([])
                  close()
                }}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                {isView ? 'View User' : 'Edit User'}
              </Typography>
              <Box> </Box>
            </Box>
            <Box
              sx={{
                p: { xs: 5, sm: 10 },
                height: 'calc(100vh - 140px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Box sx={{ my: 2 }}>
                {isView ? (
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box
                      component='img'
                      src={data?.profile_picture_url}
                      sx={{ width: 200, height: 200 }}
                    />
                  </Box>
                ) : (
                  <FileUpload
                    name='image'
                    type='PROFILE_IMAGE'
                    defaultValue={data?.profile_picture_url}
                    setFileState={setImageState}
                  />
                )}
              </Box>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    First Name
                  </Typography>
                  <TextField
                    size='small'
                    fullWidth
                    onChange={(e) => setFirstName(e.target.value)}
                    disabled={isView}
                    defaultValue={data?.firstName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Middle Name
                  </Typography>
                  <TextField
                    size='small'
                    fullWidth
                    onChange={(e) => setMiddleName(e.target.value)}
                    disabled={isView}
                    defaultValue={data?.middleName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 'bold' }}>Last Name</Typography>
                  <TextField
                    size='small'
                    fullWidth
                    onChange={(e) => setLastName(e.target.value)}
                    disabled={isView}
                    defaultValue={data?.lastName}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 'bold' }}>Phone No</Typography>
                  <TextField
                    size='small'
                    fullWidth
                    onChange={(e) => setPhone(e.target.value)}
                    disabled={isView}
                    defaultValue={data?.phoneNumber}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 'bold' }}>Email</Typography>
                  <TextField
                    size='small'
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                    disabled={isView}
                    defaultValue={data?.email}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography sx={{ fontWeight: 'bold' }}>Role</Typography>
                  <Autocomplete
                    options={[]}
                    filterSelectedOptions={true}
                    getOptionLabel={(option: any) => option.name}
                    onChange={(event, value: any) => setRoleId(value?.id)}
                    disabled={isView}
                    defaultValue={{ name: data?.role?.name }}
                    size='small'
                    renderInput={(params: any) => (
                      <TextField {...params} name='roleId' placeholder='Role' />
                    )}
                  />
                </Grid>
              </Grid>
              <Typography sx={{ fontWeight: 'bold', my: 2 }}>
                Programs
              </Typography>
              {isView ? (
                <Box
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 2,
                    border: '1px solid #EEF3F7',
                    borderRadius: 2,
                    my: 1,
                  }}
                >
                  {data?.programs?.map((program: any) => (
                    <Box
                      sx={{
                        py: 1,
                        px: 2,
                        border: '1px solid #006CEA',
                        borderRadius: 2,
                        cursor: 'pointer',
                      }}
                    >
                      <Typography
                        sx={{
                          color: '#006CEA',
                        }}
                      >
                        {program?.title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 2,
                    border: '1px solid #EEF3F7',
                    borderRadius: 2,
                    my: 1,
                  }}
                >
                  {programs?.data?.programs?.map((program: any) => (
                    <Box
                      sx={{
                        py: 1,
                        px: 2,
                        border: selectedPrograms?.includes(program?.id)
                          ? '1px solid #006CEA'
                          : '1px solid #C1C1C1',
                        borderRadius: 2,
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        if (selectedPrograms?.includes(program?.id)) {
                          setSelectedPrograms((state: any) => [
                            ...state.filter(
                              (programId: any) => programId !== program?.id
                            ),
                          ])
                        } else {
                          setSelectedPrograms((state: any) => [
                            ...state,
                            program?.id,
                          ])
                        }
                      }}
                    >
                      <Typography
                        sx={{
                          color: selectedPrograms?.includes(program?.id)
                            ? '#006CEA'
                            : '#ADADAD',
                        }}
                      >
                        {program?.name}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              )}
            </Box>
            {!isView && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  bottom: 0,
                  width: { xs: '100vw', sm: 700 },
                  height: 80,
                  p: 3,
                  bgcolor: '#F5F5F5',
                }}
              >
                <Button variant='contained' onClick={handleSubmit}>
                  {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress sx={{ color: 'white' }} size={20} />
                      <Typography>saving...</Typography>
                    </Box>
                  ) : (
                    <Typography>Save</Typography>
                  )}
                </Button>
              </Box>
            )}
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
