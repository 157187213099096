import { UseMutateFunction, useMutation } from 'react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { BASE_URL } from '../utils/config'

interface MUTATE {
  mutate: UseMutateFunction<AxiosResponse<any, any>, unknown, void, unknown>
  isLoading: boolean
  isError: boolean
  error: AxiosError | any
  isSuccess: boolean
  data: any
}

export function useAuthentication(endpoint: string) {
  const { mutate, isLoading, isError, error, isSuccess, data }: MUTATE =
    useMutation((reqData) => {
      console.log({ reqData })
      return axios.post(`${BASE_URL}${endpoint}`, reqData)
    })
  return { mutate, isLoading, isError, error, isSuccess, data }
}
