import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import ehr from '../assets/Frame.png'
import { useEffect, useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useAuthentication } from '../hooks/useAuthentication'
import { useSnackbar } from 'notistack'

export default function Login() {
  const [showPassword, setShowPassword] = useState(false)
  const [email, setEmail] = useState<string>()
  const [password, setPassword] = useState<string>()
  const navigate = useNavigate()

  const { mutate, isLoading, data, isError, error, isSuccess } =
    useAuthentication('/public/user/login')

  const handleLogin = () => {
    const data: any = { email, password }
    mutate(data)
  }

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isError) {
      console.log({ error })
      enqueueSnackbar(error?.response?.data?.message || 'Network Error', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
    if (isSuccess) {
      window.localStorage.setItem('user', JSON.stringify(data?.data))
      navigate('/')
    }
  }, [isSuccess, isError])

  return (
    <Box
      sx={{
        bgcolor: '#FBFBFB',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Grid
        container
        sx={{
          display: 'flex',
          height: '100%',
        }}
      >
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: '90%',
                height: '90%',
                borderRadius: 10,
                bgcolor: '#F7F7F7',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box component='img' src={ehr} sx={{ width: '80%' }} />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          // sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: { xs: '90%', sm: '70%', md: 400 },
                height: '100%',
                justifyContent: 'space-between',
                py: 20,
              }}
            >
              <Box>
                <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                  Login
                </Typography>
                <Typography>Provide email & password to continue</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: 13, fontWeight: 'bold', mb: 1 }}>
                  Email
                </Typography>
                <TextField
                  type='text'
                  placeholder='user@gmail.com'
                  onChange={(e) => setEmail(e.target.value)}
                  fullWidth
                />

                <Typography
                  sx={{ fontSize: 13, fontWeight: 'bold', mt: 4, mb: 1 }}
                >
                  Password
                </Typography>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder='Password'
                  fullWidth
                />
                <Box
                  sx={{ display: 'flex', justifyContent: 'flex-end', my: 2 }}
                >
                  <Button
                    onClick={() => navigate('/resetpassword')}
                    sx={{ my: 1 }}
                  >
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Forgot Password?
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box>
                <Button
                  variant='contained'
                  fullWidth
                  sx={{ color: 'white', py: 2 }}
                  onClick={handleLogin}
                >
                  {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress sx={{ color: 'white' }} />
                      <Typography>Signing in...</Typography>
                    </Box>
                  ) : (
                    <Typography>Login</Typography>
                  )}
                </Button>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    sx={{ my: 1 }}
                    onClick={() => navigate('/companyregister')}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Company Registration
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant='body2'
                  sx={{ textAlign: 'center', fontSize: 12, color: 'gray' }}
                >
                  By clicking “Log In”, on behalf of myself and the practice, I
                  agree to the GoodSeed Terms of Service and Privacy Policy.
                </Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  position: 'absolute',
                  bottom: 20,
                  width: 400,
                }}
              >
                <Typography sx={{ fontSize: 12 }}>
                  @{new Date().getFullYear()} GoodSeedCdc
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'cenetr',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 'bold', color: '#1F7DEC', fontSize: 12 }}
                  >
                    Privacy
                  </Typography>
                  <Divider flexItem orientation='vertical' />
                  <Typography
                    sx={{ fontWeight: 'bold', color: '#1F7DEC', fontSize: 12 }}
                  >
                    Terms
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
