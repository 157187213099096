import { Box, Button, IconButton, Typography } from '@mui/material'
import {
  MRT_ColumnDef,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
  MRT_ToggleFullScreenButton,
} from 'material-react-table'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import AddUser from './AddUser'
import { usePost } from '../../hooks/usePost'
import { Done, Edit, Visibility } from '@mui/icons-material'
import EditUser from './EditUser'

export default function Users() {
  const { mutate, data } = usePost('/public/user/users/list')
  const [refetch, setRefetch] = useState(false)
  const [isView, setIsView] = useState(false)

  useEffect(() => {
    const data: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    mutate(data)
  }, [refetch])

  console.log({ data })

  const [openAddUser, setOpenAddUser] = useState(false)
  const [openEditUser, setOpenEditUser] = useState(false)
  const [openViewUser, setOpenViewUser] = useState(false)
  const [editData, setEditData] = useState<any>()
  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'firstName',
        header: 'First Name',
      },
      {
        accessorKey: 'middleName',
        header: 'Middle Name',
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
      },

      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorKey: 'phoneNumber',
        header: 'Phone Number',
      },
      {
        accessorFn: (originalRow) => (
          <Box
            sx={{
              bgcolor: '#E6F4FF',
              display: 'flex',
              gap: 1,
              alignItems: 'center',
              px: 3,
              py: 1,
              borderRadius: 5,
            }}
          >
            <Done />
            <Typography>{originalRow.role.name}</Typography>
          </Box>
        ),
        id: 'roleName',
        header: 'Role',
        size: 220,
      },
      // {
      //   accessorFn: (originalRow) =>
      //     moment(originalRow.createdAt).format('lll'),
      //   id: 'createdAt',
      //   header: 'Created At',
      //   size: 220,
      // },
      // {
      //   accessorKey: 'status',
      //   header: 'Status',
      //   filterVariant: 'multi-select',
      //   size: 220,
      // },
      {
        accessorKey: 'actions' as any,
        header: 'Actions',
        enableSorting: false,
        flex: 1,
        size: 200,
        enableColumnFilter: false,
        Cell: ({ row, table }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <IconButton
              onClick={() => {
                setEditData(row.original)
                setIsView(true)
                setOpenEditUser(true)
              }}
            >
              <Visibility sx={{ color: '#006CEA' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditData(row.original)
                setIsView(false)
                setOpenEditUser(true)
              }}
            >
              <Edit sx={{ color: '#006CEA' }} />
            </IconButton>
          </Box>
        ),
      },
    ],
    []
  )

  return (
    <Box>
      <MaterialReactTable
        columns={columns}
        data={data?.data?.users || []}
        renderToolbarInternalActions={({ table }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 1,
            }}
          >
            <Button
              variant='contained'
              sx={{ borderRadius: 5 }}
              onClick={() => setOpenAddUser(true)}
            >
              Add User
            </Button>
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_ToggleDensePaddingButton table={table} />
            <MRT_ToggleFullScreenButton table={table} />
          </Box>
        )}
      />
      <AddUser
        open={openAddUser}
        close={() => setOpenAddUser(false)}
        refetch={() => {}}
      />
      {openEditUser && editData && (
        <EditUser
          open={openEditUser}
          close={() => setOpenEditUser(false)}
          refetch={refetch}
          setRefetch={setRefetch}
          data={editData}
          isView={isView}
        />
      )}
    </Box>
  )
}
