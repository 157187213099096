import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
} from '@mui/material'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'

export default function AddReportingLabel({
  open,
  close,
  refetch,
  setRefetch,
  fieldLabels,
  fieldIndex,
}: {
  open: boolean
  close: any
  refetch: boolean
  setRefetch: any
  fieldLabels: string[]
  fieldIndex: number
}) {
  const { mutate: listFields, data: fields } = usePost(
    '/public/reporting/fields'
  )
  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
      start_date: '2022-07-25T21:00:00Z',
      end_date: new Date()?.toISOString()?.split('.')[0] + 'Z',
    }
    listFields(datap)
  }, [])
  //   const [selectedFields, setSelectedFields] = useState<String[]>([])
  const [field, setField] = useState() as any

  console.log(fieldLabels, { fieldIndex })

  const { mutate, isLoading, isSuccess, isError, error } = usePost(
    '/public/reporting/summary/fields'
  )

  let index = fieldIndex

  if (fieldIndex > fieldLabels.length) {
    index = fieldLabels.length
  }

  console.log({ index })

  const handleSubmit = () => {
    let field_labels = fieldLabels
    field_labels[index] = fields?.data?.field?.find(
      (item: any) => item?.id === field
    )?.name

    const data: any = {
      field_labels,
    }
    console.log({ data })
    mutate(data)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Field Added Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      setRefetch(!refetch)
      close()
    }
    if (isError) {
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 500 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>Add Labels</Typography>
              <Box> </Box>
            </Box>
            <Box sx={{ px: 3, pt: 3 }}>
              <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                Choose Reporting Label
              </Typography>
            </Box>
            <Box
              sx={{
                px: 5,
                height: 'calc(100vh - 140px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Box sx={{ py: 2 }}>
                <Box
                  sx={{
                    px: 1,
                    borderRadius: 1,
                  }}
                >
                  <FormControl>
                    <RadioGroup
                      aria-labelledby='demo-row-radio-buttons-group-label'
                      name='row-radio-buttons-group'
                      onChange={(e) => setField(e.target.value)}
                      value={field}
                    >
                      {fields?.data?.field?.map((item: any) => {
                        if (item?.name !== '') {
                          return (
                            <FormControlLabel
                              value={item?.id}
                              control={<Radio />}
                              label={item?.name}
                            />
                          )
                        }
                      })}
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'fixed',
                bottom: 0,
                width: { xs: '100vw', sm: 500 },
                height: 80,
                py: 3,
                px: { xs: 5, sm: 10 },
              }}
            >
              <Button
                variant='contained'
                onClick={handleSubmit}
                sx={{ px: 4, py: 2.5 }}
              >
                {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CircularProgress sx={{ color: 'white' }} size={20} />
                    <Typography>submitting...</Typography>
                  </Box>
                ) : (
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Add Field
                  </Typography>
                )}
              </Button>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
