import { Add, Delete, Edit, Search, Visibility } from '@mui/icons-material'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  IconButton,
  Popover,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import NewIntake from '../components/Configurations/Intake/NewIntake'
import { usePost } from '../hooks/usePost'
import NewProgram from '../components/Configurations/Program/NewProgram'
import AddDirectoryCategory from '../components/Configurations/Directory/DirectoryCategory/AddCategory'
import { useFetch } from '../hooks/useFetch'
import AddDirectoryDepartment from '../components/Configurations/Directory/DirectoryDepartment/AddDepartment'
import axios from 'axios'
import { BASE_URL } from '../utils/config'
import { authHeader } from '../utils/auth'
import AddDirectoryTag from '../components/Configurations/Directory/DirectoryTag/AddTag'
import ProgramDetails from '../components/Configurations/Program/ProgramDetails'
import IntakeDetails from '../components/Configurations/Intake/IntakeDetails'
import EditIntake from '../components/Configurations/Intake/EditIntake'
import EditTag from '../components/Configurations/Directory/DirectoryTag/EditTag'
import IntakePdf from '../components/Configurations/Intake/IntakePdf'
import AddAppointmentType from '../components/Configurations/Appointment/AddAppointmentType'
import AddLedgerType from '../components/Configurations/Ledger/NewLedgerType'
import AddLedgerSource from '../components/Configurations/Ledger/NewLedgerSource'
import AddDocumentCategory from '../components/Configurations/Document/AddDocumentCategory'
import AddDocumentSubCategory from '../components/Configurations/Document/AddDocumentSubCategory'
import AddNoteType from '../components/Configurations/Note/AddNoteType'
import AddBarrier from '../components/Configurations/Barrier/AddBarrier'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function CustomTabPanelVertical(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function a11yPropsVertical(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Configurations() {
  const [value, setValue] = useState(0)
  const [openNewIntake, setOpenNewIntake] = useState(false)
  const [openNewProgram, setOpenNewProgram] = useState(false)
  const [openNewDirectoryCategory, setOpenNewDirectoryCategory] =
    useState(false)
  const [openNewDirectoryDepartment, setOpenNewDirectoryDepartment] =
    useState(false)
  const [openNewDirectoryTag, setOpenNewDirectoryTag] = useState(false)

  const [subDirectoryCategory, setSubDirectoryCategory] = useState() as any
  const [subDirectoryDepartment, setSubDirectoryDepartment] = useState() as any

  const [directoryDepartments, setDirectoryDepartments] = useState() as any
  const [directoryTags, setDirectoryTags] = useState() as any

  const [refetchDep, setRefetchDep] = useState(false)
  const [refetchTag, setRefetchTag] = useState(false)

  const [openProgramDetail, setOpenProgramDetail] = useState(false)
  const [openIntakeDetail, setOpenIntakeDetail] = useState(false)

  const [openEditProgram, setOpenEditProgram] = useState(false)
  const [openEditIntake, setOpenEditIntake] = useState(false)

  const [openEditTag, setOpenEditTag] = useState(false)
  const [openEditDepartment, setOpenEditDepartment] = useState(false)
  const [openEditCategory, setOpenEditCategory] = useState(false)

  const [toEditTag, setToEditTag] = useState(false)
  const [toEditDepartment, setToEditDepartment] = useState(false)
  const [toEditCategory, setToEditCategory] = useState(false)

  const [opneNewLedgerType, setOpenNewLedgerType] = useState(false)
  const [opneNewLedgerSource, setOpenNewLedgerSource] = useState(false)

  const [openNewDocuemntCategory, setOpenNewDocumentCategory] = useState(false)
  const [openNewDocuemntSubCategory, setOpenNewDocumentSubCategory] =
    useState(false)

  const [documentCategory, setDocumentCategory] = useState() as any

  const [openNewNoteType, setOpenNewNoteType] = useState(false)
  const [openNewBarrier, setOpenNewBarrier] = useState(false)

  const [programId, setProgramId] = useState()
  const [intakeId, setIntakeId] = useState()
  const [intakeData, setIntakeData] = useState()

  const [openNewType, setOpenNewType] = useState(false)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [openPdfUpload, setOpenPdfUpload] = useState(false)

  // const [openEditLedger, setOpenEditLedger] = useState() as any

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueVertical(0)
    setValue(newValue)
  }
  const [valueVertical, setValueVertical] = useState(0)

  const handleChangeVertical = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setValueVertical(newValue)
  }

  const [refetchPrograms, setRefetchPrograms] = useState(false)
  const { data: programs, mutate: fetchPrograms } = usePost(
    '/public/configs/programs/list'
  )

  const [refetchIntakes, setRefetchIntakes] = useState(false)
  const { data: intakes, mutate: fetchIntakes } = usePost(
    '/public/configs/intakes/list'
  )

  const { data: directoryCategories, refetch } = useFetch(
    'directoryCat',
    '/public/directory/directory-categories'
  )

  const { data: appointmentTypes, refetch: refetchAppointments } = useFetch(
    'apptype',
    '/public/appointment/appointment-types/list'
  )
  // const { data: directoryDepartments, refetch } = useFetch(
  //   'directoryCat',
  //   `/public/directory/directory-categories/${subDirectoryCategory?.id}/directory-departments`
  // )

  const [refetchLedgerTypes, setRefetchLedgerTypes] = useState(false)
  const { data: ledgerTypes, mutate: fetchLedgerTypes } = usePost(
    '/public/clients/ledgers/types/list'
  )

  const [refetchLedgerSource, setRefetchLedgerSource] = useState(false)
  const { data: ledgerSource, mutate: fetchLedgerSource } = usePost(
    '/public/clients/ledgers/sources/list'
  )

  const [refetchDocumentCategory, setRefetchDocumentCategory] = useState(false)
  const { data: documentCategories, mutate: fetchDocumentCategories } = usePost(
    '/public/document/document_categories/list'
  )

  const [refetchNoteTypes, setRefetchNoteTypes] = useState(false)
  const { data: noteTypes, mutate: fetchNoteTypes } = usePost(
    '/public/configs/note_types/list'
  )

  const [refetchBarriers, setRefetchBarriers] = useState(false)
  const { data: barriers, mutate: fetchBarriers } = usePost(
    '/public/clients/barriers/list'
  )

  // const [refetchDocumentSubCategory, setRefetchDocumentSubCategory] =
  //   useState(false)
  // const [documentSubCategories, setDocumentSubCategories] = useState() as any

  console.log({
    ledgerSource,
    ledgerTypes,
    documentCategories,
    noteTypes,
    barriers,
  })

  console.log({
    directoryCategories,
    directoryDepartments,
    subDirectoryCategory,
    directoryTags,
    appointmentTypes,
  })

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchBarriers(dataP)
  }, [refetchBarriers])

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchNoteTypes(dataP)
  }, [refetchNoteTypes])

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchDocumentCategories(dataP)
  }, [refetchDocumentCategory])

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchLedgerSource(dataP)
  }, [refetchLedgerSource])

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchLedgerTypes(dataP)
  }, [refetchLedgerTypes])

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchPrograms(dataP)
  }, [refetchPrograms])

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchIntakes(dataP)
  }, [refetchIntakes])
  console.log({ intakes })

  console.log({ programs })

  // useEffect(() => {
  //   axios
  //     .get(
  //       `${BASE_URL}/public/directory/directory-categories/${subDirectoryCategory?.id}/directory-departments`,
  //       { headers: authHeader() }
  //     )
  //     .then((res) => {
  //       setDocumentSubCategories(res.data)
  //     })
  //     .catch((e: any) => {
  //       console.log(e)
  //     })
  // }, [documentCategory, refetchDocumentSubCategory])

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/public/directory/directory-categories/${subDirectoryCategory?.id}/directory-departments`,
        { headers: authHeader() }
      )
      .then((res) => {
        setDirectoryDepartments(res.data)
      })
      .catch((e: any) => {
        console.log(e)
      })
  }, [subDirectoryCategory, refetchDep])

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/public/directory/directory-departments/${subDirectoryDepartment?.id}/directory-tags`,
        { headers: authHeader() }
      )
      .then((res) => {
        setDirectoryTags(res.data)
      })
      .catch((e: any) => {
        console.log(e)
      })
  }, [subDirectoryDepartment, refetchTag])

  useEffect(() => {
    if (intakeId) {
      axios
        .get(`${BASE_URL}/public/configs/intakes/${intakeId}`, {
          headers: authHeader(),
        })
        .then((res) => {
          setIntakeData(res.data)
        })
        .catch((e: any) => {
          console.log(e)
        })
    }
  }, [intakeId])

  return (
    <Box sx={{ px: 3, pt: 3 }}>
      <TextField
        InputProps={{
          startAdornment: <Search />,
          sx: {
            '&.MuiOutlinedInput-root': {
              borderRadius: 8,
              height: 45,
              bgcolor: '#E6F4FF ',
            },
          },
        }}
        placeholder='Search'
      />
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          pt: 2,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab
            label='Intakes'
            {...a11yProps(0)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
          <Tab
            label={`Programs`}
            {...a11yProps(1)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
          <Tab
            label='Ledger'
            {...a11yProps(2)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
          <Tab
            label={'Appointment'}
            {...a11yProps(3)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
          <Tab
            label='Document'
            {...a11yProps(4)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
          <Tab
            label={'Directories'}
            {...a11yProps(5)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
          <Tab
            label={'Confirmation Reason'}
            {...a11yProps(6)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
          <Tab
            label={'Note'}
            {...a11yProps(6)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
          <Tab
            label={'Barriers'}
            {...a11yProps(6)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
        </Tabs>
        <Box sx={{ height: '100%', px: 3 }}>
          <CustomTabPanel value={value} index={0}>
            <Button
              variant='contained'
              sx={{ borderRadius: 5, py: 1 }}
              // onClick={() => setOpenNewIntake(true)}
              onClick={handleClick}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Add />
                <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                  New Intake
                </Typography>
              </Box>
            </Button>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box sx={{ bgcolor: '#0178DD' }}>
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setOpenPdfUpload(true)}
                >
                  <Typography
                    sx={{
                      px: 4,
                      py: 1,
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    From Pdf
                  </Typography>
                </Box>
                <Box
                  sx={{ cursor: 'pointer' }}
                  onClick={() => setOpenNewIntake(true)}
                >
                  <Typography
                    sx={{
                      px: 4,
                      py: 1,
                      color: 'white',
                      fontWeight: 'bold',
                    }}
                  >
                    New Form
                  </Typography>
                </Box>
              </Box>
            </Popover>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Button
              variant='contained'
              sx={{ borderRadius: 5, py: 1 }}
              onClick={() => setOpenNewProgram(true)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Add />
                <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                  New Program
                </Typography>
              </Box>
            </Button>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <CustomTabPanel value={valueVertical} index={0}>
              <Button
                variant='contained'
                sx={{ borderRadius: 5, py: 1 }}
                onClick={() => setOpenNewLedgerType(true)}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Add />
                  <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                    New Ledger Type
                  </Typography>
                </Box>
              </Button>
            </CustomTabPanel>
            <CustomTabPanel value={valueVertical} index={1}>
              <Button
                variant='contained'
                sx={{ borderRadius: 5, py: 1 }}
                onClick={() => setOpenNewLedgerSource(true)}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Add />
                  <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                    New Ledger Source
                  </Typography>
                </Box>
              </Button>
            </CustomTabPanel>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Button
              variant='contained'
              sx={{ borderRadius: 5, py: 1 }}
              onClick={() => setOpenNewType(true)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Add />
                <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                  New Appointment Type
                </Typography>
              </Box>
            </Button>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            {documentCategory ? (
              <Button
                variant='contained'
                sx={{ borderRadius: 5, py: 1 }}
                onClick={() => setOpenNewDocumentSubCategory(true)}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Add />
                  <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                    New Sub Category
                  </Typography>
                </Box>
              </Button>
            ) : (
              <Button
                variant='contained'
                sx={{ borderRadius: 5, py: 1 }}
                onClick={() => setOpenNewDocumentCategory(true)}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Add />
                  <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                    New Category
                  </Typography>
                </Box>
              </Button>
            )}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <Button
              variant='contained'
              sx={{ borderRadius: 5, py: 1 }}
              onClick={() => {
                console.log({ valueVertical })
                if (subDirectoryCategory && subDirectoryDepartment) {
                  setOpenNewDirectoryTag(true)
                } else if (subDirectoryCategory) {
                  setOpenNewDirectoryDepartment(true)
                } else {
                  setOpenNewDirectoryCategory(true)
                }
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Add />
                <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                  {subDirectoryCategory && subDirectoryDepartment
                    ? 'Add Tag'
                    : subDirectoryCategory
                    ? 'Add Department'
                    : 'Add Category'}
                </Typography>
              </Box>
            </Button>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={7}>
            <Button
              variant='contained'
              sx={{ borderRadius: 5, py: 1 }}
              onClick={() => setOpenNewNoteType(true)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Add />
                <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                  New Note Type
                </Typography>
              </Box>
            </Button>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={8}>
            <Button
              variant='contained'
              sx={{ borderRadius: 5, py: 1 }}
              onClick={() => setOpenNewBarrier(true)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Add />
                <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                  New Barrier
                </Typography>
              </Box>
            </Button>
          </CustomTabPanel>
        </Box>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Box
          sx={{
            py: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            flexWrap: 'wrap',
          }}
        >
          {intakes?.data?.intakes?.map((item: any) => (
            <Box sx={{ p: 3, bgcolor: '#F4FAFF', width: 260 }}>
              <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                {item?.name}
              </Typography>
              <Typography sx={{ my: 1 }}>
                {item?.numberOfFields} Fields
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton
                  onClick={() => {
                    setIntakeId(item?.id)
                    setOpenIntakeDetail(true)
                  }}
                >
                  <Visibility sx={{ color: '#077CDE' }} />
                </IconButton>
                <IconButton
                  onClick={() => {
                    setIntakeId(item?.id)
                    setOpenEditIntake(true)
                  }}
                >
                  <Edit sx={{ color: '#077CDE' }} />
                </IconButton>
                {/* <Delete sx={{ color: '#F49B9B' }} /> */}
              </Box>
            </Box>
          ))}
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Box
          sx={{
            py: 3,
            display: 'flex',
            alignItems: 'center',
            gap: 3,
            flexWrap: 'wrap',
          }}
        >
          {programs?.data?.programs?.map((item: any) => (
            <Box sx={{ p: 3, bgcolor: '#F4FAFF', width: 260 }}>
              <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                {item?.name}
              </Typography>
              <Typography sx={{ my: 1 }}>
                {item?.numberOfFields} Fields
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 2,
                  justifyContent: 'flex-end',
                }}
              >
                <IconButton
                  onClick={() => {
                    setProgramId(item?.id)
                    setOpenProgramDetail(true)
                  }}
                >
                  <Visibility sx={{ color: '#077CDE' }} />
                </IconButton>

                {/* <Edit sx={{ color: '#077CDE' }} />
                <Delete sx={{ color: '#F49B9B' }} /> */}
              </Box>
            </Box>
          ))}
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Box
          sx={{
            display: 'flex',
            height: 'calc(100vh - 200px)',
          }}
        >
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={1.5}>
              <Box
                sx={{
                  bgcolor: '#F4FAFF',
                  height: '100%',
                  py: 3,
                }}
              >
                <Tabs
                  value={valueVertical}
                  onChange={handleChangeVertical}
                  orientation='vertical'
                  sx={{
                    '&& .MuiTab-root': {
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <Tab
                    label='Ledger Category'
                    {...a11yPropsVertical(0)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                  {/* <Tab
                    label='Support Sources'
                    {...a11yPropsVertical(1)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  /> */}
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={10.5}>
              <CustomTabPanel value={valueVertical} index={0}>
                <Box
                  sx={{
                    p: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                  }}
                >
                  {ledgerTypes?.data?.ledgerTypes?.map((item: any) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                        {item?.title}
                      </Typography>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 5 }}
                      >
                        {/* <Edit
                          sx={{ color: '#077CDE', width: 30, height: 30 }}
                          // onClick={() => setOpen}
                        /> */}
                        {/* <Delete
                          sx={{ color: '#F49B9B', width: 30, height: 30 }}
                        /> */}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </CustomTabPanel>
              {/* <CustomTabPanel value={valueVertical} index={1}>
                <Box
                  sx={{
                    p: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                  }}
                >
                  {ledgerSource?.data?.types?.map((item: any) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                        {item?.title}
                      </Typography>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 5 }}
                      >
                        <Edit
                          sx={{ color: '#077CDE', width: 30, height: 30 }}
                        />
                        <Delete
                          sx={{ color: '#F49B9B', width: 30, height: 30 }}
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>
              </CustomTabPanel> */}
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={3}>
        <Box
          sx={{
            display: 'flex',
            height: 'calc(100vh - 200px)',
          }}
        >
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={1.5}>
              <Box
                sx={{
                  bgcolor: '#F4FAFF',
                  height: '100%',
                  py: 3,
                }}
              >
                <Tabs
                  value={valueVertical}
                  onChange={handleChangeVertical}
                  orientation='vertical'
                  sx={{
                    '&& .MuiTab-root': {
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <Tab
                    label='Appt Type'
                    {...a11yPropsVertical(0)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={10.5}>
              <CustomTabPanel value={valueVertical} index={0}>
                <Box
                  sx={{
                    p: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                  }}
                >
                  {appointmentTypes?.types?.map((item: any) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                        {item?.title}
                      </Typography>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 5 }}
                      >
                        {/* <Edit
                          sx={{ color: '#077CDE', width: 30, height: 30 }}
                        />
                        <Delete
                          sx={{ color: '#F49B9B', width: 30, height: 30 }}
                        /> */}
                      </Box>
                    </Box>
                  ))}
                </Box>
              </CustomTabPanel>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={4}>
        <Box
          sx={{
            display: 'flex',
            height: 'calc(100vh - 200px)',
          }}
        >
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={1.5}>
              <Box
                sx={{
                  bgcolor: '#F4FAFF',
                  height: '100%',
                  py: 3,
                }}
              >
                <Tabs
                  value={valueVertical}
                  onChange={handleChangeVertical}
                  orientation='vertical'
                  sx={{
                    '&& .MuiTab-root': {
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  {/* <Tab
                    label='Type'
                    {...a11yPropsVertical(0)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  /> */}
                  <Tab
                    label='Categories'
                    {...a11yPropsVertical(0)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={10.5}>
              {/* <CustomTabPanel value={valueVertical} index={0}>
                <Box
                  sx={{
                    p: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                      Client
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                      <Edit sx={{ color: '#077CDE', width: 30, height: 30 }} />
                      <Delete
                        sx={{ color: '#F49B9B', width: 30, height: 30 }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                      Company
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                      <Edit sx={{ color: '#077CDE', width: 30, height: 30 }} />
                      <Delete
                        sx={{ color: '#F49B9B', width: 30, height: 30 }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                      Staff
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                      <Edit sx={{ color: '#077CDE', width: 30, height: 30 }} />
                      <Delete
                        sx={{ color: '#F49B9B', width: 30, height: 30 }}
                      />
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel> */}
              <CustomTabPanel value={valueVertical} index={0}>
                <Box
                  sx={
                    {
                      // // p: 10,
                      // display: 'flex',
                      // flexDirection: 'column',
                      // gap: 10,
                    }
                  }
                >
                  {documentCategory ? (
                    <Box sx={{ p: 5 }}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 3,
                        }}
                      >
                        <Box
                          sx={{
                            bgcolor: '#EDEDED',
                            px: 3,
                            py: 0.8,
                            cursor: 'pointer',
                            borderRadius: 2,
                            width: 90,
                          }}
                          onClick={() => setDocumentCategory()}
                        >
                          Back
                        </Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography
                            sx={{ color: '#ACACAC', fontWeight: 'bold' }}
                          >
                            {documentCategory?.name}
                          </Typography>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            {'>'}
                          </Typography>
                          <Typography sx={{ fontWeight: 'bold' }}>
                            Sub Categories
                          </Typography>
                        </Box>
                      </Box>
                      <Divider sx={{ my: 2 }} />
                      {documentCategories?.data?.documentCategories
                        ?.filter(
                          (el: any) => el?.id === documentCategory?.id
                        )?.[0]
                        ?.documentSubCategories?.map((item: any) => (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              p: 2,
                            }}
                          >
                            <Typography
                              variant='h6'
                              sx={{ fontWeight: 'bold' }}
                            >
                              {item?.name}
                            </Typography>

                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 5,
                              }}
                            >
                              <IconButton
                                onClick={() => setOpenEditDepartment(true)}
                              >
                                <Edit
                                  sx={{
                                    color: '#077CDE',
                                    width: 30,
                                    height: 30,
                                  }}
                                />
                              </IconButton>
                              <Delete
                                sx={{
                                  color: '#F49B9B',
                                  width: 30,
                                  height: 30,
                                }}
                              />
                            </Box>
                          </Box>
                        ))}
                    </Box>
                  ) : (
                    <Box>
                      {documentCategories?.data?.documentCategories?.map(
                        (item: any) => (
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              m: 5,
                            }}
                          >
                            <Typography
                              variant='h6'
                              sx={{ fontWeight: 'bold' }}
                            >
                              {item?.name}
                            </Typography>

                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 5,
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 2,
                                  px: 5,
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 15,
                                    color: '#285079',
                                  }}
                                >
                                  Sub Categories
                                </Typography>
                                <IconButton
                                  onClick={() => setDocumentCategory(item)}
                                >
                                  <Visibility
                                    sx={{
                                      color: '#077CDE',
                                      width: 30,
                                      height: 30,
                                    }}
                                  />
                                </IconButton>
                              </Box>
                              <IconButton
                              // onClick={() => {
                              //   setToEditCategory(item)
                              //   setOpenEditCategory(true)
                              // }}
                              >
                                <Edit
                                  sx={{
                                    color: '#077CDE',
                                    width: 30,
                                    height: 30,
                                  }}
                                />
                              </IconButton>
                              <Delete
                                sx={{ color: '#F49B9B', width: 30, height: 30 }}
                              />
                            </Box>
                          </Box>
                          // <Box
                          //   sx={{
                          //     p: 10,
                          //     display: 'flex',
                          //     flexDirection: 'column',
                          //     gap: 10,
                          //   }}
                          // >
                          //   <Box
                          //     sx={{
                          //       display: 'flex',
                          //       alignItems: 'center',
                          //       justifyContent: 'space-between',
                          //     }}
                          //   >
                          //     <Typography
                          //       variant='h5'
                          //       sx={{ fontWeight: 'bold', width: 150 }}
                          //     >
                          //       {item?.name}
                          //     </Typography>
                          //     <Box
                          //       sx={{
                          //         display: 'flex',
                          //         alignItems: 'center',
                          //         gap: 2,
                          //       }}
                          //     >
                          //       <Box>
                          //         <Typography
                          //           sx={{ fontWeight: 'bold', fontSize: 13 }}
                          //         >
                          //           SUB <br /> CATEGORIES
                          //         </Typography>
                          //         <Box
                          //           sx={{
                          //             display: 'flex',
                          //             alignItems: 'center',
                          //             gap: 1,
                          //             cursor: 'pointer',
                          //           }}
                          //           onClick={() => setDocumentCategory(item)}
                          //         >
                          //           <Typography
                          //             sx={{ fontSize: 11, color: '#2A8DE2' }}
                          //           >
                          //             Manage
                          //           </Typography>
                          //           <Edit
                          //             sx={{
                          //               width: 15,
                          //               height: 15,
                          //               color: '#2A8DE2',
                          //             }}
                          //           />
                          //         </Box>
                          //       </Box>
                          //       <Box
                          //         sx={{
                          //           py: 1,
                          //           px: 5,
                          //           border: '1px solid lightgray',
                          //           borderRadius: 3,
                          //           width: { xs: 200, sm: 300, md: 400 },
                          //           minHeight: 75,
                          //         }}
                          //       >
                          //         <Grid
                          //           container
                          //           spacing={2}
                          //           sx={{
                          //             display: item?.documentSubCategories
                          //               ?.length
                          //               ? 'flex'
                          //               : 'none',
                          //           }}
                          //         >
                          //           {item?.documentSubCategories?.map(
                          //             (elt: any) => (
                          //               <Grid item xs={4}>
                          //                 <Typography
                          //                   sx={{
                          //                     fontSize: 13,
                          //                     fontWeight: 'bold',
                          //                   }}
                          //                 >
                          //                   {elt?.name}
                          //                 </Typography>
                          //               </Grid>
                          //             )
                          //           )}
                          //         </Grid>
                          //       </Box>
                          //     </Box>
                          //     <Box
                          //       sx={{
                          //         display: 'flex',
                          //         alignItems: 'center',
                          //         gap: 5,
                          //       }}
                          //     >
                          //       <Edit
                          //         sx={{
                          //           color: '#077CDE',
                          //           width: 30,
                          //           height: 30,
                          //         }}
                          //       />
                          //       <Delete
                          //         sx={{
                          //           color: '#F49B9B',
                          //           width: 30,
                          //           height: 30,
                          //         }}
                          //       />
                          //     </Box>
                          //   </Box>
                          // </Box>
                        )
                      )}
                    </Box>
                  )}
                </Box>
              </CustomTabPanel>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={5}>
        <Box
          sx={{
            display: 'flex',
            height: 'calc(100vh - 200px)',
          }}
        >
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={1.5}>
              <Box
                sx={{
                  bgcolor: '#F4FAFF',
                  height: '100%',
                  py: 3,
                }}
              >
                <Tabs
                  value={valueVertical}
                  onChange={handleChangeVertical}
                  orientation='vertical'
                  sx={{
                    '&& .MuiTab-root': {
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <Tab
                    label='Categories'
                    {...a11yPropsVertical(0)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={10.5}>
              <CustomTabPanel value={valueVertical} index={0}>
                {subDirectoryCategory && subDirectoryDepartment ? (
                  <Box sx={{ p: 5 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 3,
                      }}
                    >
                      <Box
                        sx={{
                          bgcolor: '#EDEDED',
                          px: 3,
                          py: 0.8,
                          cursor: 'pointer',
                          borderRadius: 2,
                          width: 90,
                        }}
                        onClick={() => setSubDirectoryDepartment()}
                      >
                        Back
                      </Box>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Typography
                          sx={{ color: '#ACACAC', fontWeight: 'bold' }}
                        >
                          {subDirectoryCategory?.title}
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {'>'}
                        </Typography>
                        <Typography
                          sx={{ color: '#ACACAC', fontWeight: 'bold' }}
                        >
                          {subDirectoryDepartment?.title}
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {'>'}
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          Tags
                        </Typography>
                      </Box>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    {directoryTags?.tags?.map((item: any) => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          p: 2,
                        }}
                      >
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                          {item?.tag}
                        </Typography>

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 5,
                          }}
                        >
                          <IconButton
                            onClick={() => {
                              setToEditTag(item)
                              setOpenEditTag(true)
                            }}
                          >
                            <Edit
                              sx={{ color: '#077CDE', width: 30, height: 30 }}
                            />
                          </IconButton>
                          <Delete
                            sx={{ color: '#F49B9B', width: 30, height: 30 }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ) : subDirectoryCategory ? (
                  <Box sx={{ p: 5 }}>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 3,
                      }}
                    >
                      <Box
                        sx={{
                          bgcolor: '#EDEDED',
                          px: 3,
                          py: 0.8,
                          cursor: 'pointer',
                          borderRadius: 2,
                          width: 90,
                        }}
                        onClick={() => setSubDirectoryCategory()}
                      >
                        Back
                      </Box>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Typography
                          sx={{ color: '#ACACAC', fontWeight: 'bold' }}
                        >
                          {subDirectoryCategory?.title}
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          {'>'}
                        </Typography>
                        <Typography sx={{ fontWeight: 'bold' }}>
                          Departments
                        </Typography>
                      </Box>
                    </Box>
                    <Divider sx={{ my: 2 }} />
                    {directoryDepartments?.departments?.map((item: any) => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          p: 2,
                        }}
                      >
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                          {item?.title}
                        </Typography>

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 5,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              px: 5,
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 'bold',
                                fontSize: 15,
                                color: '#285079',
                              }}
                            >
                              Tags
                            </Typography>
                            <IconButton
                              onClick={() => {
                                setToEditDepartment(item)
                                setSubDirectoryDepartment(item)
                              }}
                            >
                              <Visibility
                                sx={{
                                  color: '#077CDE',
                                  width: 30,
                                  height: 30,
                                }}
                              />
                            </IconButton>
                          </Box>
                          <IconButton
                            onClick={() => setOpenEditDepartment(true)}
                          >
                            <Edit
                              sx={{ color: '#077CDE', width: 30, height: 30 }}
                            />
                          </IconButton>
                          <Delete
                            sx={{ color: '#F49B9B', width: 30, height: 30 }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Box
                    sx={{
                      p: 10,
                      display: 'flex',
                      flexDirection: 'column',
                      gap: 10,
                    }}
                  >
                    {directoryCategories?.categories?.map((item: any) => (
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                          {item?.title}
                        </Typography>

                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 5,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              px: 5,
                            }}
                          >
                            <Typography
                              sx={{
                                fontWeight: 'bold',
                                fontSize: 15,
                                color: '#285079',
                              }}
                            >
                              Departments
                            </Typography>
                            <IconButton
                              onClick={() => setSubDirectoryCategory(item)}
                            >
                              <Visibility
                                sx={{
                                  color: '#077CDE',
                                  width: 30,
                                  height: 30,
                                }}
                              />
                            </IconButton>
                          </Box>
                          <IconButton
                            onClick={() => {
                              setToEditCategory(item)
                              setOpenEditCategory(true)
                            }}
                          >
                            <Edit
                              sx={{ color: '#077CDE', width: 30, height: 30 }}
                            />
                          </IconButton>
                          <Delete
                            sx={{ color: '#F49B9B', width: 30, height: 30 }}
                          />
                        </Box>
                      </Box>
                    ))}
                  </Box>
                )}
              </CustomTabPanel>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={6}>
        <Box
          sx={{
            display: 'flex',
            height: 'calc(100vh - 200px)',
          }}
        >
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={1.5}>
              <Box
                sx={{
                  bgcolor: '#F4FAFF',
                  height: '100%',
                  py: 3,
                }}
              >
                <Tabs
                  value={valueVertical}
                  onChange={handleChangeVertical}
                  orientation='vertical'
                  sx={{
                    '&& .MuiTab-root': {
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <Tab
                    label='Status Change'
                    {...a11yPropsVertical(0)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={10.5}>
              <CustomTabPanel value={valueVertical} index={0}>
                <Box
                  sx={{
                    p: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                      Unreachable
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                      <Edit sx={{ color: '#077CDE', width: 30, height: 30 }} />
                      <Delete
                        sx={{ color: '#F49B9B', width: 30, height: 30 }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                      Unreachable
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                      <Edit sx={{ color: '#077CDE', width: 30, height: 30 }} />
                      <Delete
                        sx={{ color: '#F49B9B', width: 30, height: 30 }}
                      />
                    </Box>
                  </Box>
                </Box>
              </CustomTabPanel>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={7}>
        <Box
          sx={{
            display: 'flex',
            height: 'calc(100vh - 200px)',
          }}
        >
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={1.5}>
              <Box
                sx={{
                  bgcolor: '#F4FAFF',
                  height: '100%',
                  py: 3,
                }}
              >
                <Tabs
                  value={valueVertical}
                  onChange={handleChangeVertical}
                  orientation='vertical'
                  sx={{
                    '&& .MuiTab-root': {
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <Tab
                    label='Note Types'
                    {...a11yPropsVertical(0)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={10.5}>
              <CustomTabPanel value={valueVertical} index={0}>
                <Box
                  sx={{
                    p: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                  }}
                >
                  {noteTypes?.data?.noteTypes?.map((item: any) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                        {item?.title}
                      </Typography>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 5 }}
                      >
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                        >
                          <Checkbox checked={item?.billable} />
                          <Typography sx={{ color: '#9D9D9D' }}>
                            Billable
                          </Typography>
                        </Box>
                        <Edit
                          sx={{ color: '#077CDE', width: 30, height: 30 }}
                        />
                        <Delete
                          sx={{ color: '#F49B9B', width: 30, height: 30 }}
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>
              </CustomTabPanel>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>

      <CustomTabPanel value={value} index={8}>
        <Box
          sx={{
            display: 'flex',
            height: 'calc(100vh - 200px)',
          }}
        >
          <Grid container sx={{ height: '100%' }}>
            <Grid item xs={1.5}>
              <Box
                sx={{
                  bgcolor: '#F4FAFF',
                  height: '100%',
                  py: 3,
                }}
              >
                <Tabs
                  value={valueVertical}
                  onChange={handleChangeVertical}
                  orientation='vertical'
                  sx={{
                    '&& .MuiTab-root': {
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    },
                  }}
                >
                  <Tab
                    label='Barriers'
                    {...a11yPropsVertical(0)}
                    sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                  />
                </Tabs>
              </Box>
            </Grid>
            <Grid item xs={10.5}>
              <CustomTabPanel value={valueVertical} index={0}>
                <Box
                  sx={{
                    p: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                  }}
                >
                  {barriers?.data?.barriers?.map((item: any) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Typography variant='h5' sx={{ fontWeight: 'bold' }}>
                        {item?.title}
                      </Typography>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 5 }}
                      >
                        <Edit
                          sx={{ color: '#077CDE', width: 30, height: 30 }}
                        />
                        <Delete
                          sx={{ color: '#F49B9B', width: 30, height: 30 }}
                        />
                      </Box>
                    </Box>
                  ))}
                </Box>
              </CustomTabPanel>
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>

      <NewIntake
        open={openNewIntake}
        close={() => setOpenNewIntake(false)}
        refetch={refetchIntakes}
        setRefetch={setRefetchIntakes}
      />
      <NewProgram
        open={openNewProgram}
        close={() => setOpenNewProgram(false)}
        refetch={refetchPrograms}
        setRefetch={setRefetchPrograms}
      />

      <AddNoteType
        open={openNewNoteType}
        close={() => setOpenNewNoteType(false)}
        refetch={refetchNoteTypes}
        setRefetch={setRefetchNoteTypes}
      />
      <AddBarrier
        open={openNewBarrier}
        close={() => setOpenNewBarrier(false)}
        refetch={refetchBarriers}
        setRefetch={setRefetchBarriers}
      />

      <AddDirectoryCategory
        open={openNewDirectoryCategory}
        close={() => setOpenNewDirectoryCategory(false)}
        refetch={refetch}
      />
      <AddDirectoryDepartment
        open={openNewDirectoryDepartment}
        close={() => setOpenNewDirectoryDepartment(false)}
        refetch={refetchDep}
        setRefetchDep={setRefetchDep}
      />

      <AddLedgerType
        open={opneNewLedgerType}
        close={() => setOpenNewLedgerType(false)}
        refetch={refetchLedgerTypes}
        setRefetch={setRefetchLedgerTypes}
      />
      <AddLedgerSource
        open={opneNewLedgerSource}
        close={() => setOpenNewLedgerSource(false)}
        refetch={refetchLedgerSource}
        setRefetch={setRefetchLedgerSource}
      />

      <AddDocumentCategory
        open={openNewDocuemntCategory}
        close={() => setOpenNewDocumentCategory(false)}
        refetch={refetchDocumentCategory}
        setRefetch={setRefetchDocumentCategory}
      />

      <AddDocumentSubCategory
        open={openNewDocuemntSubCategory}
        close={() => setOpenNewDocumentSubCategory(false)}
        refetch={refetchDocumentCategory}
        setRefetch={setRefetchDocumentCategory}
      />

      {subDirectoryCategory && (
        <AddDirectoryTag
          open={openNewDirectoryTag}
          close={() => setOpenNewDirectoryTag(false)}
          categoryId={subDirectoryCategory?.id}
          refetch={refetchTag}
          setRefetchTag={setRefetchTag}
        />
      )}
      {programId && (
        <ProgramDetails
          open={openProgramDetail}
          close={() => setOpenProgramDetail(false)}
          programId={programId}
        />
      )}
      {intakeId && (
        <IntakeDetails
          open={openIntakeDetail}
          close={() => setOpenIntakeDetail(false)}
          intakeId={intakeId}
        />
      )}
      <IntakePdf open={openPdfUpload} close={() => setOpenPdfUpload(false)} />
      {intakeData && (
        <EditIntake
          open={openEditIntake}
          close={() => setOpenEditIntake(false)}
          intakeData={intakeData}
        />
      )}
      {toEditTag && (
        <EditTag
          open={openEditTag}
          close={() => setOpenEditTag(false)}
          categoryId={subDirectoryCategory?.id}
          refetch={refetchTag}
          setRefetchTag={setRefetchTag}
          tag={toEditTag}
        />
      )}
      <AddAppointmentType
        open={openNewType}
        close={() => setOpenNewType(false)}
        refetch={refetchAppointments}
      />
    </Box>
  )
}
