import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Modal,
  Slide,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import FileUpload from '../File/FileUpload'
import { DatePicker } from '@mui/x-date-pickers'
import { Add, Flag, PictureAsPdf, Remove, Send } from '@mui/icons-material'
import { useFetch } from '../../hooks/useFetch'
import moment from 'moment'
export default function GoalDetails({
  open,
  close,
  data,
}: {
  open: boolean
  close: any
  data: any
}) {
  console.log({ data })

  const { data: goalItem, refetch } = useFetch(
    'fetchgoal',
    `/public/goal/goals/${data?.id}`
  )

  console.log({ goalItem })

  useEffect(() => {
    refetch()
  }, [data])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 1200 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>Goal Details</Typography>
              <Box> </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                p: 6,
                height: 'calc(100vh - 70px)',
                bgcolor: '#F5FAFF',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Grid container spacing={3} sx={{ height: '100%' }}>
                <Grid item xs={12} md={4.5} sx={{ height: '100%', px: 5 }}>
                  <Box sx={{ py: 3 }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 26 }}>
                      {goalItem?.title}
                    </Typography>
                    <Typography
                      sx={{ fontWeight: 600, fontSize: 15, width: 270 }}
                    >
                      {goalItem?.goalDescription}
                    </Typography>
                  </Box>
                  <Divider sx={{ my: 3 }} />
                  <LinearProgress
                    variant='determinate'
                    value={40}
                    sx={{
                      height: 15,
                      borderRadius: 5,
                      bgcolor: '#E4ECF3',
                      color: '#2699FB',
                    }}
                  />
                  <Box
                    sx={{
                      bgcolor: 'white',
                      borderRadius: 10,
                      my: 5,
                      height: 50,
                      display: 'flex',
                      alignItems: 'center',
                      px: 3,
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        position: 'relative',
                        bgcolor: 'red',
                      }}
                    >
                      <Avatar
                        sx={{ width: 30, height: 30, position: 'absolute' }}
                      ></Avatar>
                      <Box
                        sx={{
                          position: 'absolute',
                          left: 23,
                          p: 0.3,
                          borderRadius: '100%',
                          bgcolor: '#F4FAFF',
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 30,
                            height: 30,
                          }}
                        ></Avatar>
                      </Box>
                      <Box
                        sx={{
                          position: 'absolute',
                          left: 46,
                          p: 0.3,
                          borderRadius: '100%',
                          bgcolor: '#F4FAFF',
                        }}
                      >
                        <Avatar
                          sx={{
                            width: 30,
                            height: 30,
                          }}
                        ></Avatar>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      bgcolor: 'white',
                      borderRadius: 10,
                      my: 5,
                      height: 50,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                      px: 3,
                    }}
                  >
                    <Flag sx={{ color: 'red' }} />
                    <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                      {goalItem?.priority} Priority
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      bgcolor: 'white',
                      borderRadius: 10,
                      my: 5,
                      height: 50,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                      px: 3,
                    }}
                  >
                    <PictureAsPdf sx={{ color: '#0C99C3' }} />
                    <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                      Customer.Csv
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={7.5}>
                  <Card
                    sx={{
                      width: '100%',
                      bgcolor: 'white',
                      boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                      height: '100%',
                      borderRadius: 4,
                      p: 7,
                    }}
                  >
                    {goalItem?.tasks?.map((elt: any, index: number) => (
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            px: 5,
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: 'bold', color: '#4F6D9A' }}
                          >
                            Task {index + 1}
                          </Typography>
                          <Typography
                            sx={{
                              color: '#878787',
                              fontSize: 13,
                              fontWeight: 'bold',
                            }}
                          >
                            {moment(elt?.dueDate)?.format('ll')}
                          </Typography>
                        </Box>

                        <Box sx={{ px: 5, py: 2 }}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 3,
                            }}
                          >
                            <Box
                              sx={{
                                bgcolor: '#E5E5E5',
                                width: 20,
                                height: 20,
                                borderRadius: 1,
                              }}
                            ></Box>
                            <Typography
                              sx={{
                                color: '#09244B',
                                fontWeight: 'bold',
                                fontSize: 16,
                              }}
                            >
                              {elt?.title}
                            </Typography>
                          </Box>
                          <Box sx={{ py: 1, px: 10 }}>
                            {elt?.subtasks?.map((item: any) => (
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                  lineHeight: 2,
                                }}
                              >
                                - {item?.name}
                              </Typography>
                            ))}
                          </Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              bgcolor: '#F4FAFF',
                              p: 1.5,
                              borderRadius: 10,
                              border: '1px solid #E7F0F7',

                              my: 2,
                            }}
                          >
                            <Avatar sx={{ width: 30, height: 30 }}></Avatar>

                            <Box
                              sx={{
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                              }}
                            >
                              <Flag sx={{ color: '#BF0C0C' }} />
                              <Typography
                                sx={{ fontWeight: 'bold', fontSize: 13 }}
                              >
                                {elt?.priority} Priority
                              </Typography>
                            </Box>
                          </Box>
                          {/* <Box
                            sx={{
                              py: 3,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 9,
                              }}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  position: 'relative',
                                }}
                              >
                                <Avatar
                                  sx={{
                                    width: 20,
                                    height: 20,
                                    position: 'absolute',
                                  }}
                                ></Avatar>
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    left: 11,
                                    p: 0.3,
                                    borderRadius: '100%',
                                    bgcolor: '#F4FAFF',
                                  }}
                                >
                                  <Avatar
                                    sx={{
                                      width: 20,
                                      height: 20,
                                    }}
                                  ></Avatar>
                                </Box>
                                <Box
                                  sx={{
                                    position: 'absolute',
                                    left: 22,
                                    p: 0.3,
                                    borderRadius: '100%',
                                    bgcolor: '#F4FAFF',
                                  }}
                                >
                                  <Avatar
                                    sx={{
                                      width: 20,
                                      height: 20,
                                    }}
                                  ></Avatar>
                                </Box>
                              </Box>
                              <Typography
                                sx={{
                                  color: '#647FA6',
                                  fontSize: 12,
                                  fontWeight: 'bold',
                                }}
                              >
                                8 Replies
                              </Typography>
                            </Box>
                            <Button>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                }}
                              >
                                <Typography
                                  sx={{ fontWeight: 'bold', fontSize: 12 }}
                                >
                                  Reply
                                </Typography>
                                <Send sx={{ width: 20, height: 20 }} />
                              </Box>
                            </Button>
                          </Box> */}
                        </Box>
                      </Box>
                    ))}
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
