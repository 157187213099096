import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import ehr from '../assets/Frame.png'
import { useEffect, useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { usePost } from '../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
export default function ResetPassword() {
  const [emailOrPhone, setEmailOrPhone] = useState<string>()
  const navigate = useNavigate()

  enum PasswordResetMethod {
    METHOD_UNSPECIFIED,
    PASSWORD_RESET_METHOD_EMAIL,
    PASSWORD_RESET_METHOD_SMS,
  }

  const { mutate, isLoading, isError, isSuccess, error } = usePost(
    '/public/user/password-reset/start'
  )

  const handleSubmit = () => {
    const data: any = {
      ...(emailOrPhone?.includes('@')
        ? { email: emailOrPhone }
        : { phone_number: emailOrPhone }),
      method: emailOrPhone?.includes('@')
        ? 'PASSWORD_RESET_METHOD_EMAIL'
        : 'PASSWORD_RESET_METHOD_SMS',
    }
    console.log({ data })
    mutate(data)
  }

  useEffect(() => {
    if (isError) {
      console.log({ error })
      enqueueSnackbar(error?.response?.data?.message || 'Network Error', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
    if (isSuccess) {
      navigate(
        `/verifyotp?address=${
          emailOrPhone?.includes('@')
            ? emailOrPhone
            : emailOrPhone?.replace('+', '%2B')
        }&method=${
          emailOrPhone?.includes('@')
            ? 'PASSWORD_RESET_METHOD_EMAIL'
            : 'PASSWORD_RESET_METHOD_SMS'
        }`
      )
    }
  }, [isSuccess, isError])

  return (
    <Box
      sx={{
        bgcolor: '#FBFBFB',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Grid
        container
        sx={{
          display: { xs: 'none', md: 'flex' },
          height: '100%',
        }}
      >
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: '90%',
                height: '90%',
                borderRadius: 10,
                bgcolor: '#F7F7F7',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box component='img' src={ehr} sx={{ width: '80%' }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: { xs: '90%', sm: '70%', md: 400 },
                height: '100%',
                justifyContent: 'space-between',
                py: 20,
              }}
            >
              <Box>
                <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                  Reset Password
                </Typography>
                <Typography>Please enter your phone or email</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                  Phone No or Email
                </Typography>
                <TextField
                  fullWidth
                  sx={{ my: 1 }}
                  onChange={(e) => setEmailOrPhone(e.target.value)}
                />
              </Box>

              <Button
                variant='contained'
                // onClick={() => navigate('/verifyotp')}
                onClick={handleSubmit}
                sx={{ py: 1.5, mb: 15 }}
              >
                {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CircularProgress sx={{ color: 'white' }} />
                    <Typography>Sending...</Typography>
                  </Box>
                ) : (
                  <Typography>Reset</Typography>
                )}
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  position: 'absolute',
                  bottom: 20,
                  width: 400,
                }}
              >
                <Typography sx={{ fontSize: 12 }}>
                  @{new Date().getFullYear()} GoodSeedCdc
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'cenetr',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 'bold', color: '#1F7DEC', fontSize: 12 }}
                  >
                    Privacy
                  </Typography>
                  <Divider flexItem orientation='vertical' />
                  <Typography
                    sx={{ fontWeight: 'bold', color: '#1F7DEC', fontSize: 12 }}
                  >
                    Terms
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {/* <Box
        sx={{
          height: '100%',
          display: { xs: 'flex', md: 'none' },
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-evenly',
            width: { xs: '80%', sm: '70%' },
          }}
        >
          <Typography
            variant='h1'
            sx={{ fontWeight: 'bold', color: '#0178DD' }}
          >
            ehr
          </Typography>
          <Box>
            <TextField
              placeholder='Email'
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              InputProps={{
                sx: {
                  '&.MuiOutlinedInput-root': {
                    borderRadius: 8,
                    height: 60,
                  },
                },
              }}
            />
            <TextField
              placeholder='Password'
              type='password'
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
              sx={{ my: 3 }}
              InputProps={{
                sx: {
                  '&.MuiOutlinedInput-root': {
                    borderRadius: 8,
                    height: 60,
                  },
                },
              }}
            />
            <Button
              variant='contained'
              sx={{ borderRadius: 7, py: 1.7 }}
              fullWidth
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                <CircularProgress sx={{ color: 'white' }} />
                <Typography>Signing in...</Typography>
              </Box>

              <Typography>Login</Typography>
            </Button>
          </Box>
        </Box>
      </Box> */}
    </Box>
  )
}
