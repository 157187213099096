export const languagesList = [
  'English',
  'Spanish',
  'Chinese',
  'Hindi',
  'Arabic',
  'Bengali',
  'Portuguese',
  'Russian',
  'Japanese',
  'Punjabi',
  'German',
  'French',
  'Urdu',
  'Indonesian',
  'Italian',
  'Turkish',
  'Korean',
  'Vietnamese',
  'Tamil',
  'Telugu',
  'Marathi',
  'Thai',
  'Gujarati',
  'Polish',
  'Ukrainian',
  'Malayalam',
  'Kannada',
  'Odia',
  'Nepali',
  'Dutch',
  'Filipino',
  'Swedish',
  'Greek',
  'Czech',
  'Hungarian',
  'Romanian',
  'Hebrew',
  'Swahili',
  'Farsi',
  'Malay',
  'Burmese',
  'Sinhala',
  'Amharic',
  'Yoruba',
  'Igbo',
  'Zulu',
  'Finnish',
  'Norwegian',
  'Danish',
  'Lithuanian',
  'Latvian',
  'Estonian',
  'Slovak',
  'Slovenian',
  'Croatian',
  'Bosnian',
  'Serbian',
  'Macedonian',
  'Albanian',
  'Georgian',
  'Armenian',
  'Azerbaijani',
  'Kazakh',
  'Kyrgyz',
  'Tajik',
  'Turkmen',
  'Uzbek',
  'Pashto',
  'Dari',
  'Tigrinya',
  'Kurdish',
  'Maltese',
  'Icelandic',
  'Fijian',
  'Samoan',
  'Tongan',
  'Maori',
  'Hawaiian',
  'Cherokee',
  'Navajo',
  'Inuktitut',
  'Greenlandic',
  'Papiamento',
  'Luxembourgish',
  'Basque',
  'Welsh',
  'Catalan',
  'Galician',
  'Irish',
  'Scottish Gaelic',
  'Scots',
  'Breton',
  'Occitan',
  'Corsican',
  'Sardinian',
  'Romansh',
  'Sorbian',
  'Aromanian',
  'Mongolian',
  'Uighur',
  'Tibetan',
  'Nahuatl',
  'Quechua',
  'Aymara',
  'Guarani',
  'Mapudungun',
  'Rapa Nui',
  'Tahitian',
  'Hausa',
  'Ibibio',
  'Fulfulde',
  'Wolof',
  'Shona',
  'Tswana',
  'Sotho',
  'Tsonga',
  'Chichewa',
  'Malagasy',
  'Bambara',
  'Hiri Motu',
  'Marshallese',
  'Palauan',
  'Kiribati',
  'Tuvaluan',
  'Nauruan',
]
