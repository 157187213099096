import { PeopleAlt } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Modal,
  Slide,
  TextField,
  Typography,
} from '@mui/material'

export default function AssignCaseManager({
  open,
  close,
}: {
  open: boolean
  close: any
}) {
  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 500 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>Assign</Typography>
              <Box> </Box>
            </Box>
            <Box
              sx={{
                px: 5,
                py: { xs: 5, sm: 10 },
                height: 'calc(100vh - 140px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Typography sx={{ fontWeight: 'bold' }}>Search</Typography>
              <Autocomplete
                options={[]}
                filterSelectedOptions={true}
                getOptionLabel={(option: any) => option.name}
                size='small'
                renderInput={(params: any) => (
                  <TextField {...params} name='userId' />
                )}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'fixed',
                bottom: 0,
                width: { xs: '100vw', sm: 500 },
                height: 80,
                py: 3,
                px: 5,
              }}
            >
              <Button variant='contained' sx={{ px: 10, py: 2.5 }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                  Done
                </Typography>
              </Button>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
