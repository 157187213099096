import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  LinearProgress,
  Popover,
  TextField,
  Typography,
} from '@mui/material'
import { Add, CalendarMonth, Close, Flag, Search } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import AddClient from '../Clients/AddClient'
import { usePost } from '../../hooks/usePost'
import moment from 'moment'
export default function Client() {
  const [isSearch, setIsSearch] = useState(false)

  const [refetchClient, setRefetchClient] = useState(false)
  const { data: clientData, mutate } = usePost('/public/clients')

  console.log({ clientData })

  const [search, setSearch] = useState() as any
  useEffect(() => {
    const datap: any = {
      filters: {
        filters: [
          {
            field: 'first_name',
            like: {
              value: search,
            },
          },
        ],
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    mutate(datap)
  }, [refetchClient, search])

  function ClientCard({ client }: any) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 6,
          width: '100%',
          my: 2,
        }}
      >
        <Avatar
          src={client?.profilePictureUrl}
          sx={{ width: 36, height: 36 }}
        />
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              my: 2,
            }}
          >
            <Typography sx={{ fontSize: 14 }}>
              {client?.firstName + ' ' + client?.lastName},{' '}
              {new Date().getFullYear() -
                moment(client?.dob).toDate().getFullYear()}
            </Typography>
            <Typography sx={{ color: '#4D72A6', fontSize: 14 }}>
              {client?.phone}
            </Typography>
          </Box>
          <Divider sx={{ color: '#F9F9FC' }} />
        </Box>
      </Box>
    )
  }
  const [openAddClient, setOpenAddClient] = useState(false)

  return (
    <Grid
      item
      xs={12}
      sm={6}
      sx={{
        borderRight: '1px solid',
        borderRightColor: '#F4F4F4',
        height: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          pl: 3,
          pr: 7,
          py: 2,
        }}
      >
        <Typography
          sx={{
            color: '#0178DD',
            fontWeight: 'bold',
            display: isSearch ? 'none' : 'block',
          }}
        >
          Clients
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            gap: 2,
            width: '100%',
          }}
        >
          <TextField
            sx={{ display: isSearch ? 'block' : 'none' }}
            size='small'
            InputProps={{
              sx: {
                '&.MuiOutlinedInput-root': {
                  borderRadius: 8,
                },
              },
              startAdornment: <Search />,
              endAdornment: (
                <IconButton onClick={() => setIsSearch(false)}>
                  <Close />
                </IconButton>
              ),
            }}
            onChange={(e) => setSearch(e.target.value)}
            fullWidth
          />
          <Button
            variant='outlined'
            sx={{
              borderRadius: 5,
              borderColor: '#EBF3F9',
              bgcolor: '#F4FAFF',
              display: isSearch ? 'none' : 'block ',
            }}
            onClick={() => setIsSearch(true)}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Search sx={{ color: 'black' }} />
              <Typography
                sx={{
                  fontSize: 13,
                  color: 'black',
                }}
              >
                Search
              </Typography>
            </Box>
          </Button>
          <Button
            variant='outlined'
            sx={{ borderRadius: 5, bgcolor: '#E6F4FF' }}
            onClick={() => setOpenAddClient(true)}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Add />
              <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                New
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          height: 'calc(46vh - 120px)',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: 0,
          },
          pl: 3,
          pr: 7,
        }}
      >
        {clientData?.data?.clients?.map((client: any) => (
          <ClientCard client={client} />
        ))}
      </Box>
      <AddClient
        open={openAddClient}
        close={() => setOpenAddClient(false)}
        refetch={refetchClient}
        setRefetch={setRefetchClient}
      />
    </Grid>
  )
}
