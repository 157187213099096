import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemText,
  Modal,
  OutlinedInput,
  Paper,
  Popover,
  Popper,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import FileUpload from '../File/FileUpload'
import { DatePicker } from '@mui/x-date-pickers'
import { languagesList } from '../../utils/languages'
import ClientStepper from './ClientStepper'
import { useFieldArray, useForm } from 'react-hook-form'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import usePlacesAutocomplete from 'use-places-autocomplete'
import axios from 'axios'
import { Add, LocationCity, LocationOn, Remove } from '@mui/icons-material'
import PinMap from '../Location/PinMap'
import { countries } from '../../utils/countrycode'
import { BASE_URL } from '../../utils/config'
import { authHeader } from '../../utils/auth'

export default function AddClient({
  open,
  close,
  refetch,
  setRefetch,
}: {
  open: boolean
  close: any
  refetch: boolean
  setRefetch: any
}) {
  const [formState, setFormState] = useState('basic')
  type FormValues = {
    // csr_no: string
    // cin_id: string
    intake_date: any
    first_name: string
    middle_name: string
    last_name: string
    dob: any
    ssn: string
    // age: string
    gender: string
    // address: string
    // country: string
    // region: string
    // woreda: string
    // house_no: string
    address: {
      id: string
      country: string
      addressLine1: string
      addressLine2: string
      city: string
      state: string
      postalCode: string
      latitude: number
      longitude: number
    }
    phone: string
    email: string
    language: string[]
    barrier_ids: string[]
    mcp_id: string
    is_veteran: boolean
    emergency_contacts:
      | {
          first_name: string
          middle_name: string
          last_name: string
          phone: string
          email: string
          relationship: string
          // address: string
          // country: string
          // region: string
          // woreda: string
          // house_no: string
          address: {
            id: string
            country: string
            addressLine1: string
            addressLine2: string
            city: string
            state: string
            postalCode: string
            latitude: number
            longitude: number
          }
        }[]
      | []

    intake_id: string
    program_ids: string[]
    profile_picture_url: string
  }
  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      // csr_no: '',
      // cin_id: '',
      intake_date: '',
      first_name: '',
      middle_name: '',
      last_name: '',
      dob: '',
      ssn: '',
      // age: '',
      gender: '',
      // address: '',
      // country: '',
      // region: '',
      // woreda: '',
      // house_no: '',
      address: {
        id: '',
        country: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        latitude: 1.2,
        longitude: 2.4,
      },
      phone: '',
      email: '',
      language: [],
      barrier_ids: [],
      mcp_id: '',
      is_veteran: false,
      emergency_contacts: [],
      intake_id: '',
      program_ids: [],
      profile_picture_url: '',
    },
    mode: 'onBlur',
  })
  const { fields, append, remove } = useFieldArray({
    name: 'emergency_contacts',
    control,
  })

  // const [anchorEl, setAnchorEl] = useState(null)

  // const handleClick = (event: any) => {
  //   setAnchorEl(event.currentTarget)
  // }

  // const handleClose = () => {
  //   setAnchorEl(null)
  // }

  // const openAc = Boolean(anchorEl)
  // const id = openAc ? 'simple-popover' : undefined

  const [contact, setContact] = useState() as any
  const [intake_date, setIntakeDate] = useState() as any
  const [dob, setDob] = useState() as any
  const [gender, setGender] = useState() as any
  const [intake_id, setIntakeId] = useState() as any
  const [program_ids, setProgramIds] = useState() as any
  const [languages, setLanguages] = useState<string[]>([])
  const [barier, setBarier] = useState<any[]>([])
  const [baseClientLocation, setBaseClientLocation] = useState() as any
  const [address, setAddress] = useState() as any
  const [isContact, setIsContact] = useState(false)
  const [contactIndex, setContactIndex] = useState(0)
  const [pcode, setPcode] = useState() as any
  const [profile_picture_url, setProfile_picture_url] = useState<string>()
  const [fileState, setFileState] = useState<any>()
  const [url, setUrl] = useState() as any

  console.log({ profile_picture_url })

  useEffect(() => {
    console.log({ fileState })
    if (fileState) {
      axios
        .get(
          `${BASE_URL}/public/document/presigned-url?fileKey=${fileState?.name}`,
          {
            headers: authHeader(),
          }
        )
        .then((res) => setUrl(res?.data?.url))
        .catch((e) => console.log({ e }))
    }
  }, [fileState])
  useEffect(() => {
    if (url) {
      const reader = new FileReader()
      reader.onload = handleReaderLoaded
      reader.readAsArrayBuffer(fileState)
    }
  }, [url])
  const handleReaderLoaded = (event: any) => {
    const binaryData = event.target.result
    console.log({ binaryData })

    axios
      .put(url, binaryData)
      .then((res) => setProfile_picture_url(url?.split('?')?.[0]))
      .catch((e) => console.log({ e }))
  }

  console.log({ pcode })

  const [contactLocation, setContactLocation] = useState<{
    changedIndex: number
    location: { index: number; value: any }[]
  }>({ changedIndex: 0, location: [] })

  console.log({ contactLocation })
  const [contactAddress, setContactAddress] = useState<
    { index: number; value: any }[]
  >([])
  console.log({ baseClientLocation, address })
  console.log({ contact })
  console.log({ contactAddress })

  const {
    mutate,
    isLoading,
    isError,
    isSuccess,
    error,
    data: clientData,
  } = usePost('/public/clients/register')

  const { data: barriers, mutate: FetchBarriers } = usePost(
    '/public/clients/barriers/list'
  )

  console.log({ clientData, barriers, barier })

  const onSubmit = (data: any) => {
    data.intake_date = intake_date?.$d?.toISOString()?.replace('.000', '')
    data.dob = dob?.$d?.toISOString()?.replace('.000', '')

    if (data?.is_veteran === 'no') {
      data.is_veteran = false
    } else {
      data.is_veteran = true
    }
    data.intake_id = intake_id
    data.program_ids = program_ids
    data.language = languages
    data.profile_picture_url = profile_picture_url
    data.barrier_ids = barier?.map((item) => item?.id)
    // data.address = address
    data.phone = pcode?.phone + data.phone
    // data.csr_no = '123'
    if (data?.emergency_contacts?.length) {
      contactAddress?.map((item, index) => {
        data.emergency_contacts[index].address = item?.value
      })
    }
    console.log({ data })
    mutate(data)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Client Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      // close()
      setFormState('stepper')
    }
    if (isError) {
      console.log({ error })
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  const { data: programs, mutate: fetchPrograms } = usePost(
    '/public/configs/programs/list'
  )

  const { data: intakes, mutate: fetchIntakes } = usePost(
    '/public/configs/intakes/list'
  )
  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    FetchBarriers(dataP)
  }, [])

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchPrograms(dataP)
  }, [])

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchIntakes(dataP)
  }, [])

  console.log({ intakes, programs })

  const [selectedOption, setSelectedOption] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)

  const handleOptionChange = (event: any, newValue: any) => {
    setSelectedOption(newValue)
  }

  const handleSaveButtonClick = () => {
    // Handle save action here
    console.log('Save button clicked')
  }

  console.log({ errors })
  function GridItem({ title }: { title: string }) {
    return (
      <Grid item xs={12} sm={6} md={3}>
        <Box>
          <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
            {title}
          </Typography>
          <TextField size='small' fullWidth />
        </Box>
      </Grid>
    )
  }

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue: setPlacesValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: 'GO_PLACES',
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  })

  console.log({ status, data })

  useEffect(() => {
    axios
      .get(
        `${BASE_URL}/maps/api/place/details/json?place_id=${baseClientLocation?.place_id}`
      )
      .then((res) => {
        console.log({ data: res?.data })
        if (res?.data?.result) {
          setPcode(
            countries?.filter(
              (item) =>
                item?.code ===
                res?.data?.result?.address_components?.filter((item: any) =>
                  item?.types?.includes('country')
                )[0]?.short_name
            )?.[0]
          )
          setAddress({
            country: res?.data?.result?.address_components?.filter(
              (item: any) => item?.types?.includes('country')
            )[0]?.long_name,
            state:
              res?.data?.result?.address_components?.filter((item: any) =>
                item?.types?.includes('administrative_area_level_1')
              )[0]?.long_name ||
              res?.data?.result?.address_components?.filter((item: any) =>
                item?.types?.includes('locality')
              )[0]?.long_name,
            city:
              res?.data?.result?.address_components?.filter((item: any) =>
                item?.types?.includes('locality')
              )[0]?.long_name ||
              res?.data?.result?.address_components?.filter((item: any) =>
                item?.types?.includes('administrative_area_level_2')
              )[0]?.long_name,
            addressLine1: baseClientLocation?.description,
            addressLine2: '',
            postalCode: '',
            latitude: res?.data?.result?.geometry?.location?.lat,
            longitude: res?.data?.result?.geometry?.location?.lng,
          })
        } else {
          setAddress()
        }
      })
      .catch((e) => console.log(e))
  }, [baseClientLocation])

  useEffect(() => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/place/details/json?place_id=${
          contactLocation?.location?.[contactLocation?.changedIndex]?.value
            ?.place_id
        }&key=AIzaSyA5AuE_ESosvMG_CijpCw1Ouga6aF_irTo`
      )
      .then((res) => {
        console.log({ data: res?.data })
        if (res?.data?.result) {
          if (
            contactAddress?.some(
              (el) => el.index === contactLocation?.changedIndex
            )
          ) {
            setContactAddress((state) => [
              ...state.filter(
                (val: any) => val.index !== contactLocation?.changedIndex
              ),
              {
                index: contactLocation?.changedIndex,
                value: {
                  country: res?.data?.result?.address_components?.filter(
                    (item: any) => item?.types?.includes('country')
                  )[0]?.long_name,
                  state:
                    res?.data?.result?.address_components?.filter((item: any) =>
                      item?.types?.includes('administrative_area_level_1')
                    )[0]?.long_name ||
                    res?.data?.result?.address_components?.filter((item: any) =>
                      item?.types?.includes('locality')
                    )[0]?.long_name,
                  city:
                    res?.data?.result?.address_components?.filter((item: any) =>
                      item?.types?.includes('locality')
                    )[0]?.long_name ||
                    res?.data?.result?.address_components?.filter((item: any) =>
                      item?.types?.includes('administrative_area_level_2')
                    )[0]?.long_name,
                  addressLine1:
                    contactLocation?.location?.[contactLocation?.changedIndex]
                      ?.value?.description,
                  addressLine2: '',
                  postalCode: '',
                  latitude: res?.data?.result?.geometry?.location?.lat,
                  longitude: res?.data?.result?.geometry?.location?.lng,
                },
              },
            ])
          } else {
            setContactAddress((state) => [
              ...state,
              {
                index: contactLocation?.changedIndex,
                value: {
                  country: res?.data?.result?.address_components?.filter(
                    (item: any) => item?.types?.includes('country')
                  )[0]?.long_name,
                  state:
                    res?.data?.result?.address_components?.filter((item: any) =>
                      item?.types?.includes('administrative_area_level_1')
                    )[0]?.long_name ||
                    res?.data?.result?.address_components?.filter((item: any) =>
                      item?.types?.includes('locality')
                    )[0]?.long_name,
                  city:
                    res?.data?.result?.address_components?.filter((item: any) =>
                      item?.types?.includes('locality')
                    )[0]?.long_name ||
                    res?.data?.result?.address_components?.filter((item: any) =>
                      item?.types?.includes('administrative_area_level_2')
                    )[0]?.long_name,
                  addressLine1:
                    contactLocation?.location?.[contactLocation?.changedIndex]
                      ?.value?.description,
                  addressLine2: '',
                  postalCode: '',
                  latitude: res?.data?.result?.geometry?.location?.lat,
                  longitude: res?.data?.result?.geometry?.location?.lng,
                },
              },
            ])
          }
        } else {
          setAddress()
        }
      })
      .catch((e) => console.log(e))
  }, [contactLocation])

  const [selectedLocation, setSelectedLocation] = useState() as any

  useEffect(() => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${selectedLocation?.lat},${selectedLocation?.lng}&key=AIzaSyA5AuE_ESosvMG_CijpCw1Ouga6aF_irTo`
      )
      .then((res) => {
        if (res?.data?.results) {
          setBaseClientLocation({
            place_id: res?.data?.results[0]?.place_id,
            description: res?.data?.results[0]?.formatted_address,
          })
        }
      })
      .catch((e) => console?.log(e))
  }, [selectedLocation])

  const handleMarkerClick = async (location: any) => {
    // Use the Google Maps Geocoding API to get the address based on the clicked location
    const geocoder = new window.google.maps.Geocoder()
    geocoder.geocode({ location }, (results, status) => {
      if (status === 'OK' && results?.[0]) {
        console.log({ results })
        const ad = results?.[0].formatted_address
        console.log('Selected Address:', ad)
      } else {
        console.error(
          'Geocode was not successful for the following reason:',
          status
        )
      }
    })

    console.log({ location })

    setSelectedLocation(location)
  }

  const handleContactMarkerClick = async (location: any, index: number) => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${location?.lat},${location?.lng}&key=AIzaSyA5AuE_ESosvMG_CijpCw1Ouga6aF_irTo`
      )
      .then((res) => {
        if (res?.data?.results) {
          if (contactLocation?.location?.some((el) => el.index === index)) {
            setContactLocation((state) => {
              const location = [
                ...state?.location?.filter((val) => val.index !== index),
                {
                  index: index,
                  value: {
                    place_id: res?.data?.results[0]?.place_id,
                    description: res?.data?.results[0]?.formatted_address,
                  },
                },
              ]
              return {
                changedIndex: index,
                location,
              }
            })
          } else {
            setContactLocation((state) => {
              const location = [
                ...state?.location,
                {
                  index: index,
                  value: {
                    place_id: res?.data?.results[0]?.place_id,
                    description: res?.data?.results[0]?.formatted_address,
                  },
                },
              ]
              return {
                changedIndex: index,
                location,
              }
            })
          }
        }
      })
      .catch((e) => console?.log(e))
  }

  console.log({ selectedLocation })

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', xl: 'calc(100vw - 280px)' },
              height: '100vh',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  bgcolor: '#FBFBFB',
                  height: 60,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  px: 3,
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#EDEDED',
                    px: 3,
                    py: 0.8,
                    cursor: 'pointer',
                    borderRadius: 2,
                  }}
                  onClick={() => {
                    close()
                    setFormState('basic')
                  }}
                >
                  Back
                </Box>
                <Typography sx={{ fontWeight: 'bold' }}>New Client</Typography>
                <Box> </Box>
              </Box>
              <Box
                sx={{
                  px: formState === 'basic' ? { xs: 5, sm: 10, md: 15 } : 0,
                  py: 5,
                  height:
                    formState === 'basic'
                      ? 'calc(100vh - 140px)'
                      : 'calc(100vh - 70px)',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                }}
              >
                {formState === 'basic' ? (
                  <Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'cneter',
                        justifyContent: 'flex-end',
                        gap: 3,
                      }}
                    >
                      {/* <Box>
                        <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                          CIN ID
                        </Typography>
                        <TextField
                          variant='standard'
                          size='small'
                          {...register('cin_id', {
                            required: true,
                          })}
                        />
                      </Box> */}
                      <Box>
                        <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                          Intake Date
                        </Typography>
                        <DatePicker
                          slotProps={{
                            textField: {
                              size: 'small',
                              variant: 'standard',
                              ...register('intake_date'),
                            },
                          }}
                          onChange={(newValue) => setIntakeDate(newValue)}
                        />
                      </Box>
                    </Box>
                    <Box sx={{ py: 5 }}>
                      <FileUpload
                        type='PROFILE_IMAGE'
                        name='clientImage'
                        setFileState={setFileState}
                      />
                      <Grid container spacing={5} sx={{ py: 2 }}>
                        <Grid item xs={12} sm={6} md={3}>
                          <Box>
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              First Name
                            </Typography>
                            <TextField
                              size='small'
                              fullWidth
                              {...register('first_name', {
                                required: true,
                              })}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Box>
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Middle Name
                            </Typography>
                            <TextField
                              size='small'
                              fullWidth
                              {...register('middle_name', {
                                required: true,
                              })}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Box>
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Last Name
                            </Typography>
                            <TextField
                              size='small'
                              fullWidth
                              {...register('last_name', {
                                required: true,
                              })}
                            />
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                          <Box>
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              SSN
                            </Typography>
                            <TextField
                              size='small'
                              fullWidth
                              {...register('ssn', {
                                required: true,
                              })}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Box>
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Date of Birth
                            </Typography>
                            <DatePicker
                              defaultValue={dob}
                              slotProps={{
                                textField: {
                                  size: 'small',
                                  fullWidth: true,
                                },
                                ...register('dob'),
                              }}
                              onChange={(newValue) => setDob(newValue)}
                            />
                          </Box>
                        </Grid>
                        {/* <Grid item xs={12} sm={6} md={3}>
                          <Box>
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Age
                            </Typography>
                            <TextField
                              size='small'
                              fullWidth
                              {...register('age', {
                                required: true,
                              })}
                            />
                          </Box>
                        </Grid> */}
                        <Grid item xs={12} sm={6} md={3}>
                          <Box>
                            <FormControl>
                              <FormLabel id='demo-row-radio-buttons-group-label'>
                                Gender
                              </FormLabel>
                              <RadioGroup
                                row
                                aria-labelledby='demo-row-radio-buttons-group-label'
                                name='row-radio-buttons-group'
                                onChange={(e) => {
                                  setGender(e.target.value)
                                }}
                                defaultValue={gender}
                              >
                                <FormControlLabel
                                  value='female'
                                  control={
                                    <Radio
                                      {...register('gender', {
                                        required: true,
                                      })}
                                    />
                                  }
                                  label='Female'
                                />
                                <FormControlLabel
                                  value='male'
                                  control={
                                    <Radio
                                      {...register('gender', {
                                        required: true,
                                      })}
                                    />
                                  }
                                  label='Male'
                                />
                                {/* <FormControlLabel
                            value='other'
                            control={<Radio />}
                            label='Other'
                          /> */}
                              </RadioGroup>
                            </FormControl>
                          </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box>
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Location
                            </Typography>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 1,
                                width: '100%',
                              }}
                            >
                              <Autocomplete
                                options={data || []}
                                getOptionLabel={(option) => option?.description}
                                onChange={(event, value) => {
                                  setBaseClientLocation(value)
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size='small'
                                    onChange={(e) =>
                                      setPlacesValue(e.target.value)
                                    }
                                  />
                                )}
                                fullWidth
                              />
                              <IconButton onClick={() => setFormState('map')}>
                                <LocationOn sx={{ color: 'primary.main' }} />
                              </IconButton>
                            </Box>
                          </Box>
                        </Grid>
                        <Box
                          sx={{
                            p: 5,
                            border: '1px solid lightgray',
                            borderRadius: 4,
                            width: '100%',
                            ml: 5,
                            my: 5,
                            display: address ? 'block' : 'none',
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 2,
                                }}
                              >
                                <Typography
                                  sx={{ fontWeight: 'bold', fontSize: 16 }}
                                >
                                  Country:
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    color: 'gray',
                                  }}
                                >
                                  {address?.country}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 2,
                                }}
                              >
                                <Typography
                                  sx={{ fontWeight: 'bold', fontSize: 16 }}
                                >
                                  State:
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    color: 'gray',
                                  }}
                                >
                                  {address?.state}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 2,
                                }}
                              >
                                <Typography
                                  sx={{ fontWeight: 'bold', fontSize: 16 }}
                                >
                                  City:
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    color: 'gray',
                                  }}
                                >
                                  {address?.city}
                                </Typography>
                              </Box>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 2,
                                }}
                              >
                                <Typography
                                  sx={{ fontWeight: 'bold', fontSize: 16 }}
                                >
                                  Address:
                                </Typography>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 14,
                                    color: 'gray',
                                  }}
                                >
                                  {address?.addressLine1}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>

                        <Grid item xs={12} sm={6} md={6}>
                          <Box>
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Phone Number
                            </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              {/* <Autocomplete
                                options={countries}
                                getOptionLabel={(item) =>
                                  item?.phone + " " + item?.code
                                }
                                size="small"
                                // onChange={(event, value) => {
                                //   setLanguages(value);
                                // }}
                                // multiple
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size="small"
                                    // InputProps={{
                                    //   sx: {
                                    //     borderTopLeftRadius: 20,
                                    //     borderBottomLeftRadius: 20,
                                    //     borderTopRightRadius: 0,
                                    //     borderBottomRightRadius: 0,
                                    //     width: 70,
                                    //     bgcolor: "#C4C4C4",
                                    //   },
                                    // }}
                                    // onChange={(e) => setLanguage(e.target.value)}
                                  />
                                )}
                              /> */}
                              <Autocomplete
                                sx={{ width: 300 }}
                                options={countries}
                                autoHighlight
                                size='small'
                                getOptionLabel={(option) => '+' + option.phone}
                                value={pcode}
                                onChange={(e, val) => setPcode(val)}
                                renderOption={(props, option) => (
                                  <Box
                                    component='li'
                                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                    {...props}
                                  >
                                    <img
                                      loading='lazy'
                                      width='20'
                                      srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                      src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                      alt=''
                                    />
                                    {option.label} ({option.code}) +
                                    {option.phone}
                                  </Box>
                                )}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    inputProps={{
                                      ...params.inputProps,
                                      autoComplete: 'new-password', // disable autocomplete and autofill
                                    }}
                                  />
                                )}
                              />
                              <TextField
                                size='small'
                                fullWidth
                                {...register('phone', {
                                  required: true,
                                })}
                                InputProps={{
                                  sx: {
                                    borderRadius: 0,
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                          <Box>
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Email
                            </Typography>
                            <TextField
                              size='small'
                              fullWidth
                              {...register('email', {
                                required: true,
                              })}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Box>
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Languages
                            </Typography>
                            <Autocomplete
                              options={languagesList}
                              getOptionLabel={(item) => item}
                              size='small'
                              onChange={(event, value) => {
                                setLanguages(value)
                              }}
                              multiple
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size='small'
                                  fullWidth
                                  // onChange={(e) => setLanguage(e.target.value)}
                                />
                              )}
                            />
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Box>
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Barier
                            </Typography>
                            <Autocomplete
                              sx={{ width: 300 }}
                              options={barriers?.data?.barriers || []}
                              autoHighlight
                              size='small'
                              multiple
                              getOptionLabel={(option) => option.title}
                              value={pcode}
                              onChange={(event, value) => {
                                setBarier(value)
                              }}
                              renderOption={(props, option) => (
                                <Box component='li' {...props}>
                                  <Typography> {option?.title}</Typography>
                                </Box>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  inputProps={{
                                    ...params.inputProps,
                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                  }}
                                />
                              )}
                            />
                            {/* <Popper
                              open={Boolean(anchorEl)}
                              anchorEl={anchorEl}
                              placement='bottom-end'
                            >
                              <Paper>
                                <List>
                                  {barriers?.data?.barriers.map(
                                    (option: any, index: number) => (
                                      <ListItem
                                        key={index}
                                        onClick={() =>
                                          handleOptionChange(null, option)
                                        }
                                      >
                                        <ListItemText primary={option.label} />
                                      </ListItem>
                                    )
                                  )}
                                  <ListItem>
                                    <Button
                                      onClick={handleSaveButtonClick}
                                      variant='contained'
                                      color='primary'
                                    >
                                      Save
                                    </Button>
                                  </ListItem>
                                </List>
                              </Paper>
                            </Popper> */}
                            {/* <Box sx={{ border: '1px solid red' }}> */}
                            {/* <OutlinedInput size='small' onClick={handleClick} />
                            <Popover
                              id={id}
                              open={openAc}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                              }}
                            >
                              <Box sx={{ py: 2 }}>
                                {barriers?.data?.barriers?.map((item: any) => (
                                  <Box
                                    sx={{
                                      '&:hover': {
                                        bgcolor: 'whitesmoke',
                                      },
                                      px: 2,
                                      py: 1,
                                      cursor: 'pointer',
                                    }}
                                  >
                                    <Typography>{item?.title}</Typography>
                                  </Box>
                                ))}
                              </Box>
                            </Popover> */}
                            {/* </Box> */}
                          </Box>
                          {/* <Box>
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              Barier
                            </Typography>
                            <Autocomplete
                              options={languagesList}
                              getOptionLabel={(item) => item}
                              size='small'
                              onChange={(event, value) => {
                                setBarier(value)
                              }}
                              multiple
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size='small'
                                  fullWidth
                                  // onChange={(e) => setLanguage(e.target.value)}
                                />
                              )}
                            />
                          </Box> */}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                          <Box>
                            <Typography
                              sx={{ fontSize: 13, fontWeight: 'bold' }}
                            >
                              MCP ID
                            </Typography>
                            <TextField
                              size='small'
                              fullWidth
                              {...register('mcp_id', {
                                required: true,
                              })}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 3 }}
                      >
                        <Typography sx={{ fontWeight: 'bold' }}>
                          Are you a Veteran?
                        </Typography>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby='demo-row-radio-buttons-group-label'
                            name='row-radio-buttons-group'
                          >
                            <FormControlLabel
                              value='yes'
                              control={
                                <Radio
                                  {...register('is_veteran', {
                                    required: true,
                                  })}
                                />
                              }
                              label='Yes'
                            />
                            <FormControlLabel
                              value='no'
                              control={
                                <Radio
                                  {...register('is_veteran', {
                                    required: true,
                                  })}
                                />
                              }
                              label='No'
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 3 }}
                      >
                        <Typography sx={{ fontWeight: 'bold' }}>
                          Do you have emergency contacts?
                        </Typography>
                        <FormControl>
                          <RadioGroup
                            row
                            aria-labelledby='demo-row-radio-buttons-group-label'
                            name='row-radio-buttons-group'
                            onChange={(e) => {
                              setContact(e.target.value)
                              if (e.target.value === 'yes') {
                                setValue('emergency_contacts', [
                                  {
                                    first_name: '',
                                    middle_name: '',
                                    last_name: '',
                                    phone: '',
                                    email: '',
                                    relationship: '',
                                    address: {
                                      id: '',
                                      country: '',
                                      addressLine1: '',
                                      addressLine2: '',
                                      city: '',
                                      state: '',
                                      postalCode: '',
                                      latitude: 1.2,
                                      longitude: 2.4,
                                    },
                                  },
                                ])
                              } else {
                                setValue('emergency_contacts', [])
                              }
                            }}
                          >
                            <FormControlLabel
                              value='yes'
                              control={<Radio />}
                              label='Yes'
                            />
                            <FormControlLabel
                              value='no'
                              control={<Radio />}
                              label='No'
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {contact === 'yes' &&
                        fields.map((item, index) => (
                          <Box>
                            <Grid container spacing={5} sx={{ py: 2 }}>
                              <Grid item xs={12} sm={6} md={3}>
                                <Box>
                                  <Typography
                                    sx={{ fontSize: 13, fontWeight: 'bold' }}
                                  >
                                    First Name
                                  </Typography>
                                  <TextField
                                    size='small'
                                    fullWidth
                                    {...register(
                                      `emergency_contacts.${index}.first_name`,
                                      {
                                        required: true,
                                      }
                                    )}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <Box>
                                  <Typography
                                    sx={{ fontSize: 13, fontWeight: 'bold' }}
                                  >
                                    Middle Name
                                  </Typography>
                                  <TextField
                                    size='small'
                                    fullWidth
                                    {...register(
                                      `emergency_contacts.${index}.middle_name`,
                                      {
                                        required: true,
                                      }
                                    )}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <Box>
                                  <Typography
                                    sx={{ fontSize: 13, fontWeight: 'bold' }}
                                  >
                                    Last Name
                                  </Typography>
                                  <TextField
                                    size='small'
                                    fullWidth
                                    {...register(
                                      `emergency_contacts.${index}.last_name`,
                                      {
                                        required: true,
                                      }
                                    )}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <Box>
                                  <Typography
                                    sx={{ fontSize: 13, fontWeight: 'bold' }}
                                  >
                                    Phone No
                                  </Typography>
                                  <TextField
                                    size='small'
                                    fullWidth
                                    {...register(
                                      `emergency_contacts.${index}.phone`,
                                      {
                                        required: true,
                                      }
                                    )}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <Box>
                                  <Typography
                                    sx={{ fontSize: 13, fontWeight: 'bold' }}
                                  >
                                    Email
                                  </Typography>
                                  <TextField
                                    size='small'
                                    fullWidth
                                    {...register(
                                      `emergency_contacts.${index}.email`,
                                      {
                                        required: true,
                                      }
                                    )}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={3}>
                                <Box>
                                  <Typography
                                    sx={{ fontSize: 13, fontWeight: 'bold' }}
                                  >
                                    Relationship
                                  </Typography>
                                  <TextField
                                    size='small'
                                    fullWidth
                                    {...register(
                                      `emergency_contacts.${index}.relationship`,
                                      {
                                        required: true,
                                      }
                                    )}
                                  />
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={6} md={6}>
                                <Box>
                                  <Typography
                                    sx={{ fontSize: 13, fontWeight: 'bold' }}
                                  >
                                    Location
                                  </Typography>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                      width: '100%',
                                    }}
                                  >
                                    <Autocomplete
                                      options={data || []}
                                      getOptionLabel={(option) =>
                                        option?.description
                                      }
                                      onChange={(event, value) => {
                                        if (
                                          contactLocation?.location?.some(
                                            (el) => el.index === index
                                          )
                                        ) {
                                          setContactLocation((state) => {
                                            const location = [
                                              ...state?.location?.filter(
                                                (val) => val.index !== index
                                              ),
                                              { index: index, value },
                                            ]
                                            return {
                                              changedIndex: index,
                                              location,
                                            }
                                          })
                                        } else {
                                          setContactLocation((state) => {
                                            const location = [
                                              ...state?.location,
                                              { index: index, value },
                                            ]
                                            return {
                                              changedIndex: index,
                                              location,
                                            }
                                          })
                                        }
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          size='small'
                                          onChange={(e) =>
                                            setPlacesValue(e.target.value)
                                          }
                                        />
                                      )}
                                      fullWidth
                                    />
                                    <IconButton
                                      onClick={() => {
                                        setIsContact(true)
                                        setContactIndex(index)
                                        setFormState('map')
                                      }}
                                    >
                                      <LocationOn
                                        sx={{ color: 'primary.main' }}
                                      />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </Grid>
                            </Grid>
                            <Box
                              sx={{
                                p: 5,
                                border: '1px solid lightgray',
                                borderRadius: 4,
                                width: '100%',
                                my: 5,
                                display: contactAddress?.[index]?.value
                                  ? 'block'
                                  : 'none',
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6} md={6}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 2,
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontWeight: 'bold', fontSize: 16 }}
                                    >
                                      Country:
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontWeight: 'bold',
                                        fontSize: 14,
                                        color: 'gray',
                                      }}
                                    >
                                      {contactAddress?.[index]?.value?.country}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 2,
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontWeight: 'bold', fontSize: 16 }}
                                    >
                                      State:
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontWeight: 'bold',
                                        fontSize: 14,
                                        color: 'gray',
                                      }}
                                    >
                                      {contactAddress?.[index]?.value?.state}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 2,
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontWeight: 'bold', fontSize: 16 }}
                                    >
                                      City:
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontWeight: 'bold',
                                        fontSize: 14,
                                        color: 'gray',
                                      }}
                                    >
                                      {contactAddress?.[index]?.value?.city}
                                    </Typography>
                                  </Box>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 2,
                                    }}
                                  >
                                    <Typography
                                      sx={{ fontWeight: 'bold', fontSize: 16 }}
                                    >
                                      Address:
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontWeight: 'bold',
                                        fontSize: 14,
                                        color: 'gray',
                                      }}
                                    >
                                      {
                                        contactAddress?.[index]?.value
                                          ?.addressLine1
                                      }
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                              }}
                            >
                              {fields.length > 1 && (
                                <Box
                                  sx={{
                                    bgcolor: '#BBC4DD',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 1,
                                    width: 200,
                                    py: 1,
                                    my: 2,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() => remove(index)}
                                >
                                  <Remove sx={{ color: 'white' }} />
                                  <Typography sx={{ color: 'white' }}>
                                    Remove
                                  </Typography>
                                </Box>
                              )}
                              {index === fields.length - 1 && (
                                <Box
                                  sx={{
                                    bgcolor: '#F4FAFF',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: 1,
                                    width: 200,
                                    py: 1,
                                    my: 2,
                                    cursor: 'pointer',
                                  }}
                                  onClick={() =>
                                    append({
                                      first_name: '',
                                      middle_name: '',
                                      last_name: '',
                                      phone: '',
                                      email: '',
                                      relationship: '',
                                      address: {
                                        id: '',
                                        country: '',
                                        addressLine1: '',
                                        addressLine2: '',
                                        city: '',
                                        state: '',
                                        postalCode: '',
                                        latitude: 1.2,
                                        longitude: 2.4,
                                      },
                                    })
                                  }
                                >
                                  <Add sx={{ color: 'primary.main' }} />
                                  <Typography sx={{ color: 'primary.main' }}>
                                    Add
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        ))}

                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 3 }}
                      >
                        <Box>
                          <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                            Intake
                          </Typography>
                          <Autocomplete
                            options={intakes?.data?.intakes || []}
                            getOptionLabel={(item: any) => item.name}
                            size='small'
                            onChange={(event, value) => setIntakeId(value?.id)}
                            // onChange={(event, value) => {
                            //   setLanguage(value)
                            // }}
                            sx={{ width: 250 }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size='small'
                                fullWidth
                                // onChange={(e) => setLanguage(e.target.value)}
                              />
                            )}
                          />
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                            Programs
                          </Typography>
                          <Autocomplete
                            options={programs?.data?.programs || []}
                            getOptionLabel={(item: any) => item.name}
                            size='small'
                            onChange={(event, value) => {
                              console.log({ value })
                              let ids: any = []
                              value?.map((item) => {
                                ids?.push(item?.id)
                              })
                              setProgramIds(ids)
                            }}
                            // onChange={(event, value) => {
                            //   setLanguage(value)
                            // }}
                            sx={{ width: 250 }}
                            multiple
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size='small'
                                fullWidth
                                // onChange={(e) => setLanguage(e.target.value)}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : formState === 'map' ? (
                  <PinMap
                    onMarkerClick={handleMarkerClick}
                    setFormState={setFormState}
                    isContact={isContact}
                    setIsContact={setIsContact}
                    contactIndex={contactIndex}
                    contactMarkerClick={handleContactMarkerClick}
                  />
                ) : (
                  <ClientStepper
                    clientId={clientData?.data?.clientId}
                    close={close}
                    refetch={refetch}
                    setRefetch={setRefetch}
                  />
                )}
              </Box>
              {formState === 'basic' && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'fixed',
                    bottom: 0,
                    width: { xs: '100vw', md: 'calc(100vw - 280px)' },
                    height: 80,
                    py: 3,
                    px: { xs: 5, sm: 10 },
                  }}
                >
                  <Button
                    variant='contained'
                    type='submit'
                    // onClick={handleSubmit}
                    sx={{ px: 4, py: 2.5, width: 300 }}
                    // onClick={() => setFormState('stepper')}
                  >
                    {isLoading ? (
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                      >
                        <CircularProgress sx={{ color: 'white' }} size={20} />
                        <Typography>submitting...</Typography>
                      </Box>
                    ) : (
                      <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                        Continue
                      </Typography>
                    )}
                  </Button>
                </Box>
              )}
            </form>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
