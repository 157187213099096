import { QueryClient, QueryClientProvider } from 'react-query'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { CssBaseline, Slide, ThemeProvider } from '@mui/material'
import theme from './theme'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { SnackbarProvider } from 'notistack'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import Login from './pages/Login'
import Layout from './components/Layout/Layout'
import UserManagement from './pages/UserManagement'
import Dashboard from './pages/Dashboard'
import ResetPassword from './pages/ResetPassword'
import VerifyOtp from './pages/VerifyOtp'
import SetNewPassword from './pages/SetNewPassword'
import Appointments from './pages/Appointments'
import CompanyRegistration from './pages/CompanyRegistration'
import Configurations from './pages/Configurations'
import Alerts from './pages/Alerts'
import Clients from './pages/Clients'
import Goals from './pages/Goals'
import Directory from './pages/Directory'
import Messaging from './pages/Messaging'
import ClientDetail from './pages/ClientDetail'
import Reports from './pages/Reports'
import { CheckAuth } from './components/auth/CheckAuth'

// import CallUI from './pages/Call'
import TestVideoCall from './pages/TestVideoCall'
import Documents from './pages/Documnets'
import ActivityLog from './pages/ActivityLog'
import Settings from './pages/Settings'
import Billing from './pages/Billing'
import Support from './pages/Support'

const queryClient = new QueryClient()
function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        TransitionComponent={Slide}
      >
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CssBaseline />
            <Router>
              <Routes>
                <Route path='/login' element={<Login />} />
                <Route path='/resetpassword' element={<ResetPassword />} />
                <Route path='/verifyotp' element={<VerifyOtp />} />
                <Route path='/setnewpassword' element={<SetNewPassword />} />
                <Route
                  path='/companyregister'
                  element={<CompanyRegistration />}
                />
                <Route
                  path='/'
                  element={
                    <CheckAuth>
                      <Layout />
                    </CheckAuth>
                  }
                >
                  <Route path='/' element={<Dashboard />} />
                  <Route path='/usermanagement' element={<UserManagement />} />
                  <Route path='/appointments' element={<Appointments />} />
                  <Route path='/alerts' element={<Alerts />} />
                  <Route path='/configurations' element={<Configurations />} />
                  <Route path='/clients' element={<Clients />} />
                  <Route path='/goals' element={<Goals />} />
                  <Route path='/directory' element={<Directory />} />
                  <Route path='/messaging' element={<Messaging />} />
                  <Route path='/clients/:id' element={<ClientDetail />} />
                  <Route path='reports' element={<Reports />} />
                  <Route path='documents' element={<Documents />} />
                  <Route path='/activitylog' element={<ActivityLog />} />
                  <Route path='/settings' element={<Settings />} />
                  <Route path='/billing' element={<Billing />} />
                  <Route path='/support' element={<Support />} />
                </Route>
              </Routes>
            </Router>
          </LocalizationProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </QueryClientProvider>
  )
}

export default App
