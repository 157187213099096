import { Close, LocationOn, Pin, Search } from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material'
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers'
import { useEffect, useState } from 'react'
import FileUpload from '../File/FileUpload'
import { useFetch } from '../../hooks/useFetch'
import { useForm } from 'react-hook-form'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import axios from 'axios'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { BASE_URL } from '../../utils/config'
import { authHeader } from '../../utils/auth'
import {
  GoogleMap,
  Marker,
  OverlayView,
  useJsApiLoader,
} from '@react-google-maps/api'

export default function ClientMap({
  open,
  close,
}: {
  open: boolean
  close: any
}) {
  const [map, setMap] = useState(null)
  const [markerPosition, setMarkerPosition] = useState() as any
  const [center, setCenter] = useState({
    lat: 8.910989899999999,
    lng: 38.8263889,
  }) as any

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA5AuE_ESosvMG_CijpCw1Ouga6aF_irTo',
  })

  console.log({ isLoaded })

  const handleMapLoad = (map: any) => {
    setMap(map)
  }

  const handleMapClick = (event: any) => {
    const { latLng } = event
    setMarkerPosition({ lat: latLng.lat(), lng: latLng.lng() })
    setCenter({ lat: latLng.lat(), lng: latLng.lng() })
  }

  // const CustomMarker = ({ text, imageUrl }: any) => {
  // return (
  //   <div
  //     style={{
  //       position: 'relative',
  //       background: 'white',
  //       padding: '10px',
  //       borderRadius: '8px',
  //     }}
  //   >
  //     <img
  //       src={imageUrl}
  //       alt='marker'
  //       style={{ width: '50px', height: '50px' }}
  //     />
  //     <p>{text}</p>
  //   </div>
  // )
  // }
  const CustomMarker = ({ text, imageUrl }: any) => {
    return (
      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <LocationOn sx={{ color: 'blue', height: 50, width: 60 }} />
        <Box
          sx={{
            my: 2,
            p: 1,
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            bgcolor: 'white',
          }}
        >
          <Avatar src={imageUrl} sx={{ height: 20, width: 20 }} />
          <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
            Kidus
          </Typography>
        </Box>
      </Box>
    )
  }

  const markers = [
    {
      id: 1,
      position: { lat: 40.748817, lng: -73.985428 },
      text: 'Marker 1',
      imageUrl: 'path/to/image1.png',
    },
    {
      id: 2,
      position: { lat: 40.758896, lng: -73.98513 },
      text: 'Marker 2',
      imageUrl: 'path/to/image2.png',
    },
    {
      id: 3,
      position: { lat: 40.748433, lng: -73.984021 },
      text: 'Marker 3',
      imageUrl: 'path/to/image3.png',
    },
  ]

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', sm: '80vw', md: '70vw' },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                gap: 5,
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                My Clients Loacation
              </Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                height: 70,
                display: 'flex',
                alignItems: 'center',
                gap: 15,
                px: 5,
              }}
            >
              <Typography>Clients in Koye Fetche</Typography>
              <Search />
              <Autocomplete
                options={['Mental Needs', 'Physical Needs', 'Spiritual Needs']}
                renderInput={(params) => (
                  <TextField {...params} label='Filter By Programs' />
                )}
                sx={{ width: 300 }}
                size='small'
              />
            </Box>

            <Box sx={{ height: '100%', width: '100%', position: 'relative' }}>
              <GoogleMap
                mapContainerStyle={{ width: '100%', height: '95%' }}
                center={center}
                zoom={8}
                onLoad={handleMapLoad}
                onClick={handleMapClick}
                options={{
                  streetViewControl: false,
                  mapTypeControl: false,
                }}
              >
                {markerPosition && <Marker position={markerPosition} />}
                {markers.map((marker) => (
                  <OverlayView
                    key={marker.id}
                    position={marker.position}
                    mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
                  >
                    <CustomMarker
                      text={marker.text}
                      imageUrl={marker.imageUrl}
                    />
                  </OverlayView>
                ))}
              </GoogleMap>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                left: 0,
                height: 400,
                width: 200,
                bgcolor: 'white',
                zIndex: 100,
              }}
            >
              AAAAAAAAAA
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
