import zod from 'zod'

export const CompanySchema = zod.object({
  first_name: zod.string({ required_error: 'First Name is Required' }).trim(),
  middle_name: zod.string().optional(),
  last_name: zod.string({ required_error: 'Last Name is Required' }).trim(),
  email: zod.string({ required_error: 'Email is Required' }).trim(),
  phone_number: zod
    .string({ required_error: 'Phone Number is Required' })
    .trim(),
  password: zod.string({ required_error: 'Password is Required' }).trim(),
  repeat_password: zod
    .string({ required_error: 'Confirmation password is Required' })
    .trim(),
  company_name: zod
    .string({ required_error: 'Compapany Name is Required' })
    .trim(),
  company_industry: zod
    .string({ required_error: 'Company Industry is Required' })
    .trim(),
  // image_url: zod.string({ required_error: 'Image is Required' }).trim(),
})
