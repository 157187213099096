import {
  Add,
  CalendarMonth,
  Close,
  Edit,
  Message,
  PeopleAlt,
  Print,
  Share,
} from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Modal,
  Popover,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import FileUpload from '../File/FileUpload'
import { useForm } from 'react-hook-form'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { authHeader } from '../../utils/auth'
import { BASE_URL } from '../../utils/config'
import { TimePicker } from '@mui/x-date-pickers'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import moment from 'moment'

export default function AppointmentDetails({
  open,
  close,
  data,
}: {
  open: boolean
  close: any
  data: any
}) {
  console.log({ data })

  function GridItem({ title, value }: { title: string; value: any }) {
    return (
      <Grid container spacing={3} sx={{ my: 2 }}>
        <Grid item xs={4}>
          <Box>
            <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box
            sx={{
              bgcolor: '#F4FAFF',
              px: 2,
              py: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {title === 'PARTICIPANTS' ? (
              <Box>
                {value?.map((item: any) => (
                  <Box
                    sx={{
                      bgcolor: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      py: 0.5,
                      px: 0.5,
                      m: 0.5,
                      justifyContent: 'flex-end',
                      flexGrow: 0,
                      borderRadius: 3,
                    }}
                  >
                    <Avatar
                      src={item?.imageurl}
                      sx={{ width: 25, height: 25 }}
                    />
                    <Typography sx={{ fontSize: 11, fontWeight: 'bold' }}>
                      {item?.name}
                    </Typography>
                    <Box
                      sx={{
                        bgcolor: '#F4FAFF',
                        px: 1,
                        py: 0.5,
                        borderRadius: 3,
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 11,
                          fontWeight: 'bold',
                          color: '#496793',
                        }}
                      >
                        {item?.type}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            ) : (
              <Typography sx={{ fontWeight: 'bold' }}>{value}</Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 700 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>Appointment</Typography>
              <Box></Box>
            </Box>
            <Divider />
            <Box
              sx={{
                py: 1,
                height: 'calc(100vh - 70px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Box sx={{ py: 5, px: { xs: 5, sm: 10 } }}>
                <GridItem
                  title='TIME'
                  value={moment(data?.startTime).format('lll')}
                />
                <GridItem title='PARTICIPANTS' value={data?.participants} />
                <GridItem title='REASON' value={data?.reason} />
                <GridItem title='TYPE' value={data?.meetingType} />
                <GridItem
                  title='Duration'
                  value={
                    (
                      moment(data?.endTime).diff(moment(data?.startTime)) /
                      1000 /
                      60
                    ).toString() + ' Min'
                  }
                />
                <Box sx={{ bgcolor: '#F4FAFF', p: 2 }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    NOTE
                  </Typography>
                  <Typography>{data?.note}</Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
