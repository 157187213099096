import { Expand, OpenInFull } from '@mui/icons-material'
import { Box, Card, Divider, IconButton, Typography } from '@mui/material'
import { useState } from 'react'
import Alerts from '../../pages/Alerts'

export default function Alert() {
  function AlertCard() {
    return (
      <Box sx={{ py: 1 }}>
        <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
          No Response on Appointment{' '}
          <span
            style={{
              fontWeight: 'normal',
              color: '#BCBCBC',
              paddingLeft: 5,
            }}
          >
            {' '}
            4:00
          </span>
        </Typography>
        <Typography sx={{ color: '#BCBCBC', fontSize: 14, my: 1 }}>
          Kidus Abba | 0912345678 | ababa@gmail.com | CRN#123
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Box sx={{ px: 1, py: 0.5, bgcolor: '#DEF0FF', borderRadius: 5 }}>
            <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
              System
            </Typography>
          </Box>
          <Typography sx={{ color: '#777E8E', fontSize: 12 }}>
            Patient haven’t responded on the Appointment
          </Typography>
        </Box>
        <Box
          sx={{
            width: '100%',
            p: 1,
            bgcolor: '#F2F2F2',
            my: 2,
            borderRadius: 2,
          }}
        >
          <Typography sx={{ fontWeight: 'bold', color: '#26335E' }}>
            Note:
          </Typography>
          <Typography sx={{ color: '#26335E', fontSize: 12 }}>
            Patient didn’t show up on appointment
          </Typography>
        </Box>
        <Divider sx={{ my: 2 }} />
      </Box>
    )
  }
  const [alertOpen, setAlertOpen] = useState(false)
  return (
    <Box sx={{ height: '43%' }}>
      <Card
        sx={{
          bgcolor: 'white',
          boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
          height: '100%',
          borderRadius: 4,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 3,
            py: 2,
          }}
        >
          <Typography sx={{ color: '#0178DD', fontWeight: 'bold' }}>
            Alerts
          </Typography>

          <IconButton onClick={() => setAlertOpen(true)}>
            <OpenInFull sx={{ rotate: '90deg', color: '#0178DD' }} />
          </IconButton>
        </Box>

        <Box
          sx={{
            px: 3,
            height: '30vh',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: 0,
            },
          }}
        >
          <AlertCard />
          <AlertCard />

          <AlertCard />
        </Box>
      </Card>
      <Card
        sx={{
          position: 'absolute',
          bgcolor: 'white',
          top: 0,
          left: 0,
          width: 'calc(100vw - 280px)',
          height: '100%',
          display: alertOpen ? 'block' : 'none',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 5,
          }}
        >
          <Typography variant='h4'>Alerts</Typography>
          <IconButton onClick={() => setAlertOpen(false)}>
            <OpenInFull sx={{ rotate: '90deg', color: '#0178DD' }} />
          </IconButton>
        </Box>
        <Box>
          <Alerts />
        </Box>
      </Card>
    </Box>
  )
}
