// import { Add, CloudUploadOutlined, Remove } from '@mui/icons-material'
// import {
//   Autocomplete,
//   Box,
//   Button,
//   Card,
//   Checkbox,
//   CircularProgress,
//   Divider,
//   FormControlLabel,
//   IconButton,
//   Modal,
//   Slide,
//   TextField,
//   Typography,
// } from '@mui/material'
// import { useEffect, useState } from 'react'
// import { useFieldArray, useForm } from 'react-hook-form'
// import { useCSVReader } from 'react-papaparse'
// import { usePost } from '../../../hooks/usePost'
// import { enqueueSnackbar } from 'notistack'
// import { useFetch } from '../../../hooks/useFetch'

// export default function EditIntake({
//   open,
//   close,
//   intakeId,
// }: {
//   open: boolean
//   close: any
//   intakeId: string
// }) {
//   const { data, refetch } = useFetch(
//     'fetchprogram',
//     `/public/configs/intakes/${intakeId}`
//   )

//   console.log({ data: data?.intake?.intakeCategory })

//   useEffect(() => {
//     refetch()
//   }, [intakeId])

//   const [csvData, setCsvData] = useState() as any
//   const [values, setValues] = useState<
//     { index: number; value: { index: number; value: any[] }[] }[]
//   >([])
//   console.log({ values })
//   const { CSVReader } = useCSVReader()

//   const { mutate, isLoading, isError, isSuccess, error } = usePost(
//     '/public/configs/intakes'
//   )

//   type FormValues = {
//     title: string
//     intakeCategory: {
//       title: string
//       intakeCategoryFields: {
//         name: string
//         type: string
//         required: boolean
//         values: string[]
//         editable: boolean
//         auditable: boolean
//         vital: boolean
//       }[]
//     }[]
//   }

//   const { register, control, handleSubmit, setValue } = useForm<FormValues>({
//     defaultValues: data?.intake,
//     mode: 'onBlur',
//   })

//   const { fields, append, remove } = useFieldArray({
//     name: 'intakeCategory',
//     control,
//   })

//   console.log({ fields })

//   const onSubmit = (data: any) => {
//     values?.map((item, index) => {
//       item?.value?.map((elt, index2) => {
//         data.intakeCategory[item.index].intakeCategoryFields[elt.index].values =
//           elt?.value
//       })
//     })

//     console.log({ data })
//     mutate(data)
//   }

//   useEffect(() => {
//     if (isSuccess) {
//       enqueueSnackbar('Intake Created Successfully', {
//         variant: 'success',
//         preventDuplicate: true,
//       })
//       setValue('title', '')
//       setValue('intakeCategory', [
//         {
//           title: '',
//           intakeCategoryFields: [
//             {
//               name: '',
//               type: '',
//               required: false,
//               values: [],
//               editable: false,
//               auditable: false,
//               vital: false,
//             },
//           ],
//         },
//       ])

//       close()
//     }
//     if (isError) {
//       console.log({ error })
//       enqueueSnackbar(
//         error?.response?.data?.message || error?.message || 'Network Error',
//         { variant: 'error', preventDuplicate: true }
//       )
//     }
//   }, [isSuccess, isError])

//   return (
//     <Modal open={open} onClose={close} closeAfterTransition>
//       <Slide in={open} direction='left'>
//         <Box sx={{ position: 'relative', float: 'right' }}>
//           <Card
//             sx={{
//               width: { xs: '100vw', xl: 'calc(100vw - 280px)' },
//               height: '100vh',
//             }}
//           >
//             <form onSubmit={handleSubmit(onSubmit)}>
//               <Box
//                 sx={{
//                   bgcolor: '#FBFBFB',
//                   height: 60,
//                   display: 'flex',
//                   alignItems: 'center',
//                   justifyContent: 'space-between',
//                   px: 3,
//                 }}
//               >
//                 <Box
//                   sx={{
//                     bgcolor: '#EDEDED',
//                     px: 3,
//                     py: 0.8,
//                     cursor: 'pointer',
//                     borderRadius: 2,
//                   }}
//                   onClick={close}
//                 >
//                   Back
//                 </Box>
//                 <Typography sx={{ fontWeight: 'bold' }}>New Intake</Typography>
//                 <Box> </Box>
//               </Box>
//               <Box
//                 sx={{
//                   p: 3,
//                   height: 'calc(100vh - 140px)',
//                   overflowY: 'auto',
//                   '&::-webkit-scrollbar': {
//                     width: 0,
//                   },
//                 }}
//               >
//                 <Box>
//                   <Typography
//                     sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
//                   >
//                     Intake Title
//                   </Typography>
//                   <TextField
//                     size='small'
//                     sx={{ width: 400 }}
//                     {...register('title', {
//                       required: true,
//                     })}
//                   />
//                 </Box>
//                 {fields?.map((field, index) => (
//                   <Box sx={{ p: 3 }}>
//                     <Typography
//                       sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
//                     >
//                       Category Title
//                     </Typography>
//                     <TextField
//                       size='small'
//                       sx={{ width: 300 }}
//                       {...register(`intakeCategory.${index}.title`, {
//                         required: true,
//                       })}
//                     />

//                     {field?.intakeCategoryFields?.map((item, index2) => (
//                       <Box
//                         sx={{
//                           my: 2,
//                           display: 'flex',
//                           alignItems: 'center',
//                           gap: 3,
//                         }}
//                       >
//                         <Box>
//                           <Typography
//                             sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
//                           >
//                             Field Name
//                           </Typography>
//                           <TextField
//                             size='small'
//                             sx={{ width: 300 }}
//                             {...register(
//                               `intakeCategory.${index}.intakeCategoryFields.${index2}.name`,
//                               {
//                                 required: true,
//                               }
//                             )}
//                           />
//                         </Box>
//                         <Box>
//                           <Typography
//                             sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
//                           >
//                             Field Type
//                           </Typography>
//                           {/* Radio = 0; Dropdown = 1; Text = 2; Checkbox = 3; Date = 4; */}
//                           <Autocomplete
//                             options={[
//                               'Text',
//                               'Dropdown',
//                               'Checkbox',
//                               'Radio',
//                               'Date',
//                             ]}
//                             getOptionLabel={(item) => item}
//                             size='small'
//                             // onChange={(event, value) => {
//                             //   setLanguage(value)
//                             // }}

//                             fullWidth
//                             sx={{ width: 250 }}
//                             renderInput={(params) => (
//                               <TextField
//                                 {...params}
//                                 size='small'
//                                 fullWidth
//                                 sx={{ bgcolor: 'white' }}
//                                 {...register(
//                                   `intakeCategory.${index}.intakeCategoryFields.${index2}.type`,
//                                   {
//                                     required: true,
//                                   }
//                                 )}
//                                 // onChange={(e) => setLanguage(e.target.value)}
//                               />
//                             )}
//                           />
//                         </Box>
//                         <Box>
//                           <Typography
//                             sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
//                           >
//                             Field values
//                           </Typography>
//                           <Autocomplete
//                             options={csvData?.data || []}
//                             getOptionLabel={(item: any) => item?.options}
//                             size='small'
//                             onChange={(event, value) => {
//                               // if (values?.some((el) => el.index === index2)) {
//                               //   setValues((state) => [
//                               //     ...state.filter(
//                               //       (val: any) => val.index !== index2
//                               //     ),
//                               //     { index: index2, value },
//                               //   ])
//                               // } else {
//                               //   setValues((state) => [
//                               //     ...state,
//                               //     { index: index2, value },
//                               //   ])
//                               // }
//                             }}
//                             multiple
//                             fullWidth
//                             sx={{ width: 250 }}
//                             renderInput={(params) => (
//                               <Box
//                                 sx={{
//                                   display: 'flex',
//                                   gap: 1,
//                                   alignItems: 'center',
//                                 }}
//                               >
//                                 <TextField
//                                   {...params}
//                                   size='small'
//                                   fullWidth
//                                   sx={{ bgcolor: 'white' }}
//                                   //   {...register(
//                                   //     `program_fields.${index2}.values`,
//                                   //     {
//                                   //       required: false,
//                                   //     }
//                                   //   )}
//                                   // onChange={(e) => setLanguage(e.target.value)}
//                                 />
//                                 <CSVReader
//                                   onUploadAccepted={(results: any) => {
//                                     // setCsvData(results)
//                                     console.log({ results })
//                                     if (results?.data) {
//                                       setValues((prevValues) => {
//                                         const newValues = [...prevValues]
//                                         if (newValues[index]) {
//                                           if (newValues[index].value[index2]) {
//                                             newValues[index].value[
//                                               index2
//                                             ].value = results?.data?.map(
//                                               (el: any) => el?.options
//                                             )
//                                           } else {
//                                             newValues[index].value[index2] = {
//                                               index: index2,
//                                               value: results?.data?.map(
//                                                 (el: any) => el?.options
//                                               ),
//                                             }
//                                           }
//                                         } else {
//                                           newValues[index] = {
//                                             index: index,
//                                             value: [
//                                               {
//                                                 index: index2,
//                                                 value: results?.data?.map(
//                                                   (el: any) => el?.options
//                                                 ),
//                                               },
//                                             ],
//                                           }
//                                         }

//                                         return newValues
//                                       })
//                                     }
//                                   }}
//                                   config={{
//                                     header: true,
//                                     delimiter: '',
//                                     encoding: 'UTF-8',
//                                   }}
//                                 >
//                                   {({ getRootProps }: any) => (
//                                     <IconButton {...getRootProps()}>
//                                       <CloudUploadOutlined
//                                         sx={{ color: '#1B85E0' }}
//                                       />
//                                     </IconButton>
//                                   )}
//                                 </CSVReader>
//                               </Box>
//                             )}
//                           />
//                         </Box>

//                         <Box>
//                           <FormControlLabel
//                             name='required'
//                             label={'Required'}
//                             control={
//                               <Checkbox
//                                 {...register(
//                                   `intakeCategory.${index}.intakeCategoryFields.${index2}.required`
//                                 )}
//                               />
//                             }
//                           />
//                           <FormControlLabel
//                             name='vital'
//                             label={'Vital'}
//                             control={
//                               <Checkbox
//                                 {...register(
//                                   `intakeCategory.${index}.intakeCategoryFields.${index2}.vital`
//                                 )}
//                               />
//                             }
//                           />
//                           <FormControlLabel
//                             name='editable'
//                             label={'Editable'}
//                             control={
//                               <Checkbox
//                                 {...register(
//                                   `intakeCategory.${index}.intakeCategoryFields.${index2}.editable`
//                                 )}
//                               />
//                             }
//                           />
//                           <FormControlLabel
//                             name='auditable'
//                             label={'Save History'}
//                             control={
//                               <Checkbox
//                                 {...register(
//                                   `intakeCategory.${index}.intakeCategoryFields.${index2}.auditable`
//                                 )}
//                               />
//                             }
//                           />
//                         </Box>

//                         <Box
//                           sx={{
//                             display: 'flex',
//                             alignItems: 'center',
//                             gap: 2,
//                           }}
//                         >
//                           {field?.intakeCategoryFields?.length > 1 && (
//                             <IconButton
//                               sx={{
//                                 bgcolor: 'error.light',
//                                 '&:hover': {
//                                   bgcolor: 'error.light',
//                                 },
//                               }}
//                               onClick={() => {
//                                 const updatedFields = [...fields]

//                                 console.log({ index2 })

//                                 updatedFields[index].intakeCategoryFields =
//                                   updatedFields[
//                                     index
//                                   ].intakeCategoryFields.filter(
//                                     (_, index3) => index3 !== index2
//                                   )

//                                 console.log({
//                                   1: updatedFields[index].intakeCategoryFields,
//                                 })

//                                 setValue('intakeCategory', updatedFields)
//                               }}
//                             >
//                               <Remove sx={{ color: 'white' }} />
//                             </IconButton>
//                           )}
//                           {index2 ===
//                             field?.intakeCategoryFields?.length - 1 && (
//                             <IconButton
//                               sx={{
//                                 bgcolor: 'primary.main',
//                                 '&:hover': {
//                                   bgcolor: 'primary.main',
//                                 },
//                               }}
//                               onClick={() => {
//                                 const updatedFields = [...fields]
//                                 updatedFields[index].intakeCategoryFields = [
//                                   ...updatedFields[index].intakeCategoryFields,
//                                   {
//                                     name: '',
//                                     type: '',
//                                     required: false,
//                                     values: [],
//                                     editable: false,
//                                     auditable: false,
//                                     vital: false,
//                                   },
//                                 ]
//                                 setValue('intakeCategory', updatedFields)
//                               }}
//                             >
//                               <Add sx={{ color: 'white' }} />
//                             </IconButton>
//                           )}
//                         </Box>
//                       </Box>
//                     ))}
//                     <Divider />
//                     <Box
//                       sx={{
//                         display: 'flex',
//                         alignItems: 'center',
//                         gap: 2,
//                       }}
//                     >
//                       {fields.length > 1 && (
//                         <Box
//                           sx={{
//                             bgcolor: '#BBC4DD',
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             gap: 1,
//                             width: 200,
//                             py: 1,
//                             my: 2,
//                             cursor: 'pointer',
//                           }}
//                           onClick={() => remove(index)}
//                         >
//                           <Remove sx={{ color: 'white' }} />
//                           <Typography sx={{ color: 'white' }}>
//                             Remove
//                           </Typography>
//                         </Box>
//                       )}
//                       {index === fields.length - 1 && (
//                         <Box
//                           sx={{
//                             bgcolor: '#F4FAFF',
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             gap: 1,
//                             width: 200,
//                             py: 1,
//                             my: 2,
//                             cursor: 'pointer',
//                           }}
//                           onClick={() =>
//                             append({
//                               title: '',
//                               intakeCategoryFields: [
//                                 {
//                                   name: '',
//                                   type: '',
//                                   required: false,
//                                   values: [],
//                                   editable: false,
//                                   auditable: false,
//                                   vital: false,
//                                 },
//                               ],
//                             })
//                           }
//                         >
//                           <Add sx={{ color: 'primary.main' }} />
//                           <Typography sx={{ color: 'primary.main' }}>
//                             Add New Catgory
//                           </Typography>
//                         </Box>
//                       )}
//                     </Box>
//                   </Box>
//                 ))}
//               </Box>

//               <Box
//                 sx={{
//                   display: 'flex',
//                   justifyContent: 'flex-end',
//                   position: 'fixed',
//                   bottom: 0,
//                   width: { xs: '100vw', md: 'calc(100vw - 280px)' },
//                   height: 80,
//                   py: 3,
//                   px: { xs: 5, sm: 10 },
//                 }}
//               >
//                 <Button
//                   variant='contained'
//                   // onClick={handleSubmit}
//                   type='submit'
//                   sx={{ px: 4, py: 2.5 }}
//                 >
//                   {isLoading ? (
//                     <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
//                       <CircularProgress sx={{ color: 'white' }} size={20} />
//                       <Typography>submitting...</Typography>
//                     </Box>
//                   ) : (
//                     <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
//                       Submit Form
//                     </Typography>
//                   )}
//                 </Button>
//               </Box>
//             </form>
//           </Card>
//         </Box>
//       </Slide>
//     </Modal>
//   )
// }

import { Add, CloudUploadOutlined, Remove } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Slide,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useCSVReader } from 'react-papaparse'
import { usePost } from '../../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import { useFetch } from '../../../hooks/useFetch'
import { BASE_URL } from '../../../utils/config'
import axios from 'axios'
import { authHeader } from '../../../utils/auth'
import { usePatch } from '../../../hooks/usePatch'

export default function NewIntake({
  open,
  close,
  intakeData,
}: {
  open: boolean
  close: any
  intakeData: any
}) {
  const [type, setType] = useState<{ value: string[] }[]>([])

  const { mutate, isLoading, isError, isSuccess, error } = usePatch(
    `/public/configs/intakes/${intakeData?.intake?.id}`
  )

  const [program_id, setProgramId] = useState() as any
  const { data: programs, mutate: fetchPrograms } = usePost(
    '/public/configs/programs/list'
  )

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchPrograms(dataP)
  }, [])

  console.log({ error })

  // const [intakeData, setIntakeData] = useState() as any

  // useEffect(() => {
  //   if (intakeId) {
  //     axios
  //       .get(`${BASE_URL}/public/configs/intakes/${intakeId}`, {
  //         headers: authHeader(),
  //       })
  //       .then((res) => {
  //         setIntakeData(res.data)
  //       })
  //       .catch((e: any) => {
  //         console.log(e)
  //       })
  //   }
  // }, [intakeId])
  console.log({ type, intakeData })

  type FormValues = {
    id?: string
    title: string
    program_id: string
    intake_category: {
      id?: string
      title: string
      intake_id: string
      intake_category_fields: {
        id?: string
        field_label: string
        name: string
        type: string
        required: boolean
        values: string[]
        editable: boolean
        auditable: boolean
        vital: boolean
        include_in_report: boolean
        intake_category_id?: string
        intake_additional_config:
          | {
              id?: string
              intake_category_field_id?: string
              intake_category_field_value: string
              option?: {
                id?: string
                intake_additional_config_id?: string
                option: string
                is_task: boolean
                is_case_note: boolean
                due_date: string
              }
            }[]
      }[]
    }[]
  }

  const { register, control, handleSubmit, setValue, reset } =
    useForm<FormValues>({
      defaultValues: {
        id: intakeData?.intake?.id,
        title: intakeData?.intake?.title,
        program_id: intakeData?.intake?.program_id,
        intake_category: intakeData?.intake?.intakeCategory?.map(
          (item: any) => {
            console.log({ id1: item?.id })

            return {
              id: item?.id,
              title: item?.title,
              intake_id: intakeData?.intake?.id,
              intake_category_fields: item?.intakeCategoryFields?.map(
                (elt: any) => {
                  console.log({ id2: item?.id })
                  return {
                    id: elt?.id,
                    field_label: elt?.fieldLabel,
                    name: elt?.name,
                    type: elt?.type,
                    required: elt?.required,
                    values: elt?.values,
                    editable: elt?.editable,
                    auditable: elt?.auditable,
                    vital: elt?.vital,
                    inclide_in_report: elt?.includeInReport,
                    intake_category_id: item?.id,
                    intake_additional_config:
                      elt?.intakeAdditionalConfig?.length &&
                      elt?.intakeAdditionalConfig?.length ===
                        elt?.values?.length
                        ? elt?.intakeAdditionalConfig?.map((elt: any) => {
                            return {
                              id: elt?.id,
                              intake_category_field_id:
                                elt?.intakeCategoryFieldId,
                              intake_category_field_value:
                                elt?.intakeCategoryFieldValue,
                              option: {
                                id: elt?.option?.[0]?.id,
                                intake_additional_config_id:
                                  elt?.option?.[0]?.intakeAdditionalConfigId,
                                option: elt?.option?.[0]?.option,
                                is_task: elt?.option?.[0]?.isTask,
                                is_case_note: elt?.option?.[0]?.isCaseNote,
                                due_date: elt?.option?.[0]?.dueDate,
                              },
                            }
                          })
                        : elt?.intakeAdditionalConfig?.length &&
                          elt?.intakeAdditionalConfig?.length !==
                            elt?.values?.length
                        ? elt?.values?.map((el: any) => {
                            if (
                              elt?.intakeAdditionalConfig?.find(
                                (elt1: any) =>
                                  elt1?.intakeCategoryFieldValue === el
                              )
                            ) {
                              return {
                                id: elt?.intakeAdditionalConfig?.find(
                                  (elt1: any) =>
                                    elt1?.intakeCategoryFieldValue === el
                                )?.id,
                                intake_category_field_id:
                                  elt?.intakeAdditionalConfig?.find(
                                    (elt1: any) =>
                                      elt1?.intakeCategoryFieldValue === el
                                  )?.intakeCategoryFieldId,
                                intake_category_field_value:
                                  elt?.intakeAdditionalConfig?.find(
                                    (elt1: any) =>
                                      elt1?.intakeCategoryFieldValue === el
                                  )?.intakeCategoryFieldValue,
                                option: {
                                  id: elt?.intakeAdditionalConfig?.find(
                                    (elt1: any) =>
                                      elt1?.intakeCategoryFieldValue === el
                                  )?.option?.[0]?.id,
                                  intake_additional_config_id:
                                    elt?.intakeAdditionalConfig?.find(
                                      (elt1: any) =>
                                        elt1?.intakeCategoryFieldValue === el
                                    )?.option?.[0]?.intakeAdditionalConfigId,
                                  option: elt?.intakeAdditionalConfig?.find(
                                    (elt1: any) =>
                                      elt1?.intakeCategoryFieldValue === el
                                  )?.option?.[0]?.option,
                                  is_task: elt?.intakeAdditionalConfig?.find(
                                    (elt1: any) =>
                                      elt1?.intakeCategoryFieldValue === el
                                  )?.option?.[0]?.isTask,
                                  is_case_note:
                                    elt?.intakeAdditionalConfig?.find(
                                      (elt1: any) =>
                                        elt1?.intakeCategoryFieldValue === el
                                    )?.option?.[0]?.isCaseNote,
                                  due_date: elt?.intakeAdditionalConfig?.find(
                                    (elt1: any) =>
                                      elt1?.intakeCategoryFieldValue === el
                                  )?.option?.[0]?.dueDate,
                                },
                              }
                            } else {
                              return {
                                intake_category_field_value: el,
                              }
                            }
                          })
                        : elt?.values?.length
                        ? elt?.values?.map((el: any) => {
                            return {
                              intake_category_field_value: el,
                            }
                          })
                        : [],
                  }
                }
              ),
            }
          }
        ),
      },
      mode: 'onBlur',
    })

  const { fields, append, remove } = useFieldArray({
    name: 'intake_category',
    control,
  })

  const program = programs?.data?.programs?.find(
    (elt: any) => elt?.id === intakeData?.intake?.programId
  )

  console.log({ program })

  console.log({ fields })

  const onSubmit = (data: any) => {
    data.program_id = program_id || program?.id
    console.log(3333333, { data, fields })
    data?.intake_category?.map((item: any, index: number) => {
      console.log(55, item?.intake_category_fields[0]?.intake_category_id)
      if (item?.intake_category_fields[0]?.intake_category_id) {
        data.intake_category[index].id =
          item?.intake_category_fields[0]?.intake_category_id
      }
    })

    data?.intake_category?.map((item: any, index: number) => {
      item?.intake_category_fields?.map((item2: any, index2: number) => {
        data.intake_category[index].intake_category_fields[index2].values =
          item2?.intake_additional_config?.map(
            (item3: any) => item3?.intake_category_field_value
          )
      })
    })
    data?.intake_category?.map((item: any, index: number) => {
      item?.intake_category_fields?.map((item2: any, index2: number) => {
        data.intake_category[index].intake_category_fields[
          index2
        ].intake_additional_config = item2?.intake_additional_config?.filter(
          (elt: any) => elt?.option
        )
      })
    })

    data?.intake_category?.map((item: any, index: number) => {
      item?.intake_category_fields?.map((item2: any, index2: number) => {
        item2?.intake_additional_config?.map((item3: any, index3: number) => {
          if (
            data.intake_category[index].intake_category_fields[index2]
              .intake_additional_config[index3].option
          ) {
            data.intake_category[index].intake_category_fields[
              index2
            ].intake_additional_config[index3].option = [item3?.option]
          }
        })
      })
    })
    console.log(4444444, { data, fields })

    console.log(1111111111111, { data })
    mutate({
      intake_id: intakeData?.intake?.id,
      intake: data,
    } as any)
  }

  useEffect(() => {
    let newType: { value: string[] }[] = []
    intakeData?.intake?.intakeCategory?.map((item: any) => {
      newType?.push({
        value: item?.intakeCategoryFields?.map((elt: any) => elt?.type),
      })
    })
    setType(newType)
  }, [intakeData])

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Intake Updated Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      setValue('title', '')
      setValue('intake_category', [
        {
          title: '',
          intake_id: intakeData?.intake?.id,
          intake_category_fields: [
            {
              field_label: '',
              name: '',
              type: '',
              values: [],
              vital: false,
              required: false,
              editable: false,
              auditable: false,
              include_in_report: false,
              intake_additional_config: [],
            },
          ],
        },
      ])
      // setRefetch(!refetch)
      close()
    }
    if (isError) {
      console.log({ error })
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', xl: 'calc(100vw - 280px)' },
              height: '100vh',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  bgcolor: '#FBFBFB',
                  height: 60,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  px: 3,
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#EDEDED',
                    px: 3,
                    py: 0.8,
                    cursor: 'pointer',
                    borderRadius: 2,
                  }}
                  onClick={close}
                >
                  Back
                </Box>
                <Typography sx={{ fontWeight: 'bold' }}>Edit Intake</Typography>
                <Box> </Box>
              </Box>
              <Box
                sx={{
                  p: 3,
                  height: 'calc(100vh - 140px)',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                }}
              >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                  <Box>
                    <Typography
                      sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                    >
                      Intake Title
                    </Typography>
                    <TextField
                      size='small'
                      sx={{ width: 400 }}
                      {...register('title', {
                        required: true,
                      })}
                    />
                  </Box>
                  {programs && (
                    <Box>
                      <Typography
                        sx={{ fontWeight: 'bold', fontSize: 12, py: 0.5 }}
                      >
                        Program
                      </Typography>
                      <Autocomplete
                        options={programs?.data?.programs || []}
                        getOptionLabel={(item: any) => item?.name}
                        size='small'
                        fullWidth
                        sx={{ width: 250 }}
                        // defaultValue={programs?.data?.programs?.find(
                        //   (elt: any) => elt?.id === intakeData?.intake?.programId
                        // )}
                        defaultValue={program}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size='small'
                            fullWidth
                            sx={{ bgcolor: 'white' }}
                          />
                        )}
                        onChange={(event, value: any) => {
                          setProgramId(value?.id)
                        }}
                      />
                    </Box>
                  )}
                </Box>
                {fields?.map((field, index) => (
                  <Box sx={{ p: 3 }}>
                    <Divider sx={{ bgcolor: '#006CEA', height: 2, mb: 3 }} />
                    <Typography
                      sx={{ fontWeight: 'bold', fontSize: 13, py: 0.5 }}
                    >
                      CATEGORY TITLE
                    </Typography>
                    <TextField
                      size='small'
                      sx={{ width: 300 }}
                      {...register(`intake_category.${index}.title`, {
                        required: true,
                      })}
                    />
                    {field?.intake_category_fields?.map((item, index2) => (
                      <Box>
                        <Box sx={{ display: 'flex' }}>
                          <Box>
                            <Box
                              sx={{
                                my: 2,
                                display: 'flex',
                                alignItems: 'center',
                                gap: 3,
                              }}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    py: 0.5,
                                  }}
                                >
                                  Question Label
                                </Typography>
                                <TextField
                                  size='small'
                                  sx={{ width: 300 }}
                                  {...register(
                                    `intake_category.${index}.intake_category_fields.${index2}.field_label`,
                                    { required: true }
                                  )}
                                />
                              </Box>
                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    py: 0.5,
                                  }}
                                >
                                  Question
                                </Typography>
                                <TextField
                                  size='small'
                                  sx={{ width: 300 }}
                                  {...register(
                                    `intake_category.${index}.intake_category_fields.${index2}.name`,
                                    { required: true }
                                  )}
                                />
                              </Box>

                              <Box>
                                <Typography
                                  sx={{
                                    fontWeight: 'bold',
                                    fontSize: 12,
                                    py: 0.5,
                                  }}
                                >
                                  Question Type
                                </Typography>
                                <Autocomplete
                                  options={[
                                    'Text',
                                    'Dropdown',
                                    'Checkbox',
                                    'Radio',
                                    'Date',
                                  ]}
                                  getOptionLabel={(item) => item}
                                  defaultValue={item?.type}
                                  size='small'
                                  fullWidth
                                  sx={{ width: 250 }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size='small'
                                      fullWidth
                                      sx={{ bgcolor: 'white' }}
                                      {...register(
                                        `intake_category.${index}.intake_category_fields.${index2}.type`,
                                        {
                                          required: true,
                                        }
                                      )}
                                    />
                                  )}
                                  onChange={(event, value: any) => {
                                    setType((prev) => {
                                      let newP = [...prev]
                                      if (newP[index]) {
                                        newP[index].value[index2] = value
                                      } else {
                                        newP[index] = { value: [value] }
                                      }
                                      return newP
                                    })

                                    if (
                                      value === 'Dropdown' ||
                                      value === 'Radio' ||
                                      value === 'Checkbox'
                                    ) {
                                      const updatedFields = [...fields]
                                      updatedFields[
                                        index
                                      ].intake_category_fields[
                                        index2
                                      ].intake_additional_config = [
                                        {
                                          intake_category_field_value: '',
                                        },
                                      ]
                                      setValue('intake_category', updatedFields)
                                    } else {
                                      const updatedFields = [...fields]
                                      updatedFields[
                                        index
                                      ].intake_category_fields[
                                        index2
                                      ].intake_additional_config = []
                                      setValue('intake_category', updatedFields)
                                    }
                                    //   setIntake_additional_config((prev) => [
                                    //     ...prev,
                                    //     { intake_category_field_value: '' },
                                    //   ])
                                    //   // setIntake_additional_config((prev) => {
                                    //   //   if(prev?.length)
                                    //   // })
                                    // } else {
                                    //   setIntake_additional_config((prev) =>
                                    //     prev?.filter(
                                    //       (item, ind) => ind !== index2
                                    //     )
                                    //   )
                                    // }
                                  }}
                                />
                              </Box>
                            </Box>
                          </Box>
                          <Box sx={{ m: 2, flexGrow: 1 }}>
                            {field?.intake_category_fields?.[
                              index2
                            ]?.intake_additional_config?.map(
                              (item3, index3) => (
                                <Box>
                                  <Box>
                                    <Grid container>
                                      <Grid item xs={12} md={3.7}>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            gap: 2,
                                          }}
                                        >
                                          <Box>
                                            <Typography
                                              sx={{
                                                fontWeight: 'bold',
                                                fontSize: 12,
                                                py: 0.5,
                                              }}
                                            >
                                              {type?.[index]?.value?.[
                                                index2
                                              ]?.toLowerCase()}{' '}
                                              {index3 + 1}
                                            </Typography>
                                            <TextField
                                              size='small'
                                              // sx={{ width: 300 }}
                                              {...register(
                                                `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.intake_category_field_value`,
                                                {
                                                  required: true,
                                                }
                                              )}
                                            />
                                          </Box>
                                          {field?.intake_category_fields?.[
                                            index2
                                          ]?.intake_additional_config?.length >
                                            1 && (
                                            <IconButton
                                              sx={{
                                                bgcolor: '#BCBCBC',
                                                '&:hover': {
                                                  bgcolor: '#BCBCBC',
                                                },
                                              }}
                                              onClick={() => {
                                                // setType((prev) =>
                                                //   prev?.filter(
                                                //     (item, index5) => index5 !== index2
                                                //   )
                                                // )
                                                const updatedFields = [
                                                  ...fields,
                                                ]

                                                updatedFields[
                                                  index
                                                ].intake_category_fields[
                                                  index2
                                                ].intake_additional_config =
                                                  updatedFields[
                                                    index
                                                  ].intake_category_fields[
                                                    index2
                                                  ].intake_additional_config.filter(
                                                    (_, index5) =>
                                                      index5 !== index3
                                                  )

                                                setValue(
                                                  'intake_category',
                                                  updatedFields
                                                )
                                              }}
                                            >
                                              <Remove sx={{ color: 'white' }} />
                                            </IconButton>
                                          )}
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} md={0.8}>
                                        <Divider
                                          orientation='vertical'
                                          sx={{ mx: 2, height: 80, mt: 1 }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={7.5}>
                                        <Box>
                                          {item3?.option ? (
                                            <Box>
                                              <Typography
                                                sx={{
                                                  fontWeight: 'bold',
                                                  fontSize: 12,
                                                }}
                                              >
                                                Option
                                              </Typography>
                                              <Box
                                                sx={{ display: 'flex', gap: 2 }}
                                              >
                                                <Box>
                                                  <textarea
                                                    style={{
                                                      width: '100%',
                                                      height: 80,
                                                      borderRadius: 8,
                                                      resize: 'none',
                                                    }}
                                                    {...register(
                                                      `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.option`,
                                                      {
                                                        required: true,
                                                      }
                                                    )}
                                                  />
                                                  <Box
                                                    sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent:
                                                        'space-between',
                                                    }}
                                                  >
                                                    <FormControlLabel
                                                      name='addtocaseplan'
                                                      label={
                                                        'Add to Care Plan Notes'
                                                      }
                                                      slotProps={{
                                                        typography: {
                                                          fontSize: 11,
                                                          color: '#8D8D8D',
                                                          fontWeight: 'bold',
                                                        },
                                                      }}
                                                      control={
                                                        <Checkbox
                                                          defaultChecked={
                                                            item3?.option
                                                              ?.is_case_note
                                                          }
                                                          {...register(
                                                            `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.is_case_note`
                                                          )}
                                                        />
                                                      }
                                                    />
                                                    <FormControlLabel
                                                      name='addtotodolist'
                                                      // label={'Add to To do List Due is '}
                                                      label={
                                                        <Typography
                                                          sx={{
                                                            fontSize: 11,
                                                            color: '#A0A0A0',
                                                            fontWeight: 'bold',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 12,
                                                              color: '#596B86',
                                                            }}
                                                          >
                                                            Add to To do List
                                                          </span>
                                                          <br />
                                                          Due in Bussines Days
                                                        </Typography>
                                                      }
                                                      slotProps={{
                                                        typography: {
                                                          fontSize: 12,
                                                          color: '#485B78',
                                                          fontWeight: 'bold',
                                                        },
                                                      }}
                                                      control={
                                                        <Checkbox
                                                          {...register(
                                                            `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.is_task`,
                                                            {
                                                              required: true,
                                                            }
                                                          )}
                                                          checked={true}
                                                        />
                                                      }
                                                    />
                                                    <TextField
                                                      type='number'
                                                      size='small'
                                                      sx={{ width: 80 }}
                                                      {...register(
                                                        `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.due_date`
                                                      )}
                                                    />
                                                  </Box>
                                                </Box>

                                                <IconButton
                                                  sx={{
                                                    bgcolor: '#BCBCBC',
                                                    '&:hover': {
                                                      bgcolor: '#BCBCBC',
                                                    },
                                                    width: 30,
                                                    height: 30,
                                                  }}
                                                  onClick={() => {
                                                    const updatedFields = [
                                                      ...fields,
                                                    ]
                                                    delete updatedFields[index]
                                                      .intake_category_fields[
                                                      index2
                                                    ].intake_additional_config[
                                                      index3
                                                    ].option

                                                    setValue(
                                                      'intake_category',
                                                      updatedFields
                                                    )
                                                  }}
                                                >
                                                  <Remove
                                                    sx={{ color: 'white' }}
                                                  />
                                                </IconButton>
                                              </Box>
                                            </Box>
                                          ) : (
                                            <Typography
                                              sx={{
                                                fontWeight: 'bold',
                                                fontSize: 12,
                                              }}
                                            >
                                              You can add options to show after
                                              selection
                                            </Typography>
                                          )}

                                          {!item3?.option && (
                                            <IconButton
                                              sx={{
                                                bgcolor: 'primary.main',
                                                '&:hover': {
                                                  bgcolor: 'primary.main',
                                                },
                                                width: 30,
                                                height: 30,
                                              }}
                                              onClick={() => {
                                                const updatedFields = [
                                                  ...fields,
                                                ]
                                                updatedFields[
                                                  index
                                                ].intake_category_fields[
                                                  index2
                                                ].intake_additional_config[
                                                  index3
                                                ].option = {
                                                  due_date: '',
                                                  is_case_note: false,
                                                  is_task: false,
                                                  option: '',
                                                }

                                                setValue(
                                                  'intake_category',
                                                  updatedFields
                                                )
                                              }}
                                            >
                                              <Add sx={{ color: 'white' }} />
                                            </IconButton>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Divider sx={{ my: 2 }} />
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 2,
                                    }}
                                  >
                                    {index3 ===
                                      field?.intake_category_fields?.[index2]
                                        ?.intake_additional_config?.length -
                                        1 && (
                                      <IconButton
                                        sx={{
                                          bgcolor: 'primary.main',
                                          '&:hover': {
                                            bgcolor: 'primary.main',
                                          },
                                        }}
                                        onClick={() => {
                                          const updatedFields = [...fields]
                                          updatedFields[
                                            index
                                          ].intake_category_fields[
                                            index2
                                          ].intake_additional_config = [
                                            ...updatedFields[index]
                                              .intake_category_fields[index2]
                                              .intake_additional_config,
                                            {
                                              intake_category_field_value: '',
                                            },
                                          ]
                                          setValue(
                                            'intake_category',
                                            updatedFields
                                          )
                                        }}
                                      >
                                        <Add sx={{ color: 'white' }} />
                                      </IconButton>
                                    )}
                                  </Box>
                                </Box>
                              )
                            )}
                            {/* {field?.intake_category_fields?.[
                              index2
                            ]?.values?.map((item3, index3) => (
                              <Box>
                                
                              </Box>
                            ))} */}
                            {/* {field?.intake_category_fields?.[
                              index2
                            ]?.values?.map((item3, index3) => (
                              <Box>
                                <Box>
                                  <Grid container>
                                    <Grid item xs={12} md={3.7}>
                                      <Box
                                        sx={{
                                          display: 'flex',
                                          alignItems: 'flex-end',
                                          gap: 2,
                                        }}
                                      >
                                        <Box>
                                          <Typography
                                            sx={{
                                              fontWeight: 'bold',
                                              fontSize: 12,
                                              py: 0.5,
                                            }}
                                          >
                                            {type?.[index]?.value?.[
                                              index2
                                            ]?.toLowerCase()}{' '}
                                            {index3 + 1}
                                          </Typography>
                                          <TextField
                                            size='small'
                                            defaultValue={item3}
                                            {...register(
                                              `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.intake_category_field_value`,
                                              {
                                                required: true,
                                              }
                                            )}
                                          />
                                        </Box>
                                        {field?.intake_category_fields?.[index2]
                                          ?.intake_additional_config?.length >
                                          1 && (
                                          <IconButton
                                            sx={{
                                              bgcolor: '#BCBCBC',
                                              '&:hover': {
                                                bgcolor: '#BCBCBC',
                                              },
                                            }}
                                            onClick={() => {
                                           
                                              const updatedFields = [...fields]

                                              updatedFields[
                                                index
                                              ].intake_category_fields[
                                                index2
                                              ].intake_additional_config =
                                                updatedFields[
                                                  index
                                                ].intake_category_fields[
                                                  index2
                                                ].intake_additional_config.filter(
                                                  (_, index5) =>
                                                    index5 !== index3
                                                )

                                              setValue(
                                                'intake_category',
                                                updatedFields
                                              )
                                            }}
                                          >
                                            <Remove sx={{ color: 'white' }} />
                                          </IconButton>
                                        )}
                                      </Box>
                                    </Grid>
                                    <Grid item xs={12} md={0.8}>
                                      <Divider
                                        orientation='vertical'
                                        sx={{ mx: 2, height: 80, mt: 1 }}
                                      />
                                    </Grid>
                                    <Grid item xs={12} md={7.5}>
                                      <Box>
                                        {field?.intake_category_fields?.[index2]
                                          ?.intake_additional_config[index3]
                                          ?.option ? (
                                          <Box>
                                            <Typography
                                              sx={{
                                                fontWeight: 'bold',
                                                fontSize: 12,
                                              }}
                                            >
                                              Option
                                            </Typography>
                                            <Box
                                              sx={{
                                                display: 'flex',
                                                gap: 2,
                                              }}
                                            >
                                              <Box>
                                                <textarea
                                                  style={{
                                                    width: '100%',
                                                    height: 80,
                                                    borderRadius: 8,
                                                    resize: 'none',
                                                  }}
                                                  {...register(
                                                    `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.option`,
                                                    {
                                                      required: true,
                                                    }
                                                  )}
                                                />
                                                <Box
                                                  sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent:
                                                      'space-between',
                                                  }}
                                                >
                                                  <FormControlLabel
                                                    name='addtocaseplan'
                                                    label={
                                                      'Add to Case Plan Notes'
                                                    }
                                                    slotProps={{
                                                      typography: {
                                                        fontSize: 11,
                                                        color: '#8D8D8D',
                                                        fontWeight: 'bold',
                                                      },
                                                    }}
                                                    control={
                                                      <Checkbox
                                                        {...register(
                                                          `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.is_case_note`,
                                                          {
                                                            required: true,
                                                          }
                                                        )}
                                                        defaultChecked={
                                                          field
                                                            ?.intake_category_fields?.[
                                                            index2
                                                          ]
                                                            ?.intake_additional_config[
                                                            index3
                                                          ]?.option
                                                            ?.is_case_note
                                                        }
                                                      />
                                                    }
                                                  />
                                                  <FormControlLabel
                                                    name='addtotodolist'
                                                    label={
                                                      <Typography
                                                        sx={{
                                                          fontSize: 11,
                                                          color: '#A0A0A0',
                                                          fontWeight: 'bold',
                                                        }}
                                                      >
                                                        <span
                                                          style={{
                                                            fontSize: 12,
                                                            color: '#596B86',
                                                          }}
                                                        >
                                                          Add to To do List
                                                        </span>
                                                        <br />
                                                        Due in Bussines Days
                                                      </Typography>
                                                    }
                                                    slotProps={{
                                                      typography: {
                                                        fontSize: 12,
                                                        color: '#485B78',
                                                        fontWeight: 'bold',
                                                      },
                                                    }}
                                                    control={
                                                      <Checkbox
                                                        {...register(
                                                          `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.is_task`,
                                                          {
                                                            required: true,
                                                          }
                                                        )}
                                                        checked={true}
                                                      />
                                                    }
                                                  />
                                                  <TextField
                                                    type='number'
                                                    size='small'
                                                    sx={{ width: 80 }}
                                                    {...register(
                                                      `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.due_date`,
                                                      {
                                                        required: true,
                                                      }
                                                    )}
                                                  />
                                                </Box>
                                              </Box>

                                              <IconButton
                                                sx={{
                                                  bgcolor: '#BCBCBC',
                                                  '&:hover': {
                                                    bgcolor: '#BCBCBC',
                                                  },
                                                  width: 30,
                                                  height: 30,
                                                }}
                                                onClick={() => {
                                                  const updatedFields = [
                                                    ...fields,
                                                  ]
                                                  delete updatedFields[index]
                                                    .intake_category_fields[
                                                    index2
                                                  ].intake_additional_config[
                                                    index3
                                                  ].option

                                                  setValue(
                                                    'intake_category',
                                                    updatedFields
                                                  )
                                                }}
                                              >
                                                <Remove
                                                  sx={{ color: 'white' }}
                                                />
                                              </IconButton>
                                            </Box>
                                          </Box>
                                        ) : (
                                          <Typography
                                            sx={{
                                              fontWeight: 'bold',
                                              fontSize: 12,
                                            }}
                                          >
                                            You can add options to show after
                                            selection
                                          </Typography>
                                        )}

                                        {!field?.intake_category_fields?.[
                                          index2
                                        ]?.intake_additional_config[index3]
                                          ?.option && (
                                          <IconButton
                                            sx={{
                                              bgcolor: 'primary.main',
                                              '&:hover': {
                                                bgcolor: 'primary.main',
                                              },
                                              width: 30,
                                              height: 30,
                                            }}
                                            onClick={() => {
                                              const updatedFields = [...fields]
                                              updatedFields[
                                                index
                                              ].intake_category_fields[
                                                index2
                                              ].intake_additional_config[
                                                index3
                                              ].option = {
                                                due_date: '',
                                                is_case_note: false,
                                                is_task: false,
                                                option: '',
                                              }

                                              setValue(
                                                'intake_category',
                                                updatedFields
                                              )
                                            }}
                                          >
                                            <Add sx={{ color: 'white' }} />
                                          </IconButton>
                                        )}
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </Box>
                                <Divider sx={{ my: 2 }} />
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 2,
                                  }}
                                >
                                  {index3 ===
                                    field?.intake_category_fields?.[index2]
                                      ?.intake_additional_config?.length -
                                      1 && (
                                    <IconButton
                                      sx={{
                                        bgcolor: 'primary.main',
                                        '&:hover': {
                                          bgcolor: 'primary.main',
                                        },
                                      }}
                                      onClick={() => {
                                        const updatedFields = [...fields]
                                        updatedFields[
                                          index
                                        ].intake_category_fields[
                                          index2
                                        ].intake_additional_config = [
                                          ...updatedFields[index]
                                            .intake_category_fields[index2]
                                            .intake_additional_config,
                                          {
                                            intake_category_field_value: '',
                                          },
                                        ]
                                        setValue(
                                          'intake_category',
                                          updatedFields
                                        )
                                      }}
                                    >
                                      <Add sx={{ color: 'white' }} />
                                    </IconButton>
                                  )}
                                </Box>
                              </Box>
                            ))} */}
                            {/* AAAAAAAa
                            {field?.intake_category_fields?.[
                              index2
                            ]?.intake_additional_config?.map(
                              (item3, index3) => (
                                <Box>
                                  <Box>
                                    <Grid container>
                                      <Grid item xs={12} md={3.7}>
                                        <Box
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'flex-end',
                                            gap: 2,
                                          }}
                                        >
                                          <Box>
                                            <Typography
                                              sx={{
                                                fontWeight: 'bold',
                                                fontSize: 12,
                                                py: 0.5,
                                              }}
                                            >
                                              {type?.[index]?.value?.[
                                                index2
                                              ]?.toLowerCase()}{' '}
                                              {index3 + 1}
                                            </Typography>
                                            <TextField
                                              size='small'
                                              // sx={{ width: 300 }}
                                              // defaultValue={
                                              //   intakeData?.intakeCategory
                                              //     ?.intakeCategoryFields?.[
                                              //     index2
                                              //   ]?.intakeAdditionalConfig?.[
                                              //     index3
                                              //   ]?.intakeCategoryFieldValue
                                              // }
                                              {...register(
                                                `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.intake_category_field_value`,
                                                {
                                                  required: true,
                                                }
                                              )}
                                            />
                                          </Box>
                                          {field?.intake_category_fields?.[
                                            index2
                                          ]?.intake_additional_config?.length >
                                            1 && (
                                            <IconButton
                                              sx={{
                                                bgcolor: '#BCBCBC',
                                                '&:hover': {
                                                  bgcolor: '#BCBCBC',
                                                },
                                              }}
                                              onClick={() => {
                                                // setType((prev) =>
                                                //   prev?.filter(
                                                //     (item, index5) => index5 !== index2
                                                //   )
                                                // )
                                                const updatedFields = [
                                                  ...fields,
                                                ]

                                                updatedFields[
                                                  index
                                                ].intake_category_fields[
                                                  index2
                                                ].intake_additional_config =
                                                  updatedFields[
                                                    index
                                                  ].intake_category_fields[
                                                    index2
                                                  ].intake_additional_config.filter(
                                                    (_, index5) =>
                                                      index5 !== index3
                                                  )

                                                setValue(
                                                  'intake_category',
                                                  updatedFields
                                                )
                                              }}
                                            >
                                              <Remove sx={{ color: 'white' }} />
                                            </IconButton>
                                          )}
                                        </Box>
                                      </Grid>
                                      <Grid item xs={12} md={0.8}>
                                        <Divider
                                          orientation='vertical'
                                          sx={{ mx: 2, height: 80, mt: 1 }}
                                        />
                                      </Grid>
                                      <Grid item xs={12} md={7.5}>
                                        <Box>
                                          {item3?.option ? (
                                            <Box>
                                              <Typography
                                                sx={{
                                                  fontWeight: 'bold',
                                                  fontSize: 12,
                                                }}
                                              >
                                                Option
                                              </Typography>
                                              <Box
                                                sx={{
                                                  display: 'flex',
                                                  gap: 2,
                                                }}
                                              >
                                                <Box>
                                                  <textarea
                                                    style={{
                                                      width: '100%',
                                                      height: 80,
                                                      borderRadius: 8,
                                                      resize: 'none',
                                                    }}
                                                    {...register(
                                                      `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.option`,
                                                      {
                                                        required: true,
                                                      }
                                                    )}
                                                  />
                                                  <Box
                                                    sx={{
                                                      display: 'flex',
                                                      alignItems: 'center',
                                                      justifyContent:
                                                        'space-between',
                                                    }}
                                                  >
                                                    <FormControlLabel
                                                      name='addtocaseplan'
                                                      label={
                                                        'Add to Case Plan Notes'
                                                      }
                                                      slotProps={{
                                                        typography: {
                                                          fontSize: 11,
                                                          color: '#8D8D8D',
                                                          fontWeight: 'bold',
                                                        },
                                                      }}
                                                      control={
                                                        <Checkbox
                                                          {...register(
                                                            `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.is_case_note`,
                                                            {
                                                              required: true,
                                                            }
                                                          )}
                                                          defaultChecked={
                                                            item3?.option
                                                              ?.is_case_note
                                                          }
                                                        />
                                                      }
                                                    />
                                                    <FormControlLabel
                                                      name='addtotodolist'
                                                      // label={'Add to To do List Due is '}
                                                      label={
                                                        <Typography
                                                          sx={{
                                                            fontSize: 11,
                                                            color: '#A0A0A0',
                                                            fontWeight: 'bold',
                                                          }}
                                                        >
                                                          <span
                                                            style={{
                                                              fontSize: 12,
                                                              color: '#596B86',
                                                            }}
                                                          >
                                                            Add to To do List
                                                          </span>
                                                          <br />
                                                          Due in Bussines Days
                                                        </Typography>
                                                      }
                                                      slotProps={{
                                                        typography: {
                                                          fontSize: 12,
                                                          color: '#485B78',
                                                          fontWeight: 'bold',
                                                        },
                                                      }}
                                                      control={
                                                        <Checkbox
                                                          {...register(
                                                            `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.is_task`,
                                                            {
                                                              required: true,
                                                            }
                                                          )}
                                                          checked={true}
                                                        />
                                                      }
                                                    />
                                                    <TextField
                                                      type='number'
                                                      size='small'
                                                      sx={{ width: 80 }}
                                                      {...register(
                                                        `intake_category.${index}.intake_category_fields.${index2}.intake_additional_config.${index3}.option.due_date`,
                                                        {
                                                          required: true,
                                                        }
                                                      )}
                                                    />
                                                  </Box>
                                                </Box>

                                                <IconButton
                                                  sx={{
                                                    bgcolor: '#BCBCBC',
                                                    '&:hover': {
                                                      bgcolor: '#BCBCBC',
                                                    },
                                                    width: 30,
                                                    height: 30,
                                                  }}
                                                  onClick={() => {
                                                    const updatedFields = [
                                                      ...fields,
                                                    ]
                                                    delete updatedFields[index]
                                                      .intake_category_fields[
                                                      index2
                                                    ].intake_additional_config[
                                                      index3
                                                    ].option

                                                    setValue(
                                                      'intake_category',
                                                      updatedFields
                                                    )
                                                  }}
                                                >
                                                  <Remove
                                                    sx={{ color: 'white' }}
                                                  />
                                                </IconButton>
                                              </Box>
                                            </Box>
                                          ) : (
                                            <Typography
                                              sx={{
                                                fontWeight: 'bold',
                                                fontSize: 12,
                                              }}
                                            >
                                              You can add options to show after
                                              selection
                                            </Typography>
                                          )}

                                          {!item3?.option && (
                                            <IconButton
                                              sx={{
                                                bgcolor: 'primary.main',
                                                '&:hover': {
                                                  bgcolor: 'primary.main',
                                                },
                                                width: 30,
                                                height: 30,
                                              }}
                                              onClick={() => {
                                                const updatedFields = [
                                                  ...fields,
                                                ]
                                                updatedFields[
                                                  index
                                                ].intake_category_fields[
                                                  index2
                                                ].intake_additional_config[
                                                  index3
                                                ].option = {
                                                  due_date: '',
                                                  is_case_note: false,
                                                  is_task: false,
                                                  option: '',
                                                }

                                                setValue(
                                                  'intake_category',
                                                  updatedFields
                                                )
                                              }}
                                            >
                                              <Add sx={{ color: 'white' }} />
                                            </IconButton>
                                          )}
                                        </Box>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                  <Divider sx={{ my: 2 }} />
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 2,
                                    }}
                                  >
                                    {index3 ===
                                      field?.intake_category_fields?.[index2]
                                        ?.intake_additional_config?.length -
                                        1 && (
                                      <IconButton
                                        sx={{
                                          bgcolor: 'primary.main',
                                          '&:hover': {
                                            bgcolor: 'primary.main',
                                          },
                                        }}
                                        onClick={() => {
                                          const updatedFields = [...fields]
                                          updatedFields[
                                            index
                                          ].intake_category_fields[
                                            index2
                                          ].intake_additional_config = [
                                            ...updatedFields[index]
                                              .intake_category_fields[index2]
                                              .intake_additional_config,
                                            {
                                              intake_category_field_value: '',
                                            },
                                          ]
                                          setValue(
                                            'intake_category',
                                            updatedFields
                                          )
                                        }}
                                      >
                                        <Add sx={{ color: 'white' }} />
                                      </IconButton>
                                    )}
                                  </Box>
                                </Box>
                              )
                            )} */}
                          </Box>
                        </Box>
                        <Divider />
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            gap: 2,
                          }}
                        >
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box>
                              <FormControlLabel
                                name='required'
                                label={'Required'}
                                control={
                                  <Checkbox
                                    defaultChecked={item?.required}
                                    {...register(
                                      `intake_category.${index}.intake_category_fields.${index2}.required`
                                    )}
                                  />
                                }
                              />
                              <FormControlLabel
                                name='vital'
                                label={'Vital'}
                                control={
                                  <Checkbox
                                    defaultChecked={item?.vital}
                                    {...register(
                                      `intake_category.${index}.intake_category_fields.${index2}.vital`
                                    )}
                                  />
                                }
                              />
                              <FormControlLabel
                                name='editable'
                                label={'Editable'}
                                control={
                                  <Checkbox
                                    defaultChecked={item?.editable}
                                    {...register(
                                      `intake_category.${index}.intake_category_fields.${index2}.editable`
                                    )}
                                  />
                                }
                              />
                              <FormControlLabel
                                name='auditable'
                                label={'Save History'}
                                control={
                                  <Checkbox
                                    defaultChecked={item?.auditable}
                                    {...register(
                                      `intake_category.${index}.intake_category_fields.${index2}.auditable`
                                    )}
                                  />
                                }
                              />
                              <FormControlLabel
                                name='include_in_report'
                                label={'Include in report'}
                                control={
                                  <Checkbox
                                    defaultChecked={item?.include_in_report}
                                    {...register(
                                      `intake_category.${index}.intake_category_fields.${index2}.include_in_report`
                                    )}
                                  />
                                }
                              />
                            </Box>
                          </Box>
                          {field?.intake_category_fields?.length > 1 && (
                            <Box
                              sx={{
                                bgcolor: '#BBC4DD',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 1,
                                width: 200,
                                py: 1,
                                my: 2,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                // setType((prev) =>
                                //   prev?.filter(
                                //     (item, index5) => index5 !== index2
                                //   )
                                // )
                                const updatedFields = [...fields]

                                console.log({ index2 })

                                updatedFields[index].intake_category_fields =
                                  updatedFields[
                                    index
                                  ].intake_category_fields.filter(
                                    (_, index3) => index3 !== index2
                                  )

                                console.log({
                                  1: updatedFields[index]
                                    .intake_category_fields,
                                })

                                setValue('intake_category', updatedFields)
                              }}
                            >
                              <Remove sx={{ color: 'white' }} />
                              <Typography sx={{ color: 'white' }}>
                                Remove Field
                              </Typography>
                            </Box>
                          )}
                          {index2 ===
                            field?.intake_category_fields?.length - 1 && (
                            <Box
                              sx={{
                                bgcolor: '#F4FAFF',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: 1,
                                width: 200,
                                py: 1,
                                my: 2,
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                const updatedFields = [...fields]
                                updatedFields[index].intake_category_fields = [
                                  ...updatedFields[index]
                                    .intake_category_fields,
                                  {
                                    field_label: '',
                                    name: '',
                                    type: '',
                                    values: [],
                                    required: false,
                                    editable: false,
                                    auditable: false,
                                    vital: false,
                                    include_in_report: false,
                                    intake_additional_config: [],
                                  },
                                ]
                                setValue('intake_category', updatedFields)
                              }}
                            >
                              <Add sx={{ color: 'primary.main' }} />
                              <Typography sx={{ color: 'primary.main' }}>
                                Add New Field
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Box>
                    ))}
                    <Divider />
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',

                        gap: 2,
                      }}
                    >
                      {fields.length > 1 && (
                        <Box
                          sx={{
                            bgcolor: '#BBC4DD',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                            width: 200,
                            py: 1,
                            my: 2,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            remove(index)
                          }}
                        >
                          <Remove sx={{ color: 'white' }} />
                          <Typography sx={{ color: 'white' }}>
                            Remove
                          </Typography>
                        </Box>
                      )}
                      {index === fields.length - 1 && (
                        <Box
                          sx={{
                            bgcolor: '#F4FAFF',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 1,
                            width: 200,
                            py: 1,
                            my: 2,
                            cursor: 'pointer',
                          }}
                          onClick={() =>
                            append({
                              title: '',
                              intake_id: intakeData?.intake?.id,
                              intake_category_fields: [
                                {
                                  field_label: '',
                                  name: '',
                                  type: '',
                                  required: false,
                                  values: [],
                                  editable: false,
                                  auditable: false,
                                  vital: false,
                                  include_in_report: false,
                                  intake_additional_config: [],
                                },
                              ],
                            })
                          }
                        >
                          <Add sx={{ color: 'primary.main' }} />
                          <Typography sx={{ color: 'primary.main' }}>
                            Add New Catgory
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  bottom: 0,
                  width: { xs: '100vw', md: 'calc(100vw - 280px)' },
                  height: 80,
                  py: 3,
                  px: 5,
                }}
              >
                <Button
                  variant='contained'
                  // onClick={handleSubmit}
                  type='submit'
                  sx={{ px: 4, py: 2.5 }}
                >
                  {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress sx={{ color: 'white' }} size={20} />
                      <Typography>submitting...</Typography>
                    </Box>
                  ) : (
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Submit Form
                    </Typography>
                  )}
                </Button>
              </Box>
            </form>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
