import {
  Article,
  CameraAlt,
  Close,
  CloudUpload,
  Done,
  InsertPhoto,
  PictureAsPdf,
  ReportGmailerrorred,
  Upload,
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import { formatFileSize } from '../../utils/formatFileSize'
import { FileRejection, useDropzone } from 'react-dropzone'

export default function FileUpload({
  type,
  multiple,
  name,
  size,
  maxFiles,
  fileSize,
  setFileState,
  defaultValue,
  isLoading,
}: {
  type: 'PROFILE_IMAGE' | 'IMAGE' | 'FILE'
  multiple?: boolean
  name: string
  size?: number
  maxFiles?: number
  fileSize?: number
  setFileState?: Function
  defaultValue?: string
  isLoading?: boolean
}) {
  const [filesToUpload, setFilesToUpload] = useState<File[]>([])
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>([])
  const [files, setFiles] = useState([]) as any

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (multiple) {
        setFilesToUpload([...filesToUpload, ...acceptedFiles])
      } else {
        setFilesToUpload([...acceptedFiles])
      }
      if (type === 'IMAGE' || type === 'PROFILE_IMAGE') {
        setFiles(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        )
      }
    },
    [filesToUpload]
  )
  const onDropRejected = useCallback((fileRejections: FileRejection[]) => {
    setRejectedFiles([...fileRejections])
  }, [])

  const removeAcceptedFile = (file: File) => () => {
    const newFiles = [...filesToUpload]
    newFiles.splice(newFiles.indexOf(file), 1)
    setFilesToUpload(newFiles)
    if (type === 'IMAGE' || type === 'PROFILE_IMAGE') {
      setFiles(
        newFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    }
  }

  const removeRejectedFile = (rejectedFile: FileRejection) => () => {
    const newFiles = [...rejectedFiles]
    newFiles.splice(newFiles.indexOf(rejectedFile), 1)
    setRejectedFiles(newFiles)
  }
  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      onDrop,
      onDropRejected,
      ...(type === 'IMAGE' || type === 'PROFILE_IMAGE'
        ? {
            accept: {
              'image/*': [],
            },
          }
        : {
            accept: {
              'application/pdf': [],
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                [],
              'image/*': [],
            },
          }),

      maxFiles: maxFiles || 1,
      multiple: multiple || false,
      maxSize: 1000000,
    })

  let formData = new FormData()

  filesToUpload?.length &&
    filesToUpload?.map((file: File) => {
      formData.append(file.name, file)
    })

  useEffect(() => {
    return () => files.forEach((file: any) => URL.revokeObjectURL(file.preview))
  }, [])

  useEffect(() => {
    if (filesToUpload?.length) {
      setFileState && setFileState(filesToUpload[0])
    }
  }, [filesToUpload])

  function FileList({
    file,
    variant,
    handleClose,
  }: {
    file: File
    variant: string
    handleClose: any
  }) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          px: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <PictureAsPdf />
          <Typography>{file?.name}</Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
          }}
        >
          <Typography>{formatFileSize(file?.size)}</Typography>
          {variant === 'success' ? (
            <Done color='primary' />
          ) : (
            <ReportGmailerrorred color='error' />
          )}
          <Tooltip arrow placement='bottom' title='clear'>
            <IconButton>
              <Close onClick={handleClose} />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
    )
  }

  function LinearProgressWithLabel(props: any & { value: number }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', px: 2 }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant='determinate' {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant='body2' color='text.secondary'>{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ...(type === 'PROFILE_IMAGE' && { alignItems: 'center' }),
      }}
    >
      {type !== 'PROFILE_IMAGE' && (
        <Box>
          <Box
            {...getRootProps()}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderWidth: 2,
              borderRadius: 2,
              borderColor: '#A0C8EB',
              borderStyle: 'dashed',
              cursor: 'pointer',
              width: name === 'pdfform' ? 600 : '100%',
              height: size ? size : 200,
            }}
          >
            <input {...getInputProps()} />
            {defaultValue ? (
              <Box
                component='img'
                src={defaultValue}
                sx={{ width: '100%', height: 200 }}
              />
            ) : (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  my: 3,
                  mx: 2,
                }}
              >
                {filesToUpload.length > 0 && name === 'pdfform' ? (
                  filesToUpload?.map((item: File) => (
                    <Box
                      sx={{
                        width: 600,
                        px: 5,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                      >
                        <PictureAsPdf
                          sx={{ width: 60, height: 60, color: 'primary.main' }}
                        />
                        <Box>
                          <Typography
                            sx={{ color: '#002163', fontWeight: 'bold' }}
                          >
                            {item?.name}
                          </Typography>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              color: '#919192',
                            }}
                          >
                            <Typography variant='body2'>
                              {formatFileSize(item?.size)}
                            </Typography>
                            <Box sx={{ border: '1px solid #919192' }}></Box>
                            <Typography variant='body2'>
                              a couple of seconds left
                            </Typography>
                          </Box>
                          {isLoading && (
                            <LinearProgress
                              color='primary'
                              sx={{ width: 450 }}
                            />
                          )}
                        </Box>
                      </Box>
                      {/* <Typography sx={{ color: '#919192' }}>1%</Typography> */}
                    </Box>
                  ))
                ) : filesToUpload.length > 0 && name !== 'pdfform' ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Article sx={{ color: 'primary.main' }} />
                    <Typography
                      sx={{ fontWeight: 'bold', color: 'primary.main' }}
                    >
                      File Uploaded Successfully
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    {name === 'pdfform' ? (
                      <PictureAsPdf
                        sx={{
                          width: 60,
                          height: 60,
                          color: 'primary.main',
                        }}
                      />
                    ) : (
                      <Upload
                        sx={{
                          width: 30,
                          height: 30,
                          color: 'black',
                          my: 1,
                        }}
                      />
                    )}
                  </Box>
                )}

                {!isDragActive && filesToUpload.length === 0 && (
                  <Typography
                    variant='subtitle1'
                    color='#5C6C8F'
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 15,
                      textAlign: 'center',
                    }}
                  >
                    Drag & Drop or{' '}
                    <span style={{ color: '#2D93F6' }}>Choose File</span> to
                    upload
                  </Typography>
                )}
                {isDragActive && !isDragReject && (
                  <Typography
                    variant='subtitle1'
                    color='#5C6C8F'
                    sx={{ fontWeight: 'bold', textAlign: 'center' }}
                  >
                    Drop the files here ...
                  </Typography>
                )}
                {isDragReject && (
                  <Typography
                    variant='subtitle1'
                    color='error'
                    sx={{ fontWeight: 'bold', textAlign: 'center' }}
                  >
                    File type not accepted.
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
      )}

      {type === 'PROFILE_IMAGE' && (
        <Box>
          <Avatar
            {...getRootProps()}
            sx={{
              width: 120,
              height: 120,
              display: 'flex',
              flexDirection: 'column',
              border: '1px solid blue',
              mb: 1,
              cursor: 'pointer',
            }}
          >
            <input {...getInputProps()} />
            {files?.length ? (
              <Box
                component='img'
                src={files[0].preview}
                sx={{ width: size || 120, height: size || 120 }}
                onLoad={() => {
                  URL.revokeObjectURL(files[0].preview)
                }}
              />
            ) : defaultValue ? (
              <Box
                component='img'
                src={defaultValue}
                sx={{ width: '100%', height: 200 }}
              />
            ) : (
              <>
                <Box
                  sx={{
                    bgcolor: 'white',
                    width: '100%',
                    height: '65%',
                  }}
                />
                <Box
                  sx={{
                    bgcolor: 'primary.main',
                    width: '100%',
                    height: '35%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <InsertPhoto sx={{ color: 'white' }} />
                </Box>
              </>
            )}
          </Avatar>
        </Box>
      )}

      {rejectedFiles.length > 0 &&
        rejectedFiles?.map((item: FileRejection) => (
          <Box>
            <FileList
              file={item.file}
              variant='error'
              handleClose={removeRejectedFile(item)}
            />
            <Typography variant='overline' color='error' sx={{ px: 2 }}>
              {item?.errors[0]?.code === 'file-too-large'
                ? `File is larger than ${formatFileSize(fileSize || 1000000)}`
                : item.errors[0].message}
            </Typography>
            <Divider />
          </Box>
        ))}
      {/* <Box>
        {fetcherData?.data && (
          <input
            name={name}
            value={
              multiple ? JSON.stringify(pathArray) : fetcherData?.data[0]?.path
            }
            type='hidden'
            defaultValue={defaultValue}
          />
        )}
      </Box> */}
    </Box>
  )
}
