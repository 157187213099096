import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  LinearProgress,
  Popover,
  Typography,
} from '@mui/material'
import Appointment from '../components/Dashboard/Appointment'
import { Add, CalendarMonth, Flag, Search } from '@mui/icons-material'
import React from 'react'
import GoalsAndTasks from '../components/Dashboard/GoalsAndTasks'
import Client from '../components/Dashboard/Clients'
import Directory from '../components/Dashboard/Directory'
import Messages from '../components/Dashboard/Messages'
import Alert from '../components/Dashboard/Alert'

export default function Dashboard() {
  return (
    <Box sx={{ position: 'relative' }}>
      <Grid container sx={{ minHeight: 'calc(100vh - 65px)' }}>
        <Grid item xs={12} sm={12} md={8.5}>
          <Grid container sx={{ height: '46vh', p: 3 }}>
            <Appointment />
            <GoalsAndTasks />
          </Grid>
          <Divider />
          <Box sx={{ height: '46vh', p: 3 }}>
            <Grid
              container
              sx={{
                border: '2px solid #DDEFFF',
                height: '100%',
                borderRadius: 5,
              }}
            >
              <Client />
              <Messages />
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={3.5} sx={{ p: 3, bgcolor: '#F4FAFF' }}>
          <Directory />
          <Box sx={{ height: '4%' }}></Box>
          <Alert />
        </Grid>
      </Grid>
    </Box>
  )
}

// import React, { useState, useRef, useEffect } from 'react'
// import { useDrop } from 'react-dnd'
// import { PDFDocument, rgb, RotationTypes } from 'pdf-lib'
// import { Worker, Viewer } from '@react-pdf-viewer/core'
// import '@react-pdf-viewer/core/lib/styles/index.css'
// import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'
// import { DndProvider } from 'react-dnd'
// import { HTML5Backend } from 'react-dnd-html5-backend'
// import { pdfjs } from 'react-pdf'

// const DragAndDropContext = ({ children }: any) => {
//   return <DndProvider backend={HTML5Backend}>{children}</DndProvider>
// }

// const PdfEditor = () => {
//   const [file, setFile] = useState(null) as any
//   const [image, setImage] = useState(null) as any
//   const [pdfBytes, setPdfBytes] = useState(null) as any
//   const [pdfUrl, setPdfUrl] = useState(null) as any
//   const viewerRef = useRef(null) as any
//   const defaultLayoutPluginInstance = defaultLayoutPlugin()

//   const handleFileChange = (e: any) => {
//     setFile(e.target.files[0])
//   }

//   const handleImageChange = (e: any) => {
//     setImage(e.target.files[0])
//   }

//   const modifyPdf = async (
//     pdfBytes: any,
//     imgBytes: any,
//     imgX: any,
//     imgY: any
//   ) => {
//     const pdfDoc = await PDFDocument.load(pdfBytes)
//     const pngImage = await pdfDoc.embedPng(imgBytes)
//     const pages = pdfDoc.getPages()
//     const firstPage = pages[0]

//     // Adjust the coordinates to match the PDF's coordinate system
//     const { width, height } = firstPage.getSize()
//     const adjustedX = imgX
//     const adjustedY = height - imgY - 100

//     // firstPage.drawImage(pngImage, {
//     //   x: adjustedX,
//     //   y: adjustedY,
//     //   width: 100,
//     //   height: 100,
//     // })

//     firstPage.drawText('Hello, world!', {
//       x: 50,
//       y: 550,
//       size: 30,
//       color: rgb(0, 0.53, 0.71),
//       rotate: { angle: 45, type: RotationTypes.Degrees },
//     })
//     const modifiedPdfBytes = await pdfDoc.save()
//     return modifiedPdfBytes
//   }

//   useEffect(() => {
//     console.log({ pdfBytes })
//     if (pdfBytes) {
//       PDFDocument.load(pdfBytes).then((res) => {
//         const pages = res.getPages()
//         console.log({ pages })
//         const firstPage = pages[0]

//         firstPage.drawText('Hello, world!', {
//           x: 50,
//           y: 550,
//           size: 30,
//           color: rgb(0, 0.53, 0.71),
//           // rotate: { angle: 45, type: RotationTypes.Degrees },
//         })
//         res.save().then((res2) => {
//           const blob = new Blob([res2], { type: 'application/pdf' })
//           const url = URL.createObjectURL(blob)
//           setPdfUrl(url as any)
//         })
//       })
//     }
//   }, [pdfBytes])

//   const handleFileUpload = async () => {
//     if (file) {
//       const reader = new FileReader()
//       reader.onload = async (e) => {
//         const pdfBytes = new Uint8Array(e?.target?.result as any)

//         setPdfBytes(pdfBytes as any)
//         setPdfUrl(URL.createObjectURL(file as any) as any)
//       }
//       reader.readAsArrayBuffer(file)
//     }
//   }

//   const [{ isOver }, drop] = useDrop(() => ({
//     accept: 'image',
//     drop: (item, monitor) => {
//       const offset = monitor.getClientOffset() as any
//       const dropX = offset.x - viewerRef.current.getBoundingClientRect().left
//       const dropY = offset.y - viewerRef.current.getBoundingClientRect().top
//       if (pdfBytes && image) {
//         const imgReader = new FileReader()
//         imgReader.onload = async (e) => {
//           const imgBytes = new Uint8Array(e?.target?.result as any)
//           const modifiedPdfBytes = await modifyPdf(
//             pdfBytes,
//             imgBytes,
//             dropX,
//             dropY
//           )

//           const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' })
//           const url = URL.createObjectURL(blob)
//           setPdfUrl(url as any)

//           const link = document.createElement('a')
//           link.href = url
//           link.download = 'modified_with_image.pdf'
//           link.click()

//           URL.revokeObjectURL(url)
//         }
//         imgReader.readAsArrayBuffer(image)
//       }
//     },
//     collect: (monitor) => ({
//       isOver: monitor.isOver(),
//     }),
//   }))

//   useEffect(() => {
//     if (pdfBytes) {
//       setPdfUrl(
//         URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }))
//       )
//     }
//   }, [pdfBytes])

//   return (
//     <div>
//       <input type='file' accept='application/pdf' onChange={handleFileChange} />
//       <button onClick={handleFileUpload}>Upload PDF</button>
//       <input type='file' accept='image/png' onChange={handleImageChange} />
//       <div
//         ref={drop}
//         style={{
//           border: '2px dashed gray',
//           padding: '20px',
//           marginTop: '20px',
//           backgroundColor: isOver ? 'lightgray' : 'white',
//         }}
//       >
//         Drop the image here
//       </div>
//       {pdfUrl && (
//         <div
//           ref={viewerRef}
//           style={{ border: '1px solid black', marginTop: '20px' }}
//         >
//           <Worker
//             workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
//           >
//             <Viewer fileUrl={pdfUrl} />
//           </Worker>
//         </div>
//       )}
//     </div>
//   )
// }

// const App = () => {
//   return (
//     <DragAndDropContext>
//       <PdfEditor />
//     </DragAndDropContext>
//   )
// }

// export default App
