import { Send } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Card,
  Divider,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { GridExpandMoreIcon } from "@mui/x-data-grid";
import { useState } from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Support() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Box
      sx={{
        bgcolor: "#F4FAFF",
        m: 3,
        height: "calc(100vh - 100px)",
        borderRadius: 5,
      }}
    >
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
          pt: 2,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Support"
            {...a11yProps(0)}
            sx={{ fontWeight: "bold", fontSize: 12, mx: 2 }}
          />
          <Tab
            label={`FAQ's`}
            {...a11yProps(1)}
            sx={{ fontWeight: "bold", fontSize: 12, mx: 2 }}
          />{" "}
          <Tab
            label={`Resources`}
            {...a11yProps(2)}
            sx={{ fontWeight: "bold", fontSize: 12, mx: 2 }}
          />{" "}
          <Tab
            label={`Documents`}
            {...a11yProps(3)}
            sx={{ fontWeight: "bold", fontSize: 12, mx: 2 }}
          />
        </Tabs>
      </Box>
      <Box sx={{ height: "100%", px: 3 }}>
        <CustomTabPanel value={value} index={0}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "calc(100vh - 160px)",
            }}
          >
            <Card sx={{ py: 1, borderRadius: 3, position: "relative" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                  py: 1,
                  px: 3,
                }}
              >
                <Typography sx={{ fontWeight: "bold" }}>Email Issue</Typography>
                <Box sx={{ border: "3px solid gray", borderRadius: "100%" }} />
                <Typography>Last Modified - Apr 2, 2023</Typography>
              </Box>
              <Divider />
              <Box sx={{ p: 3 }}>
                <Box sx={{ px: 2, py: 2, display: "flex", gap: 3, pr: 10 }}>
                  <Avatar sx={{ width: 40, height: 40, my: 2 }} />
                  <Box
                    sx={{
                      bgcolor: "#F4FAFF",
                      py: 2,
                      px: 4,
                      borderRadius: 5,
                      my: 2,
                      width: 500,
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Can you generate me a report on housing program associated
                      with Nib Insurance?
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    px: 2,
                    py: 2,
                    display: "flex",
                    gap: 3,
                    justifyContent: "flex-end",
                    pl: 10,
                  }}
                >
                  <Box
                    sx={{
                      p: 3,
                      border: "1px solid gray",
                      borderRadius: 5,
                      zIndex: 100,
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Yeah Sure. Here is the report on housing program
                    </Typography>
                  </Box>
                  <Avatar sx={{ width: 40, height: 40, my: 2 }} />
                </Box>
                <Box sx={{ px: 2, py: 2, display: "flex", gap: 3, pr: 10 }}>
                  <Avatar sx={{ width: 40, height: 40, my: 2 }} />
                  <Box
                    sx={{
                      bgcolor: "#F4FAFF",
                      py: 2,
                      px: 4,
                      borderRadius: 5,
                      my: 2,
                      width: 500,
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>
                      Can you generate me a report on housing program associated
                      with Nib Insurance?
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  p: 5,
                }}
              >
                <TextField
                  variant="outlined"
                  placeholder="Wrtie Here"
                  fullWidth
                  InputProps={{
                    endAdornment: <Send sx={{ color: "primary.main" }} />,
                    sx: {
                      borderRadius: 30,
                      bgcolor: "#F7F7F8",
                      border: "none",
                    },
                  }}
                />
              </Box>
            </Card>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "calc(100vh - 160px)",
            }}
          >
            <Card sx={{ borderRadius: 3, width: 800 }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<GridExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography sx={{ fontWeight: "bold" }}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Suspendisse malesuada lacus ex, sit amet blandit leo lobortis
                  eget.
                </AccordionDetails>
              </Accordion>
            </Card>
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={2}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "calc(100vh - 160px)",
            }}
          >
            <Card
              sx={{
                borderRadius: 3,
                p: 5,
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: 900 }}>
                <Box sx={{ my: 2 }}>
                  <Typography sx={{ fontWeight: "bold", fontSize: 20, py: 2 }}>
                    Test Program outreach with precision
                  </Typography>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                    blandit leo lobortis eget.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                    sit amet blandit leo lobortis eget.Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit. Suspendisse malesuada
                    lacus ex, sit amet blandit leo lobortis eget.
                  </Typography>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                    blandit leo lobortis eget.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                    sit amet blandit leo lobortis eget.Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit. Suspendisse malesuada
                    lacus ex, sit amet blandit leo lobortis eget.
                  </Typography>
                </Box>
                <Box sx={{ my: 2 }}>
                  <Typography sx={{ fontWeight: "bold", fontSize: 20, py: 2 }}>
                    Test Program outreach with precision
                  </Typography>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                    blandit leo lobortis eget.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                    sit amet blandit leo lobortis eget.Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit. Suspendisse malesuada
                    lacus ex, sit amet blandit leo lobortis eget.
                  </Typography>
                  <Typography>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Suspendisse malesuada lacus ex, sit amet blandit leo
                    lobortis eget. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Suspendisse malesuada lacus ex, sit amet
                    blandit leo lobortis eget.Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit. Suspendisse malesuada lacus ex,
                    sit amet blandit leo lobortis eget.Lorem ipsum dolor sit
                    amet, consectetur adipiscing elit. Suspendisse malesuada
                    lacus ex, sit amet blandit leo lobortis eget.
                  </Typography>
                </Box>
              </Box>
            </Card>
          </Box>
        </CustomTabPanel>
      </Box>
    </Box>
  );
}
