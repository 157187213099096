import {
  Add,
  CalendarMonth,
  Close,
  Edit,
  Message,
  PeopleAlt,
  Print,
  Save,
  Share,
} from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Modal,
  Popover,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import FileUpload from '../File/FileUpload'
import { set, useForm } from 'react-hook-form'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { authHeader } from '../../utils/auth'
import { BASE_URL } from '../../utils/config'
import { TimePicker } from '@mui/x-date-pickers'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import AssignClients from './AssignClients'
import TagPeople from './TagPeople'
import { usePatch } from '../../hooks/usePatch'

export default function DirectoryDetails({
  open,
  close,
  data,
}: {
  open: boolean
  close: any
  data: any
}) {
  console.log({ data })

  const [openAssign, setOpenAssign] = useState(false)
  const [openTag, setOpenTag] = useState(false)
  const [isWritingNote, setIsWritingNote] = useState(false)
  const [note, setNote] = useState('')

  const { data: assignedClients, refetch: refetchAssigned } = useFetch(
    'fetch assigned clients',
    `/public/directory/directories/${data?.id}/clients`
  )
  const { data: taggedPeople, refetch: refetchTagged } = useFetch(
    'fetch tagged People',
    `/public/directory/directories/${data?.id}/people`
  )

  const { mutate, isSuccess, isError, error } = usePatch(
    `/public/directory/directories/${data?.id}`
  )

  const handleSubmitNote = () => {
    mutate({ internalNote: note } as any)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Internal Note Added Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      close()
    }
    if (isError) {
      console.log({ error })
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  console.log({ assignedClients, taggedPeople })

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 700 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                {data?.firstName + ' ' + data?.lastName}
              </Typography>
              <Box></Box>
            </Box>
            <Divider />
            <Box
              sx={{
                py: 1,
                height: 'calc(100vh - 70px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: 5,
                  px: { xs: 3, sm: 5 },
                  py: 2,
                }}
              >
                <Edit sx={{ color: '#0178DD' }} />
                <Message sx={{ color: '#0178DD' }} />
                <Share sx={{ color: '#0178DD' }} />
                <Print sx={{ color: '#0178DD' }} />
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  px: { xs: 3, sm: 5 },
                  py: 3,
                  gap: 6,
                }}
              >
                <Avatar
                  sx={{ width: 100, height: 100 }}
                  src={data?.imageUrl}
                  alt={data?.firstName}
                />
                <Box>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {data?.firstName + ' ' + data?.lastName}
                  </Typography>
                  <Typography sx={{ color: '#A0AABE', fontSize: 12 }}>
                    {data?.tags?.[0]?.tag}
                  </Typography>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    {data?.phone} | {data?.email}
                  </Typography>
                </Box>
              </Box>
              <Divider />
              <Box>
                {data?.workingHours?.map((item: any) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      justifyContent: 'space-evenly',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                        px: { xs: 3, sm: 5 },
                        py: 3,
                      }}
                    >
                      <CalendarMonth />
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 1,
                          px: 2,
                          py: 0.4,
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#8AA3BF',
                            fontWeight: 'bold',
                            fontSize: 14,
                          }}
                        >
                          {item?.days?.join(', ')}
                        </Typography>
                        <Divider
                          sx={{ color: '#8AA3BF' }}
                          flexItem
                          orientation='vertical'
                        />
                        <Typography
                          sx={{
                            color: '#8AA3BF',
                            fontWeight: 'bold',
                            fontSize: 13,
                          }}
                        >
                          {item?.from}
                        </Typography>
                        <Typography
                          sx={{
                            color: '#8AA3BF',
                            fontWeight: 'bold',
                            fontSize: 13,
                          }}
                        >
                          {item?.to}
                        </Typography>
                      </Box>
                    </Box>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Schedule
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box
                sx={{
                  gap: 2,
                  px: { xs: 3, sm: 5 },
                  py: 3,
                }}
              >
                <Typography sx={{ fontWeight: 'bold' }}>
                  Specialities and Experties
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 4,
                    py: 2,
                    px: 2,
                    flexWrap: 'wrap',
                  }}
                >
                  {data?.tags?.map((item: any) => (
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      {item?.tag}
                    </Typography>
                  ))}
                </Box>
              </Box>
              <Box sx={{ px: { xs: 3, sm: 5 }, py: 3 }}>
                <Typography
                  sx={{ color: '#6DBAFC', fontSize: 16, fontWeight: 'bold' }}
                >
                  Internal Notes
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                  <Box
                    sx={{
                      bgcolor: '#F4FAFF',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: 100,
                      height: 100,
                      cursor: 'pointer',
                    }}
                    onClick={() => setIsWritingNote(true)}
                  >
                    {!data?.internalNote || data?.internalNote === '' ? (
                      <Add sx={{ color: '#3376D6' }} />
                    ) : (
                      <Edit sx={{ color: '#3376D6' }} />
                    )}

                    <Typography sx={{ fontWeight: 'bold', color: '#3376D6' }}>
                      {!data?.internalNote || data?.internalNote === ''
                        ? 'Add'
                        : 'Edit'}{' '}
                      Note
                    </Typography>
                  </Box>
                  {isWritingNote ? (
                    <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                      <textarea
                        style={{
                          resize: 'none',
                          width: 300,
                          height: 100,
                          borderRadius: 3,
                          padding: 3,
                        }}
                        onChange={(e) => setNote(e.target.value)}
                        defaultValue={data?.internalNote}
                      />
                      <Box>
                        <IconButton onClick={() => setIsWritingNote(false)}>
                          <Close sx={{ color: 'red' }} />
                        </IconButton>
                        <IconButton
                          onClick={() => {
                            setIsWritingNote(false)
                            handleSubmitNote()
                          }}
                        >
                          <Save sx={{ color: 'blue' }} />
                        </IconButton>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        border: '1px solid #EEF3F8',
                        borderRadius: 4,
                        p: 3,
                        width: 300,
                      }}
                    >
                      <Typography sx={{ color: '#8AA3BF', fontSize: 12 }}>
                        {data?.internalNote && data?.internalNote !== ''
                          ? data?.internalNote
                          : 'Click add to add a new note'}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-evenly',
                  gap: 3,
                  px: { xs: 3, sm: 5 },
                  py: 3,
                }}
              >
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 10,
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>Group</Typography>
                    <Button onClick={() => setOpenTag(true)}>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Add sx={{ color: '#2D90E3' }} />
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 12,
                            color: '#2D90E3',
                          }}
                        >
                          Tag People
                        </Typography>
                      </Box>
                    </Button>
                  </Box>
                  {taggedPeople?.taggedPeople?.map((item: any) => (
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 4,
                          py: 2,
                        }}
                      >
                        <Avatar
                          sx={{ width: 30, height: 30 }}
                          src={item?.photoUrl}
                        />
                        <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                          {item?.name}
                        </Typography>
                      </Box>
                      <Divider />
                    </Box>
                  ))}
                </Box>
                <Divider flexItem orientation='vertical' />
                <Box>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: 6,
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>
                      Assigned Clients
                    </Typography>
                    <Button onClick={() => setOpenAssign(true)}>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Add sx={{ color: '#2D90E3' }} />
                        <Typography
                          sx={{
                            fontWeight: 'bold',
                            fontSize: 12,
                            color: '#2D90E3',
                          }}
                        >
                          AssignClients
                        </Typography>
                      </Box>
                    </Button>
                  </Box>
                  {assignedClients?.assignedClients?.map((item: any) => (
                    <Box>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 4,
                          py: 2,
                        }}
                      >
                        <Avatar
                          sx={{ width: 30, height: 30 }}
                          src={item?.photoUrl}
                        />
                        <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                          {item?.name}
                        </Typography>
                      </Box>
                      <Divider />
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Card>
          <AssignClients
            open={openAssign}
            close={() => setOpenAssign(false)}
            directory_id={data?.id}
            refetch={refetchAssigned}
          />

          <TagPeople
            open={openTag}
            close={() => setOpenTag(false)}
            directory_id={data?.id}
            refetch={refetchTagged}
          />
        </Box>
      </Slide>
    </Modal>
  )
}
