import { Box, Button, Typography } from '@mui/material'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { useState } from 'react'
import { set } from 'react-hook-form'
export default function PinMap({
  onMarkerClick,
  setFormState,
  isContact,
  setIsContact,
  contactIndex,
  contactMarkerClick,
}: any) {
  const [map, setMap] = useState(null)
  const [markerPosition, setMarkerPosition] = useState() as any
  const [center, setCenter] = useState({
    lat: 8.910989899999999,
    lng: 38.8263889,
  }) as any

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyA5AuE_ESosvMG_CijpCw1Ouga6aF_irTo',
  })

  const handleMapLoad = (map: any) => {
    setMap(map)
  }

  const handleMapClick = (event: any) => {
    const { latLng } = event
    setMarkerPosition({ lat: latLng.lat(), lng: latLng.lng() })
    setCenter({ lat: latLng.lat(), lng: latLng.lng() })
  }

  const handleSave = () => {
    if (isContact) {
      contactMarkerClick(markerPosition, contactIndex)
      setIsContact(false)
    } else {
      onMarkerClick(markerPosition)
    }
    setFormState('basic')
  }

  return isLoaded ? (
    <Box sx={{ height: '100%', width: '100%' }}>
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '95%' }}
        center={center}
        zoom={8}
        onLoad={handleMapLoad}
        onClick={handleMapClick}
        options={{
          streetViewControl: false,
          mapTypeControl: false,
        }}
      >
        {markerPosition && <Marker position={markerPosition} />}
      </GoogleMap>
      <Box
        sx={{
          py: 2,
          display: 'flex',
          justifyContent: 'flex-end',
          px: 5,
        }}
      >
        <Button variant='contained' sx={{ px: 3, py: 1 }} onClick={handleSave}>
          <Typography sx={{ fontSize: 14, fontWeight: 'bold' }}>
            Save
          </Typography>
        </Button>
      </Box>
    </Box>
  ) : (
    <>Map Failed to Load</>
  )
}
