import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import AddAppointment from '../components/Appointment/AddAppointment'
import { Add, ChevronLeft, ChevronRight, Group } from '@mui/icons-material'
import { usePost } from '../hooks/usePost'
import AppointmentDetails from '../components/Appointment/AppointmentDetails'
import moment from 'moment'
import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  .fc-today {
    background-color: blue !important;
  }
`
// import { Calendar } from 'antd'
// import type { Dayjs } from 'dayjs'
// import type { CalendarProps } from 'antd'

export default function Appointments({ defaultData }: { defaultData?: any }) {
  // const onPanelChange = (value: Dayjs, mode: CalendarProps<Dayjs>['mode']) => {
  //   console.log(value.format('YYYY-MM-DD'), mode)
  // }

  // const [openAddAppointment, setOpenAddAppointment] = useState(false)

  // return (
  //   <Box sx={{ p: 3 }}>
  //     <Grid container>
  //       <Grid item xs={12} sm={9} sx={{ px: 3 }}>
  //         <Calendar onPanelChange={onPanelChange} />
  //       </Grid>
  //       <Grid item xs={12} sm={3} sx={{ px: 3 }}>
  //         <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
  //           <Button
  //             variant='contained'
  //             sx={{ borderRadius: 7, py: 1.5 }}
  //             onClick={() => setOpenAddAppointment(true)}
  //           >
  //             <Box sx={{ display: 'flex', alignItems: 'center' }}>
  //               <Add />
  //               <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
  //                 New Appointment
  //               </Typography>
  //             </Box>
  //           </Button>
  //         </Box>
  //         <Box sx={{ my: 10 }}>
  //           <Typography sx={{ fontWeight: 'bold', color: '#09244B' }}>
  //             Upcoming Appointment
  //           </Typography>
  //           <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, my: 2 }}>
  //             <Group />
  //             <Box>
  //               <Typography sx={{ color: '#040E28', fontWeight: 'bold' }}>
  //                 Lancet General Hospital
  //               </Typography>
  //               <Typography sx={{ fontSize: 12 }}>
  //                 <span style={{ color: '#0178DD', fontWeight: 'bold' }}>
  //                   08:00
  //                 </span>{' '}
  //                 Appointment With{' '}
  //                 <span style={{ fontWeight: 'bold' }}>Dr. Solomon</span>
  //               </Typography>
  //             </Box>
  //           </Box>
  //           <Divider sx={{ my: 2, mx: 7 }} />
  //           <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, my: 2 }}>
  //             <Group />
  //             <Box>
  //               <Typography sx={{ color: '#040E28', fontWeight: 'bold' }}>
  //                 Lancet General Hospital
  //               </Typography>
  //               <Typography sx={{ fontSize: 12 }}>
  //                 <span style={{ color: '#0178DD', fontWeight: 'bold' }}>
  //                   08:00
  //                 </span>{' '}
  //                 Appointment With{' '}
  //                 <span style={{ fontWeight: 'bold' }}>Dr. Solomon</span>
  //               </Typography>
  //             </Box>
  //           </Box>
  //           <Divider sx={{ my: 2, mx: 7 }} />
  //           <Box sx={{ display: 'flex', alignItems: 'center', gap: 4, my: 2 }}>
  //             <Group />
  //             <Box>
  //               <Typography sx={{ color: '#040E28', fontWeight: 'bold' }}>
  //                 Lancet General Hospital
  //               </Typography>
  //               <Typography sx={{ fontSize: 12 }}>
  //                 <span style={{ color: '#0178DD', fontWeight: 'bold' }}>
  //                   08:00
  //                 </span>{' '}
  //                 Appointment With{' '}
  //                 <span style={{ fontWeight: 'bold' }}>Dr. Solomon</span>
  //               </Typography>
  //             </Box>
  //           </Box>
  //         </Box>
  //       </Grid>
  //     </Grid>
  //     <AddAppointment
  //       open={openAddAppointment}
  //       close={() => setOpenAddAppointment(false)}
  //     />
  //   </Box>
  // )

  const [refetch, setRefetch] = useState(false)

  const { data: appointments, mutate: fetchAppointments } = usePost(
    '/public/appointment/appointments/list'
  )

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchAppointments(dataP)
  }, [refetch])

  console.log({ appointments })

  let events: any = []

  if (defaultData) {
    defaultData?.data?.appointments?.map((appointment: any) => {
      events?.push({
        title: `${appointment?.participants?.[0]?.name},${
          appointment?.participants?.[1]?.name || ''
        }`,
        start: appointment?.startTime,
        backgroundColor: appointment?.color,
        id: appointment?.id,
      })
    })
  } else {
    appointments?.data?.appointments?.map((appointment: any) => {
      events?.push({
        title: `${appointment?.participants?.[0]?.name},${
          appointment?.participants?.[1]?.name || ''
        }`,
        start: appointment?.startTime,
        backgroundColor: appointment?.color,
        id: appointment?.id,
      })
    })
  }

  // const events = [{ title: 'Meeting', start: new Date() }]

  const [openAddAppointment, setOpenAddAppointment] = useState(false)
  const [openAppointmentDetails, setOpenAppointmentDetails] = useState(false)
  const [appontment, setAppointment] = useState() as any

  const customButtons = {
    myCustomButton: {
      text: 'Custom Button',
      // click: function () {
      //   // Handle custom button click event
      //   alert('Custom button clicked!')
      // },
    },
  }
  // const handlePrevButtonClick = () => {
  //   // Handle logic for previous button click
  //   console.log('Previous button clicked')
  // }

  // const handleNextButtonClick = () => {
  //   // Handle logic for next button click
  //   console.log('Next button clicked')
  // }
  // const header = () => {
  //   return (
  //     <div>
  //       <button onClick={handlePrevButtonClick}>Custom Prev</button>
  //       <button onClick={handleNextButtonClick}>Custom Next</button>
  //       <h2>Title</h2>
  //       <button>Today</button>
  //     </div>
  //   )
  // }
  const calendarRef = useRef(null) as any
  const titleRef = useRef(null)
  const [title, setTitle] = useState(null) as any
  const [active, setActive] = useState('month')

  const [updated, setUpdated] = useState(false)

  const handlePrevButtonClick = () => {
    if (calendarRef.current) {
      calendarRef?.current?.getApi()?.prev()
      setUpdated(!updated)
    }
  }

  const handleNextButtonClick = () => {
    if (calendarRef.current) {
      calendarRef?.current?.getApi()?.next()
      setUpdated(!updated)
      // const api = calendarRef?.current?.getApi()
      // api.prev()
      // const title = api?.view?.title // Get the current view title (month and year)
      // console.log('Current view title:', title)
    }
  }

  const updateTitle = () => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi()
      titleRef.current = api.view.title
      setTitle(api.view.title)
    }
  }

  useEffect(() => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi()
      titleRef.current = api.view.title // Store the current view title in the ref
      console.log('Current view title:', titleRef.current)
      setTitle(api.view.title)
    }
  }, [updated])

  useEffect(() => {
    updateTitle() // Call the updateTitle function when the component first loads
  }, [])

  const handleToggleDayGridMonth = () => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi()
      api.changeView('dayGridMonth')
      updateTitle()
      setActive('month')
    }
  }

  const handleToggleTimeGridWeek = () => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi()
      api.changeView('timeGridWeek')
      updateTitle()
      setActive('week')
    }
  }

  const handleToggleTimeGridDay = () => {
    if (calendarRef.current) {
      const api = calendarRef.current.getApi()
      api.changeView('timeGridDay')
      updateTitle()
      setActive('day')
    }
  }

  function renderEventContent(eventInfo: any) {
    console?.log({ eventInfo })
    console.log({ event: eventInfo?.event?._def?.publicId })
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          bgcolor: eventInfo?.backgroundColor,
          borderRadius: 1,
          px: 2,
          py: 0.5,
          width: '100%',
        }}
        onClick={() => {
          setAppointment(eventInfo?.event?._def?.publicId)
          setOpenAppointmentDetails(true)
        }}
      >
        <Typography sx={{ fontWeight: 'bold', color: '#5277FF', fontSize: 10 }}>
          {eventInfo.timeText}
        </Typography>
        <Typography sx={{ fontWeight: 'bold', fontSize: 10, color: 'white' }}>
          {eventInfo.event.title}
        </Typography>
      </Box>
      // <>
      //   <b>{eventInfo.timeText}</b>
      //   <i>{eventInfo.event.title}</i>
      // </>
    )
  }

  const upcoming = appointments?.data?.appointments?.filter(
    (item: any) => moment(item?.startTime).diff(moment(new Date())) > 0
  )

  console.log({ up: appointments?.data?.appointments, upcoming })

  return (
    <Box sx={{ p: 3 }}>
      <GlobalStyle />
      <Grid container>
        <Grid item xs={12} sm={9} sx={{ px: 3 }}>
          <Box
            sx={{
              bgcolor: '#E6F4FF',
              py: 1,
              px: 3,
              borderRadius: 2,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <IconButton onClick={handlePrevButtonClick}>
                <ChevronLeft sx={{ color: '#2699FB', width: 40, height: 40 }} />
              </IconButton>
              <Typography sx={{ color: '#0178DD', fontWeight: 'bold' }}>
                {title}
              </Typography>
              <IconButton onClick={handleNextButtonClick}>
                <ChevronRight
                  sx={{ color: '#2699FB', width: 40, height: 40 }}
                />
              </IconButton>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <Box
                sx={{
                  cursor: 'pointer',
                  ...(active === 'day' && {
                    py: 1.5,
                    px: 2,
                    borderRadius: 1,
                    bgcolor: 'white',
                    boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                  }),
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    color: active === 'day' ? '#0178DD' : '#000000',
                  }}
                  onClick={handleToggleTimeGridDay}
                >
                  Day
                </Typography>
              </Box>
              <Box
                sx={{
                  cursor: 'pointer',
                  ...(active === 'week' && {
                    py: 1.5,
                    px: 2,
                    borderRadius: 1,
                    bgcolor: 'white',
                    boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                  }),
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 'bold',
                    color: active === 'week' ? '#0178DD' : '#000000',
                  }}
                  onClick={handleToggleTimeGridWeek}
                >
                  Week
                </Typography>
              </Box>
              <Box
                sx={{
                  cursor: 'pointer',
                  ...(active === 'month' && {
                    py: 1.5,
                    px: 2,
                    borderRadius: 1,
                    bgcolor: 'white',
                    boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                  }),
                }}
                onClick={handleToggleDayGridMonth}
              >
                <Typography
                  sx={{
                    color: active === 'month' ? '#0178DD' : '#000000',
                    fontWeight: 'bold',
                  }}
                >
                  Month
                </Typography>
              </Box>
            </Box>
          </Box>
          <FullCalendar
            ref={calendarRef}
            plugins={[interactionPlugin, dayGridPlugin, timeGridPlugin]}
            initialView='dayGridMonth'
            events={events}
            eventContent={renderEventContent}
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            height={'80vh'}
            dateClick={() => setOpenAddAppointment(true)}
            // customButtons={customButtons}
            dayCellContent={dayCellContent}
            headerToolbar={{
              // start: 'customPrevButton customNextButton',
              start: '',
              center: '',
              end: '',
            }}
            // customButtons={
            //   {
            //     // customPrevButton: {
            //     //   text: '',
            //     //   click: handlePrevButtonClick,
            //     // },
            //     // customNextButton: {
            //     //   text: '',
            //     //   click: handleNextButtonClick,
            //     // },
            //   }
            // }
            // // headerToolbar={header}
          />
        </Grid>
        <Grid item xs={12} sm={3} sx={{ px: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              variant='contained'
              sx={{ borderRadius: 7, py: 1.5 }}
              onClick={() => setOpenAddAppointment(true)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Add />
                <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                  New Appointment
                </Typography>
              </Box>
            </Button>
          </Box>
          <Box sx={{ my: 10 }}>
            <Typography sx={{ fontWeight: 'bold', color: '#09244B' }}>
              Upcoming Appointment
            </Typography>
            {defaultData
              ? defaultData?.data?.appointments?.map(
                  (item: any, index: number) => {
                    if (index < 3) {
                      return (
                        <Box>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 4,
                              my: 2,
                              cursor: 'pointer',
                            }}
                            onClick={() => {
                              setAppointment(item?.id)
                              setOpenAppointmentDetails(true)
                            }}
                          >
                            <Group />
                            <Box>
                              <Typography
                                sx={{ color: '#040E28', fontWeight: 'bold' }}
                              >
                                {item?.meetingType}{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                  Appointment
                                </span>
                              </Typography>
                              <Typography sx={{ fontSize: 12 }}>
                                <span
                                  style={{
                                    color: '#0178DD',
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {moment(item?.startTime)
                                    .format('lll')
                                    .toString()}
                                </span>{' '}
                                <br />
                                Appointment With{' '}
                                <span style={{ fontWeight: 'bold' }}>
                                  {item?.participants?.[0]?.name}
                                </span>
                              </Typography>
                            </Box>
                          </Box>
                          <Divider sx={{ my: 2, mx: 7 }} />
                        </Box>
                      )
                    }
                  }
                )
              : upcoming?.map((item: any, index: number) => {
                  if (index < 5) {
                    return (
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 4,
                            my: 2,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            setAppointment(item?.id)
                            setOpenAppointmentDetails(true)
                          }}
                        >
                          <Group />
                          <Box>
                            <Typography
                              sx={{ color: '#040E28', fontWeight: 'bold' }}
                            >
                              Appointment With{' '}
                              <span style={{ fontWeight: 'bold' }}>
                                {item?.participants?.[0]?.name}
                              </span>
                            </Typography>
                            <Typography sx={{ fontSize: 12 }}>
                              <span
                                style={{
                                  color: '#0178DD',
                                  fontWeight: 'bold',
                                }}
                              >
                                {moment(item?.startTime)
                                  .format('lll')
                                  .toString()}
                              </span>{' '}
                              <br />
                              Appointment With{' '}
                              <span style={{ fontWeight: 'bold' }}>
                                {item?.participants?.[0]?.name}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                        <Divider sx={{ my: 2, mx: 7 }} />
                      </Box>
                    )
                  }
                })}
          </Box>
        </Grid>
      </Grid>
      <AddAppointment
        open={openAddAppointment}
        close={() => setOpenAddAppointment(false)}
        refetch={refetch}
        setRefetch={setRefetch}
      />
      <AppointmentDetails
        open={openAppointmentDetails}
        close={() => setOpenAppointmentDetails(false)}
        data={
          appointments?.data?.appointments?.filter(
            (item: any) => item?.id === appontment
          )?.[0]
        }
      />
    </Box>
  )
}

// a custom render function

const dayCellContent = (arg: any) => {
  return (
    // <>
    //   {/* <span>{arg.dayNumberText}</span> */}
    //   <div
    //   // style={{
    //   //   position: 'absolute',
    //   //   top: 0,
    //   //   left: 0,
    //   //   transform: 'translate(-50%, -50%)',
    //   //   cursor: 'pointer',
    //   //   border: '1px solid red',
    //   // }}
    //   // onClick={() => handleAddIconClick(arg.date)}
    //   >
    //     +
    //   </div>
    // </>
    <Box sx={{ cursor: 'pointer' }}>
      <span>{arg.dayNumberText}</span>
      <Box
        sx={{
          position: 'absolute',
          left: -65,
          top: 50,
        }}
      >
        <Add sx={{ color: '#BCBCBC' }} />
      </Box>
    </Box>
  )
}
