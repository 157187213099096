import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import ehr from '../assets/Frame.png'
import { useEffect, useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { enqueueSnackbar } from 'notistack'
import { usePost } from '../hooks/usePost'

export default function SetNewPassword() {
  const [password, setPassword] = useState<String>()
  const [showPassword, setShowPassword] = useState(false)
  const [confirmPassword, setConfirmPassword] = useState<String>()
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const address = searchParams.get('address')
  const method = searchParams.get('method')

  const { mutate, isLoading, isError, isSuccess, error } = usePost(
    '/public/user/password-reset/reset'
  )

  const handleSubmit = () => {
    const passwordsMatch: boolean = password === confirmPassword
    if (!passwordsMatch) {
      enqueueSnackbar(`Password and confirm password doesn't match`, {
        variant: 'error',
        preventDuplicate: true,
      })
      return
    }

    const data: any = {
      ...(address?.includes('@')
        ? { email: address }
        : { phone_number: address }),
      method: method,
      new_password: password,
    }
    console.log({ data })
    mutate(data)
  }

  useEffect(() => {
    if (isError) {
      console.log({ error })
      enqueueSnackbar(error?.response?.data?.message || 'Network Error', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
    if (isSuccess) {
      navigate(`/login`)
    }
  }, [isSuccess, isError])

  return (
    <Box
      sx={{
        bgcolor: '#FBFBFB',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Grid
        container
        sx={{
          height: '100%',
        }}
      >
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: '90%',
                height: '90%',
                borderRadius: 10,
                bgcolor: '#F7F7F7',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box component='img' src={ehr} sx={{ width: '80%' }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: { xs: '90%', sm: '70%', md: 400 },
                height: '100%',
                justifyContent: 'space-between',
                py: 20,
              }}
            >
              <Box>
                <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                  Set New password
                </Typography>
                <Typography>Enter and confirm your new password</Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: 13, fontWeight: 'bold', mb: 1 }}>
                  Password
                </Typography>
                <TextField
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setPassword(e.target.value)}
                  fullWidth
                />

                <Typography
                  sx={{ fontSize: 13, fontWeight: 'bold', mt: 4, mb: 1 }}
                >
                  Confirm Password
                </Typography>
                <TextField
                  type={showConfirmPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                        >
                          {showConfirmPassword ? (
                            <VisibilityOff />
                          ) : (
                            <Visibility />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder='Password'
                  fullWidth
                />
              </Box>
              <Box>
                <Button
                  variant='contained'
                  fullWidth
                  sx={{ color: 'white', py: 2, mb: 15 }}
                  onClick={handleSubmit}
                >
                  {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress sx={{ color: 'white' }} />
                      <Typography>Setting...</Typography>
                    </Box>
                  ) : (
                    <Typography>Set</Typography>
                  )}
                </Button>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  position: 'absolute',
                  bottom: 20,
                  width: 400,
                }}
              >
                <Typography sx={{ fontSize: 12 }}>
                  @{new Date().getFullYear()} GoodSeedCdc
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'cenetr',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 'bold', color: '#1F7DEC', fontSize: 12 }}
                  >
                    Privacy
                  </Typography>
                  <Divider flexItem orientation='vertical' />
                  <Typography
                    sx={{ fontWeight: 'bold', color: '#1F7DEC', fontSize: 12 }}
                  >
                    Terms
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
