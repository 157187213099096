import { LocationOn } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material'
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers'
import { useEffect, useState } from 'react'
import FileUpload from '../File/FileUpload'
import { useFetch } from '../../hooks/useFetch'
import { useForm } from 'react-hook-form'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import { BASE_URL } from '../../utils/config'
import { authHeader } from '../../utils/auth'
import axios from 'axios'

export default function AddDocument({
  open,
  close,
}: {
  open: boolean
  close: any
}) {
  const [imageState, setImageState] = useState<any>()
  const [url, setUrl] = useState() as any
  const [categoryId, setCategoryId] = useState() as any
  const [subCatId, setSubCatId] = useState() as any
  const [expireDate, setExpireDate] = useState() as any
  console.log({ imageState, url })
  const { data: documentCategories, mutate: fetchDocumentCategories } = usePost(
    '/public/document/document_categories/list'
  )

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchDocumentCategories(dataP)
  }, [])

  console.log({ documentCategories })

  const { mutate, isLoading, isError, isSuccess, error } = usePost(
    '/public/document/documents'
  )

  type FormValues = {
    title: string
    document_sub_category_id: string
    document_category_id: string
    url: string
    expireDate: string
  }

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      title: '',
      document_sub_category_id: '',
      document_category_id: '',
      url: '',
      expireDate: '',
    },
    mode: 'onBlur',
  })

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Document Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      // setRefetch(!refetch)
      close()
    }
    if (isError) {
      console.log({ error })
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  useEffect(() => {
    console.log({ imageState })
    if (imageState) {
      axios
        .get(
          `${BASE_URL}/public/document/presigned-url?fileKey=${imageState?.name}`,
          {
            headers: authHeader(),
          }
        )
        .then((res) => setUrl(res?.data?.url))
        .catch((e) => console.log({ e }))
    }
  }, [imageState])
  useEffect(() => {
    if (url) {
      const reader = new FileReader()
      reader.onload = handleReaderLoaded
      reader.readAsArrayBuffer(imageState)
    }
  }, [url])
  const handleReaderLoaded = (event: any) => {
    const binaryData = event.target.result
    console.log({ binaryData })

    axios
      .put(url, binaryData)
      .then((res) => console.log({ res }))
      .catch((e) => console.log({ e }))
  }

  const onSubmit = (data: any) => {
    data.url = url?.split('?')[0]
    data.document_sub_category_id = subCatId
    data.document_category_id = categoryId
    data.expireDate = expireDate?.$d?.toISOString()?.replace('.000', '')
    console.log(233333333333333, { data })
    mutate(data)
  }
  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', sm: 600 },
              height: '100vh',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  bgcolor: '#FBFBFB',
                  height: 60,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  px: 3,
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#EDEDED',
                    px: 3,
                    py: 0.8,
                    cursor: 'pointer',
                    borderRadius: 2,
                  }}
                  onClick={close}
                >
                  Back
                </Box>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Add New Document
                </Typography>
                <Box> </Box>
              </Box>
              <Divider />
              <Box
                sx={{
                  px: { xs: 5, sm: 10 },
                  py: 5,
                  height: 'calc(100vh - 140px)',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                }}
              >
                <Box>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Document Title
                  </Typography>
                  <TextField
                    size='small'
                    fullWidth
                    {...register('title', {
                      required: true,
                    })}
                  />
                </Box>
                <Box sx={{ my: 2 }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Document Category
                  </Typography>
                  <Autocomplete
                    options={documentCategories?.data?.documentCategories || []}
                    getOptionLabel={(item: any) => item.name}
                    size='small'
                    onChange={(event, value) => {
                      setCategoryId(value?.id)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size='small' fullWidth />
                    )}
                  />
                </Box>
                <Box sx={{ my: 2 }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Document Sub Category
                  </Typography>
                  <Autocomplete
                    options={
                      documentCategories?.data?.documentCategories?.find(
                        (item: any) => item.id === categoryId
                      )?.documentSubCategories || []
                    }
                    getOptionLabel={(item: any) => item.name}
                    size='small'
                    onChange={(event, value) => {
                      setSubCatId(value?.id)
                    }}
                    renderInput={(params) => (
                      <TextField {...params} size='small' fullWidth />
                    )}
                  />
                </Box>

                <Box>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Expire Date
                  </Typography>
                  <DatePicker
                    value={expireDate}
                    onChange={(newValue) => setExpireDate(newValue)}
                    sx={{ width: '100%' }}
                    slotProps={{ textField: { size: 'small' } }}
                  />
                </Box>
                <Box sx={{ my: 2 }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Attatch File
                  </Typography>
                  <FileUpload
                    name='file'
                    type='FILE'
                    size={150}
                    setFileState={setImageState}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  gap: 5,
                  bottom: 0,
                  width: { xs: '100vw', sm: 600 },
                  height: 80,
                  pb: 3,
                  px: 5,
                  // bgcolor: '#F5F5F5',
                }}
              >
                <Button
                  variant='contained'
                  type='submit'
                  // onClick={handleSubmit}
                  sx={{ px: 2, borderRadius: 2, height: 50 }}
                >
                  {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress sx={{ color: 'white' }} size={20} />
                      <Typography>submitting...</Typography>
                    </Box>
                  ) : (
                    <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                      Add Document
                    </Typography>
                  )}
                </Button>
              </Box>
            </form>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
