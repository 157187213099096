import { Add, Delete, Edit } from '@mui/icons-material'
import { Avatar, Box, Button, Grid, Tab, Tabs, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useFetch } from '../hooks/useFetch'
import AddTemplate from '../components/Settings/ReminderTemplate/AddTemplate'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Settings() {
  const navigate = useNavigate()
  const [value, setValue] = useState(0)
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const { data: user } = useFetch('current', '/public/user/current')
  const { data: reminderTemplates, refetch } = useFetch(
    'reminder',
    '/public/appointment/reminder-templates'
  )

  console.log({ user, reminderTemplates })

  function ItemCard({ title, value }: { title: string; value: string }) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, py: 3 }}>
        <Box sx={{ width: 200 }}>
          <Typography
            sx={{ color: '#B0B0B0', fontSize: 12, fontWeight: 'bold' }}
          >
            {title}
          </Typography>
        </Box>
        <Box sx={{ bgcolor: '#F4FAFF', width: 350, px: 3, py: 1 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
            {value}
          </Typography>
        </Box>
      </Box>
    )
  }

  const [openAddTemplate, setOpenAddTemplate] = useState(false)

  return (
    <Box>
      <Box sx={{ m: 2 }}>
        <Box
          sx={{
            bgcolor: '#EDEDED',
            px: 3,
            py: 0.8,
            cursor: 'pointer',
            borderRadius: 2,
            width: 100,
            display: 'flex',
            justifyContent: 'center',
          }}
          onClick={() => navigate(-1)}
        >
          Back
        </Box>
      </Box>
      <Box sx={{ my: 2 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab
            label='Personal Info'
            {...a11yProps(0)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
          <Tab
            label={`Reminder Template`}
            {...a11yProps(1)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
        </Tabs>
      </Box>
      <Box>
        <CustomTabPanel value={value} index={0}>
          <Box sx={{ p: 5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 5 }}>
              <Box sx={{ width: 200 }}>
                <Typography
                  sx={{ color: '#B0B0B0', fontSize: 12, fontWeight: 'bold' }}
                >
                  Profile Image
                </Typography>
              </Box>
              <Avatar sx={{ width: 120, height: 120 }} />
            </Box>
            <ItemCard title='First Name' value={user?.user?.firstName} />
            <ItemCard title='Middle Name' value={user?.user?.middleName} />
            <ItemCard title='Last Name' value={user?.user?.lastName} />
            <ItemCard title='Phone Number' value={user?.user?.phoneNumber} />
            <ItemCard title='Email' value={user?.user?.email} />
            {/* <ItemCard title='Address' value={user?.user?.firstName} /> */}
            <ItemCard title='Role' value={user?.user?.role?.name} />
            {/* <ItemCard title='Clinical / Non-clinical' value={user?.user?.firstName} /> */}
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 5 }}>
            <Box sx={{ width: '80%' }}>
              {reminderTemplates?.templates?.map((template: any) => (
                <Box
                  sx={{
                    border: '1px solid #E6EEF6',
                    borderRadius: 3,
                    width: '100%',
                    my: 2,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      p: 3,
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor: '#E6F4FF',
                        borderRadius: 5,
                        py: 1,
                        px: 2,
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }}>
                        {template?.name}
                      </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Edit sx={{ color: 'primary.main' }} />
                      <Delete sx={{ color: '#F49B9B' }} />
                    </Box>
                  </Box>
                  {template?.schedules?.map((schedule: any) => (
                    <Box sx={{ py: 1, px: 5 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={8}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                            Time
                          </Typography>
                        </Grid>
                        <Grid item xs={4}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                            Platform
                          </Typography>
                        </Grid>

                        <Grid item xs={8}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#787878',
                                fontSize: 14,
                                fontWeight: 'bold',
                              }}
                            >
                              Every
                            </Typography>
                            <Box
                              sx={{
                                bgcolor: '#F4FAFF',
                                py: 1,
                                px: 2,
                                borderRadius: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 100,
                              }}
                            >
                              <Typography sx={{ fontWeight: 'bold' }}>
                                {schedule?.every}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                bgcolor: '#F4FAFF',
                                py: 1,
                                px: 2,
                                borderRadius: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 100,
                              }}
                            >
                              <Typography sx={{ fontWeight: 'bold' }}>
                                {schedule?.unit}
                              </Typography>
                            </Box>
                            <Typography
                              sx={{
                                color: '#787878',
                                fontSize: 14,
                                fontWeight: 'bold',
                              }}
                            >
                              Before
                            </Typography>
                            <Box
                              sx={{
                                bgcolor: '#F4FAFF',
                                py: 1,
                                px: 2,
                                borderRadius: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 100,
                              }}
                            >
                              <Typography sx={{ fontWeight: 'bold' }}>
                                {schedule?.before}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                bgcolor: '#F4FAFF',
                                py: 1,
                                px: 2,
                                borderRadius: 1,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: 100,
                              }}
                            >
                              <Typography sx={{ fontWeight: 'bold' }}>
                                {schedule?.beforeUnit}
                              </Typography>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={4}>
                          <Box
                            sx={{
                              border: '1px solid #EAF1F7',
                              py: 1,
                              px: 2,
                              borderRadius: 1,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: 70,
                            }}
                          >
                            <Typography
                              sx={{
                                color: '#5FAAEA',
                                fontSize: 12,
                                fontWeight: 'bold',
                              }}
                            >
                              {schedule?.platform?.[0]}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </Box>
              ))}

              {/* <Box
                sx={{
                  border: '1px solid #E6EEF6',
                  borderRadius: 3,
                  width: '80%',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    p: 3,
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: '#E6F4FF',
                      borderRadius: 5,
                      py: 1,
                      px: 2,
                    }}
                  >
                    <Typography sx={{ fontWeight: 'bold', flexGrow: 1 }}>
                      Standard Reminder
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <Edit sx={{ color: 'primary.main' }} />
                    <Delete sx={{ color: '#F49B9B' }} />
                  </Box>
                </Box>
                <Box sx={{ py: 1, px: 5 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                        Time
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>
                        Platform
                      </Typography>
                    </Grid>

                    <Grid item xs={8}>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                      >
                        <Typography
                          sx={{
                            color: '#787878',
                            fontSize: 14,
                            fontWeight: 'bold',
                          }}
                        >
                          Every
                        </Typography>
                        <Box
                          sx={{
                            bgcolor: '#F4FAFF',
                            py: 1,
                            px: 2,
                            borderRadius: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 100,
                          }}
                        >
                          <Typography sx={{ fontWeight: 'bold' }}>4</Typography>
                        </Box>
                        <Box
                          sx={{
                            bgcolor: '#F4FAFF',
                            py: 1,
                            px: 2,
                            borderRadius: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 100,
                          }}
                        >
                          <Typography sx={{ fontWeight: 'bold' }}>
                            Hours
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            color: '#787878',
                            fontSize: 14,
                            fontWeight: 'bold',
                          }}
                        >
                          Before
                        </Typography>
                        <Box
                          sx={{
                            bgcolor: '#F4FAFF',
                            py: 1,
                            px: 2,
                            borderRadius: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 100,
                          }}
                        >
                          <Typography sx={{ fontWeight: 'bold' }}>3</Typography>
                        </Box>
                        <Box
                          sx={{
                            bgcolor: '#F4FAFF',
                            py: 1,
                            px: 2,
                            borderRadius: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 100,
                          }}
                        >
                          <Typography sx={{ fontWeight: 'bold' }}>
                            Days
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={4}>
                      <Box
                        sx={{
                          border: '1px solid #EAF1F7',
                          py: 1,
                          px: 2,
                          borderRadius: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: 70,
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#5FAAEA',
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                        >
                          Text
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box> */}
            </Box>
            <Button
              variant='contained'
              sx={{ borderRadius: 7, height: 40 }}
              onClick={() => setOpenAddTemplate(true)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Add />
                <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                  New Template
                </Typography>
              </Box>
            </Button>
          </Box>
        </CustomTabPanel>
      </Box>
      <AddTemplate
        open={openAddTemplate}
        close={() => setOpenAddTemplate(false)}
        refetch={refetch}
      />
    </Box>
  )
}
