import { Box, Button, IconButton, Typography } from '@mui/material'
import {
  MRT_ColumnDef,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
  MRT_ToggleFullScreenButton,
} from 'material-react-table'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { Done, Visibility } from '@mui/icons-material'
import ActivityDetails from './ActivityDetail'
import { usePost } from '../../hooks/usePost'

export default function Client() {
  const [openActivity, setOpenActivity] = useState(false)
  const [userId, setUserId] = useState(false)

  const { data: activityData, mutate } = usePost(
    '/public/activity_log/activity_logs/list'
  )

  console.log({ activityData })

  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
        order: [
          {
            field: 'created_at',
            order_type: 1,
          },
        ],
      },
    }
    mutate(datap)
  }, [])

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'firstName',
        header: 'First Name',
        accessorFn: (originalRow) => originalRow?.user?.firstName,
      },
      {
        accessorKey: 'middleName',
        header: 'Middle Name',
        accessorFn: (originalRow) => originalRow?.user?.middleName,
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
        accessorFn: (originalRow) => originalRow?.user?.lastName,
      },

      {
        accessorKey: 'email',
        header: 'Email',
        accessorFn: (originalRow) => originalRow?.user?.email,
      },
      {
        accessorFn: (originalRow) =>
          moment(originalRow?.createdAt).format('lll'),
        id: 'createdAt',
        header: 'Date',
        size: 220,
      },
      {
        accessorKey: 'actions' as any,
        header: 'Activity',
        enableSorting: false,
        flex: 1,
        size: 200,
        enableColumnFilter: false,
        Cell: ({ row, table }) => (
          <IconButton
            onClick={() => {
              setUserId(row.original.user.id)
              setOpenActivity(true)
            }}
          >
            <Visibility sx={{ color: '#0178DD' }} />
          </IconButton>
        ),
      },
    ],
    []
  )

  return (
    <Box>
      <MaterialReactTable
        columns={columns}
        data={activityData?.data?.activityLogs || []}
        renderToolbarInternalActions={({ table }) => (
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
              gap: 1,
            }}
          >
            <MRT_ToggleFiltersButton table={table} />
            <MRT_ShowHideColumnsButton table={table} />
            <MRT_ToggleDensePaddingButton table={table} />
            <MRT_ToggleFullScreenButton table={table} />
          </Box>
        )}
      />
      {userId && (
        <ActivityDetails
          open={openActivity}
          close={() => setOpenActivity(false)}
          userId={userId}
        />
      )}
    </Box>
  )
}
