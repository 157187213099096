import { PeopleAlt } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Modal,
  Slide,
  TextField,
  Typography,
} from '@mui/material'
import { usePost } from '../../../../hooks/usePost'
import { useEffect, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { useFetch } from '../../../../hooks/useFetch'
import { usePatch } from '../../../../hooks/usePatch'

export default function EditTag({
  open,
  close,
  categoryId,
  refetch,
  setRefetchTag,
  tag,
}: {
  open: boolean
  close: any
  categoryId: string
  refetch: any
  setRefetchTag: any
  tag: any
}) {
  const [title, setTitle] = useState() as any
  const [directoryCategoryDepartmentId, setDepartmentId] = useState() as any

  console.log({ tag })
  const { mutate, isLoading, isError, error, isSuccess } = usePatch(
    `/public/directory/directory-tags/${tag?.id}`
  )

  console.log({ categoryId })

  const { data: directoryDepartments } = useFetch(
    'directoryDeps',
    `/public/directory/directory-categories/${categoryId}/directory-departments`
  )

  console.log({ categoryId, directoryDepartments })

  const handleSubmit = () => {
    const data: any = {
      tag: {
        ...(directoryCategoryDepartmentId && { directoryCategoryDepartmentId }),
        ...(title && { tag: title }),
      },
    }
    console.log({ data })
    mutate(data)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Tag Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      close()
      setRefetchTag(!refetch)
    }
    if (isError) {
      console.log({ error })
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 500 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>Edit Tag</Typography>
              <Box></Box>
            </Box>
            <Box
              sx={{
                px: 5,
                py: { xs: 5, sm: 5 },
                height: 'calc(100vh - 140px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Box>
                <Typography sx={{ fontWeight: 'bold', fontSize: 15, py: 1 }}>
                  Department
                </Typography>
                <Autocomplete
                  options={directoryDepartments?.departments}
                  getOptionLabel={(item: any) => item.title}
                  size='small'
                  onChange={(event, value) => {
                    setDepartmentId(value.id)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size='small'
                      fullWidth
                      //   onChange={(e) => setCategoryId(e.target.value)}
                    />
                  )}
                  defaultValue={directoryDepartments?.departments?.[0]}
                />
              </Box>
              <Box>
                <Typography sx={{ fontWeight: 'bold', fontSize: 15, py: 1 }}>
                  Title
                </Typography>
                <TextField
                  size='small'
                  fullWidth
                  onChange={(e) => setTitle(e.target.value)}
                  defaultValue={tag?.tag}
                  // {...register('sss_no', {
                  //   required: true,
                  // })}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'fixed',
                bottom: 0,
                width: { xs: '100vw', sm: 500 },
                height: 80,
                py: 3,
                px: 5,
              }}
            >
              <Button
                variant='contained'
                sx={{ px: 10, py: 2.5 }}
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CircularProgress sx={{ color: 'white' }} size={20} />
                    <Typography>submitting...</Typography>
                  </Box>
                ) : (
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Save
                  </Typography>
                )}
              </Button>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
