import { Box, Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import Users from '../components/users/Users'
import Roles from '../components/Role/Role'
import { usePost } from '../hooks/usePost'
import { useFetch } from '../hooks/useFetch'
// import Users from '../components/users/Users'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function UserManagement() {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const { mutate, data, isLoading } = usePost(
    '/public/access-control/roles/list'
  )
  const { data: permissions } = useFetch(
    'permissions',
    '/public/access-control/permissions'
  )

  const [cols, setCols] = useState([]) as any

  useEffect(() => {
    const data: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    mutate(data)
  }, [])

  const [refetch, setRefetch] = useState(false)

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab label='Users' {...a11yProps(0)} />
          <Tab label='Role' {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Users />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        {data && permissions && (
          <Roles
            data={data}
            permissions={permissions}
            refetch={refetch}
            setRefetch={setRefetch}
          />
        )}
      </CustomTabPanel>
    </Box>
  )
}
