import { PeopleAlt } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Modal,
  Slide,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { enqueueSnackbar } from 'notistack'
import { usePost } from '../../../hooks/usePost'

export default function AddAppointmentType({
  open,
  close,
  refetch,
}: {
  open: boolean
  close: any
  refetch: any
}) {
  const [title, setTitle] = useState() as any
  const { mutate, isLoading, isError, error, isSuccess } = usePost(
    '/public/appointment/appointment-types'
  )

  const handleSubmit = () => {
    const data: any = {
      title,
    }
    mutate(data)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Appointment Type Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      close()
      refetch()
    }
    if (isError) {
      console.log({ error })
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 500 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>Add</Typography>
              <Box></Box>
            </Box>
            <Box
              sx={{
                px: 5,
                py: { xs: 5, sm: 5 },
                height: 'calc(100vh - 140px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>
                Title
              </Typography>
              <TextField
                size='small'
                fullWidth
                onChange={(e) => setTitle(e.target.value)}
                // {...register('sss_no', {
                //   required: true,
                // })}
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'fixed',
                bottom: 0,
                width: { xs: '100vw', sm: 500 },
                height: 80,
                py: 3,
                px: 5,
              }}
            >
              <Button
                variant='contained'
                sx={{ px: 10, py: 2.5 }}
                onClick={handleSubmit}
              >
                {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CircularProgress sx={{ color: 'white' }} size={20} />
                    <Typography>submitting...</Typography>
                  </Box>
                ) : (
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Add
                  </Typography>
                )}
              </Button>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
