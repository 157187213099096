import * as React from 'react'
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles'
import Box from '@mui/material/Box'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import CssBaseline from '@mui/material/CssBaseline'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import {
  ArrowDownward,
  Article,
  AutoAwesome,
  CalendarMonth,
  Close,
  Dangerous,
  Dashboard,
  Done,
  Download,
  Error,
  Help,
  Info,
  KeyboardArrowDown,
  ListAlt,
  MenuOpen,
  Message,
  Mic,
  Money,
  PeopleAlt,
  PieChart,
  PinDrop,
  QuestionMark,
  QuestionMarkOutlined,
  RemoveRedEye,
  ReplayOutlined,
  Report,
  ReportGmailerrorred,
  Send,
  Settings,
  TrackChanges,
} from '@mui/icons-material'
import {
  Avatar,
  Button,
  Card,
  Modal,
  Popover,
  TextField,
  Tooltip,
} from '@mui/material'
import { NavLink, Outlet, useNavigate } from 'react-router-dom'
import enhance from '../../assets/Enhancelogo.svg'
import { logout } from '../../utils/auth'
import ClientMap from '../Clients/ClientMap'
import AIThinking from '../AI/AIThinking'
import AICard from '../AI/AICard'
import AIAudio from '../AI/AIAudio'
import AIAssistant from '../AI/AIAssistant'

const drawerWidth = 280

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export default function MiniDrawer() {
  const theme = useTheme()
  const [open, setOpen] = React.useState(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [assistantOpen, setAssistantOpen] = React.useState(false)
  const navigate = useNavigate()

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout()
    navigate('/login')
  }

  const openPopover = Boolean(anchorEl)

  function SideItem({ linkTo, icon, text }: any) {
    return (
      <ListItemButton
        component={NavLink}
        to={linkTo}
        // @ts-ignore

        style={({ isActive }: { isActive: any }) =>
          isActive
            ? {
                color: 'white',
                backgroundColor: '#0178DD',
                borderRadius: 10,
                marginLeft: open ? 20 : 5,
                marginRight: open ? 20 : 5,
                marginTop: 5,
                marginBottom: 5,
                boxShadow: '1px 3px 5px 1px lightgray',
              }
            : {
                color: 'black',
                borderRadius: 10,
                marginLeft: open ? 20 : 5,
                marginRight: open ? 20 : 5,
                marginTop: 5,
                marginBottom: 5,
              }
        }
      >
        <Tooltip arrow placement='right' title={text}>
          <ListItemIcon
            sx={{
              color: 'inherit',
              minWidth: 0,
              mr: 3,
              justifyContent: 'center',
            }}
          >
            {icon}
          </ListItemIcon>
        </Tooltip>
        <ListItemText
          sx={{
            opacity: open ? 1 : 0,
          }}
          primary={
            <Typography
              sx={{
                color: 'inherit',
                fontWeight: 'bold',
                fontFamily: 'sans-serif',
                fontSize: 14,
              }}
            >
              {text}
            </Typography>
          }
        />
      </ListItemButton>
    )
  }

  function ListItems() {
    return (
      <Box
        sx={{
          height: '100vh',
          bgcolor: '#F4FAFF',
        }}
      >
        <List
          sx={{
            height: 'calc(100vh - 150px)',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: 0,
            },
          }}
        >
          <SideItem linkTo='/' icon={<Dashboard />} text='Dashboard' />
          <SideItem linkTo='clients' icon={<PeopleAlt />} text='Clients' />
          <SideItem
            linkTo='appointments'
            icon={<CalendarMonth />}
            text='Appointments'
          />
          <SideItem linkTo='goals' icon={<ListAlt />} text='Goals' />
          <SideItem linkTo='directory' icon={<Article />} text='Directory' />
          <SideItem linkTo='documents' icon={<Article />} text='Document Mgt' />
          <SideItem
            linkTo='usermanagement'
            icon={<PeopleAlt />}
            text='User Management'
          />
          <SideItem
            linkTo='configurations'
            icon={<Settings />}
            text='Configurations'
          />
          <SideItem
            linkTo='activitylog'
            icon={<TrackChanges />}
            text='Activity Log'
          />
          {/* <SideItem
            linkTo='alerts'
            icon={<ReportGmailerrorred />}
            text='Alerts'
          /> */}
          <SideItem linkTo='reports' icon={<PieChart />} text='Reports' />
          {/* <SideItem linkTo='billing' icon={<Money />} text='Billing' /> */}
          {/* <SideItem linkTo='messaging' icon={<Message />} text='Messaging' /> */}
          {assistantOpen ? (
            <Box sx={{ pl: 4 }}>
              <Box
                sx={{
                  my: 2,
                  bgcolor: 'white',
                  borderTopLeftRadius: 22,
                  borderBottomLeftRadius: 22,
                  height: 55,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 3,
                  cursor: 'pointer',
                  px: 3,
                }}
                onClick={() => setAssistantOpen(false)}
              >
                <AutoAwesome sx={{ color: '#FFAC33' }} />
                <Typography
                  sx={{ fontWeight: 'bold', fontSize: 17, color: '#FFAC33' }}
                >
                  Austin AI
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box sx={{ px: 4 }}>
              <Box
                sx={{
                  my: 2,
                  bgcolor: '#FFAC33',
                  borderRadius: 10,
                  width: 70,
                  height: 50,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => setAssistantOpen(true)}
              >
                <AutoAwesome sx={{ color: 'white' }} />
              </Box>
            </Box>
          )}
        </List>
        {/* <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            width: drawerWidth,
            bgcolor: 'white',
            borderRight: '1px solid #E0E0E0',
          }}
        >
          <Divider />
          <SideItem linkTo='support' icon={<Help />} text='Support' />
        </Box> */}
      </Box>
    )
  }

  const [openLocation, setOpenLocation] = React.useState(false)

  function CCard() {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 5, mb: 3 }}>
        <Avatar />
        <Typography>Kidus Ababa</Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography sx={{ fontSize: 12 }}>Comprehensive need</Typography>
        </Box>
        <Box
          sx={{
            bgcolor: '#F7F7F7',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            px: 2,
            borderRadius: 5,
            py: 0.5,
          }}
        >
          <Done sx={{ color: '#2699FB' }} />
          <Typography sx={{ color: '#2699FB' }}>Active</Typography>
        </Box>
        <Typography sx={{ fontSize: 12 }}>Dec 5, 2024, 10:01 AM</Typography>
        <RemoveRedEye sx={{ color: '#0178DD' }} />
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position='fixed'
        open={open}
        sx={{
          bgcolor: 'white',
          boxShadow: 'none',
          borderBottom: '1px solid',
          borderColor: '#E0E0E0',
        }}
      >
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: open ? 'flex-end' : 'space-between',
          }}
        >
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon sx={{ color: 'black' }} />
          </IconButton>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
            {/* <Avatar onClick={handleClick} sx={{ mx: 2, cursor: 'pointer' }} /> */}
            <IconButton onClick={() => setOpenLocation(true)}>
              <PinDrop sx={{ color: 'black' }} />
            </IconButton>
            <IconButton onClick={() => navigate('settings')}>
              <Settings sx={{ color: 'black' }} />
            </IconButton>
            <Popover
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
            >
              <Box></Box>
            </Popover>
            <Button
              variant='outlined'
              onClick={() => handleLogout()}
              sx={{ color: 'black', borderRadius: 5, borderColor: '#E9F1F7' }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                Log out
              </Typography>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant='permanent' open={open}>
        <DrawerHeader>
          <Box
            sx={{
              display: open ? 'flex' : 'none',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              mb: 2,
            }}
          >
            <Box
              component='img'
              src={enhance}
              sx={{ width: 60, height: 50, mx: 2, my: 2 }}
            />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <MenuOpen sx={{ width: 28, height: 28 }} />
              )}
            </IconButton>
          </Box>
        </DrawerHeader>

        <ListItems />
      </Drawer>
      <Box
        component='main'
        sx={{
          flexGrow: 1,
          width: 'calc(100vw - 280px)',
        }}
      >
        <DrawerHeader />
        <Outlet />
        <AIAssistant
          open={assistantOpen}
          close={() => setAssistantOpen(false)}
        />
      </Box>
      <ClientMap open={openLocation} close={() => setOpenLocation(false)} />
    </Box>
  )
}
