import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import ehr from '../assets/Frame.png'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import PinInput from 'react-pin-input'
import { Edit } from '@mui/icons-material'
import { usePost } from '../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
export default function VerifyOtp() {
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()
  const address = searchParams.get('address')
  const method = searchParams.get('method')
  console.log({ address, method })

  const { mutate, isLoading, isError, isSuccess, error } = usePost(
    '/public/user/password-reset/confirm'
  )

  const {
    mutate: resend,
    isLoading: isResendLoading,
    isError: isResendError,
    isSuccess: isResendSuccess,
    error: resendError,
  } = usePost('/public/user/password-reset/start')

  const handleComplete = (value: any, index: number) => {
    console.log({ value, index })

    const data: any = {
      ...(address?.includes('@')
        ? { email: address }
        : { phone_number: address }),
      method: method,
      code: value,
    }
    console.log({ data })
    mutate(data)
  }

  const handleResend = () => {
    const data: any = {
      ...(address?.includes('@')
        ? { email: address }
        : { phone_number: address }),
      method: method,
    }
    console.log({ data })
    resend(data)
  }

  useEffect(() => {
    if (isError) {
      console.log({ error })
      enqueueSnackbar(error?.response?.data?.message || 'Network Error', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
    if (isSuccess) {
      navigate(
        `/setnewpassword?address=${
          address?.includes('@') ? address : address?.replace('+', '%2B')
        }&method=${method}`
      )
    }
  }, [isSuccess, isError])

  useEffect(() => {
    if (isResendError) {
      console.log({ resendError })
      enqueueSnackbar(resendError?.response?.data?.message || 'Network Error', {
        preventDuplicate: true,
        variant: 'error',
      })
    }
    if (isResendSuccess) {
      enqueueSnackbar('Verification Code Sent.', {
        preventDuplicate: true,
        variant: 'success',
      })
      const newTime = 198
      setTimeLeft(newTime)
      const endTime: any = Date.now() + newTime * 1000
      localStorage.setItem('endTime', endTime)
    }
  }, [isResendSuccess, isResendError])

  const [timeLeft, setTimeLeft] = useState(() => {
    const savedTime = localStorage.getItem('timeLeft')
    return savedTime ? parseInt(savedTime, 10) : 198 // Start from 198 seconds
  })

  useEffect(() => {
    const endTime: any = localStorage.getItem('endTime')
    const now = Date.now()

    if (endTime) {
      const remainingTime = Math.floor((endTime - now) / 1000)
      if (remainingTime > 0) {
        setTimeLeft(remainingTime)
      } else {
        setTimeLeft(0)
      }
    }

    if (timeLeft > 0) {
      const intervalId = setInterval(() => {
        setTimeLeft((prevTime) => {
          const newTime: any = prevTime - 1
          localStorage.setItem('timeLeft', newTime)
          return newTime
        })
      }, 1000)

      return () => clearInterval(intervalId)
    }
  }, [timeLeft])

  console.log({ timeLeft })

  useEffect(() => {
    if (timeLeft <= 0) {
      localStorage.removeItem('timeLeft')
      localStorage.removeItem('endTime')
    } else {
      const endTime: any = Date.now() + timeLeft * 1000
      localStorage.setItem('endTime', endTime)
    }
  }, [timeLeft])

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60)
    const remainingSeconds = seconds % 60
    return `${String(minutes).padStart(2, '0')}:${String(
      remainingSeconds
    ).padStart(2, '0')}`
  }

  return (
    <Box
      sx={{
        bgcolor: '#FBFBFB',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Grid
        container
        sx={{
          height: '100%',
        }}
      >
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: '90%',
                height: '90%',
                borderRadius: 10,
                bgcolor: '#F7F7F7',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box component='img' src={ehr} sx={{ width: '80%' }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: { xs: '90%', sm: '70%', md: 400 },
                height: '100%',
                justifyContent: 'space-between',
                py: 20,
              }}
            >
              <Box>
                <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                  Verify OTP
                </Typography>
                <Typography>
                  Please enter the otp sent to your phone or email
                </Typography>
              </Box>
              <Box
                sx={{
                  bgcolor: '#F7F7F7',
                  px: 2,
                  // py: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontSize: 17, color: '#0178DD' }}>
                  {address}
                </Typography>
                <IconButton onClick={() => navigate('/resetpassword')}>
                  <Edit sx={{ color: 'primary.main' }} />
                </IconButton>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <PinInput
                  length={address?.includes('@') ? 6 : 4}
                  type='numeric'
                  inputMode='number'
                  inputStyle={{
                    borderColor: 'gray',
                    borderRadius: 10,
                    width: address?.includes('@') ? 50 : 60,
                    height: address?.includes('@') ? 60 : 70,
                    marginRight: address?.includes('@') ? 6 : 15,
                    marginLeft: address?.includes('@') ? 6 : 15,
                  }}
                  inputFocusStyle={{
                    borderColor: 'green',
                  }}
                  onComplete={(value, index) => {
                    handleComplete(value, index)
                  }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
              </Box>
              <Box sx={{ mb: 10 }}>
                <Button
                  variant='contained'
                  // onClick={() => navigate('/setnewpassword')}
                  sx={{ py: 1.5 }}
                  fullWidth
                  disabled
                >
                  {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress sx={{ color: 'white' }} />
                      <Typography>Verifying...</Typography>
                    </Box>
                  ) : (
                    <Typography>Verify</Typography>
                  )}
                </Button>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button
                    sx={{ my: 1 }}
                    onClick={handleResend}
                    disabled={timeLeft > 0}
                  >
                    <Typography sx={{ fontWeight: 'bold' }}>
                      {isResendLoading ? (
                        <Box
                          sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
                        >
                          <CircularProgress sx={{ color: 'white' }} />
                          <Typography>Sending...</Typography>
                        </Box>
                      ) : (
                        <Typography>
                          {timeLeft > 0
                            ? `Resend in ${formatTime(timeLeft)}`
                            : 'Resend'}
                        </Typography>
                      )}
                    </Typography>
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  position: 'absolute',
                  bottom: 20,
                  width: 400,
                }}
              >
                <Typography sx={{ fontSize: 12 }}>
                  @{new Date().getFullYear()} GoodSeedCdc
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'cenetr',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 'bold', color: '#1F7DEC', fontSize: 12 }}
                  >
                    Privacy
                  </Typography>
                  <Divider flexItem orientation='vertical' />
                  <Typography
                    sx={{ fontWeight: 'bold', color: '#1F7DEC', fontSize: 12 }}
                  >
                    Terms
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
