import { Edit, LocationOn, Print, Send } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material'
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers'
import { useEffect, useState } from 'react'
import FileUpload from '../File/FileUpload'
import { useFetch } from '../../hooks/useFetch'
import { useForm } from 'react-hook-form'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import { Worker, Viewer } from '@react-pdf-viewer/core'
import '@react-pdf-viewer/core/lib/styles/index.css'
import { pdfjs } from 'react-pdf'
import EditDocument from './EditDocument'
import { getDocType } from './DocumentTypeChecker'

export default function DocumentDetail({
  open,
  close,
  documentData,
}: {
  open: boolean
  close: any
  documentData: any
}) {
  console.log({ documentData })

  const type = getDocType(documentData?.documentCurrentVersion?.url)
  console.log({ type })

  const [openEditDocument, setOpenEditDocument] = useState(false)
  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', sm: 900 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                Non Disclosure
              </Typography>
              <Box></Box>
            </Box>
            <Divider />
            <Box
              sx={{
                height: 'calc(100vh - 70px)',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                  justifyContent: 'flex-end',
                  py: 1,
                  px: 10,
                }}
              >
                <IconButton onClick={() => setOpenEditDocument(true)}>
                  <Edit sx={{ color: '#3091E3' }} />
                </IconButton>
                <Send sx={{ color: '#3091E3' }} />
                <Print sx={{ color: '#3091E3' }} />
              </Box>
              <Divider />
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Box sx={{ p: 3 }}>
                      <Typography sx={{ color: '#4C607C', fontWeight: 'bold' }}>
                        VERSIONS
                      </Typography>
                      <Box sx={{ py: 1 }}>
                        {documentData?.documentVersions?.map(
                          (item: any, index: number) => {
                            if (index === 0) {
                              return (
                                <Box
                                  sx={{
                                    bgcolor: '#F4FAFF',
                                    width: 70,
                                    height: 35,
                                    border: '1px solid #EDF3F7',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: '#548DDD',
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    V{item?.versionNumber + 1}
                                  </Typography>
                                </Box>
                              )
                            } else {
                              return (
                                <Box
                                  sx={{
                                    bgcolor: '#F4FAFF',
                                    width: 55,
                                    height: 30,
                                    border: '1px solid #EDF3F7',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontWeight: 'bold',
                                      color: '#576B87',
                                    }}
                                  >
                                    V{item?.versionNumber + 1}
                                  </Typography>
                                </Box>
                              )
                            }
                          }
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={10}>
                    <Box
                      sx={{
                        height: 'calc(100vh - 120px)',
                        overflowY: 'auto',
                        '&::-webkit-scrollbar': {
                          width: 0,
                        },
                      }}
                    >
                      <Worker
                        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                      >
                        <Viewer
                          fileUrl={documentData?.documentCurrentVersion?.url}
                        />
                      </Worker>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Card>
          <EditDocument
            open={openEditDocument}
            close={() => setOpenEditDocument(false)}
            documentData={documentData}
          />
        </Box>
      </Slide>
    </Modal>
  )
}
