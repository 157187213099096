import { LocationOn } from '@mui/icons-material'
import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material'
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers'
import { useEffect, useState } from 'react'
import FileUpload from '../File/FileUpload'
import { useFetch } from '../../hooks/useFetch'
import { useForm } from 'react-hook-form'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import axios from 'axios'
import { BASE_URL } from '../../utils/config'
import { authHeader } from '../../utils/auth'
import moment from 'moment'

export default function AddLedger({
  open,
  close,
  clientId,
}: {
  open: boolean
  close: any
  clientId: string
}) {
  const { mutate: fetchTypes, data: types } = usePost(
    '/public/clients/ledgers/types/list'
  )

  const { mutate: fetchSources, data: sources } = usePost(
    '/public/clients/ledgers/sources/list'
  )

  const { data: directories, mutate: fetchDirectories } = usePost(
    '/public/directory/directories/list'
  )

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchDirectories(dataP)
  }, [])

  console.log({ directories })

  const [type, setType] = useState() as any
  const [source, setSource] = useState() as any
  const [amount, setAmount] = useState() as any
  const [documentId, setDocumentId] = useState() as any
  const [imageState, setImageState] = useState<any>()
  const [url, setUrl] = useState() as any
  const [fileUploaded, setFileUploaded] = useState(false)

  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchTypes(datap)
  }, [])

  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchSources(datap)
  }, [])

  console.log({ types, sources })

  const { mutate, isLoading, isError, isSuccess, error } = usePost(
    `/public/clients/${clientId}/ledgers`
  )

  const { mutate: createDoc } = usePost('/public/document/documents')

  useEffect(() => {
    console.log({ imageState })
    if (imageState) {
      axios
        .get(
          `${BASE_URL}/public/document/presigned-url?fileKey=${imageState?.name}`,
          {
            headers: authHeader(),
          }
        )
        .then((res) => setUrl(res?.data?.url))
        .catch((e) => console.log({ e }))
    }
  }, [imageState])

  useEffect(() => {
    if (url) {
      const reader = new FileReader()
      reader.onload = handleReaderLoaded
      reader.readAsArrayBuffer(imageState)
    }
  }, [url])
  const handleReaderLoaded = (event: any) => {
    const binaryData = event.target.result
    console.log({ binaryData })

    axios
      .put(url, binaryData)
      .then((res) => setFileUploaded(true))
      .catch((e) => console.log({ e }))
  }

  useEffect(() => {
    if (fileUploaded) {
      createDoc({
        title: 'Ledger Document',
        document_sub_category_id: '2aefe50d-53ce-4a17-8cab-d8f3cd0512f1',
        document_category_id: 'e220927b-d21d-49d1-a851-e33f92fecbce',
        url: url?.split('?')[0],
        expireDate: moment(new Date())
          .add(10, 'year')
          .toDate()
          ?.toISOString()
          ?.replace('.000', ''),
      } as any)
    }
  }, [fileUploaded])

  const handleSubmit = () => {
    const data: any = {
      client_ledger_type_id: type?.id,
      amount,
      client_ledger_source_id: source?.id,
      // document_id: 'd3176f3d-a66a-4cf0-baec-c5f7464d88c0',
      client_id: clientId,
      document_url: url?.split('?')[0],
    }
    console.log(1111111111, { data })
    mutate(data)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Ledger Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      close()
    }
    if (isError) {
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', sm: 600 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>Add Ledger</Typography>
              <Box> </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                px: { xs: 5, sm: 10 },
                py: 5,
                height: 'calc(100vh - 140px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Box sx={{ my: 2 }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                  Type
                </Typography>
                <Autocomplete
                  options={types?.data?.ledgerTypes || []}
                  getOptionLabel={(item: any) => item.title}
                  size='small'
                  onChange={(event, value) => {
                    setType(value)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size='small' fullWidth />
                  )}
                />
              </Box>
              <Box>
                <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                  Amount
                </Typography>
                <TextField
                  size='small'
                  type='number'
                  fullWidth
                  onChange={(e) => setAmount(e.target.value)}
                />
              </Box>
              <Box sx={{ my: 2 }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                  Source
                </Typography>
                <Autocomplete
                  options={directories?.data?.directories || []}
                  getOptionLabel={(item: any) => item.businessName}
                  size='small'
                  onChange={(event, value) => {
                    setSource(value)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} size='small' fullWidth />
                  )}
                />
              </Box>

              <Box sx={{ my: 2 }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                  Attatch File
                </Typography>
                <FileUpload
                  name='file'
                  type='FILE'
                  size={150}
                  setFileState={setImageState}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'fixed',
                gap: 5,
                bottom: 0,
                width: { xs: '100vw', sm: 600 },
                height: 80,
                pb: 3,
                px: 5,
                // bgcolor: '#F5F5F5',
              }}
            >
              <Button
                variant='contained'
                type='submit'
                onClick={handleSubmit}
                sx={{ px: 2, borderRadius: 2, height: 50, width: 200 }}
              >
                {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CircularProgress sx={{ color: 'white' }} size={20} />
                    <Typography>submitting...</Typography>
                  </Box>
                ) : (
                  <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                    Add
                  </Typography>
                )}
              </Button>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
