import {
  Avatar,
  Box,
  Button,
  Card,
  Modal,
  Slide,
  Typography,
} from '@mui/material'
import { useFetch } from '../../hooks/useFetch'
import { usePost } from '../../hooks/usePost'
import { useEffect, useState } from 'react'
import moment from 'moment'
import {
  Add,
  Article,
  CalendarMonth,
  Edit,
  FormatListBulleted,
  GraphicEq,
} from '@mui/icons-material'

export default function ClientTimeline({
  open,
  close,
  clientId,
}: {
  open: boolean
  close: any
  clientId: string
}) {
  const { data: client, refetch } = useFetch(
    'getoneclient',
    `/public/clients/${clientId}`
  )
  console.log({ client })

  const { data: clientTimeline, mutate: getClientTimmeline } = usePost(
    `/public/clients/${clientId}/timeline`
  )
  console.log({ client, clientTimeline })

  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    getClientTimmeline(datap)
  }, [])

  const [viewType, setViewType] = useState('timeline')

  function TimelineViewComponent({ item, index }: any) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'flex-end', gap: 2, px: 0.8 }}>
        <Box
          sx={{ width: 100, border: '2px solid #E2ECF4', height: 1, mb: 3 }}
        ></Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            position: 'relative',
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              bottom: 60,
              display: index % 2 === 0 ? 'flex' : 'none',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: 150,
                bgcolor: '#F4FAFF',
                p: 1,
                borderRadius: 1.5,
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: 17 }}>
                {item?.itemType === 'CLIENT_TIMELINE_ITEM_TYPE_APPOINTMENT'
                  ? 'Appointment'
                  : 'Intake'}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {item?.itemType === 'CLIENT_TIMELINE_ITEM_TYPE_APPOINTMENT'
                  ? item?.appointment?.appointmentType
                  : 'Intake Data Entered'}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {moment(item?.timestamp).format('ll')}
              </Typography>
            </Box>
            <Box
              sx={{
                height: 170,
                border: '1px solid #DCDCDC',
                width: 0,
              }}
            ></Box>
          </Box>
          <Box
            sx={{
              bgcolor: '#E6F4FF',
              width: 50,
              height: 50,
              borderRadius: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {item?.itemType?.split('_')?.[
              item?.itemType?.split('_')?.length - 1
            ] === 'APPOINTMENT' ? (
              <CalendarMonth sx={{ color: '#656565', width: 30, height: 30 }} />
            ) : (
              <Article sx={{ color: '#656565', width: 30, height: 30 }} />
            )}
          </Box>
          <Box
            sx={{
              position: 'absolute',
              top: 60,
              display: index % 2 === 0 ? 'none' : 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                height: 170,
                border: '1px solid #DCDCDC',
                width: 0,
              }}
            ></Box>
            <Box
              sx={{
                width: 150,
                bgcolor: '#F4FAFF',
                p: 1,
                borderRadius: 1.5,
              }}
            >
              <Typography sx={{ fontWeight: 'bold', fontSize: 17 }}>
                {item?.itemType === 'CLIENT_TIMELINE_ITEM_TYPE_APPOINTMENT'
                  ? 'Appointment'
                  : 'Intake'}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {item?.itemType === 'CLIENT_TIMELINE_ITEM_TYPE_APPOINTMENT'
                  ? item?.appointment?.appointmentType
                  : 'Intake'}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {moment(item?.timestamp).format('ll')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }

  function ListViewComponent({ item, index }: any) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 3, mb: 5 }}>
        <Typography sx={{ width: 110 }}>
          {moment(item?.timestamp).format('ll')}
        </Typography>
        <Box sx={{ width: 100, border: '2px solid #E2ECF4', height: 1 }}></Box>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <Box
            sx={{
              bgcolor: '#E6F4FF',
              width: 50,
              height: 50,
              borderRadius: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {item?.itemType?.split('_')?.[
              item?.itemType?.split('_')?.length - 1
            ] === 'APPOINTMENT' ? (
              <CalendarMonth sx={{ color: '#656565', width: 30, height: 30 }} />
            ) : (
              <Article sx={{ color: '#656565', width: 30, height: 30 }} />
            )}
          </Box>
          <Box
            sx={{
              // border: '1px solid gray',
              // height: 150,
              // width: 1,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              border: '1px dashed #E3E3E3',
              left: '50%',
              transform: 'translate(-50%, -0%)',
              height: 43,
            }}
          ></Box>
        </Box>

        <Box sx={{ border: '2px solid #E2ECF4', height: 1, width: 100 }}></Box>
        <Box sx={{ bgcolor: '#F4FAFF', borderRadius: 10, px: 2, py: 0.7 }}>
          <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>
            {item?.itemType === 'CLIENT_TIMELINE_ITEM_TYPE_APPOINTMENT'
              ? 'Appointment'
              : 'Intake'}
          </Typography>
          <Typography sx={{ fontSize: 13 }}>
            {item?.itemType === 'CLIENT_TIMELINE_ITEM_TYPE_APPOINTMENT'
              ? item?.appointment?.appointmentType
              : 'Intake Data Entered'}
          </Typography>
        </Box>
      </Box>
    )
  }

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', sm: '80vw', md: '70vw' },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                Client Timeline
              </Typography>
              <Box></Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderBottom: '1px solid #E2E2E2',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 4,
                  px: 5,
                  py: 2,
                }}
              >
                <Avatar src={client?.client?.profilePictureUrl} />
                <Box>
                  <Typography sx={{ fontSize: 12 }}>
                    <span style={{ fontWeight: 'bold' }}>Name: </span>
                    {(client?.client?.firstName || '') +
                      ' ' +
                      (client?.client?.middleName || '') +
                      ' ' +
                      (client?.client?.lastName || '')}
                  </Typography>
                  <Typography sx={{ fontSize: 12 }}>
                    <span style={{ fontWeight: 'bold' }}>DOB: </span>
                    {moment(client?.client?.dob).format('l')}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{ display: 'flex', alignItems: 'center', gap: 2, px: 5 }}
              >
                <Box
                  sx={{
                    px: 2,
                    py: 0.5,
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    bgcolor: viewType === 'timeline' ? '#F7F7F7' : '',
                    borderRadius: 1,
                    cursor: 'pointer',
                  }}
                  onClick={() => setViewType('timeline')}
                >
                  <GraphicEq
                    sx={{
                      color: viewType === 'timeline' ? '#0178DD' : 'black',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 15,
                      color: viewType === 'timeline' ? '#0178DD' : 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    Timeline View
                  </Typography>
                </Box>
                <Box
                  sx={{
                    px: 2,
                    py: 0.5,
                    display: 'flex',
                    gap: 1,
                    alignItems: 'center',
                    bgcolor: viewType !== 'timeline' ? '#F7F7F7' : '',
                    borderRadius: 1,
                    cursor: 'pointer',
                  }}
                  onClick={() => setViewType('list')}
                >
                  <FormatListBulleted
                    sx={{
                      color: viewType !== 'timeline' ? '#0178DD' : 'black',
                    }}
                  />
                  <Typography
                    sx={{
                      fontSize: 15,
                      color: viewType !== 'timeline' ? '#0178DD' : 'black',
                      fontWeight: 'bold',
                    }}
                  >
                    List View
                  </Typography>
                </Box>
                <Button
                  variant='contained'
                  sx={{ borderRadius: 7, height: 46 }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Add />
                    <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                      Add Event Log
                    </Typography>
                  </Box>
                </Button>
              </Box>
            </Box>
            {viewType === 'timeline' ? (
              <Box
                sx={{
                  height: 'calc(100vh - 133px)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {clientTimeline?.data?.lineItems?.map(
                  (item: any, index: number) => (
                    <TimelineViewComponent item={item} index={index} />
                  )
                )}
              </Box>
            ) : (
              <Box
                sx={{
                  height: 'calc(100vh - 133px)',
                  py: 6,
                  px: 10,
                }}
              >
                {clientTimeline?.data?.lineItems?.map(
                  (item: any, index: number) => (
                    <ListViewComponent item={item} index={index} />
                  )
                )}
              </Box>
            )}
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
