import { Box, Modal, Typography } from '@mui/material'
import FileUpload from '../../File/FileUpload'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export default function IntakePdf({
  open,
  close,
}: {
  open: boolean
  close: any
}) {
  const [fileState, setFileState] = useState<File>()
  const navigate = useNavigate()

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Box sx={{ width: '100vw', height: '100vh', bgcolor: 'white' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            bgcolor: '#FBFBFB',
            height: 60,
            borderBottom: '1px solid',
            borderBottomColor: '#F4F4F4',
            px: 5,
          }}
        >
          <Box
            sx={{
              borderRadius: 2,
              bgcolor: '#F0F0F0',
              py: 1,
              px: 4,
              cursor: 'pointer',
            }}
            onClick={close}
          >
            <Typography variant='body1' sx={{ fontWeight: 'bold' }}>
              Back
            </Typography>
          </Box>
          <Typography sx={{ fontWeight: 'bold' }}>Upload Pdf</Typography>
          <Box></Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: 'calc(100vh - 60px)',
          }}
        >
          <FileUpload
            name='pdfform'
            type='FILE'
            setFileState={setFileState}
            size={400}
            // isLoading={isLoading}
          />
        </Box>
      </Box>
    </Modal>
  )
}
