import {
  AttachFile,
  Close,
  Draw,
  LocationOn,
  Person,
} from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
  createFilterOptions,
} from '@mui/material'
import { DatePicker, DateTimePicker, TimePicker } from '@mui/x-date-pickers'
import { useEffect, useState } from 'react'
import FileUpload from '../File/FileUpload'
import { useFetch } from '../../hooks/useFetch'
import { useForm } from 'react-hook-form'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import axios from 'axios'
import usePlacesAutocomplete from 'use-places-autocomplete'
import { BASE_URL } from '../../utils/config'
import { authHeader } from '../../utils/auth'

export default function CreateMessage({
  open,
  close,
  refetch,
  setRefetch,
}: {
  open: boolean
  close: any
  refetch?: boolean
  setRefetch?: any
}) {
  const [fileUrl, setFileUrl] = useState<string>()
  const [fileState, setFileState] = useState<any>()
  const [url, setUrl] = useState() as any
  const [selectedPlatforms, setSelectedPlatforms] = useState<string[]>([])

  console.log({ fileUrl })

  useEffect(() => {
    console.log({ fileState })
    if (fileState) {
      axios
        .get(
          `${BASE_URL}/public/document/presigned-url?fileKey=${fileState?.name}`,
          {
            headers: authHeader(),
          }
        )
        .then((res) => setUrl(res?.data?.url))
        .catch((e) => console.log({ e }))
    }
  }, [fileState])
  useEffect(() => {
    if (url) {
      const reader = new FileReader()
      reader.onload = handleReaderLoaded
      reader.readAsArrayBuffer(fileState)
    }
  }, [url])
  const handleReaderLoaded = (event: any) => {
    const binaryData = event.target.result
    console.log({ binaryData })

    axios
      .put(url, binaryData)
      .then((res) => setFileUrl(url?.split('?')?.[0]))
      .catch((e) => console.log({ e }))
  }

  //   useEffect(() => {
  //     if (isSuccess) {
  //       enqueueSnackbar('Message Sent Successfully', {
  //         variant: 'success',
  //         preventDuplicate: true,
  //       })
  //       setRefetch(!refetch)
  //       close()
  //     }
  //     if (isError) {
  //       console.log({ error })
  //       enqueueSnackbar(
  //         error?.response?.data?.message || error?.message || 'Network Error',
  //         { variant: 'error', preventDuplicate: true }
  //       )
  //     }
  //   }, [isSuccess, isError])

  //   console.log({ appointmentTypes, participants })

  type FormValues = {}

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {},
    mode: 'onBlur',
  })

  const onSubmit = (data: any) => {}

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue: setPlacesValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    callbackName: 'GO_PLACES',
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  })

  const platforms = [
    { name: 'Text', value: 'TEXT' },
    { name: 'Email', value: 'EMAIL' },
    { name: 'Fax', value: 'FAX' },
  ]

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', sm: '80vw', md: 500 },
              height: '100vh',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  bgcolor: '#FBFBFB',
                  height: 60,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  px: 3,
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#EDEDED',
                    px: 3,
                    py: 0.8,
                    cursor: 'pointer',
                    borderRadius: 2,
                  }}
                  onClick={close}
                >
                  Back
                </Box>
                <Typography sx={{ fontWeight: 'bold' }}>New Message</Typography>
                <Box> </Box>
              </Box>
              <Divider />
              <Box
                sx={{
                  px: { xs: 5 },
                  py: 5,
                  height: 'calc(100vh - 140px)',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                }}
              >
                <Box>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Person(s)
                  </Typography>
                  <Autocomplete
                    //   options={participants?.data?.participants || []}
                    options={[]}
                    getOptionLabel={(item: any) => item.name}
                    size='small'
                    multiple
                    //   onChange={(event, value) => {
                    //     setSelectedParticipants(value)
                    //   }}
                    renderTags={(value, getTagProps) => (
                      <Box
                        sx={{
                          display: 'flex',
                          gap: 1,
                          alignItems: 'center',
                          flexWrap: 'wrap',
                        }}
                      >
                        {value.map((option, index) => (
                          <Box
                            sx={{
                              bgcolor: '#F4FAFF',
                              display: 'flex',
                              alignItems: 'center',
                              gap: 1,
                              border: '1px solid #ECEDED',
                              px: 1,
                              py: 0.5,
                              borderRadius: 1.5,
                            }}
                          >
                            <Avatar sx={{ width: 20, height: 20 }} />
                            <Typography
                              sx={{ fontWeight: 'bold', fontSize: 12 }}
                            >
                              {option?.name?.split(' ')[0]}
                            </Typography>
                            <IconButton
                              sx={{
                                border: '1px solid gray',
                                width: 16,
                                height: 16,
                              }}
                              onClick={() =>
                                getTagProps({ index })?.onDelete(option)
                              }
                            >
                              <Close
                                sx={{
                                  width: 13,
                                  height: 13,
                                  color: 'gray',
                                }}
                              />
                            </IconButton>
                          </Box>
                        ))}
                      </Box>
                    )}
                    renderOption={(props, option) => (
                      <Box
                        component='li'
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 6,
                          width: '100%',
                        }}
                        {...props}
                      >
                        <Avatar sx={{ width: 45, height: 45 }} />

                        <Typography sx={{ width: 200 }}>
                          {option?.name}
                        </Typography>
                        <Box
                          sx={{
                            bgcolor: '#F4FAFF',
                            px: 1,
                            borderRadius: 5,
                          }}
                        >
                          <Typography
                            sx={{
                              color: '#4D72A6',
                              textAlign: 'center',
                              fontWeight: 'bold',
                              fontSize: 12,
                            }}
                          >
                            Therapist
                          </Typography>
                        </Box>
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField {...params} size='small' fullWidth />
                    )}
                  />
                </Box>
                <Box sx={{ py: 3 }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Platform
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 3,
                      py: 1,
                    }}
                  >
                    {platforms?.map((item) => (
                      <Box
                        sx={{
                          border: selectedPlatforms?.includes(item?.value)
                            ? '1px solid #4099E5'
                            : '1px solid #EAF1F7',
                          py: 1,
                          px: 2,
                          borderRadius: 1,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          minWidth: 60,
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          setSelectedPlatforms((platform) => {
                            if (platform.includes(item?.value)) {
                              return platform.filter((p) => p !== item?.value)
                            }
                            return [...platform, item?.value]
                          })
                        }
                      >
                        <Typography
                          sx={{
                            color: selectedPlatforms?.includes(item?.value)
                              ? '#4099E5'
                              : '#BBBBBB',
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                        >
                          {item?.name}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Template
                  </Typography>

                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      width: '100%',
                    }}
                  >
                    <Autocomplete
                      options={[]}
                      getOptionLabel={(option) => option}
                      // onChange={(event, value) => {
                      //   setBaseClientLocation(value)
                      // }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size='small'
                          // onChange={(e) => setPlacesValue(e.target.value)}
                        />
                      )}
                      fullWidth
                    />
                  </Box>
                </Box>
                <Box sx={{ my: 2 }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Subject
                  </Typography>

                  <TextField size='small' fullWidth />
                </Box>
                <Box sx={{ my: 3 }}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Message
                  </Typography>

                  <Box
                    sx={{
                      border: '1px solid #C4C4C4',
                      height: 168,
                      borderRadius: 2,
                    }}
                  >
                    <textarea
                      style={{
                        resize: 'none',
                        width: '100%',
                        height: 125,
                        borderRadius: 10,
                        border: 'none',
                        outline: 'none',
                        padding: 2,
                      }}
                    />
                    <Box
                      sx={{
                        bgcolor: '#C4C4C4',
                        height: 35,
                        width: '100%',
                        borderBottomRightRadius: 8,
                        borderBottomLeftRadius: 8,
                        px: 3,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        gap: 3,
                      }}
                    >
                      <IconButton>
                        <Person sx={{ color: '#0178DD' }} />
                      </IconButton>
                      <IconButton>
                        <Draw sx={{ color: '#0178DD' }} />
                      </IconButton>
                      <IconButton>
                        <AttachFile sx={{ color: '#0178DD' }} />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  gap: 5,
                  bottom: 0,
                  width: { xs: '100vw', sm: '80vw', md: 500 },
                  height: 80,
                  pb: 3,
                  px: 10,
                  // bgcolor: '#F5F5F5',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 120,
                    height: 55,
                    bgcolor: '#EFEFEF',
                    borderRadius: 2,
                    cursor: 'pointer',
                  }}
                  onClick={close}
                >
                  <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                    Save as Draft
                  </Typography>
                </Box>
                <Button
                  variant='contained'
                  type='submit'
                  // onClick={handleSubmit}
                  sx={{ px: 2, borderRadius: 2, height: 50 }}
                >
                  {/* {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress sx={{ color: 'white' }} size={20} />
                      <Typography>submitting...</Typography>
                    </Box>
                  ) : ( */}
                  <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                    Send Message
                  </Typography>
                  {/* )} */}
                </Button>
              </Box>
            </form>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
