import {
  Add,
  AddCard,
  Delete,
  Edit,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ManageAccounts,
  Message,
  Search,
  Send,
  TagFaces,
  Visibility,
} from '@mui/icons-material'
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import PdfImage from '../../assets/pdfimage.svg'
import AddDocument from '../Documents/AddDocument'
import DocumentDetail from '../Documents/DocumentDetail'
import { usePost } from '../../hooks/usePost'
import axios from 'axios'
import AddDocumentCategory from '../Configurations/Document/AddDocumentCategory'
import moment from 'moment'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function CustomTabPanelVertical(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

function a11yPropsVertical(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function ClientDocuments({ client_id }: { client_id: string }) {
  const [value, setValue] = useState(0)
  const [documentId, setDocumentId] = useState() as any

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValueVertical(0)
    setValue(newValue)
  }
  const [valueVertical, setValueVertical] = useState(0)

  const handleChangeVertical = (
    event: React.SyntheticEvent,
    newValue: number
  ) => {
    setValueVertical(newValue)
  }

  const [categoryId, setCategoryId] = useState() as any
  const [subcategoryId, setSubCategoryId] = useState() as any
  const [openAddDocument, setOpenAddDocument] = useState(false)
  const [openDetail, setOpenDetail] = useState(false)
  const [openNewDocuemntCategory, setOpenNewDocumentCategory] = useState(false)
  const [documentSubCategoryId, setDocumentSubCategoryId] = useState() as any

  const [refetchDocumentCategory, setRefetchDocumentCategory] = useState(false)
  const { data: documentCategories, mutate: fetchDocumentCategories } = usePost(
    '/public/document/document_categories/list'
  )

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchDocumentCategories(dataP)
  }, [refetchDocumentCategory])

  const { data: documents, mutate: fetchDocuments } = usePost(
    `/public/document/client_documents/${client_id}/list`
  )

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchDocuments(dataP)
  }, [fetchDocuments])

  console.log({ documents })

  const types = ['All', 'Client', 'Company']

  const subDocuments = documents?.data?.documents?.filter(
    (item: any) => item?.documentSubCategoryId === documentSubCategoryId
  )

  const documentsList = documentSubCategoryId
    ? subDocuments
    : documents?.data?.documents

  return (
    <Box sx={{ pt: 3 }}>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          pt: 2,
          display: 'flex',
          justifyContent: 'space-between',
          //   alignItems: 'center',
          px: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 2,
          }}
        >
          <Box sx={{ py: 1 }}>
            <TextField
              InputProps={{
                startAdornment: <Search />,
                sx: {
                  '&.MuiOutlinedInput-root': {
                    borderRadius: 8,
                    height: 45,
                    bgcolor: '#E6F4FF ',
                  },
                },
              }}
              placeholder='Search'
              //   sx={{ my: 1 }}
            />
          </Box>
        </Box>
        <Box sx={{ height: '100%', px: 3 }}>
          <Button
            variant='contained'
            sx={{ borderRadius: 5, py: 1 }}
            onClick={() => setOpenAddDocument(true)}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Add />
              <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                New Document
              </Typography>
            </Box>
          </Button>
        </Box>
      </Box>

      {types?.map((item, index) => (
        <CustomTabPanel value={value} index={index}>
          <Box
            sx={{
              display: 'flex',
              height: 'calc(100vh - 170px)',
            }}
          >
            <Grid container sx={{ height: '100%' }}>
              <Grid item xs={2}>
                <Box
                  sx={{
                    pt: 2,
                    px: 3,
                  }}
                >
                  {documentCategories?.data?.documentCategories?.map(
                    (item: any) => (
                      <Box>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            py: 1,
                            cursor: 'pointer',
                          }}
                          onClick={() => {
                            if (categoryId === item?.id) {
                              setCategoryId()
                              setSubCategoryId()
                            } else {
                              setCategoryId(item?.id)
                            }
                          }}
                        >
                          <Typography sx={{ fontWeight: 'bold', fontSize: 19 }}>
                            {item?.name?.slice(0, 11)}
                          </Typography>
                          {categoryId === item?.id ? (
                            <KeyboardArrowUp sx={{ width: 35, height: 35 }} />
                          ) : (
                            <KeyboardArrowDown sx={{ width: 35, height: 35 }} />
                          )}
                        </Box>
                        {categoryId === item?.id && (
                          <Box>
                            {item?.documentSubCategories?.map((elt: any) => (
                              <Box
                                sx={{
                                  cursor: 'pointer',
                                  '&:hover': { bgcolor: '#F7F7F7' },
                                  py: 0.5,
                                  px: 1,
                                  //   bgcolor:
                                  //     elt?.id === departmentId ? '#F7F7F7' : 'white',
                                }}
                                onClick={() =>
                                  setDocumentSubCategoryId(elt?.id)
                                }
                              >
                                <Typography
                                  sx={{
                                    color: '#A0AABE',
                                    fontSize: 12,
                                    fontWeight: 'bold',
                                  }}
                                >
                                  {elt?.name}
                                </Typography>
                              </Box>
                            ))}
                          </Box>
                        )}
                      </Box>
                    )
                  )}
                  <Button onClick={() => setOpenNewDocumentCategory(true)}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          border: '2px solid blue',
                          borderRadius: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Add />
                      </Box>
                      <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                        Add Category
                      </Typography>
                    </Box>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={10} sx={{ px: 7, py: 3 }}>
                {documentsList?.map((item: any) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 5,
                      my: 3,
                    }}
                  >
                    <Box component='img' src={PdfImage} />
                    <Box sx={{ width: 350 }}>
                      <Box
                        sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
                      >
                        <Typography
                          sx={{
                            fontSize: 13,
                            color: '#B4BCCC',
                            fontWeight: 'bold',
                          }}
                        >
                          #K01B2
                        </Typography>
                        <Box
                          sx={{
                            bgcolor: '#6781A8',
                            p: 0.25,
                            borderRadius: '100%',
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: 13,
                            color: '#B4BCCC',
                            fontWeight: 'bold',
                          }}
                        >
                          Kidus Abba
                        </Typography>
                        <Box
                          sx={{
                            bgcolor: '#6781A8',
                            p: 0.25,
                            borderRadius: '100%',
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: 13,
                            color: '#B4BCCC',
                            fontWeight: 'bold',
                          }}
                        >
                          0912345678
                        </Typography>
                        <Box
                          sx={{
                            bgcolor: '#6781A8',
                            p: 0.25,
                            borderRadius: '100%',
                          }}
                        />
                        <Typography
                          sx={{
                            fontSize: 13,
                            color: '#B4BCCC',
                            fontWeight: 'bold',
                          }}
                        >
                          {moment(item?.createdAt)?.format('l')}
                        </Typography>
                      </Box>
                      <Typography sx={{ color: '#495377', fontWeight: 'bold' }}>
                        {item?.title} ({`V${item?.documentVersions?.length}`})
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        flexWrap: 'wrap',
                        width: 150,
                      }}
                    >
                      {item?.programs?.map((elt: any) => (
                        <Box
                          sx={{
                            bgcolor: '#E6F4FF',
                            px: 1,
                            py: 0.2,
                            borderRadius: 1,
                          }}
                        >
                          <Typography
                            sx={{
                              color: '#92999F',
                              fontSize: 12,
                              fontWeight: 'bold',
                            }}
                          >
                            {elt?.title}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Typography
                        sx={{
                          color: '#8EA1BE',
                          fontSize: 14,
                          fontWeight: 'bold',
                        }}
                      >
                        Added By
                      </Typography>
                      <Avatar sx={{ width: 30, height: 30 }} />
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                      <IconButton
                        onClick={() => {
                          setDocumentId(item?.id)
                          setOpenDetail(true)
                        }}
                      >
                        <Visibility sx={{ color: '#3376D6' }} />
                      </IconButton>
                      {/* <Edit sx={{ color: '#3376D6' }} />
                        <Send sx={{ color: '#3376D6' }} /> */}
                    </Box>
                  </Box>
                ))}
              </Grid>
            </Grid>
          </Box>
        </CustomTabPanel>
      ))}
      <AddDocumentCategory
        open={openNewDocuemntCategory}
        close={() => setOpenNewDocumentCategory(false)}
        refetch={refetchDocumentCategory}
        setRefetch={setRefetchDocumentCategory}
      />
      <AddDocument
        open={openAddDocument}
        close={() => setOpenAddDocument(false)}
      />
      {documentId && (
        <DocumentDetail
          open={openDetail}
          close={() => setOpenDetail(false)}
          documentData={documents?.data?.documents?.find(
            (item: any) => item?.id === documentId
          )}
        />
      )}
    </Box>
  )
}
