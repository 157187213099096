import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  Slider,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import FileUpload from '../File/FileUpload'
import { DatePicker } from '@mui/x-date-pickers'
import { Add, Close } from '@mui/icons-material'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'

function valuetext(value: number) {
  return `${value}`
}

export default function GenerateReport({
  open,
  close,
}: {
  open: boolean
  close: any
}) {
  const items = [
    'MCP ID',
    'Languages',
    'First Name',
    'Middle Name',
    'Last Name',
    'Date of Birth',
    'Program',
    'Age',
    'Address',
    'Phone',
    'Emploment Status',
    'Housing Status',
    'Medications',
    'Doctor',
    'Note',
    'Email',
    'OutReach',
    'Case Manager',
    'Health Insurance',
    'Source of Income',
    'Therapist',
    'Physical Disability',
    'Mental Health',
  ]

  const [reportType, setReportType] = useState('custom')

  const { mutate: listFields, data: fields } = usePost(
    '/public/reporting/fields'
  )

  const { mutate: getPrograms, data: programs } = usePost(
    '/public/configs/programs/list'
  )

  const { mutate: getDirectories, data: directories } = usePost(
    '/public/directory/directories/list'
  )

  const { mutate: getCaseManagers, data: caseManagers } = usePost(
    '/public/user/users/list'
  )

  const { mutate, isLoading, isError, isSuccess, error } = usePost(
    '/public/reporting/generate'
  )

  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
      start_date: '2022-07-25T21:00:00Z',
      end_date: new Date()?.toISOString()?.split('.')[0] + 'Z',
    }
    listFields(datap)
  }, [])

  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    getPrograms(datap)
  }, [])
  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    getDirectories(datap)
  }, [])

  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    getCaseManagers(datap)
  }, [])

  console.log({ fields, programs, directories, caseManagers })

  const [selectedFields, setSelectedFields] = useState<String[]>([])
  const [startDate, setStartDate] = useState() as any
  const [endDate, setEndDate] = useState() as any
  const [program_ids, setProgramIds] = useState<any[]>([])
  const [directory_ids, setDirectoryIds] = useState<any[]>([])
  const [health_insurance_ids, setHealthInsuranceIds] = useState<any[]>([])
  const [case_manager_ids, setCaseManagerIds] = useState<any[]>([])
  const [gender, setGender] = useState<String>('')
  const [age_range, setAgeRange] = useState<number[]>([20, 37])

  const handleSubmit = () => {
    const data: any = {
      field_ids: selectedFields,
      start_date: startDate?.$d?.toISOString()?.replace('.000', ''),
      end_date: endDate?.$d?.toISOString()?.replace('.000', ''),
      program_ids: program_ids?.map((item: any) => {
        return item?.id
      }),
      directory_ids: directory_ids?.map((item: any) => {
        return item?.id
      }),
      health_insurance_ids,
      case_manager_ids: case_manager_ids?.map((item: any) => {
        return item?.id
      }),
      gender,
      age_range: {
        from: age_range[0],
        to: age_range[1],
      },
    }

    console.log({ data })
    mutate(data)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Report Generated Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      close()
    }
    if (isError) {
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: reportType === 'custom' ? 1200 : 600 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 50,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                Generate Report
              </Typography>
              <Box> </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                p: 6,
                height: 'calc(100vh - 140px)',
                bgcolor: '#F5FAFF',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  pb: 3,
                }}
              >
                <RadioGroup
                  aria-labelledby='demo-radio-buttons-group-label'
                  defaultValue='mif'
                  name='radio-buttons-group'
                  row
                  value={`${reportType}`}
                  onChange={(e) => {
                    setReportType(e.target.value)
                  }}
                  sx={{ gap: 10 }}
                >
                  <FormControlLabel
                    value='custom'
                    control={<Radio />}
                    label='Custom'
                    slotProps={{
                      typography: {
                        sx: {
                          fontSize: 20,
                        },
                      },
                    }}
                  />
                  <FormControlLabel
                    value='mif'
                    control={<Radio />}
                    label='MIF'
                    slotProps={{
                      typography: {
                        sx: {
                          fontSize: 20,
                        },
                      },
                    }}
                  />
                </RadioGroup>
              </Box>
              {reportType === 'custom' ? (
                <Box>
                  <Grid container spacing={3} sx={{ height: '100%' }}>
                    <Grid item xs={12} md={7.5}>
                      <Card
                        sx={{
                          width: '100%',
                          bgcolor: 'white',
                          boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                          height: '100%',
                          borderRadius: 4,
                          p: 7,
                        }}
                      >
                        <Box>
                          <Typography
                            variant='subtitle1'
                            sx={{ fontWeight: 'bold' }}
                          >
                            Choose Report Display Fields
                          </Typography>
                          <Box sx={{ my: 4 }}>
                            <Grid container spacing={2} columns={12}>
                              {fields?.data?.field?.map((item: any) => {
                                if (item?.name !== '') {
                                  return (
                                    <Grid item xs={6} sm={4} md={4}>
                                      <Box
                                        sx={{
                                          border: '1px solid #F1F1F1',
                                          px: 1,
                                          borderRadius: 1,
                                        }}
                                      >
                                        <FormControlLabel
                                          slotProps={{
                                            typography: {
                                              sx: {
                                                fontSize: 12,
                                              },
                                            },
                                          }}
                                          name={item?.name}
                                          label={item?.name}
                                          onChange={(e) => {
                                            if (
                                              selectedFields?.includes(item?.id)
                                            ) {
                                              setSelectedFields(
                                                (state: any) => [
                                                  ...state.filter(
                                                    (fieldid: any) =>
                                                      fieldid !== item?.id
                                                  ),
                                                ]
                                              )
                                            } else {
                                              setSelectedFields(
                                                (state: any) => [
                                                  ...state,
                                                  item?.id,
                                                ]
                                              )
                                            }
                                          }}
                                          control={<Checkbox />}
                                        />
                                      </Box>
                                    </Grid>
                                  )
                                }
                              })}
                            </Grid>
                          </Box>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item xs={12} md={4.5} sx={{ height: '100%' }}>
                      <Box sx={{ height: '100%', px: 2 }}>
                        {/* <Box>
                          <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                            Template
                          </Typography>
                          <TextField
                            size='small'
                            fullWidth
                            sx={{ bgcolor: 'white' }}
                          />
                        </Box> */}
                        <Box sx={{ my: 4 }}>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                sx={{ fontWeight: 'bold', fontSize: 13 }}
                              >
                                Start Date
                              </Typography>
                              <DatePicker
                                sx={{ width: '100%' }}
                                slotProps={{
                                  textField: {
                                    size: 'small',
                                    sx: { bgcolor: 'white' },
                                  },
                                }}
                                onChange={(newValue) => setStartDate(newValue)}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                              <Typography
                                sx={{ fontWeight: 'bold', fontSize: 13 }}
                              >
                                End Date
                              </Typography>
                              <DatePicker
                                sx={{ width: '100%' }}
                                slotProps={{
                                  textField: {
                                    size: 'small',
                                    sx: { bgcolor: 'white' },
                                  },
                                }}
                                onChange={(newValue) => setEndDate(newValue)}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                        <Box sx={{ my: 4 }}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                            Programs
                          </Typography>
                          <Autocomplete
                            multiple
                            options={programs?.data?.programs || []}
                            getOptionLabel={(item: any) => item?.name}
                            size='small'
                            onChange={(event, value) => {
                              setProgramIds(value)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size='small'
                                fullWidth
                                sx={{ bgcolor: 'white' }}
                                // onChange={(e) => setLanguage(e.target.value)}
                              />
                            )}
                          />
                        </Box>
                        <Box sx={{ my: 4 }}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                            Directories
                          </Typography>
                          <Autocomplete
                            multiple
                            options={directories?.data?.directories || []}
                            getOptionLabel={(item: any) =>
                              item?.firstName + ' ' + item?.lastName
                            }
                            size='small'
                            onChange={(event, value) => {
                              setDirectoryIds(value)
                            }}
                            renderTags={(value, getTagProps) => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 1,
                                  alignItems: 'center',
                                  flexWrap: 'wrap',
                                }}
                              >
                                {value.map((option, index) => (
                                  <Box
                                    sx={{
                                      bgcolor: '#F4FAFF',
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                      border: '1px solid #ECEDED',
                                      px: 1,
                                      py: 0.5,
                                      borderRadius: 1.5,
                                    }}
                                  >
                                    <Avatar sx={{ width: 20, height: 20 }} />
                                    <Typography
                                      sx={{ fontWeight: 'bold', fontSize: 12 }}
                                    >
                                      {option?.firstName}
                                    </Typography>
                                    <IconButton
                                      sx={{
                                        border: '1px solid gray',
                                        width: 16,
                                        height: 16,
                                      }}
                                      onClick={() =>
                                        getTagProps({ index })?.onDelete(option)
                                      }
                                    >
                                      <Close
                                        sx={{
                                          width: 13,
                                          height: 13,
                                          color: 'gray',
                                        }}
                                      />
                                    </IconButton>
                                  </Box>
                                ))}
                              </Box>
                            )}
                            renderOption={(props, option) => (
                              <Box
                                component='li'
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 6,
                                  width: '100%',
                                }}
                                {...props}
                              >
                                <Avatar
                                  sx={{ width: 45, height: 45 }}
                                  src={option?.imageUrl}
                                />

                                <Typography sx={{ width: 200 }}>
                                  {option?.firstName + ' ' + option?.lastName}
                                </Typography>
                                <Box
                                  sx={{
                                    bgcolor: '#F4FAFF',
                                    px: 1,
                                    borderRadius: 5,
                                    py: 0.5,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: '#4D72A6',
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      fontSize: 11,
                                    }}
                                  >
                                    {option?.tags?.[0]?.tag}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size='small'
                                fullWidth
                                sx={{ bgcolor: 'white' }}
                              />
                            )}
                          />
                        </Box>
                        <Box sx={{ my: 4 }}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                            Case Manager
                          </Typography>
                          <Autocomplete
                            multiple
                            options={caseManagers?.data?.users || []}
                            getOptionLabel={(item: any) =>
                              item?.firstName +
                              ' ' +
                              item?.middleName +
                              ' ' +
                              item?.lastName
                            }
                            size='small'
                            onChange={(event, value) => {
                              setCaseManagerIds(value)
                            }}
                            renderTags={(value, getTagProps) => (
                              <Box
                                sx={{
                                  display: 'flex',
                                  gap: 1,
                                  alignItems: 'center',
                                  flexWrap: 'wrap',
                                }}
                              >
                                {value.map((option, index) => (
                                  <Box
                                    sx={{
                                      bgcolor: '#F4FAFF',
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 1,
                                      border: '1px solid #ECEDED',
                                      px: 1,
                                      py: 0.5,
                                      borderRadius: 1.5,
                                    }}
                                  >
                                    <Avatar sx={{ width: 20, height: 20 }} />
                                    <Typography
                                      sx={{ fontWeight: 'bold', fontSize: 12 }}
                                    >
                                      {option?.firstName}
                                    </Typography>
                                    <IconButton
                                      sx={{
                                        border: '1px solid gray',
                                        width: 16,
                                        height: 16,
                                      }}
                                      onClick={() =>
                                        getTagProps({ index })?.onDelete(option)
                                      }
                                    >
                                      <Close
                                        sx={{
                                          width: 13,
                                          height: 13,
                                          color: 'gray',
                                        }}
                                      />
                                    </IconButton>
                                  </Box>
                                ))}
                              </Box>
                            )}
                            renderOption={(props, option) => (
                              <Box
                                component='li'
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 6,
                                  width: '100%',
                                }}
                                {...props}
                              >
                                <Avatar
                                  sx={{ width: 45, height: 45 }}
                                  src={option?.imageUrl}
                                />

                                <Typography sx={{ width: 200 }}>
                                  {option?.firstName +
                                    ' ' +
                                    option?.lastName +
                                    ' ' +
                                    option?.lastName}
                                </Typography>
                                <Box
                                  sx={{
                                    bgcolor: '#F4FAFF',
                                    px: 1,
                                    borderRadius: 5,
                                    py: 0.5,
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: '#4D72A6',
                                      textAlign: 'center',
                                      fontWeight: 'bold',
                                      fontSize: 11,
                                    }}
                                  >
                                    {option?.tags?.[0]?.tag}
                                  </Typography>
                                </Box>
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size='small'
                                fullWidth
                                sx={{ bgcolor: 'white' }}
                              />
                            )}
                          />
                        </Box>
                        <Box sx={{ my: 4 }}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                            Health Insurance
                          </Typography>
                          <Autocomplete
                            options={[]}
                            getOptionLabel={(item) => item}
                            size='small'
                            // onChange={(event, value) => {
                            //   setLanguage(value)
                            // }}
                            multiple
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size='small'
                                fullWidth
                                sx={{ bgcolor: 'white' }}
                                // onChange={(e) => setLanguage(e.target.value)}
                              />
                            )}
                          />
                        </Box>
                        <Box sx={{ my: 4 }}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                            Gender
                          </Typography>
                          <Autocomplete
                            options={['Male', 'Female']}
                            getOptionLabel={(item) => item}
                            size='small'
                            onChange={(event, value) => {
                              setGender(value as string)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                size='small'
                                fullWidth
                                sx={{ bgcolor: 'white' }}
                                // onChange={(e) => setLanguage(e.target.value)}
                              />
                            )}
                          />
                        </Box>
                        <Box sx={{ my: 4 }}>
                          <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                            Age
                          </Typography>

                          <Slider
                            getAriaLabel={() => 'Age range'}
                            value={age_range}
                            onChange={(
                              event: Event,
                              newValue: number | number[]
                            ) => {
                              setAgeRange(newValue as number[])
                            }}
                            valueLabelDisplay='auto'
                            getAriaValueText={valuetext}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box>
                  <Box>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Start Date
                    </Typography>
                    <DatePicker
                      slotProps={{
                        textField: {
                          size: 'small',
                          sx: { bgcolor: 'white' },
                          fullWidth: true,
                        },
                      }}
                    />
                  </Box>
                  <Box sx={{ my: 4 }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Programs
                    </Typography>
                    <Autocomplete
                      options={[
                        'Comprehensive Needs',
                        'Housing Needs',
                        'Medical Needs',
                        'Employment Needs',
                      ]}
                      getOptionLabel={(item) => item}
                      size='small'
                      // onChange={(event, value) => {
                      //   setLanguage(value)
                      // }}
                      multiple
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size='small'
                          fullWidth
                          sx={{ bgcolor: 'white' }}
                          // onChange={(e) => setLanguage(e.target.value)}
                        />
                      )}
                    />
                  </Box>
                  <Box sx={{ my: 4 }}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Health Insurance
                    </Typography>
                    <Autocomplete
                      options={['Medhin', 'Madot', 'Momona', 'Shekihna']}
                      getOptionLabel={(item) => item}
                      size='small'
                      // onChange={(event, value) => {
                      //   setLanguage(value)
                      // }}
                      multiple
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size='small'
                          fullWidth
                          sx={{ bgcolor: 'white' }}
                          // onChange={(e) => setLanguage(e.target.value)}
                        />
                      )}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                position: 'fixed',
                bottom: 0,
                width: {
                  xs: '100vw',
                  md: reportType === 'custom' ? 1200 : 600,
                },
                height: 80,
                py: 3,
                px: 5,
              }}
            >
              <Button
                variant='contained'
                onClick={handleSubmit}
                sx={{ px: 4, py: 2.5 }}
              >
                {isLoading ? (
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <CircularProgress sx={{ color: 'white' }} size={20} />
                    <Typography>submitting...</Typography>
                  </Box>
                ) : (
                  <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                    Generate Report
                  </Typography>
                )}
              </Button>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
