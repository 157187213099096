import {
  Add,
  ArrowDownward,
  Download,
  Edit,
  GppGood,
  KeyboardArrowDown,
  LocationOn,
  PeopleAlt,
  Print,
} from '@mui/icons-material'
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material'
import { ClockIcon } from '@mui/x-date-pickers'
import { MaterialReactTable } from 'material-react-table'
import moment from 'moment'
import { useState } from 'react'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}
export default function Billing() {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const columns = [
    {
      id: 'client',
      header: 'Client',
    },
    {
      id: 'type',
      header: 'Type',
    },
    {
      id: 'program',
      header: 'Program',
    },
    {
      id: 'program',
      header: 'Program',
    },
    {
      id: 'insurance',
      header: 'Insurance',
    },
    {
      id: 'clin',
      header: 'Clin',
    },
    {
      id: 'diagnosis',
      header: 'Diagnosis',
    },
  ]

  return (
    <Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: 'divider',
          px: 2,
          pt: 2,
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
        >
          <Tab
            label='Billing Summary'
            {...a11yProps(0)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
          <Tab
            label='Billing Invoices'
            {...a11yProps(1)}
            sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
          />
        </Tabs>
        <Button
          variant='contained'
          sx={{ borderRadius: 7, py: 1, my: 1 }}
          // onClick={() => setOpenNewIntake(true)}
          //   onClick={handleClick}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Add />
            <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
              Generate Billing Report
            </Typography>
          </Box>
        </Button>
      </Box>
      <Box sx={{ p: 3 }}>
        {/* <MaterialReactTable
          columns={columns}
          data={[]}
          renderToolbarInternalActions={() => <></>}
        /> */}
        <Box
          sx={{
            bgcolor: '#F4FAFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              px: 3,
              py: 2,
            }}
          >
            <Typography
              sx={{ color: '#163055', fontWeight: 'bold', fontSize: 14 }}
            >
              Patient Accounting
            </Typography>
            <Box sx={{ border: '2px solid #163055', borderRadius: '100%' }} />
            <Typography
              sx={{ color: '#163055', fontWeight: 'bold', fontSize: 14 }}
            >
              Insurance Claims
            </Typography>
            <Box sx={{ border: '2px solid #163055', borderRadius: '100%' }} />
            <Typography
              sx={{ color: '#163055', fontWeight: 'bold', fontSize: 14 }}
            >
              Insurance Payments
            </Typography>
          </Box>
          <IconButton sx={{}}>
            <KeyboardArrowDown sx={{ width: 40, height: 40 }} />
          </IconButton>
        </Box>
        <Box sx={{ my: 3 }}>
          <Grid container>
            <Grid item xs={12} sm={6} md={2.5}>
              <Box>
                <Typography
                  sx={{ color: '#627DA5', fontSize: 12, fontWeight: 'bold' }}
                >
                  Insurance Status
                </Typography>
                <Box
                  sx={{
                    border: '1px solid #2CD889',
                    px: 2,
                    py: 1,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 2,
                    width: 150,
                    borderRadius: 2,
                    my: 2,
                  }}
                >
                  <GppGood sx={{ color: '#2CD889' }} />
                  <Typography sx={{ fontWeight: 'bold', fontSize: 14 }}>
                    Verified
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              <Box>
                <Typography
                  sx={{ color: '#627DA5', fontSize: 12, fontWeight: 'bold' }}
                >
                  Eligibility Status
                </Typography>
                <Box
                  sx={{
                    border: '1px solid #EFF4F9',
                    // py: 1,
                    px: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    gap: 2,
                    width: 250,
                    borderRadius: 2,
                    my: 2,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 'bold',
                      fontSize: 14,
                      color: '#A1A1A1',
                      px: 2,
                      py: 1.5,
                    }}
                  >
                    Not Verified
                  </Typography>
                  <Button variant='contained' sx={{ height: 35 }}>
                    Check
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6} md={4.5}>
              <Box>
                <Typography
                  sx={{ color: '#627DA5', fontSize: 12, fontWeight: 'bold' }}
                >
                  Date Coverage
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                  <Box
                    sx={{
                      border: '1px solid #EFF4F9',
                      py: 1,
                      px: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 2,
                      width: 250,
                      borderRadius: 2,
                      my: 2,
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: 'bold',
                        fontSize: 14,
                        px: 2,
                        color: '#364C6C',
                      }}
                    >
                      {`${moment().format('ll')} - ${moment().format('ll')}`}
                    </Typography>
                  </Box>
                  <Box
                    sx={{ bgcolor: '#CDD4E8', px: 2, py: 1.5, borderRadius: 3 }}
                  >
                    <Typography
                      sx={{
                        color: '#354B6D',
                        fontWeight: 'bold',
                        fontSize: 14,
                      }}
                    >
                      Capitated
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} md={2.5}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 3,
                  height: '100%',
                }}
              >
                <Edit sx={{ color: 'primary.main' }} />
                <Download sx={{ color: 'primary.main' }} />
                <Print sx={{ color: 'primary.main' }} />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ bgcolor: '#F4FAFF', p: 4 }}>
          <Typography
            sx={{ color: '#3376D6', fontWeight: 'bold', fontSize: 17 }}
          >
            {`${moment().format('lll')} - ${moment().format('lll')}`}
          </Typography>
          <Typography sx={{ fontWeight: 'bold' }}>Diagnosis</Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 4 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ fontSize: 12, color: '#9FA1A2' }}>
                259.00
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                Homelessness
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ fontSize: 12, color: '#9FA1A2' }}>
                Service Code:
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                H0043
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ fontSize: 12, color: '#9FA1A2' }}>
                DOB:
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                1/1/2000
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography sx={{ fontSize: 12, color: '#9FA1A2' }}>
                Clinician
              </Typography>
              <Typography sx={{ fontSize: 12, fontWeight: 'bold' }}>
                Abebe
              </Typography>
            </Box>
          </Box>
          <Box sx={{ my: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box
              sx={{
                bgcolor: 'white',
                width: 150,
                py: 1,
                boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography sx={{ color: '#74A1E3', fontWeight: 'bold' }}>
                Mental Needs
              </Typography>
            </Box>
            <Box sx={{ border: '2px solid #163055', borderRadius: '100%' }} />
            <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
              Consultation Note
            </Typography>
          </Box>
          <Box sx={{ my: 2, display: 'flex', alignItems: 'center', gap: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <ClockIcon />
              <Typography sx={{ fontWeight: 'bold' }}>60 minutes</Typography>
            </Box>
            <Divider orientation='vertical' flexItem />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <PeopleAlt />
              <Typography sx={{ fontWeight: 'bold' }}>60 minutes</Typography>
            </Box>
            <Divider orientation='vertical' flexItem />
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <LocationOn />
              <Typography sx={{ fontWeight: 'bold' }}>60 minutes</Typography>
            </Box>
          </Box>
          <Box sx={{ my: 2, display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box
              sx={{
                bgcolor: 'white',
                width: 550,
                height: 170,
                py: 1,
                boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                borderRadius: 2,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            ></Box>
            <Box>
              <Button
                variant='contained'
                sx={{ borderRadius: 5, bgcolor: '#2CD889' }}
                fullWidth
              >
                Approve
              </Button>
              <Box
                sx={{ display: 'flex', alignItems: 'center', my: 1, gap: 2 }}
              >
                <Button
                  variant='contained'
                  color='warning'
                  sx={{ borderRadius: 5 }}
                >
                  <Typography sx={{ fontSize: 12 }}>correction</Typography>
                </Button>
                <Button
                  color='secondary'
                  variant='contained'
                  sx={{ borderRadius: 5 }}
                >
                  <Typography sx={{ fontSize: 12 }}>
                    mark non-billable
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
