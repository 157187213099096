import {
  Add,
  CalendarMonth,
  Close,
  Edit,
  Message,
  PeopleAlt,
  Print,
  Share,
} from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Modal,
  Popover,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import FileUpload from '../File/FileUpload'
import { useForm } from 'react-hook-form'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { authHeader } from '../../utils/auth'
import { BASE_URL } from '../../utils/config'
import { TimePicker } from '@mui/x-date-pickers'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import moment from 'moment'

export default function LedgerDetail({
  open,
  close,
  client_id,
  ledger_id,
}: {
  open: boolean
  close: any
  client_id: string
  ledger_id: string
}) {
  const { data: ledgerData } = useFetch(
    'ledgerdetail',
    `/public/clients/${client_id}/ledgers/${ledger_id}`
  )

  console.log({ client_id, ledger_id })

  console.log({ ledgerData })

  function GridItem({ title, value }: { title: string; value: string }) {
    return (
      <Grid container spacing={3} sx={{ my: 2 }}>
        <Grid item xs={4}>
          <Box>
            <Typography sx={{ fontWeight: 'bold' }}>{title}</Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box
            sx={{
              bgcolor: '#F4FAFF',
              px: 2,
              py: 1,
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            <Typography sx={{ fontWeight: 'bold' }}>{value}</Typography>
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 700 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>Ledger</Typography>
              <Box></Box>
            </Box>
            <Divider />
            <Box
              sx={{
                py: 1,
                height: 'calc(100vh - 70px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Box sx={{ py: 5, px: { xs: 5, sm: 10 } }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Box>
                    <Box
                      component='img'
                      src={ledgerData?.data?.documentUrl}
                      sx={{ maxHeight: 350, maxWidth: '98%' }}
                    />
                  </Box>
                </Box>
                <GridItem
                  title='TYPE'
                  value={ledgerData?.data?.clientLedgerType?.title}
                />
                <GridItem
                  title='AMOUNT'
                  value={`$${ledgerData?.data?.amount}`}
                />
                <GridItem
                  title='SOURCE'
                  value={ledgerData?.data?.clientLedgerSource?.title}
                />
                <GridItem
                  title='DATE'
                  value={moment(ledgerData?.data?.createdAt).format('lll')}
                />
              </Box>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
