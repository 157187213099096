import {
  Add,
  Delete,
  Done,
  Edit,
  ReportGmailerrorred,
  Search,
  Visibility,
} from '@mui/icons-material'
import {
  Box,
  Button,
  IconButton,
  Switch,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import {
  MRT_ColumnDef,
  MRT_ShowHideColumnsButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
  MaterialReactTable,
  MRT_ToggleFullScreenButton,
} from 'material-react-table'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import AddClient from '../components/Clients/AddClient'
import { useNavigate } from 'react-router-dom'
import { usePost } from '../hooks/usePost'
import axios from 'axios'

type Client = {
  name: string
  phone: string
  dob: string
  email: string
  intake: string
  program: string[]
  status: string
  createdAt: string
}

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Clients() {
  const [openAddClient, setOpenAddClient] = useState(false)
  const navigate = useNavigate()
  const data = [{ name: 'Kidus Ababa' }]
  const [refetchClient, setRefetchClient] = useState(false)
  const { data: clientData, mutate } = usePost('/public/clients')
  const [value, setValue] = useState(0)

  const [search, setSearch] = useState() as any

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }
  useEffect(() => {
    const datap: any = {
      filters: {
        filters: [
          {
            field: 'first_name',
            like: {
              value: search,
            },
          },
          {
            field: 'status',
            like: {
              value: value === 0 ? 'ACTIVE' : 'INACTIVE',
            },
          },
        ],
        pagination: {
          page: 1,
          per_page: 100,
        },
        order: [
          {
            field: 'created_at',
            order_type: 'DESC',
          },
        ],
      },
    }
    mutate(datap)
  }, [refetchClient, search, value])
  // useEffect(() => {
  //   const datap: any = {
  //     filters: {
  //       pagination: {
  //         page: 1,
  //         per_page: 100,
  //       },
  //       filters: [
  //         {
  //           field: 'status',
  //           equal: {
  //             value: {
  //               string_value: value === 0 ? 'ACTIVE' : 'INACTIVE',
  //             },
  //           },
  //         },
  //         {
  //           field: 'first_name',
  //           like: {
  //             value: search,
  //           },
  //         },
  //       ],
  //     },
  //   }
  //   mutate(datap)
  // }, [refetchClient, value, search])

  console.log({ data, clientData })

  const columns = useMemo<MRT_ColumnDef<any>[]>(
    () => [
      {
        accessorKey: 'actions' as any,
        header: 'Actions',
        enableSorting: false,
        flex: 1,
        // size: 200,
        enableColumnFilter: false,
        Cell: ({ row, table }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <IconButton onClick={() => navigate(row?.original?.id)}>
              <Visibility sx={{ color: 'primary.main' }} />
            </IconButton>
          </Box>
        ),
      },
      {
        accessorKey: 'firstName',
        header: 'First Name',
      },
      {
        accessorKey: 'lastName',
        header: 'Last Name',
      },
      {
        accessorKey: 'phone',
        header: 'Phone No',
      },
      {
        accessorFn: (originalRow) => moment(originalRow.dob).format('ll'),
        id: 'dob',
        header: 'Date of Birth',
        size: 180,
      },
      {
        accessorKey: 'email',
        header: 'Email',
      },
      {
        accessorFn: (originalRow) => (
          <Box
            sx={{
              bgcolor: '#DEF0FF',
              py: 0.6,
              px: 1,
              borderRadius: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography
              sx={{ fontWeight: 'bold', fontSize: 11, color: '#7E878E' }}
            >
              {originalRow.intakeName}
            </Typography>
          </Box>
        ),
        id: 'intakeName',
        header: 'Intake',
      },
      {
        accessorFn: (originalRow) => (
          <Box
            sx={{
              backgroundColor: '#F7F7F7',
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              minWidth: 100,
              borderRadius: 1,
            }}
          >
            {originalRow?.status === 'ACTIVE' ? (
              <Done sx={{ color: '#39A2FB' }} />
            ) : (
              <ReportGmailerrorred sx={{ color: '#777777' }} />
            )}
            <Typography
              color={originalRow?.status === 'ACTIVE' ? '#39A2FB' : '#777777'}
              sx={{ px: 1, fontSize: 12, fontWeight: 'bold' }}
            >
              {originalRow?.status}
            </Typography>
            <Switch
              checked={originalRow?.status === 'ACTIVE' ? true : false}
              sx={{
                color: originalRow?.status === 'ACTIVE' ? '#39A2FB' : '#777777',
              }}
            />
          </Box>
        ),
        id: 'status',
        header: 'Status',
      },
      {
        accessorFn: (originalRow) =>
          moment(originalRow.createdAt).format('lll'),
        id: 'createdAt',
        header: 'Created At',
        size: 220,
      },
    ],
    []
  )

  return (
    <Box sx={{ p: 3, height: 'calc(100vh - 64px)' }}>
      <Box sx={{ height: 300 }}>
        <MaterialReactTable
          columns={columns}
          data={clientData?.data?.clients || []}
          enableSorting={false}
          enableColumnActions={false}
          renderTopToolbarCustomActions={({ table }) => (
            <Box sx={{ display: 'flex', aalignItems: 'center', gap: 5, mt: 3 }}>
              <Box sx={{ py: 1 }}>
                <TextField
                  InputProps={{
                    startAdornment: <Search />,
                    sx: {
                      '&.MuiOutlinedInput-root': {
                        borderRadius: 8,
                        height: 45,
                        bgcolor: '#E6F4FF ',
                      },
                    },
                  }}
                  placeholder='Search'
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Box>
              <Tabs value={value} onChange={handleChange}>
                <Tab
                  label='Active'
                  {...a11yProps(0)}
                  sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                />
                <Tab
                  label={`New`}
                  {...a11yProps(1)}
                  sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                />
                <Tab
                  label='Discontinued'
                  {...a11yProps(2)}
                  sx={{ fontWeight: 'bold', fontSize: 12, mx: 2 }}
                />
              </Tabs>
            </Box>
          )}
          renderToolbarInternalActions={({ table }) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
                alignItems: 'center',
                gap: 1,
                height: 100,
              }}
            >
              <Button
                variant='contained'
                sx={{ borderRadius: 5, height: 50 }}
                onClick={() => setOpenAddClient(true)}
              >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Add />
                  <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                    New Client
                  </Typography>
                </Box>
              </Button>
              <MRT_ToggleFiltersButton table={table} />
              <MRT_ShowHideColumnsButton table={table} />
              <MRT_ToggleDensePaddingButton table={table} />
              <MRT_ToggleFullScreenButton table={table} />
            </Box>
          )}
          muiTableContainerProps={{
            sx: { maxHeight: 'calc(100vh - 278px)' },
          }}
        />
      </Box>

      <AddClient
        open={openAddClient}
        close={() => setOpenAddClient(false)}
        refetch={refetchClient}
        setRefetch={setRefetchClient}
      />
    </Box>
  )
}
