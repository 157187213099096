import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  LinearProgress,
  Popover,
  Typography,
} from '@mui/material'
import { Add, CalendarMonth, Flag } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import AddTask from '../Task/AddTask'
import AddGoal from '../Goal/AddGoal'
import GoalDetails from '../Goal/GoalDetails'
import { usePost } from '../../hooks/usePost'
import moment from 'moment'

export default function GoalsAndTasks() {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [refetchGoal, setRefetchGoal] = useState(false)
  const { data: goalData, mutate } = usePost('/public/goal/goals/list')

  console.log({ goalData })

  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    mutate(datap)
  }, [refetchGoal])

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const [openAddTask, setOpenAddTask] = useState(false)
  const [openAddGoal, setOpenAddGoal] = useState(false)
  const [openGoalDetail, setOpenGoalDetail] = useState(false)
  const [goalItem, setGoalItem] = useState() as any

  function GoalCard({ goal }: any) {
    return (
      <Box
        onClick={() => {
          setGoalItem(goal)
          setOpenGoalDetail(true)
        }}
        sx={{ cursor: 'pointer' }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'cener',
            px: 3,
            py: 2,
            justifyContent: 'space-between',
          }}
        >
          <Typography sx={{ color: '#889CB9' }}>
            <span style={{ fontWeight: 'bold', color: 'black' }}>
              {goal?.title}
            </span>{' '}
            ({goal?.tasks?.length} Tasks)
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <CalendarMonth sx={{ color: '#6F7070' }} />
            <Typography
              sx={{
                fontSize: 12,
                color: '#6F7070',
                fontWeight: 'bold',
              }}
            >
              {moment(goal?.dueDate)?.format('ll')}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            px: 3,
          }}
        >
          <LinearProgress
            variant='determinate'
            value={40}
            sx={{ height: 8, borderRadius: 3, bgcolor: '#F4FAFF' }}
          />
        </Box>
        <Box
          sx={{
            mx: 3,
            my: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            bgcolor: '#F4FAFF',
            p: 1.5,
            borderRadius: 10,
            border: '1px solid #E7F0F7',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
            }}
          >
            <Avatar
              sx={{ width: 30, height: 30, position: 'absolute' }}
            ></Avatar>
            <Box
              sx={{
                position: 'absolute',
                left: 23,
                p: 0.3,
                borderRadius: '100%',
                bgcolor: '#F4FAFF',
              }}
            >
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                }}
              ></Avatar>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                left: 46,
                p: 0.3,
                borderRadius: '100%',
                bgcolor: '#F4FAFF',
              }}
            >
              <Avatar
                sx={{
                  width: 30,
                  height: 30,
                }}
              ></Avatar>
            </Box>
          </Box>
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Flag sx={{ color: '#BF0C0C' }} />
            <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
              {goal?.priority} Priority
            </Typography>
          </Box>
        </Box>
        <Divider
          sx={{
            mt: 3,
            mx: 3,
          }}
        />
      </Box>
    )
  }

  return (
    <Grid item xs={12} sm={6} sx={{ height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 3,
          py: 2,
        }}
      >
        <Typography sx={{ color: '#0178DD', fontWeight: 'bold' }}>
          Goals & Tasks
        </Typography>
        <Button
          variant='outlined'
          sx={{ borderRadius: 5, bgcolor: '#E6F4FF' }}
          onClick={handleClick}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Add />
            <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
              Add
            </Typography>
          </Box>
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box sx={{ bgcolor: '#0178DD' }}>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => setOpenAddGoal(true)}
            >
              <Typography
                sx={{
                  px: 4,
                  py: 1,
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                Goal
              </Typography>
            </Box>
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => setOpenAddTask(true)}
            >
              <Typography
                sx={{
                  px: 4,
                  py: 1,
                  color: 'white',
                  fontWeight: 'bold',
                }}
              >
                Task
              </Typography>
            </Box>
          </Box>
        </Popover>
      </Box>
      <Divider sx={{ mx: 3 }} />
      <Box
        sx={{
          height: 'calc(46vh - 120px)',
          overflowY: 'auto',
          '&::-webkit-scrollbar': {
            width: 0,
          },
        }}
      >
        {goalData?.data?.goals?.map((item: any) => (
          <GoalCard goal={item} />
        ))}
        {/* <GoalCard />
        <GoalCard />
        <GoalCard />
        <GoalCard />
        <GoalCard />
        <GoalCard />
        <GoalCard /> */}
      </Box>
      <AddTask open={openAddTask} close={() => setOpenAddTask(false)} />
      <AddGoal
        open={openAddGoal}
        close={() => setOpenAddGoal(false)}
        refetch={refetchGoal}
        setRefetch={setRefetchGoal}
      />
      {goalItem && (
        <GoalDetails
          open={openGoalDetail}
          close={() => setOpenGoalDetail(false)}
          data={goalItem}
        />
      )}
    </Grid>
  )
}
