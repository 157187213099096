import {
  Add,
  ArrowDownward,
  Edit,
  ExpandMore,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ManageAccounts,
  Message,
  Search,
  Visibility,
} from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import AddDirectory from '../components/Directory/AddDirectory'
import { usePost } from '../hooks/usePost'
import { useFetch } from '../hooks/useFetch'
import { authHeader } from '../utils/auth'
import { BASE_URL } from '../utils/config'
import axios from 'axios'
import DirectoryDetails from '../components/Directory/DirectoryDetails'
import TagPeople from '../components/Directory/TagPeople'
import EditDirectory from '../components/Directory/EditDirectory'
import AddDirectoryCategory from '../components/Configurations/Directory/DirectoryCategory/AddCategory'

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function Directory() {
  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const [categoryId, setCategoryId] = useState() as any
  const [departmentId, setDepartmentId] = useState() as any
  const [directory, setDirectory] = useState() as any
  const [openTag, setOpenTag] = useState(false)
  const [directoryId, setDirectoryId] = useState() as any
  const [openEdit, setOpenEdit] = useState(false)

  const [openAddDirectory, setOpenAddDirectory] = useState(false)

  const { data: directoryCategories, refetch: refetchCategory } = useFetch(
    'directoryCat',
    '/public/directory/directory-categories'
  )
  const [departments, setDetartments] = useState() as any

  const { data: directories, mutate: fetchDirectories } = usePost(
    '/public/directory/directories/list'
  )

  const [openDetail, setOpenDetail] = useState(false)
  const [refetch, setRefetch] = useState(false)

  const [openCategory, setOpenCategory] = useState(false)

  useEffect(() => {
    if (categoryId) {
      axios
        .get(
          `${BASE_URL}/public/directory/directory-categories/${categoryId}/directory-departments`,
          { headers: authHeader() }
        )
        .then((res) => {
          setDetartments(res.data)
        })
        .catch((e: any) => {
          console.log(e)
        })
    }
  }, [categoryId])

  useEffect(() => {
    const dataP: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchDirectories(dataP)
  }, [refetch])

  console.log({ directories })

  const depDirectories = directories?.data?.directories?.filter((item: any) =>
    item?.departments?.some((elt: any) => elt?.id === departmentId)
  )

  const directoriesList = departmentId
    ? depDirectories
    : directories?.data?.directories

  console.log({ directoriesList })

  return (
    <Box sx={{ p: 3 }}>
      <Grid container>
        <Grid item xs={2.2}>
          <Box sx={{ p: 2 }}>
            <TextField
              InputProps={{
                startAdornment: <Search />,
                sx: {
                  '&.MuiOutlinedInput-root': {
                    borderRadius: 8,
                    height: 45,
                    bgcolor: '#E6F4FF',
                  },
                },
              }}
              placeholder='Search'
            />
          </Box>
          <Box
            sx={{
              pt: 2,
              px: 3,
            }}
          >
            <Box
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setCategoryId()
                setDepartmentId()
              }}
            >
              <Typography sx={{ fontWeight: 'bold', py: 1, fontSize: 15 }}>
                All
              </Typography>
            </Box>
            {directoryCategories?.categories?.map((item: any) => (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    py: 1,
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    if (categoryId === item?.id) {
                      setCategoryId()
                      setDepartmentId()
                    } else {
                      setCategoryId(item?.id)
                    }
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold', fontSize: 15 }}>
                    {item?.title}
                  </Typography>
                  {categoryId === item?.id ? (
                    <KeyboardArrowUp sx={{ width: 30, height: 30 }} />
                  ) : (
                    <KeyboardArrowDown sx={{ width: 30, height: 30 }} />
                  )}
                </Box>
                {categoryId === item?.id && (
                  <Box>
                    {departments?.departments?.map((elt: any) => (
                      <Box
                        sx={{
                          cursor: 'pointer',
                          '&:hover': { bgcolor: '#F7F7F7' },
                          py: 0.5,
                          px: 1,
                          bgcolor:
                            elt?.id === departmentId ? '#F7F7F7' : 'white',
                        }}
                        onClick={() => setDepartmentId(elt?.id)}
                      >
                        <Typography
                          sx={{
                            color: '#A0AABE',
                            fontSize: 12,
                            fontWeight: 'bold',
                          }}
                        >
                          {elt?.title}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
            ))}
            <Button onClick={() => setOpenCategory(true)}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <Box
                  sx={{
                    border: '2px solid blue',
                    borderRadius: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Add />
                </Box>
                <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                  Add Category
                </Typography>
              </Box>
            </Button>
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', py: 2 }}>
            <Button
              variant='contained'
              sx={{ borderRadius: 6, py: 1.5, px: 3 }}
              onClick={() => setOpenAddDirectory(true)}
            >
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Add />
                <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                  New Directory
                </Typography>
              </Box>
            </Button>
          </Box>
          <Divider sx={{ my: 3 }} />
          <Box sx={{ my: 2 }}>
            {directoriesList?.map((item: any) => (
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Avatar
                    sx={{ width: 70, height: 70 }}
                    src={item?.imageUrl}
                    alt={item?.firstName}
                  />
                  <Box sx={{ width: 150 }}>
                    <Typography sx={{ color: '#A0AABE', fontSize: 12 }}>
                      {item?.businessName}
                    </Typography>
                    <Typography
                      sx={{
                        color: '#344068',
                        fontSize: 13,
                        fontWeight: 'bold',
                      }}
                    >
                      {item?.firstName + ' ' + item?.lastName}
                    </Typography>
                    <Typography>{item?.phone}</Typography>
                  </Box>
                  <Box sx={{ width: 150 }}>
                    <Typography sx={{ fontSize: 12, color: '#A0AABE' }}>
                      {item?.categories?.[0]?.title}
                    </Typography>
                    <Typography
                      sx={{
                        color: '#344068',
                        fontSize: 15,
                        fontWeight: 'bold',
                      }}
                    >
                      {item?.departments?.[0]?.title}
                    </Typography>
                  </Box>
                  <Box sx={{ width: 150 }}>
                    {item?.tags?.map((elt: any) => (
                      <Box
                        sx={{
                          bgcolor: '#F4FAFF',
                          borderRadius: 2,
                          py: 0.5,
                          m: 1,
                          textAlign: 'center',
                        }}
                      >
                        <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                          {elt?.tag}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton
                      onClick={() => {
                        setDirectoryId(item?.id)
                        setOpenTag(true)
                      }}
                    >
                      <ManageAccounts sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setDirectory(item)
                        setOpenDetail(true)
                      }}
                    >
                      <Visibility sx={{ color: '#0178DD' }} />
                    </IconButton>
                    <IconButton
                      onClick={() => {
                        setDirectory(item)
                        setOpenEdit(true)
                      }}
                    >
                      <Edit sx={{ color: '#0178DD' }} />
                    </IconButton>
                    {/* <IconButton>
                      <Message sx={{ color: '#0178DD' }} />
                    </IconButton> */}
                  </Box>
                </Box>
                <Divider sx={{ my: 2 }} />
              </Box>
            ))}
          </Box>
        </Grid>
      </Grid>
      <AddDirectory
        open={openAddDirectory}
        close={() => setOpenAddDirectory(false)}
        refetch={refetch}
        setRefetch={setRefetch}
      />
      <AddDirectoryCategory
        open={openCategory}
        close={() => setOpenCategory(false)}
        refetch={refetchCategory}
      />
      <DirectoryDetails
        open={openDetail}
        close={() => setOpenDetail(false)}
        data={directory}
      />
      <TagPeople
        open={openTag}
        close={() => setOpenTag(false)}
        directory_id={directoryId}
        refetch={() => {}}
      />
      {directory && (
        <EditDirectory
          open={openEdit}
          close={() => setOpenEdit(false)}
          directoryData={directory}
          refetch={refetch}
          setRefetch={setRefetch}
        />
      )}
    </Box>
  )
}
