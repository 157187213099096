import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Modal,
  Slide,
  TextField,
  Typography,
} from '@mui/material'
import { useFetch } from '../../hooks/useFetch'
import { useEffect, useState } from 'react'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import { usePatch } from '../../hooks/usePatch'

export default function EditRole({
  open,
  close,
  data,
  refetch,
  setRefetch,
  isView,
}: {
  open: boolean
  close: any
  data: any
  refetch: boolean
  setRefetch: any
  isView: boolean
}) {
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>([])
  const [name, setName] = useState<string>()
  const { data: permissions } = useFetch(
    'permissions',
    '/public/access-control/permissions'
  )
  const { mutate, isLoading, isError, isSuccess, error } = usePatch(
    `/public/access-control/roles/${data?.id}`
  )

  console.log({ data, permissions })

  let permissionIds: string[] = []
  data?.permissions?.map((item: any) => {
    permissionIds.push(item?.id)
  })

  const handleSubmit = () => {
    const data: any = {
      name,
      permission_ids: selectedPermissions,
    }
    mutate(data)
  }

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Role Updated Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      setRefetch(!refetch)
      setSelectedPermissions([])
      close()
    }
    if (isError) {
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  useEffect(() => {
    setSelectedPermissions(permissionIds)
  }, [])

  console.log({ selectedPermissions })

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 900 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                {isView ? 'Role Details' : 'Edit Role'}
              </Typography>
              <Box> </Box>
            </Box>
            <Divider />
            <Box
              sx={{
                p: { xs: 5 },
                height: 'calc(100vh - 140px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Box>
                <Box>
                  <Typography sx={{ fontSize: 13, fontWeight: 'bold' }}>
                    Role Name
                  </Typography>
                  <TextField
                    size='small'
                    onChange={(e) => setName(e.target.value)}
                    defaultValue={data?.name}
                    disabled={isView}
                  />
                </Box>
              </Box>
              <Divider sx={{ my: 3 }} />
              <Box sx={{ py: 2 }}>
                {permissions?.permissionGroups?.map((item: any) => (
                  <Box>
                    <Typography
                      sx={{ color: '#9C9C9C', fontWeight: 'bold', my: 1 }}
                    >
                      {item?.name}
                    </Typography>
                    {item?.permissions?.map((permission: any) => (
                      <Chip
                        icon={
                          <FormControlLabel
                            name='permissions'
                            value={permission?.id}
                            label={
                              permission?.name?.split('.')?.[2] +
                              ' ' +
                              permission?.name?.split('.')?.[1]
                            }
                            onChange={(e) => {
                              if (
                                selectedPermissions?.includes(permission?.id)
                              ) {
                                setSelectedPermissions((state: any) => [
                                  ...state.filter(
                                    (permissionId: any) =>
                                      permissionId !== permission?.id
                                  ),
                                ])
                              } else {
                                setSelectedPermissions((state: any) => [
                                  ...state,
                                  permission?.id,
                                ])
                              }
                            }}
                            control={
                              <Checkbox
                                disabled={isView}
                                checked={selectedPermissions?.includes(
                                  permission?.id
                                )}
                              />
                            }
                          />
                        }
                        sx={{
                          m: 1,
                          bgcolor: '#F4FAFF',
                          border: '1px solid #F1F2F2',
                        }}
                      />
                    ))}
                  </Box>
                ))}
              </Box>
            </Box>
            {!isView && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  bottom: 0,
                  width: { xs: '100vw', sm: 900 },
                  height: 80,
                  py: 3,
                  px: { xs: 5, sm: 10 },
                }}
              >
                <Button
                  variant='contained'
                  onClick={handleSubmit}
                  sx={{ px: 4, py: 2.5 }}
                >
                  {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress sx={{ color: 'white' }} size={20} />
                      <Typography>submitting...</Typography>
                    </Box>
                  ) : (
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Edit Role
                    </Typography>
                  )}
                </Button>
              </Box>
            )}
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
