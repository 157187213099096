import {
  Add,
  CalendarMonth,
  Close,
  Edit,
  Message,
  PeopleAlt,
  Print,
  Share,
} from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Modal,
  Popover,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { usePost } from '../../hooks/usePost'
import { useEffect } from 'react'
import moment from 'moment'

export default function ActivityDetails({
  open,
  close,
  userId,
}: {
  open: boolean
  close: any
  userId: any
}) {
  console.log({ userId })

  const { data: activityData, mutate } = usePost(
    '/public/activity_log/activity_logs/user'
  )

  console.log({ activityData })

  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
        order: [
          {
            field: 'created_at',
            order_type: 2,
          },
        ],
      },
      user_id: userId,
    }
    mutate(datap)
  }, [userId])

  const checkAction = (action: any) => {
    let actionName = ''
    switch (action) {
      case 'POST':
        actionName = 'Created'
        break
      case 'PATCH':
        actionName = 'Updated'
        break
      case 'PUT':
        actionName = 'Updated'
        break
      case 'DELETE':
        actionName = 'Deleted'
        break
      case 'GET':
        actionName = 'Fetched'
        break
      default:
        break
    }
    return actionName
  }

  const checkSubject = (url: string) => {
    return (
      url?.split('/')?.[2]?.charAt(0).toUpperCase() +
      url?.split('/')?.[2]?.slice(1).toLowerCase()
    )
  }

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 500 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>Activity</Typography>
              <Box></Box>
            </Box>
            <Divider />
            <Box
              sx={{
                py: 1,
                height: 'calc(100vh - 70px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
                bgcolor: '#F4FAFF',
              }}
            >
              {activityData?.data?.activityLogs?.map((activity: any) => (
                <Box
                  sx={{
                    bgcolor: 'white',
                    my: 3,
                    mx: 5,
                    borderRadius: 2,
                    boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                    p: 2,
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold', fontSize: '13' }}>
                    {moment(activity?.createdAt).format('lll')}
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Box sx={{ display: 'flex', gap: 5 }}>
                    <Typography sx={{ color: '#58B1FC' }}>
                      {checkAction(activity?.action)} <br />{' '}
                      {checkSubject(activity?.url)}
                    </Typography>
                    <Box>
                      {activity?.requestObject &&
                        Object.keys(activity?.requestObject)?.map(
                          (item: any) => {
                            if (
                              item !== 'id' &&
                              !item?.includes('id') &&
                              !item?.includes('Id')
                            ) {
                              return (
                                <Box
                                  sx={{
                                    display: 'flex',
                                    gap: 1,
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 12,
                                      // fontWeight: 'bold',
                                      lineHeight: 2,
                                    }}
                                  >
                                    {item}:{' '}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: 13,
                                      fontWeight: 'bold',
                                      lineHeight: 2,
                                    }}
                                  >
                                    {JSON.stringify(
                                      activity?.requestObject[item]
                                    )}
                                  </Typography>
                                </Box>
                              )
                            }
                          }
                        )}
                    </Box>
                    {/* <Box>
                      <Typography sx={{ color: '#58B1FC' }}>
                        Abebaw Butako
                      </Typography>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: 'bold', lineHeight: 2 }}
                      >
                        Kidney Checkup
                      </Typography>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: 'bold', lineHeight: 2 }}
                      >
                        In person
                      </Typography>
                      <Typography
                        sx={{ fontSize: 12, fontWeight: 'bold', lineHeight: 2 }}
                      >
                        Dr. Solomon
                      </Typography>
                    </Box> */}
                  </Box>
                </Box>
              ))}
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
