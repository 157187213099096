import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Typography,
} from '@mui/material'
import { useEffect, useState } from 'react'
import FileUpload from '../File/FileUpload'
import { DatePicker } from '@mui/x-date-pickers'
import { Add, Close, Remove } from '@mui/icons-material'
import { useFieldArray, useForm } from 'react-hook-form'
import { usePost } from '../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import axios from 'axios'
import { BASE_URL } from '../../utils/config'
import { authHeader } from '../../utils/auth'
export default function AddGoal({
  open,
  close,
  refetch,
  setRefetch,
}: {
  open: boolean
  close: any
  refetch: boolean
  setRefetch: any
}) {
  const [priority, setPriority] = useState('HIGH')

  const [catId, setCatId] = useState() as any

  const [dueDate, setDueDate] = useState() as any
  const [file_url, setFileUrl] = useState<string>()
  const [fileState, setFileState] = useState<any>()
  const [url, setUrl] = useState() as any
  const [taskDueDates, setTaskDueDates] = useState<
    { index: number; value: any }[]
  >([])
  const [taskPriorities, setTaskPriorities] = useState<
    { index: number; value: any }[]
  >([])
  const [taskAssignees, setTaskAssignees] = useState<
    { index: number; value: any }[]
  >([])
  const [subTasks, setSubTasks] = useState<
    { index: number; value: string[] }[]
  >([{ index: 0, value: [''] }])
  console.log({ taskPriorities, taskAssignees, subTasks })

  type FormValues = {
    categoryId: string
    title: string
    dueDate: string
    goalDescription: string
    priority: string
    type: string
    file_url: string
    tasks: {
      title: string
      description: string
      dueDate: string
      task_participant_ids: string[]
      priority: string
      subtasks: string[]
    }[]
  }

  const {
    register,
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      categoryId: '',
      title: '',
      dueDate: '',
      goalDescription: '',
      priority: '',
      type: 'goal',
      file_url: '',
      tasks: [
        {
          title: '',
          description: '',
          dueDate: '',
          task_participant_ids: [],
          priority: '',
          subtasks: [''],
        },
      ],
    },
  })

  const { fields, append, remove } = useFieldArray({
    name: 'tasks',
    control,
  })

  const { data: clientData, mutate: fetchParticipants } =
    usePost('/public/clients')
  console.log({ clientData })
  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    fetchParticipants(datap)
  }, [])

  const { mutate, isLoading, isError, isSuccess, error } =
    usePost('/public/goal/goals')

  const { data: categoryData, mutate: getCategories } = usePost(
    '/public/goal/goal_categories/list'
  )

  console.log({ categoryData })

  useEffect(() => {
    const datap: any = {
      filters: {
        pagination: {
          page: 1,
          per_page: 100,
        },
      },
    }
    getCategories(datap)
  }, [])

  console.log({ catId })

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Goal Created Successfully', {
        variant: 'success',
        preventDuplicate: true,
      })
      setRefetch(!refetch)
      close()
    }
    if (isError) {
      console.log({ error })
      enqueueSnackbar(
        error?.response?.data?.message || error?.message || 'Network Error',
        { variant: 'error', preventDuplicate: true }
      )
    }
  }, [isSuccess, isError])

  const onSubmit = (data: any) => {
    data.dueDate = dueDate?.$d?.toISOString()?.replace('.000', '')
    data.categoryId = catId?.id
    data.priority = priority
    data.file_url = file_url
    taskDueDates?.map((item) => {
      data.tasks[item.index].dueDate = item?.value?.$d
        ?.toISOString()
        ?.replace('.000', '')
    })
    taskPriorities?.map((item) => {
      data.tasks[item.index].priority = item?.value
    })
    taskAssignees?.map((item) => {
      data.tasks[item.index].task_participant_ids = item?.value?.map(
        (elt: any) => elt?.id
      )
    })
    subTasks?.map((item) => {
      data.tasks[item.index].subtasks = item?.value
    })
    console.log('result', { data })
    mutate(data)
  }

  useEffect(() => {
    console.log({ fileState })
    if (fileState) {
      axios
        .get(
          `${BASE_URL}/public/document/presigned-url?fileKey=${fileState?.name}`,
          {
            headers: authHeader(),
          }
        )
        .then((res) => setUrl(res?.data?.url))
        .catch((e) => console.log({ e }))
    }
  }, [fileState])
  useEffect(() => {
    if (url) {
      const reader = new FileReader()
      reader.onload = handleReaderLoaded
      reader.readAsArrayBuffer(fileState)
    }
  }, [url])
  const handleReaderLoaded = (event: any) => {
    const binaryData = event.target.result
    console.log({ binaryData })

    axios
      .put(url, binaryData)
      .then((res) => setFileUrl(url))
      .catch((e) => console.log({ e }))
  }

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 1200 },
              height: '100vh',
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box
                sx={{
                  bgcolor: '#FBFBFB',
                  height: 60,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  px: 3,
                }}
              >
                <Box
                  sx={{
                    bgcolor: '#EDEDED',
                    px: 3,
                    py: 0.8,
                    cursor: 'pointer',
                    borderRadius: 2,
                  }}
                  onClick={close}
                >
                  Back
                </Box>
                <Typography sx={{ fontWeight: 'bold' }}>New Goal</Typography>
                <Box> </Box>
              </Box>
              <Divider />
              <Box
                sx={{
                  p: 6,
                  height: 'calc(100vh - 140px)',
                  bgcolor: '#F5FAFF',
                  overflowY: 'auto',
                  '&::-webkit-scrollbar': {
                    width: 0,
                  },
                }}
              >
                <Grid container spacing={3} sx={{ height: '100%' }}>
                  <Grid item xs={12} md={4.5} sx={{ height: '100%' }}>
                    <Box sx={{ height: '100%', px: 2 }}>
                      <Box>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                          Goal Title
                        </Typography>
                        <TextField
                          size='small'
                          fullWidth
                          {...register('title', {
                            required: true,
                          })}
                        />
                      </Box>
                      <Box sx={{ my: 2 }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                          Due Date
                        </Typography>
                        <DatePicker
                          value={dueDate}
                          onChange={(newValue) => setDueDate(newValue)}
                          sx={{ width: '100%' }}
                          slotProps={{ textField: { size: 'small' } }}
                        />
                      </Box>

                      <Box sx={{ my: 2 }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                          Goal Description
                        </Typography>
                        <textarea
                          style={{
                            borderRadius: 10,
                            borderColor: 'gray',
                            resize: 'none',
                            width: '100%',
                            height: 100,
                          }}
                          {...register('goalDescription', {
                            required: true,
                          })}
                        />
                      </Box>
                      <Box>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                          Priority
                        </Typography>
                        {/*
                        <TextField
                          size='small'
                          fullWidth
                          {...register('priority', {
                            required: true,
                          })}
                        />
                      </Box> */}
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: 1,
                          }}
                        >
                          <FormControl sx={{ display: 'flex' }}>
                            <RadioGroup
                              value={priority}
                              onChange={(e) => {
                                setPriority(e.target.value)
                              }}
                              row
                              name='priority'
                            >
                              <FormControlLabel
                                value='HIGH'
                                control={
                                  <Radio
                                    sx={{
                                      color:
                                        priority === 'HIGH'
                                          ? '#fff'
                                          : '#5E7FA4',
                                      '&.MuiButtonBase-root': {
                                        padding: '0px',
                                        marginRight: '5px',
                                        '&.Mui-checked': {
                                          color: '#fff',
                                        },
                                      },
                                    }}
                                    {...register('priority', {
                                      required: true,
                                    })}
                                  />
                                }
                                label='HIGH'
                                sx={{
                                  px: 3,
                                  py: 1,
                                  // marginRight: '0',
                                  // marginTop: '90px',
                                  background:
                                    priority === 'HIGH' ? '#0A81F5' : '#F7F7F7',
                                  color: priority === 'HIGH' ? '#fff' : '#000',
                                  borderRadius: '10px',
                                  // height: '35px',
                                }}
                              />
                              <FormControlLabel
                                value='MEDIUM'
                                control={
                                  <Radio
                                    sx={{
                                      color:
                                        priority === 'MEDIUM'
                                          ? '#fff'
                                          : '#5E7FA4',
                                      '&.MuiButtonBase-root': {
                                        padding: '0px',
                                        marginRight: '5px',
                                        '&.Mui-checked': {
                                          color: '#fff',
                                        },
                                      },
                                    }}
                                    {...register('priority', {
                                      required: true,
                                    })}
                                  />
                                }
                                label='MEDIUM'
                                sx={{
                                  px: 2,
                                  //   marginRight: '0',
                                  //   marginTop: '15px',
                                  background:
                                    priority === 'MEDIUM'
                                      ? '#0A81F5'
                                      : '#F7F7F7',
                                  color:
                                    priority === 'MEDIUM' ? '#fff' : '#000',
                                  borderRadius: '10px',
                                  //   height: '35px',
                                }}
                              />
                              <FormControlLabel
                                value='LOW'
                                control={
                                  <Radio
                                    sx={{
                                      color:
                                        priority === 'LOW' ? '#fff' : '#5E7FA4',
                                      '&.MuiButtonBase-root': {
                                        padding: '0px',
                                        marginRight: '5px',
                                        '&.Mui-checked': {
                                          color: '#fff',
                                        },
                                      },
                                    }}
                                    {...register('priority', {
                                      required: true,
                                    })}
                                  />
                                }
                                label='LOW'
                                sx={{
                                  px: 2,
                                  //   marginRight: '0',
                                  //   marginTop: '15px',
                                  background:
                                    priority === 'LOW' ? '#0A81F5' : '#F7F7F7',
                                  color: priority === 'LOW' ? '#fff' : '#000',
                                  borderRadius: '10px',
                                  //   height: '35px',
                                }}
                              />
                            </RadioGroup>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box sx={{ my: 2 }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                          Choose Category
                        </Typography>
                        <Autocomplete
                          options={categoryData?.data?.goalCategories || []}
                          getOptionLabel={(item: any) => item.name}
                          size='small'
                          onChange={(event, value) => {
                            setCatId(value)
                          }}
                          renderInput={(params) => (
                            <TextField {...params} size='small' fullWidth />
                          )}
                        />
                      </Box>
                      <Box sx={{ my: 2 }}>
                        <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                          Attatch File
                        </Typography>
                        {/* <textarea
                        style={{
                          borderRadius: 10,
                          borderColor: 'gray',
                          resize: 'none',
                          width: '100%',
                          height: 100,
                        }}
                      /> */}
                        <FileUpload
                          name='attatchedFile'
                          type='FILE'
                          size={100}
                          setFileState={setFileState}
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={7.5}>
                    <Card
                      sx={{
                        width: '100%',
                        bgcolor: 'white',
                        boxShadow: '4px 4px 10px 1px rgba(0,0,0,0.07)',
                        height: '100%',
                        borderRadius: 4,
                        p: 7,
                      }}
                    >
                      {fields?.map((field, index) => (
                        <Box>
                          <Typography
                            variant='h6'
                            sx={{ color: '#0178DD', fontWeight: 'bold' }}
                          >
                            TASK {index + 1}
                          </Typography>
                          <Box sx={{ my: 3 }}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={6}>
                                <Typography
                                  sx={{ fontWeight: 'bold', fontSize: 13 }}
                                >
                                  Task Title
                                </Typography>
                                <TextField
                                  size='small'
                                  fullWidth
                                  {...register(`tasks.${index}.title`, {
                                    required: true,
                                  })}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <Typography
                                  sx={{ fontWeight: 'bold', fontSize: 13 }}
                                >
                                  Due Date
                                </Typography>
                                <DatePicker
                                  // value={appDate}
                                  // onChange={(newValue) => setAppDate(newValue)}
                                  onChange={(newValue) => {
                                    if (
                                      taskDueDates?.some(
                                        (el) => el.index === index
                                      )
                                    ) {
                                      setTaskDueDates((state) => [
                                        ...state.filter(
                                          (val: any) => val.index !== index
                                        ),
                                        { index: index, value: newValue },
                                      ])
                                    } else {
                                      setTaskDueDates((state) => [
                                        ...state,
                                        { index: index, value: newValue },
                                      ])
                                    }
                                  }}
                                  sx={{ width: '100%' }}
                                  slotProps={{ textField: { size: 'small' } }}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Typography
                                  sx={{ fontWeight: 'bold', fontSize: 13 }}
                                >
                                  Assign
                                </Typography>
                                <Autocomplete
                                  options={clientData?.data?.clients || []}
                                  getOptionLabel={(item: any) =>
                                    item.firstName + ' ' + item?.lastName
                                  }
                                  size='small'
                                  multiple
                                  onChange={(event, value) => {
                                    if (
                                      taskAssignees?.some(
                                        (el) => el.index === index
                                      )
                                    ) {
                                      setTaskAssignees((state) => [
                                        ...state.filter(
                                          (val: any) => val.index !== index
                                        ),
                                        { index: index, value: value },
                                      ])
                                    } else {
                                      setTaskAssignees((state) => [
                                        ...state,
                                        { index: index, value: value },
                                      ])
                                    }
                                  }}
                                  renderTags={(value, getTagProps) => (
                                    <Box
                                      sx={{
                                        display: 'flex',
                                        gap: 1,
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                      }}
                                    >
                                      {value.map((option, index) => (
                                        <Box
                                          sx={{
                                            bgcolor: '#F4FAFF',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: 1,
                                            border: '1px solid #ECEDED',
                                            px: 1,
                                            py: 0.5,
                                            borderRadius: 1.5,
                                          }}
                                        >
                                          <Avatar
                                            sx={{ width: 20, height: 20 }}
                                          />
                                          <Typography
                                            sx={{
                                              fontWeight: 'bold',
                                              fontSize: 12,
                                            }}
                                          >
                                            {option?.firstName?.split(' ')[0]}
                                          </Typography>
                                          <IconButton
                                            sx={{
                                              border: '1px solid gray',
                                              width: 16,
                                              height: 16,
                                            }}
                                            onClick={() =>
                                              getTagProps({ index })?.onDelete(
                                                option
                                              )
                                            }
                                          >
                                            <Close
                                              sx={{
                                                width: 13,
                                                height: 13,
                                                color: 'gray',
                                              }}
                                            />
                                          </IconButton>
                                        </Box>
                                      ))}
                                    </Box>
                                  )}
                                  renderOption={(props, option) => (
                                    <Box
                                      component='li'
                                      sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 6,
                                        width: '100%',
                                      }}
                                      {...props}
                                    >
                                      <Avatar sx={{ width: 45, height: 45 }} />

                                      <Typography sx={{ width: 200 }}>
                                        {option?.firstName +
                                          ' ' +
                                          option?.lastName}
                                      </Typography>
                                      <Box
                                        sx={{
                                          bgcolor: '#F4FAFF',
                                          px: 1,
                                          borderRadius: 5,
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            color: '#4D72A6',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                            fontSize: 12,
                                          }}
                                        >
                                          Client
                                        </Typography>
                                      </Box>
                                    </Box>
                                  )}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size='small'
                                      fullWidth
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Typography
                                  sx={{ fontWeight: 'bold', fontSize: 13 }}
                                >
                                  Priority
                                </Typography>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    borderRadius: 1,
                                  }}
                                >
                                  <FormControl sx={{ display: 'flex' }}>
                                    <RadioGroup
                                      value={
                                        taskPriorities?.find(
                                          (item) => item?.index === index
                                        )?.value
                                      }
                                      onChange={(e) => {
                                        if (
                                          taskPriorities?.some(
                                            (el) => el.index === index
                                          )
                                        ) {
                                          setTaskPriorities((state) => [
                                            ...state.filter(
                                              (val: any) => val.index !== index
                                            ),
                                            {
                                              index: index,
                                              value: e.target.value,
                                            },
                                          ])
                                        } else {
                                          setTaskPriorities((state) => [
                                            ...state,
                                            {
                                              index: index,
                                              value: e.target.value,
                                            },
                                          ])
                                        }
                                      }}
                                      row
                                      name='taskpriority'
                                    >
                                      <FormControlLabel
                                        value='HIGH'
                                        control={
                                          <Radio
                                            sx={{
                                              color:
                                                taskPriorities?.find(
                                                  (item) =>
                                                    item?.index === index
                                                )?.value === 'HIGH'
                                                  ? '#fff'
                                                  : '#5E7FA4',
                                              '&.MuiButtonBase-root': {
                                                padding: '0px',
                                                marginRight: '5px',
                                                '&.Mui-checked': {
                                                  color: '#fff',
                                                },
                                              },
                                            }}
                                            {...register(
                                              `tasks.${index}.priority`,
                                              {
                                                required: true,
                                              }
                                            )}
                                          />
                                        }
                                        label='HIGH'
                                        sx={{
                                          px: 3,
                                          py: 1,
                                          // marginRight: '0',
                                          // marginTop: '90px',
                                          background:
                                            taskPriorities?.find(
                                              (item) => item?.index === index
                                            )?.value === 'HIGH'
                                              ? '#0A81F5'
                                              : '#F7F7F7',
                                          color:
                                            taskPriorities?.find(
                                              (item) => item?.index === index
                                            )?.value === 'HIGH'
                                              ? '#fff'
                                              : '#000',
                                          borderRadius: '10px',
                                          // height: '35px',
                                        }}
                                      />
                                      <FormControlLabel
                                        value='MEDIUM'
                                        control={
                                          <Radio
                                            sx={{
                                              color:
                                                taskPriorities?.find(
                                                  (item) =>
                                                    item?.index === index
                                                )?.value === 'MEDIUM'
                                                  ? '#fff'
                                                  : '#5E7FA4',
                                              '&.MuiButtonBase-root': {
                                                padding: '0px',
                                                marginRight: '5px',
                                                '&.Mui-checked': {
                                                  color: '#fff',
                                                },
                                              },
                                            }}
                                            {...register(
                                              `tasks.${index}.priority`,
                                              {
                                                required: true,
                                              }
                                            )}
                                          />
                                        }
                                        label='MEDIUM'
                                        sx={{
                                          px: 2,
                                          //   marginRight: '0',
                                          //   marginTop: '15px',
                                          background:
                                            taskPriorities?.find(
                                              (item) => item?.index === index
                                            )?.value === 'MEDIUM'
                                              ? '#0A81F5'
                                              : '#F7F7F7',
                                          color:
                                            taskPriorities?.find(
                                              (item) => item?.index === index
                                            )?.value === 'MEDIUM'
                                              ? '#fff'
                                              : '#000',
                                          borderRadius: '10px',
                                          //   height: '35px',
                                        }}
                                      />
                                      <FormControlLabel
                                        value='LOW'
                                        control={
                                          <Radio
                                            sx={{
                                              color:
                                                taskPriorities?.find(
                                                  (item) =>
                                                    item?.index === index
                                                )?.value === 'LOW'
                                                  ? '#fff'
                                                  : '#5E7FA4',
                                              '&.MuiButtonBase-root': {
                                                padding: '0px',
                                                marginRight: '5px',
                                                '&.Mui-checked': {
                                                  color: '#fff',
                                                },
                                              },
                                            }}
                                            {...register(
                                              `tasks.${index}.priority`,
                                              {
                                                required: true,
                                              }
                                            )}
                                          />
                                        }
                                        label='LOW'
                                        sx={{
                                          px: 2,
                                          //   marginRight: '0',
                                          //   marginTop: '15px',
                                          background:
                                            taskPriorities?.find(
                                              (item) => item?.index === index
                                            )?.value === 'LOW'
                                              ? '#0A81F5'
                                              : '#F7F7F7',
                                          color:
                                            taskPriorities?.find(
                                              (item) => item?.index === index
                                            )?.value === 'LOW'
                                              ? '#fff'
                                              : '#000',
                                          borderRadius: '10px',
                                          //   height: '35px',
                                        }}
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Box>
                              </Grid>
                              <Grid item xs={12} sm={12}>
                                <Typography
                                  sx={{ fontWeight: 'bold', fontSize: 13 }}
                                >
                                  Task Description
                                </Typography>
                                <textarea
                                  style={{
                                    borderRadius: 10,
                                    borderColor: 'gray',
                                    resize: 'none',
                                    width: '100%',
                                    height: 100,
                                  }}
                                  {...register(`tasks.${index}.description`, {
                                    required: true,
                                  })}
                                />
                              </Grid>
                            </Grid>
                            {subTasks?.[index]?.value?.map((item, index2) => (
                              // ))}
                              // {subCount?.map((item, index) => (
                              <Box sx={{ py: 2 }}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: 1,
                                    width: '100%',
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: 11,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    Sub
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: 11,
                                      fontWeight: 'bold',
                                    }}
                                  >
                                    {' '}
                                    Task
                                  </Typography>
                                  <TextField
                                    size='small'
                                    fullWidth
                                    InputProps={{
                                      sx: {
                                        '&.MuiOutlinedInput-root': {
                                          borderRadius: 8,
                                        },
                                      },
                                    }}
                                    value={subTasks?.[index]?.value?.[index2]}
                                    onChange={(e) => {
                                      setSubTasks((prevValues) => {
                                        const newValues = [...prevValues]
                                        newValues[index].value[index2] =
                                          e.target.value
                                        return newValues
                                      })
                                    }}
                                  />
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      gap: 2,
                                    }}
                                  >
                                    {subTasks?.[index]?.value?.length > 1 && (
                                      <IconButton
                                        sx={{
                                          bgcolor: 'error.main',
                                          '&:hover': {
                                            color: 'white',
                                            bgcolor: 'error.main',
                                          },
                                        }}
                                        onClick={() =>
                                          setSubTasks((prevValues) => {
                                            const newValues = [...prevValues]
                                            newValues[index].value = newValues[
                                              index
                                            ].value?.filter(
                                              (elt, ind) => ind !== index2
                                            )
                                            console.log({ newValues })
                                            return newValues
                                          })
                                        }
                                      >
                                        <Remove sx={{ color: 'white' }} />
                                      </IconButton>
                                    )}
                                    {index2 ===
                                      subTasks?.[index]?.value?.length - 1 && (
                                      <IconButton
                                        sx={{
                                          bgcolor: 'primary.main',
                                          '&:hover': {
                                            color: 'white',
                                            bgcolor: 'primary.main',
                                          },
                                        }}
                                        onClick={() =>
                                          setSubTasks((prevValues) => {
                                            const newValues = [...prevValues]
                                            newValues[index].value.push('')
                                            return newValues
                                          })
                                        }
                                      >
                                        <Add sx={{ color: 'white' }} />
                                      </IconButton>
                                    )}
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                          </Box>
                          <Divider />
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: 2,
                              mt: 3,
                            }}
                          >
                            {fields?.length > 1 && (
                              <Button
                                variant='outlined'
                                sx={{ borderColor: 'error.light', my: 2 }}
                                onClick={() => {
                                  setSubTasks((prev) => {
                                    const newData = prev?.filter(
                                      (elt, inde) => inde !== index
                                    )
                                    return newData
                                  })
                                  remove(index)
                                }}
                              >
                                <Box
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <Remove sx={{ color: 'red' }} />
                                  <Typography
                                    sx={{
                                      fontSize: 11,
                                      fontWeight: 'bold',
                                      color: 'red',
                                    }}
                                  >
                                    Remove Task
                                  </Typography>
                                </Box>
                              </Button>
                            )}
                            {index === fields?.length - 1 && (
                              <Button
                                variant='outlined'
                                sx={{ bgcolor: '#E6F4FF', my: 2 }}
                                onClick={() => {
                                  setSubTasks((prev) => {
                                    const newData = [...prev]
                                    newData?.push({
                                      index: index + 1,
                                      value: [''],
                                    })
                                    return newData
                                  })
                                  append({
                                    title: '',
                                    description: '',
                                    dueDate: '',
                                    task_participant_ids: [],
                                    priority: '',
                                    subtasks: [''],
                                  })
                                }}
                              >
                                <Box
                                  sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                  <Add />
                                  <Typography
                                    sx={{ fontSize: 11, fontWeight: 'bold' }}
                                  >
                                    Add Task
                                  </Typography>
                                </Box>
                              </Button>
                            )}
                          </Box>
                        </Box>
                      ))}
                    </Card>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  position: 'fixed',
                  bottom: 0,
                  width: { xs: '100vw', md: 1200 },
                  height: 80,
                  py: 3,
                  px: 5,
                }}
              >
                <Button
                  variant='contained'
                  type='submit'
                  // onClick={handleSubmit}
                  sx={{ px: 4, py: 2.5 }}
                >
                  {isLoading ? (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CircularProgress sx={{ color: 'white' }} size={20} />
                      <Typography>submitting...</Typography>
                    </Box>
                  ) : (
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Create Goal
                    </Typography>
                  )}
                </Button>
              </Box>
            </form>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
