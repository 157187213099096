import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material'
import ehr from '../assets/Frame.png'
import { useEffect, useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import FileUpload from '../components/File/FileUpload'
import { usePost } from '../hooks/usePost'
import { useSnackbar } from 'notistack'
import { validate } from '../utils/validation/validate'
import { CompanySchema } from '../utils/validation/schemas/CompanyRegistrationSchema'

export default function CompanyRegistration() {
  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [showRepeatPassword, setShowRepeatPassword] = useState(false)
  const [first_name, setFirstName] = useState<string>()
  const [middle_name, setMiddleName] = useState<string>()
  const [last_name, setLastName] = useState<string>()
  const [email, setEmail] = useState<string>()
  const [phone_number, setPhone] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [repeat_password, setRepeatPassword] = useState<string>()
  const [company_name, setCompanyName] = useState<string>()
  const [company_industry, setCompanyIndustry] = useState<string>()
  const [imageState, setImageState] = useState<any>()
  const [image_url, setImageUrl] = useState<any>()
  const [validation, setValidation] = useState<{
    success: boolean
    validated: any
    fields: any
    fieldErrors: any
  }>()

  const { mutate, isLoading, isError, error, isSuccess } = usePost(
    '/public/user/companies/register'
  )

  const handleCreate = () => {
    const data: any = {
      first_name,
      middle_name,
      last_name,
      email,
      phone_number,
      password,
      repeat_password,
      company_name,
      company_industry,
      image_url,
    }

    const {
      success,
      data: validated,
      fields,
      fieldErrors,
    } = validate(data, CompanySchema)
    console.log({ success, validated, fields, fieldErrors })
    setValidation({ success, validated, fields, fieldErrors })
    if (success) {
      mutate(data)
    }
  }

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (isError) {
      console.log({ error })
      enqueueSnackbar(error.response.data.message, {
        preventDuplicate: true,
        variant: 'error',
      })
    }
    if (isSuccess) {
      enqueueSnackbar('Company Registered Successfully', {
        preventDuplicate: true,
        variant: 'success',
      })
    }
  }, [isSuccess, isError])

  return (
    <Box
      sx={{
        bgcolor: '#FBFBFB',
        width: '100vw',
        height: '100vh',
      }}
    >
      <Grid
        container
        sx={{
          display: { xs: 'none', md: 'flex' },
          height: '100%',
        }}
      >
        <Grid
          item
          xs={0}
          sm={0}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                width: '90%',
                height: '90%',
                borderRadius: 10,
                bgcolor: '#F7F7F7',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box component='img' src={ehr} sx={{ width: '80%' }} />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
        >
          <Box
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: { xs: '90%', sm: '70%', md: '80%' },
                height: '100%',
                justifyContent: 'center',
                // py: 20,
              }}
            >
              <Box sx={{ my: 3 }}>
                <Typography variant='h4' sx={{ fontWeight: 'bold' }}>
                  Company Registration
                </Typography>
                <Typography>Fill in the details below to continue</Typography>
              </Box>
              <Box>
                <Box sx={{ my: 2 }}>
                  <FileUpload
                    name='image'
                    type='PROFILE_IMAGE'
                    setFileState={setImageState}
                  />
                </Box>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      First Name
                    </Typography>
                    <TextField
                      size='small'
                      fullWidth
                      onChange={(e) => setFirstName(e.target.value)}
                      error={
                        validation?.fieldErrors?.['first_name'] ? true : false
                      }
                      helperText={validation?.fieldErrors?.['first_name']?.[0]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Last Name
                    </Typography>
                    <TextField
                      size='small'
                      fullWidth
                      onChange={(e) => setLastName(e.target.value)}
                      error={
                        validation?.fieldErrors?.['last_name'] ? true : false
                      }
                      helperText={validation?.fieldErrors?.['last_name']?.[0]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Email
                    </Typography>
                    <TextField
                      size='small'
                      fullWidth
                      onChange={(e) => setEmail(e.target.value)}
                      error={validation?.fieldErrors?.['email'] ? true : false}
                      helperText={validation?.fieldErrors?.['email']?.[0]}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Phone No
                    </Typography>
                    <TextField
                      size='small'
                      fullWidth
                      onChange={(e) => setPhone(e.target.value)}
                      error={
                        validation?.fieldErrors?.['phone_number'] ? true : false
                      }
                      helperText={
                        validation?.fieldErrors?.['phone_number']?.[0]
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{ fontSize: 13, fontWeight: 'bold', mb: 1 }}
                    >
                      Password
                    </Typography>
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      size='small'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder='Password'
                      fullWidth
                      error={
                        validation?.fieldErrors?.['password'] ||
                        (repeat_password && password !== repeat_password)
                          ? true
                          : false
                      }
                      helperText={
                        validation?.fieldErrors?.['password']?.[0] ||
                        (repeat_password &&
                          password !== repeat_password &&
                          'Confirmation Password does not match with password')
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography
                      sx={{ fontSize: 13, fontWeight: 'bold', mb: 1 }}
                    >
                      Confirm Password
                    </Typography>
                    <TextField
                      type={showPassword ? 'text' : 'password'}
                      size='small'
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='end'>
                            <IconButton
                              aria-label='toggle password visibility'
                              onClick={() =>
                                setShowRepeatPassword(!showPassword)
                              }
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => setRepeatPassword(e.target.value)}
                      placeholder='Password'
                      fullWidth
                      error={
                        validation?.fieldErrors?.['repeat_password'] ||
                        (repeat_password && password !== repeat_password)
                          ? true
                          : false
                      }
                      helperText={
                        validation?.fieldErrors?.['repeat_password']?.[0] ||
                        (repeat_password &&
                          password !== repeat_password &&
                          'Confirmation Password does not match with password')
                      }
                    />
                  </Grid>
                </Grid>
                <Divider sx={{ my: 3 }} />
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Company Name
                    </Typography>
                    <TextField
                      size='small'
                      fullWidth
                      onChange={(e) => setCompanyName(e.target.value)}
                      error={
                        validation?.fieldErrors?.['company_name'] ? true : false
                      }
                      helperText={
                        validation?.fieldErrors?.['company_name']?.[0]
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Typography sx={{ fontWeight: 'bold', fontSize: 13 }}>
                      Company Industry
                    </Typography>
                    <TextField
                      size='small'
                      fullWidth
                      onChange={(e) => setCompanyIndustry(e.target.value)}
                      error={
                        validation?.fieldErrors?.['company_industry']
                          ? true
                          : false
                      }
                      helperText={
                        validation?.fieldErrors?.['company_industry']?.[0]
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                  my: 2,
                }}
              >
                <Button
                  variant='contained'
                  sx={{ color: 'white', py: 1, width: 300 }}
                  onClick={handleCreate}
                >
                  <Typography>Create Account</Typography>
                </Button>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button sx={{ my: 1 }} onClick={() => navigate('/login')}>
                    <Typography sx={{ fontWeight: 'bold' }}>Login</Typography>
                  </Button>
                </Box>
              </Box>
              <Box>
                <Typography
                  variant='body2'
                  sx={{
                    textAlign: 'center',
                    fontSize: 12,
                    color: 'gray',
                    px: 3,
                  }}
                >
                  By clicking “Log In”, on behalf of myself and the practice, I
                  agree to the GoodSeed Terms of Service and Privacy Policy.
                </Typography>
              </Box>

              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  position: 'absolute',
                  bottom: 20,
                  width: 400,
                  alignSelf: 'center',
                }}
              >
                <Typography sx={{ fontSize: 12 }}>
                  @{new Date().getFullYear()} GoodSeedCdc
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'cenetr',
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{ fontWeight: 'bold', color: '#1F7DEC', fontSize: 12 }}
                  >
                    Privacy
                  </Typography>
                  <Divider flexItem orientation='vertical' />
                  <Typography
                    sx={{ fontWeight: 'bold', color: '#1F7DEC', fontSize: 12 }}
                  >
                    Terms
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
