import {
  Add,
  CalendarMonth,
  Close,
  Edit,
  Message,
  PeopleAlt,
  Print,
  Share,
} from '@mui/icons-material'
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Popover,
  Radio,
  RadioGroup,
  Slide,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import FileUpload from '../../File/FileUpload'
import { useForm } from 'react-hook-form'
import { useFetch } from '../../../hooks/useFetch'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { authHeader } from '../../../utils/auth'
import { BASE_URL } from '../../../utils/config'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { usePost } from '../../../hooks/usePost'
import { enqueueSnackbar } from 'notistack'
import moment from 'moment'

export default function ProgramDetails({
  open,
  close,
  programId,
}: {
  open: boolean
  close: any
  programId: any
}) {
  console.log({ programId })

  const { data, refetch } = useFetch(
    'fetchprogram',
    `/public/configs/programs/${programId}`
  )
  console.log({ data })

  useEffect(() => {
    refetch()
  }, [programId])

  return (
    <Modal open={open} onClose={close} closeAfterTransition>
      <Slide in={open} direction='left'>
        <Box sx={{ position: 'relative', float: 'right' }}>
          <Card
            sx={{
              width: { xs: '100vw', md: 900 },
              height: '100vh',
            }}
          >
            <Box
              sx={{
                bgcolor: '#FBFBFB',
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 3,
              }}
            >
              <Box
                sx={{
                  bgcolor: '#EDEDED',
                  px: 3,
                  py: 0.8,
                  cursor: 'pointer',
                  borderRadius: 2,
                }}
                onClick={close}
              >
                Back
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>
                Program Details
              </Typography>
              <Box></Box>
            </Box>
            <Divider />
            <Box
              sx={{
                py: 1,
                height: 'calc(100vh - 70px)',
                overflowY: 'auto',
                '&::-webkit-scrollbar': {
                  width: 0,
                },
              }}
            >
              <Box sx={{ p: 5 }}>
                <Typography sx={{ fontWeight: 'bold', fontSize: 18 }}>
                  {data?.program?.title}
                </Typography>
                <Box sx={{ py: 3 }}>
                  <Grid container spacing={3}>
                    {data?.program?.programFields?.map((field: any) => (
                      <Grid item xs={6}>
                        <Box>
                          {field?.type === 'Text' ? (
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                  py: 0.5,
                                }}
                              >
                                {field?.name}
                              </Typography>
                              <TextField size='small' fullWidth />
                            </Box>
                          ) : field?.type === 'Dropdown' ? (
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                  py: 0.5,
                                }}
                              >
                                {field?.name}
                              </Typography>
                              <Autocomplete
                                options={field?.values || []}
                                getOptionLabel={(item: any) => item}
                                size='small'
                                // onChange={(event, value) => {
                                //   setLanguage(value)
                                // }}

                                fullWidth
                                sx={{ width: '100%' }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    size='small'
                                    fullWidth
                                    sx={{ bgcolor: 'white' }}
                                  />
                                )}
                              />
                            </Box>
                          ) : field?.type === 'Date' ? (
                            <Box>
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                  py: 0.5,
                                }}
                              >
                                {field?.name}
                              </Typography>
                              <DatePicker
                                //   value={appDate}
                                //   onChange={(newValue) => setAppDate(newValue)}
                                sx={{ width: '100%' }}
                                slotProps={{ textField: { size: 'small' } }}
                              />
                            </Box>
                          ) : field?.type === 'Checkbox' ? (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 3,
                              }}
                            >
                              <Typography
                                sx={{
                                  fontWeight: 'bold',
                                  fontSize: 12,
                                  py: 0.5,
                                }}
                              >
                                {field?.name}
                              </Typography>
                              {field?.values?.map((elt: any) => (
                                <FormControlLabel
                                  name={elt}
                                  label={elt}
                                  control={
                                    <Checkbox
                                    //   {...register(`program_fields.${index2}.required`)}
                                    />
                                  }
                                />
                              ))}
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 3,
                              }}
                            >
                              <Typography
                                sx={{ fontWeight: 'bold', fontSize: 18 }}
                              >
                                {data?.program?.title}
                              </Typography>
                              <RadioGroup
                                row
                                aria-labelledby='demo-row-radio-buttons-group-label'
                                name='row-radio-buttons-group'
                              >
                                {field?.values?.map((elt: any) => (
                                  <FormControlLabel
                                    value='yes'
                                    control={
                                      <Radio
                                      //   {...register('is_veteran', {
                                      //     required: true,
                                      //   })}
                                      />
                                    }
                                    label='Yes'
                                  />
                                ))}
                              </RadioGroup>
                            </Box>
                          )}
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Card>
        </Box>
      </Slide>
    </Modal>
  )
}
